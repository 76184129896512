import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { storeAnalyticsAndReportsConfig } from '../analytics/storeAnalyticsAndReportsConfig';
import ReportTableListView from '../analytics/ReportTableListView';
import ReportView from '../analytics/ReportView';

const StoreReportsView = () => {
  const { state: authState } = useContext(AuthContext);
  const { state, generateStoreReport } = useContext(StoreContext);

  return (
    <Routes>
      <Route
        element={
          <ReportTableListView
            baseUrl="/merchant"
            reports={storeAnalyticsAndReportsConfig.reports}
          />
        }
        path="/"
      />
      <Route
        element={
          <ReportView
            entityId={authState.pkEcomStore}
            onGenerateReport={(data, onSuccess, onError) =>
              generateStoreReport(
                authState.pkEcomStore,
                data,
                onSuccess,
                onError
              )
            }
            reportConfigs={storeAnalyticsAndReportsConfig.reports}
            reportResult={state.ecomStoreReport.value}
            reportViewType="store"
            urlBase="/merchant"
          />
        }
        path="/:uuid"
      />
      <Route element={<Navigate replace to="/merchant/reports" />} path="*" />
    </Routes>
  );
};

export default StoreReportsView;
