import React, { useContext, useRef } from 'react';
import HeaderView from '../../common/HeaderView';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { storeAnalyticsAndReportsConfig } from '../analytics/storeAnalyticsAndReportsConfig';
import AnalyticsOverviewView from '../analytics/AnalyticsOverviewView';
import { ECOM_REPORT_ENUM } from '../analytics/reportsConfig';

const StoreAnalyticsView = () => {
  const hasFetched = useRef(false);
  const { state: authState } = useContext(AuthContext);
  const { state, fetchStoreAnalytics, generateStoreReport } = useContext(
    StoreContext
  );
  const reportConfig = storeAnalyticsAndReportsConfig.reports.find(
    (c) => c.uuid === ECOM_REPORT_ENUM.OVERVIEW
  );

  const loading =
    !hasFetched.current ||
    state.ecomStoreAnalytics.loading ||
    state.ecomStoreReport.loading ||
    reportConfig.uuid !== state.ecomStoreReport.value?.uuid;

  const onFetchAnalyticsOverview = (filter) => {
    const groupByPeriod = reportConfig.groupBy === 'period';
    const compareEnabled = groupByPeriod && reportConfig.compareEnabled;
    generateStoreReport(authState.pkEcomStore, {
      uuid: reportConfig.uuid,
      headers: reportConfig.defaultHeaders,
      groupBy: reportConfig.groupBy,
      ...filter,
      periodInterval: groupByPeriod ? filter.periodInterval : null,
      compareStartDate: compareEnabled ? filter.compareStartDate : null,
      compareEndDate: compareEnabled ? filter.compareEndDate : null
    });

    fetchStoreAnalytics(authState.pkEcomStore, filter);
    hasFetched.current = true;
  };

  return (
    <HeaderView noHeader>
      <AnalyticsOverviewView
        analyticConfigs={storeAnalyticsAndReportsConfig.analytics}
        dataState={state.ecomStoreAnalytics.value}
        entityId={authState.pkEcomStore}
        loading={loading}
        onFetchOverview={onFetchAnalyticsOverview}
        reportResult={state.ecomStoreReport.value}
        urlBase="/merchant/reports"
      />
    </HeaderView>
  );
};

export default StoreAnalyticsView;
