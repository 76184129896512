/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mantine/core';
import { ShoppingCart } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import { useCartController } from '../../../../../helpers/webstoreCheckoutHelper';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';
import WsCheckoutCartSummary from './WsCheckoutCartSummary';
import WsCheckoutCartProductCard from './WsCheckoutCartProductCard';
import AppCard from '../../../../common/AppCard';
import { triggerNotification } from '../../../../../helpers/notification';
import WsUnavailableProductsAlert from './WsUnavailableProductsAlert';

const WsCheckoutCart = ({
  baseStoreUrl,
  blocks,
  config,
  themeConfig,
  ecomStoreTheme,
  colorSchemes,
  mediaQueries,
  sort,
  onNavigate,
  pathname,
  hasFetchedCart,
  ...rest
}) => {
  const {
    ecomStoreCart,
    isGuest,
    isCartFetching,
    checkoutStoreCart,
    removeStoreProducts,
    updateStoreCartProducts,
    removeUnavailableStoreCartProducts,
    onShowCartProductCustomization,
    getGuestCartStorage
  } = useCartController();
  const { state } = useContext(StoreUserContext);
  const [checkoutState, setCheckoutState] = useState({
    email: '',
    fkEcomStoreRosterPlayer: null,
    loading: false,
    loadingCheckout: false
  });
  const { isTabletOrSmaller } = mediaQueries;
  const loading = !hasFetchedCart || isCartFetching || !ecomStoreCart;

  useEffect(() => {
    if (ecomStoreCart) {
      const guestStorageCart = getGuestCartStorage(ecomStoreCart.fkEcomStore);
      setCheckoutState({
        loading: false,
        loadingCheckout: false,
        email: guestStorageCart?.email || '',
        fkEcomStoreRosterPlayer: ecomStoreCart.fkEcomStoreRosterPlayer?.toString()
      });
    }
  }, [ecomStoreCart]);

  const createCheckoutSession = () => {
    setCheckoutState({
      ...checkoutState,
      loadingCheckout: true
    });
    checkoutStoreCart(
      ecomStoreTheme.ecomStore.pkEcomStore,
      {
        email: checkoutState.email,
        fkEcomStoreRosterPlayer: checkoutState.fkEcomStoreRosterPlayer
      },
      ({ sessionUrl }) => {
        window.location.href = sessionUrl;
      },
      (error) => {
        triggerNotification(error);
        setCheckoutState({
          ...checkoutState,
          loadingCheckout: false
        });
      }
    );
  };

  const onRemoveCartProducts = (ecomStoreCartProducts = []) => {
    setCheckoutState({
      ...checkoutState,
      loading: true
    });
    removeStoreProducts(
      ecomStoreTheme.ecomStore.pkEcomStore,
      ecomStoreCartProducts,
      () => {
        setCheckoutState({
          ...checkoutState,
          loading: false
        });
      },
      (error) => {
        triggerNotification(error);
        setCheckoutState({
          ...checkoutState,
          loading: false
        });
      }
    );
  };

  const onUpdateProducts = (ecomStoreCartProducts) => {
    setCheckoutState({
      ...checkoutState,
      loading: true
    });
    updateStoreCartProducts(
      ecomStoreTheme.ecomStore.pkEcomStore,
      { ecomStoreCartProducts },
      () => {
        setCheckoutState({
          ...checkoutState,
          loading: false
        });
      },
      (error) => {
        triggerNotification(error);
        setCheckoutState({
          ...checkoutState,
          loading: false
        });
      }
    );
  };

  const onRemoveUnavailableProducts = () => {
    setCheckoutState({
      ...checkoutState,
      loading: true
    });
    removeUnavailableStoreCartProducts(
      ecomStoreTheme.ecomStore.pkEcomStore,
      () => {
        setCheckoutState({
          ...checkoutState,
          loading: false
        });
        triggerNotification('Cart updated!', 'success');
      },
      (error) => {
        triggerNotification(error);
        setCheckoutState({
          ...checkoutState,
          loading: false
        });
      }
    );
  };

  return (
    <AppStack style={{ flex: 1, gap: 16, margin: '16px 0px' }}>
      <AppFlexbox style={{ padding: isTabletOrSmaller ? '0px 12px' : 0 }}>
        <AppText style={{ fontSize: 32, fontWeight: 700 }}>
          Shopping cart
        </AppText>
      </AppFlexbox>
      {!loading &&
        ecomStoreCart?.latestVersionEcomStoreCartProducts &&
        ecomStoreCart?.latestVersionEcomStoreCartProducts.length > 0 && (
          <WsUnavailableProductsAlert
            baseStoreUrl={baseStoreUrl}
            ecomStoreCartProducts={ecomStoreCart.ecomStoreCartProducts}
            latestVersionEcomStoreCartProducts={
              ecomStoreCart.latestVersionEcomStoreCartProducts
            }
            mediaQueries={mediaQueries}
            onNavigate={onNavigate}
            onRemoveUnavailableProducts={onRemoveUnavailableProducts}
            submitting={checkoutState.loading || checkoutState.loadingCheckout}
          />
        )}
      <AppFlexbox
        style={{
          alignItems: isTabletOrSmaller ? 'stretch' : 'start',
          gap: isTabletOrSmaller ? 12 : 32,
          flexDirection: isTabletOrSmaller ? 'column' : 'row',
          flexWrap: 'nowrap'
        }}
      >
        {loading ? (
          <AppStack style={{ flex: 2, gap: 16 }}>
            {Array.from(
              Array(ecomStoreCart?.checkoutCart.lineItems.length || 3)
            ).map((x, i) => (
              <WsCheckoutCartProductCard
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                baseStoreUrl={baseStoreUrl}
                loading
                mediaQueries={mediaQueries}
                onNavigate={onNavigate}
              />
            ))}
          </AppStack>
        ) : ecomStoreCart.checkoutCart.lineItems.length === 0 ? (
          <AppFlexbox style={{ flex: 2 }}>
            <AppCard
              radius={isTabletOrSmaller ? 0 : 'md'}
              shadow="lg"
              style={{ flex: 1, padding: 32 }}
              withBorder
            >
              <AppFlexbox
                style={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <ShoppingCart color="#000" size={80} />
                <AppStack style={{ gap: 5 }}>
                  <AppText style={{ fontSize: 20, fontWeight: 500 }}>
                    Your shopping cart is empty.
                  </AppText>
                  <Button
                    color="dark"
                    component={Link}
                    onClick={onNavigate}
                    style={{ marginTop: 5 }}
                    to={`${baseStoreUrl}/products`}
                    variant="outline"
                  >
                    Start shopping
                  </Button>
                </AppStack>
              </AppFlexbox>
            </AppCard>
          </AppFlexbox>
        ) : (
          <AppStack style={{ flex: 2, gap: 16 }}>
            {ecomStoreCart.checkoutCart.lineItems.map((lineItem) => {
              const product = ecomStoreCart.ecomStoreCartProducts.find(
                (p) => p.pkEcomStoreCartProduct === lineItem.entityId
              );
              return (
                <WsCheckoutCartProductCard
                  key={lineItem.entityId}
                  baseStoreUrl={baseStoreUrl}
                  ecomStoreCartProduct={product}
                  latestVersionEcomStoreCartProduct={ecomStoreCart.latestVersionEcomStoreCartProducts?.find(
                    (p) =>
                      p.ecomVendorProductVariant.uuid ===
                      product.ecomStoreProductVariant.ecomVendorProductVariant
                        .uuid
                  )}
                  lineItem={lineItem}
                  mediaQueries={mediaQueries}
                  onCustomize={() => {
                    onShowCartProductCustomization(product);
                  }}
                  onNavigate={onNavigate}
                  onRemove={onRemoveCartProducts}
                  onRemoveUnavailableProducts={onRemoveUnavailableProducts}
                  onUpdate={onUpdateProducts}
                  submitting={
                    checkoutState.loading || checkoutState.loadingCheckout
                  }
                />
              );
            })}
          </AppStack>
        )}

        <AppStack style={{ flex: 1 }}>
          <WsCheckoutCartSummary
            checkoutCart={ecomStoreCart?.checkoutCart}
            disabled={!!ecomStoreCart?.latestVersionEcomStoreCartProducts}
            ecomStoreRosterPlayers={state.ecomStoreRosterPlayers.value}
            guestEmail={checkoutState.email}
            isGuest={isGuest}
            loading={loading || checkoutState.loading}
            loadingCheckout={checkoutState.loadingCheckout}
            mediaQueries={mediaQueries}
            onCheckout={createCheckoutSession}
            onEmailChange={(email) =>
              setCheckoutState({ ...checkoutState, email })
            }
            onSelectRosterPlayer={(fkEcomStoreRosterPlayer) =>
              setCheckoutState({
                ...checkoutState,
                fkEcomStoreRosterPlayer
              })
            }
            selectedPkEcomStoreRosterPlayer={
              checkoutState.fkEcomStoreRosterPlayer
            }
          />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

WsCheckoutCart.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  hasFetchedCart: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string,
  sort: PropTypes.number,
  themeConfig: PropTypes.object
};

export default WsCheckoutCart;
