import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  Image,
  Button,
  Anchor,
  HoverCard,
  Grid,
  Skeleton
} from '@mantine/core';
import { ArrowLeft } from 'tabler-icons-react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { VIEW_ACTIONS_ENUM } from '../../../config/constants';
import VendorCatalogProductSearchList from './VendorCatalogProductSearchList';
import VendorCatalogSearchFilter from './VendorCatalogSearchFilter';
import { formatUtcDate, sterilizeUrlHandle } from '../../../helpers/format';
import {
  getVendorBranding,
  useProductSearchFilter
} from '../../../helpers/catalogHelper';
import AppImage from '../../common/AppImage';
import * as classes from '../../../styles/animations.module.css';
import ConfirmVendorProductsModal from './ConfirmVendorProductsModal';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';
import { LOCATION_LIST_OPTIONS } from '../../../config/locationConstants';
import VendorCatalogResourceUnavailable from './VendorCatalogResourceUnavailable';

const VendorCatalogVendorView = ({ vendorPreview, baseUrl }) => {
  const { search } = useLocation();
  const fromLocation = new URLSearchParams(search).get('from');
  const fromResource = new URLSearchParams(search).get('fromResource');
  const fromQuery = new URLSearchParams(search).get('query');
  const hasFetchedCollections = useRef(false);
  const { ecomVendor, collectionName } = useParams();
  const { hasSearched } = useProductSearchFilter();
  const { state: authState } = useContext(AuthContext);
  const { state: helperState } = useContext(HelperContext);
  const { state, fetchStoreCatalogVendorCollections } = useContext(
    StoreContext
  );
  const {
    isLargeMobileOrSmaller,
    isTabletOrSmaller,
    isLaptopOrSmaller,
    isLargeDesktopOrSmaller
  } = useMediaQueryIndex();
  const [hideCollections, setHideCollections] = useState(false);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const vendor = state.catalogVendors.value.find(
    (v) => v.urlHandle === ecomVendor
  );
  const branding = getVendorBranding(vendor);
  const vendorProvinces = vendor?.ecomVendorProvinces
    .map((p) =>
      LOCATION_LIST_OPTIONS.find((f) => f.value.toString() === p.toString())
    )
    .filter((p) => p)
    .sort((a, b) => a.label.localeCompare(b.label));

  const vendorCollections = state.catalogVendorCollections.value.filter(
    (c) => c.fkEcomVendor === vendor?.pkEcomVendor
  );
  const selectedCollection = vendorCollections.find(
    (c) => sterilizeUrlHandle(c.name) === collectionName
  );
  const isDemoMode =
    authState.userData.isAdmin && helperState.vendorCatalog.isDemoMode;
  const isWrongViewMode = vendor && vendor.isDemoVendor !== isDemoMode;
  const showNotFound = state.catalogVendors.value.length > 0 && !vendor;

  const collectionsLoading =
    vendor?.ecomVendorCollectionCount > 0 &&
    ((!hasFetchedCollections.current && vendorCollections.length === 0) ||
      state.catalogVendorCollections.loading);
  const viewLoading = !vendor || collectionsLoading;

  useEffect(() => {
    if (
      !isWrongViewMode &&
      vendor &&
      vendor.ecomVendorCollectionCount > 0 &&
      vendorCollections.length === 0
    ) {
      fetchStoreCatalogVendorCollections(
        {
          pkEcomStore: vendorPreview ? null : authState.pkEcomStore,
          pkEcomVendor: vendorPreview ? authState.pkEcomVendor : null
        },
        { fkEcomVendor: vendor.pkEcomVendor }
      );
      hasFetchedCollections.current = true;
    }
  }, [vendor, isWrongViewMode]);

  useEffect(() => {
    setHideCollections(hasSearched);
  }, [hasSearched]);

  const getBackPath = () => {
    switch (fromLocation?.toLowerCase()) {
      case 'vendor-product':
        return `${baseUrl}/products/${ecomVendor}/${fromResource}${
          fromQuery ? `?query=${encodeURIComponent(fromQuery ?? '')}` : ''
        }`;
      case 'products':
        return `${baseUrl}/products/${fromResource}${
          fromQuery
            ? `?${new URLSearchParams(
                decodeURIComponent(fromQuery)
              ).toString()}`
            : ''
        }`;
      case 'categories':
        return `${baseUrl}/categories/${fromResource}${
          fromQuery
            ? `?${new URLSearchParams(
                decodeURIComponent(fromQuery)
              ).toString()}`
            : ''
        }`;
      default: {
        return `${baseUrl}${
          fromQuery
            ? `?${new URLSearchParams(
                decodeURIComponent(fromQuery)
              ).toString()}`
            : ''
        }`;
      }
    }
  };

  return isWrongViewMode || showNotFound ? (
    <AppStack
      style={{
        flex: 1,
        width: '100%',
        maxWidth: 1600,
        margin: isLargeDesktopOrSmaller ? 0 : '16px 0px 70px 0px',
        gap: 0,
        alignSelf: 'center'
      }}
    >
      <AppFlexbox style={{ padding: '16px 0px' }}>
        <Button
          color="dark"
          component={Link}
          leftSection={<ArrowLeft size={18} />}
          to={getBackPath()}
        >
          Back to search
        </Button>
      </AppFlexbox>
      <VendorCatalogResourceUnavailable notFound={showNotFound} />
    </AppStack>
  ) : !viewLoading ? (
    <AppStack
      style={{
        flex: 1,
        width: '100%',
        maxWidth: 1600,
        margin: isLargeDesktopOrSmaller ? 0 : '16px 0px 70px 0px',
        gap: 0,
        alignSelf: 'center'
      }}
    >
      <AppStack style={{ position: 'relative' }} visibleFrom="sm">
        <AppStack style={{ opacity: 0, height: 100 }} />
        <AppCard
          radius="md"
          shadow="xl"
          style={{
            background: `linear-gradient(135deg, ${
              branding.secondaryColor ?? '#2980b9'
            } 0%, ${branding.primaryColor ?? '#6dd5fa'} 100%)`,
            borderRadius: 0,
            color: '#FFF',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            maxHeight: 250,
            width: '100%',
            position: 'absolute',
            padding: 0
          }}
        >
          <AppStack
            style={{
              width: '100%',
              height: '100%',
              position: 'relative'
            }}
          >
            {vendor.bannerImageUrl && (
              <Image
                fit="fill"
                src={vendor.bannerImageUrl}
                style={{ width: '100%', height: '100%' }}
              />
            )}
          </AppStack>
          <AppStack
            style={{ position: 'absolute', left: 0, top: 0, padding: 32 }}
          >
            <Button
              color="dark"
              component={Link}
              leftSection={<ArrowLeft size={18} />}
              to={getBackPath()}
            >
              Back to search
            </Button>
          </AppStack>
        </AppCard>
        <AppFlexbox style={{ gap: 10, alignItems: 'end' }}>
          <AppCard
            radius="md"
            shadow="xl"
            style={{
              maxHeight: 182,
              flex: 1,
              margin: 16,
              backgroundColor: '#FFF',
              color: '#000',
              overflow: 'hidden'
            }}
            withBorder
          >
            <AppFlexbox
              style={{
                gap: 16,
                overflow: 'hidden'
              }}
            >
              <AppStack style={{ width: 150, height: 150 }}>
                <Image
                  fit="contain"
                  h="100%"
                  src={vendor.logoImageUrl}
                  w="100%"
                />
              </AppStack>
              <AppFlexbox style={{ gap: 40, flex: 1 }}>
                <AppStack style={{ gap: 0, alignItems: 'start' }}>
                  <AppStack style={{ gap: 0, flex: 1 }}>
                    <AppText style={{ fontSize: 20, fontWeight: 700 }}>
                      {vendor.name}
                    </AppText>
                    {vendor.createdAt && (
                      <AppText style={{ fontSize: 14 }}>
                        Joined{' '}
                        {dayjs(formatUtcDate(vendor.createdAt)).format(
                          'MMMM D, YYYY'
                        )}
                      </AppText>
                    )}

                    <AppFlexbox style={{ gap: 5, flexWrap: 'nowrap' }}>
                      <AppText style={{ fontSize: 14 }}>Website:</AppText>
                      {vendor.domain && (
                        <Anchor
                          component={Link}
                          style={{
                            fontSize: 14,
                            color: 'dodgerblue'
                          }}
                          target="_blank"
                          to={vendor.domain}
                          underline="always"
                        >
                          {vendor.domain ?? 'N/A'}
                        </Anchor>
                      )}
                    </AppFlexbox>
                    {vendorProvinces.length > 0 && (
                      <AppText style={{ fontSize: 14 }}>
                        Ships to {vendorProvinces[0].label}
                        {vendorProvinces.length > 1 ? (
                          <>
                            {' '}
                            and{' '}
                            <HoverCard shadow="md">
                              <HoverCard.Target>
                                <Anchor
                                  style={{
                                    fontSize: 14,
                                    color: 'dodgerblue'
                                  }}
                                  underline="always"
                                >
                                  {vendorProvinces.length - 1} more
                                </Anchor>
                              </HoverCard.Target>
                              <HoverCard.Dropdown>
                                <AppStack style={{ gap: 2 }}>
                                  {vendorProvinces.slice(1).map((p) => (
                                    <AppText
                                      key={p.value}
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500
                                      }}
                                    >
                                      {p.label}
                                    </AppText>
                                  ))}
                                </AppStack>
                              </HoverCard.Dropdown>
                            </HoverCard>
                          </>
                        ) : (
                          ''
                        )}
                      </AppText>
                    )}
                  </AppStack>
                  <AppText style={{ fontSize: 14 }}>
                    Support:{' '}
                    <Anchor
                      href={`mailto:${vendor.supportEmail}`}
                      style={{ fontSize: 14, color: 'dodgerblue' }}
                      underline="always"
                    >
                      {vendor.supportEmail}
                    </Anchor>
                  </AppText>
                </AppStack>
                <AppStack
                  style={{
                    gap: 0,
                    flex: 1,
                    alignItems: 'start',
                    overflow: 'hidden'
                  }}
                  visibleFrom="xlg"
                >
                  <AppText style={{ fontSize: 12 }}>
                    {vendor.description}
                  </AppText>
                </AppStack>
              </AppFlexbox>
            </AppFlexbox>
          </AppCard>
          <AppFlexbox
            style={{
              flex: 1,
              maxWidth: 600,
              padding: 16,
              alignItems: 'end',
              justifyContent: 'end'
            }}
          >
            <AppFlexbox style={{ gap: 8, paddingBottom: 16 }}>
              <Button
                color="dark"
                component={Link}
                leftSection={<ArrowLeft size={18} />}
                to={getBackPath()}
                variant="outline"
              >
                Back to search
              </Button>
              <Button
                color="blue"
                onClick={() => {
                  if (!vendorPreview) {
                    onOpenModal(VIEW_ACTIONS_ENUM.CREATE);
                  }
                }}
                style={{ cursor: vendorPreview ? 'not-allowed' : 'pointer' }}
              >
                Use all products{' '}
                {isLaptopOrSmaller
                  ? ''
                  : `from ${selectedCollection?.name ?? vendor.name}`}
              </Button>
            </AppFlexbox>
          </AppFlexbox>
        </AppFlexbox>
      </AppStack>

      <AppStack hiddenFrom="sm" style={{ gap: 0 }}>
        <AppCard
          radius="md"
          shadow="xl"
          style={{
            background: `linear-gradient(135deg, ${
              branding.secondaryColor ?? '#2980b9'
            } 0%, ${branding.primaryColor ?? '#6dd5fa'} 100%)`,
            borderRadius: 0,
            color: '#FFF',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            alignItems: 'center',
            justifyContent: 'center',
            maxHeight: 200,
            width: '100%',
            padding: 0
          }}
        >
          <AppStack
            style={{
              width: '100%',
              height: '100%',
              position: 'relative'
            }}
          >
            {vendor.bannerImageUrl && (
              <Image
                fit="fill"
                src={vendor.bannerImageUrl}
                style={{ width: '100%', height: '100%' }}
              />
            )}
          </AppStack>
        </AppCard>

        <AppCard
          radius={0}
          shadow="xl"
          style={{
            backgroundColor: '#FFF',
            color: '#000',
            overflow: 'hidden',
            maxHeight: 180
          }}
        >
          <AppFlexbox
            style={{
              gap: 16,
              overflow: 'hidden'
            }}
          >
            <AppStack style={{ minWidth: 80, maxWidth: 125 }}>
              <Image
                fit="contain"
                h="100%"
                src={vendor.logoImageUrl}
                w="100%"
              />
            </AppStack>
            <AppFlexbox style={{ gap: 40, flex: 1 }}>
              <AppStack style={{ gap: 0, alignItems: 'start' }}>
                <AppStack style={{ gap: 0, flex: 1 }}>
                  <AppText style={{ fontSize: 20, fontWeight: 700 }}>
                    {vendor.name}
                  </AppText>
                  {vendor.createdAt && (
                    <AppText style={{ fontSize: 14 }}>
                      Joined{' '}
                      {dayjs(formatUtcDate(vendor.createdAt)).format(
                        'MMMM D, YYYY'
                      )}
                    </AppText>
                  )}

                  <AppFlexbox style={{ gap: 5, flexWrap: 'nowrap' }}>
                    <AppText style={{ fontSize: 14 }}>Website:</AppText>
                    {vendor.domain && (
                      <Anchor
                        component={Link}
                        style={{
                          fontSize: 14,
                          color: 'dodgerblue'
                        }}
                        target="_blank"
                        to={vendor.domain}
                        underline="always"
                      >
                        {vendor.domain ?? 'N/A'}
                      </Anchor>
                    )}
                  </AppFlexbox>

                  {vendorProvinces.length > 0 && (
                    <AppText style={{ fontSize: 14 }}>
                      Ships to {vendorProvinces[0].label}
                      {vendorProvinces.length > 1 ? (
                        <>
                          {' '}
                          and{' '}
                          <HoverCard shadow="md">
                            <HoverCard.Target>
                              <Anchor
                                style={{ fontSize: 14, color: 'dodgerblue' }}
                                underline="always"
                              >
                                {vendorProvinces.length - 1} more
                              </Anchor>
                            </HoverCard.Target>
                            <HoverCard.Dropdown>
                              <AppStack style={{ gap: 2 }}>
                                {vendorProvinces.slice(1).map((p) => (
                                  <AppText
                                    key={p.value}
                                    style={{ fontSize: 14, fontWeight: 500 }}
                                  >
                                    {p.label}
                                  </AppText>
                                ))}
                              </AppStack>
                            </HoverCard.Dropdown>
                          </HoverCard>
                        </>
                      ) : (
                        ''
                      )}
                    </AppText>
                  )}
                </AppStack>
                <AppText style={{ fontSize: 14 }}>
                  Support:{' '}
                  <Anchor
                    href={`mailto:${vendor.supportEmail}`}
                    style={{ fontSize: 14, color: 'dodgerblue' }}
                    underline="always"
                  >
                    {vendor.supportEmail}
                  </Anchor>
                </AppText>
              </AppStack>
            </AppFlexbox>
          </AppFlexbox>
        </AppCard>
        <AppFlexbox style={{ padding: 16, paddingBottom: 0 }}>
          <AppFlexbox style={{ gap: 8, flex: 1 }}>
            <Button
              color="dark"
              component={Link}
              leftSection={<ArrowLeft size={18} />}
              style={{ flex: isLargeMobileOrSmaller ? 1 : 'unset' }}
              to={getBackPath()}
              variant="outline"
            >
              Back to search
            </Button>
            <Button
              color="blue"
              onClick={() => {
                if (!vendorPreview) {
                  onOpenModal(VIEW_ACTIONS_ENUM.CREATE);
                }
              }}
              style={{
                cursor: vendorPreview ? 'not-allowed' : 'pointer',
                flex: isLargeMobileOrSmaller ? 1 : 'unset'
              }}
            >
              Use all products{' '}
              {isLargeMobileOrSmaller
                ? ''
                : `from ${selectedCollection?.name ?? vendor.name}`}
            </Button>
          </AppFlexbox>
        </AppFlexbox>
      </AppStack>

      <AppStack
        style={{
          gap: 16,
          padding: isTabletOrSmaller ? 16 : '0px 16px 16px 16px'
        }}
      >
        <VendorCatalogSearchFilter fkEcomVendor={vendor.pkEcomVendor} />

        {!selectedCollection &&
          !hideCollections &&
          vendorCollections.length > 0 && (
            <AppStack style={{ gap: 5 }}>
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <AppText style={{ fontSize: 24, fontWeight: 700 }}>
                  Collections
                </AppText>
              </AppFlexbox>
              <Grid columns={5}>
                {vendorCollections.map((c) => (
                  <Grid.Col key={c.pkEcomVendorCollection} span={1}>
                    <AppCard
                      className={classes['grow-container']}
                      component={Link}
                      radius="md"
                      shadow="xl"
                      style={{
                        flex: 1,
                        cursor: 'pointer',
                        borderRadius: 10,
                        padding: 16,
                        height: '100%'
                      }}
                      to={`${baseUrl}/vendors/${ecomVendor}/collections/${sterilizeUrlHandle(
                        c.name
                      )}${search}`}
                    >
                      <AppStack style={{ flex: 1, gap: 5 }}>
                        <AppStack style={{ flex: 1, height: 250 }}>
                          <Grid columns={c.previewImages.length === 1 ? 1 : 2}>
                            {c.previewImages.map((img, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <Grid.Col key={index} span={1}>
                                <AppStack
                                  style={{
                                    width: '100%',
                                    height:
                                      c.previewImages.length > 2 ? 100 : 200
                                  }}
                                >
                                  <AppImage
                                    fit="contain"
                                    height="100%"
                                    src={img}
                                    width="100%"
                                  />
                                </AppStack>
                              </Grid.Col>
                            ))}
                          </Grid>
                        </AppStack>

                        <AppText
                          style={{
                            fontSize: 18,
                            fontWeight: 700,
                            textAlign: 'center'
                          }}
                        >
                          {c.name}
                        </AppText>
                      </AppStack>
                    </AppCard>
                  </Grid.Col>
                ))}
              </Grid>
            </AppStack>
          )}

        <VendorCatalogProductSearchList
          baseUrl={baseUrl}
          fkEcomVendor={vendor.pkEcomVendor}
          fkEcomVendorCollection={selectedCollection?.pkEcomVendorCollection}
          title={selectedCollection ? selectedCollection.name : 'All products'}
          titleBackLink={
            selectedCollection ? `${baseUrl}/vendors/${ecomVendor}` : null
          }
          vendorPreview={vendorPreview}
        />
      </AppStack>

      <ConfirmVendorProductsModal
        ecomVendor={vendor}
        ecomVendorCollection={selectedCollection}
        isOpen={modalState.isOpen}
        onClose={onCloseModal}
      />
    </AppStack>
  ) : (
    <AppStack
      style={{
        flex: 1,
        width: '100%',
        maxWidth: 1600,
        margin: isLargeDesktopOrSmaller ? 0 : '16px 0px 70px 0px',
        gap: 16,
        alignSelf: 'center'
      }}
    >
      <AppStack style={{ position: 'relative' }} visibleFrom="sm">
        <AppStack style={{ opacity: 0, height: 100 }} />
        <AppCard
          radius="md"
          shadow="xl"
          style={{
            borderRadius: 0,
            color: '#FFF',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            alignItems: 'center',
            justifyContent: 'center',
            height: 250,
            width: '100%',
            position: 'absolute',
            padding: 0
          }}
        >
          <AppStack
            style={{ width: '100%', height: '100%', position: 'relative' }}
          >
            <Skeleton height="100%" width="100%" />
          </AppStack>
        </AppCard>
        <AppFlexbox style={{ gap: 10, alignItems: 'end' }}>
          <AppCard
            radius="md"
            shadow="xl"
            style={{
              maxHeight: 182,
              flex: 1,
              margin: 16,
              overflow: 'hidden'
            }}
          >
            <AppFlexbox
              style={{
                gap: 16,
                overflow: 'hidden'
              }}
            >
              <AppStack style={{ width: 150, height: 150 }}>
                <Skeleton height="100%" width="100%" />
              </AppStack>
              <AppFlexbox style={{ gap: 40, flex: 1 }}>
                <AppStack style={{ gap: 0, alignItems: 'start' }}>
                  <AppStack style={{ gap: 5, flex: 1 }}>
                    <Skeleton height={28} width={180} />
                    <Skeleton height={16} width={130} />
                    <Skeleton height={16} width={130} />
                    <Skeleton height={16} width={130} />
                  </AppStack>
                  <Skeleton height={16} width={250} />
                </AppStack>
                <AppStack
                  style={{
                    gap: 5,
                    flex: 1,
                    alignItems: 'start',
                    overflow: 'hidden'
                  }}
                >
                  <Skeleton height={10} width="100%" />
                  <Skeleton height={10} width="100%" />
                  <Skeleton height={10} width="50%" />
                </AppStack>
              </AppFlexbox>
            </AppFlexbox>
          </AppCard>
          <AppFlexbox
            style={{
              flex: 1,
              maxWidth: 600,
              paddingBottom: 16,
              alignItems: 'end',
              justifyContent: 'end'
            }}
          >
            <AppFlexbox style={{ gap: 8, paddingBottom: 16 }}>
              <Skeleton height={34} width={125} />
              <Skeleton height={34} width={255} />
            </AppFlexbox>
          </AppFlexbox>
        </AppFlexbox>
      </AppStack>

      <AppStack hiddenFrom="sm" style={{ gap: 0 }}>
        <AppCard
          radius="md"
          shadow="xl"
          style={{
            borderRadius: 0,
            color: '#FFF',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            alignItems: 'center',
            justifyContent: 'center',
            maxHeight: 200,
            width: '100%',
            padding: 0
          }}
        >
          <AppStack
            style={{ width: '100%', height: '100%', position: 'relative' }}
          >
            <Skeleton height="100%" width="100%" />
          </AppStack>
        </AppCard>

        <AppCard
          radius={0}
          shadow="xl"
          style={{
            overflow: 'hidden',
            maxHeight: 180
          }}
        >
          <AppFlexbox
            style={{
              gap: 16,
              overflow: 'hidden'
            }}
          >
            <AppStack style={{ minWidth: 125, maxWidth: 125 }}>
              <Skeleton height="100%" width="100%" />
            </AppStack>
            <AppFlexbox style={{ gap: 40, flex: 1 }}>
              <AppStack style={{ gap: 5, alignItems: 'start' }}>
                <AppStack style={{ gap: 5, flex: 1 }}>
                  <Skeleton height={28} width={180} />
                  <Skeleton height={16} width={130} />
                  <Skeleton height={16} width={130} />
                  <Skeleton height={16} width={130} />
                </AppStack>
                <Skeleton height={16} width={250} />
              </AppStack>
            </AppFlexbox>
          </AppFlexbox>
        </AppCard>
        <AppFlexbox style={{ padding: 16, paddingBottom: 0 }}>
          <AppFlexbox style={{ gap: 8, flex: 1 }}>
            <Skeleton
              height={34}
              width={isLargeMobileOrSmaller ? '100%' : 255}
            />
            <Skeleton
              height={34}
              width={isLargeMobileOrSmaller ? '100%' : 255}
            />
          </AppFlexbox>
        </AppFlexbox>
      </AppStack>

      <AppStack
        style={{
          gap: 16,
          padding: isTabletOrSmaller ? 16 : '0px 16px 16px 16px'
        }}
      >
        <VendorCatalogSearchFilter />
        <VendorCatalogProductSearchList loading />
      </AppStack>
    </AppStack>
  );
};

VendorCatalogVendorView.propTypes = {
  baseUrl: PropTypes.string,
  vendorPreview: PropTypes.bool
};

export default VendorCatalogVendorView;
