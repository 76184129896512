import React, { useContext, useEffect, useRef } from 'react';
import { Receipt } from 'tabler-icons-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import TableView from '../../common/TableView';
import { usePaginationFilter } from '../../../helpers/hooks';
import HeaderView from '../../common/HeaderView';
import { triggerNotification } from '../../../helpers/notification';
import { currencyFormat, formatUtcDate } from '../../../helpers/format';
import { ECOM_ORDER_PRODUCT_STATUS_ENUM } from '../../../config/constants';

const TABLE_COLUMNS = [
  {
    label: 'Order ID',
    value: 'order',
    sortable: true
  },
  {
    label: 'Date',
    value: 'date',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  },
  {
    label: 'Customer',
    value: 'customer',
    sortable: true
  },
  {
    label: 'Merchant',
    value: 'store',
    sortable: true
  },
  {
    label: 'Products ordered',
    value: 'products',
    sortable: true
  },
  {
    label: 'Total',
    value: 'total',
    sortable: true
  }
];

const VendorOrderTable = ({ isBulkOrders }) => {
  const fetchedTableType = useRef(null);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { state: authState } = useContext(AuthContext);
  const { state, fetchPaginatedVendorOrders } = useContext(VendorContext);
  const {
    pageIndex,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data: orderData,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  } = usePaginationFilter(
    {
      search: '',
      store: '',
      sort: 'order_desc'
    },
    (newFilter) => {
      fetchPaginatedVendorOrders(
        authState.pkEcomVendor,
        newFilter,
        null,
        (error) => {
          triggerNotification(error);
        }
      );
    },
    isBulkOrders ? state.paginatedEcomOrdersBulk : state.paginatedEcomOrders
  );
  const tableInfo = isBulkOrders
    ? {
        title: 'Bulk orders',
        type: 'bulk',
        urlBase: '/vendor/bulk-orders'
      }
    : {
        title: 'Orders',
        type: 'normal',
        urlBase: '/vendor/orders'
      };

  const loading = fetchedTableType.current !== tableInfo.type || dataLoading;

  useEffect(() => {
    if (authState.pkEcomVendor) {
      fetchPaginatedVendorOrders(
        authState.pkEcomVendor,
        {
          isBulkShipment: isBulkOrders,
          search: new URLSearchParams(search).get('customer') || '',
          store: new URLSearchParams(search).get('merchant') || '',
          sort: 'order_desc'
        },
        null,
        (error) => {
          triggerNotification(error);
        }
      );
      fetchedTableType.current = tableInfo.type;
    }
  }, [authState.pkEcomVendor, isBulkOrders]);

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: tableInfo.title,
          to: tableInfo.urlBase
        }
      ]}
    >
      <TableView
        columns={TABLE_COLUMNS}
        disabled={!loading && totalNoFilterCount === 0}
        disabledContent={{
          title: 'No orders found.',
          description:
            'Orders will appear here once customers begin to place orders.',
          icon: <Receipt color="#000" size={125} />
        }}
        emptyMessage="No orders found."
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by customer',
            value: filter.search || '',
            onChange: (v) =>
              onFilterChange({
                ...filter,
                search: v
              })
          },
          {
            key: 2,
            label: 'Search',
            placeholder: 'Filter by merchant',
            value: filter.store || '',
            onChange: (v) =>
              onFilterChange({
                ...filter,
                store: v
              })
          },
          {
            key: 3,
            label: 'Status',
            type: 'select',
            value: filter.status || null,
            placeholder: 'Filter by status',
            searchable: true,
            clearable: true,
            data: [
              { value: 'unfulfilled', label: 'Unfulfilled' },
              { value: 'fulfilled', label: 'Fulfilled' },
              { value: 'refunded', label: 'Refunded' }
            ],
            onChange: (value) =>
              onFilterChange({ ...filter, status: value }, true)
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={loading}
        noSort
        onAction={(action, item) => {
          if (action === 'view') {
            navigate(`${tableInfo.urlBase}/${item.key}`);
          }
          else if (action === 'fulfill') {
            navigate(`${tableInfo.urlBase}/${item.key}/fulfill`);
          }
        }}
        onChangeSortBy={onSortChange}
        onPageChange={onPageChange}
        onRefresh={onRefresh}
        pageIndex={pageIndex}
        rows={orderData.map((d) => {
          const bulkInfo = d.ecomOrderProductBulk.reduce(
            (r, current) => ({
              totalCost:
                r.totalCost +
                (current.ecomOrderProduct.productCost +
                  current.ecomOrderProduct.addonCost) *
                  current.count,
              itemCount: r.itemCount + current.count,
              isFullyFulfilled:
                r.isFullyFulfilled &&
                current.fkEcomOrderProductStatus.toString() !==
                  ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING
            }),
            {
              totalCost: 0,
              itemCount: 0,
              isFullyFulfilled: true
            }
          );

          const productInfo = d.ecomOrderProducts.reduce(
            (r, current) => ({
              totalCost:
                r.totalCost +
                (current.productCost + current.addonCost) * current.count,
              itemCount: r.itemCount + current.count,
              isFullyFulfilled:
                r.isFullyFulfilled &&
                current.fkEcomOrderProductStatus.toString() !==
                  ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING
            }),
            {
              totalCost: 0,
              itemCount: 0,
              isFullyFulfilled: true
            }
          );

          const statusInfo =
            productInfo.isFullyFulfilled && bulkInfo.isFullyFulfilled
              ? {
                  color: 'green',
                  label: 'Fulfilled'
                }
              : {
                  color: 'orange',
                  label: 'Unfulfilled'
                };

          return {
            key: d.pkEcomOrder,
            actions: [
              {
                label: 'View',
                value: 'view'
              },
              ...(!productInfo.isFullyFulfilled || !bulkInfo.isFullyFulfilled
                ? [
                    {
                      label: 'Fulfill',
                      value: 'fulfill'
                    }
                  ]
                : [])
            ],
            columns: [
              {
                key: 1,
                label: `#${d.pkEcomOrder}`,
                weight: 500,
                component: Link,
                to: `${tableInfo.urlBase}/${d.pkEcomOrder}`
              },
              {
                key: 2,
                label: dayjs(formatUtcDate(d.purchasedAt)).format(
                  'MMMM D, YYYY h:mm A'
                )
              },
              {
                key: 3,
                ...statusInfo
              },
              {
                key: 4,
                label: d.ecomCustomer.user?.name,
                weight: 500,
                subLabel: d.ecomCustomer.email,
                component: Link,
                to: `${tableInfo.urlBase}/${d.pkEcomOrder}`
              },
              {
                key: 5,
                label: d.ecomStore.name,
                weight: 500,
                subLabel: d.ecomStore.domain,
                component: Link,
                to: `${tableInfo.urlBase}/${d.pkEcomOrder}`
              },
              {
                key: 6,
                label: productInfo.itemCount + bulkInfo.itemCount
              },
              {
                key: 7,
                label: currencyFormat(
                  (productInfo.totalCost + bulkInfo.totalCost) / 100
                )
              }
            ]
          };
        })}
        sortBy={filter.sort || TABLE_COLUMNS[0].value}
        tableTitle={tableInfo.title}
        totalCount={totalCount}
        totalPages={totalPages}
      />
    </HeaderView>
  );
};

VendorOrderTable.propTypes = { isBulkOrders: PropTypes.bool };

export default VendorOrderTable;
