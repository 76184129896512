import React, { useContext, useEffect, useRef } from 'react';
import { User } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import HeaderView from '../../common/HeaderView';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import TableView from '../../common/TableView';
import { triggerNotification } from '../../../helpers/notification';
import { usePaginationFilter } from '../../../helpers/hooks';
import { currencyFormat } from '../../../helpers/format';

const TABLE_COLUMNS = [
  {
    label: 'Customer',
    value: 'name',
    sortable: true
  },
  {
    label: 'Orders',
    value: 'orders',
    sortable: true
  },
  {
    label: 'Total spent',
    value: 'total_spent',
    sortable: true
  },
  {
    label: 'Amount refunded',
    value: 'total_refunded',
    sortable: true
  }
];

const StoreCustomersTableView = () => {
  const hasFetched = useRef(false);
  const { state: authState } = useContext(AuthContext);
  const { state, fetchPaginatedStoreCustomers } = useContext(StoreContext);
  const {
    pageIndex,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data: customerData,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  } = usePaginationFilter(
    { search: '' },
    (newFilter) => {
      fetchPaginatedStoreCustomers(
        authState.pkEcomStore,
        newFilter,
        null,
        (error) => {
          triggerNotification(error);
        }
      );
    },
    state.paginatedEcomCustomers
  );
  const loading = !hasFetched.current || dataLoading;
  const noCustomers = !loading && totalNoFilterCount === 0;

  useEffect(() => {
    if (authState.pkEcomStore) {
      fetchPaginatedStoreCustomers(authState.pkEcomStore, {}, null, (error) => {
        triggerNotification(error);
      });
      hasFetched.current = true;
    }
  }, [authState.pkEcomStore]);

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: 'Customers',
          to: '/merchant/customers'
        }
      ]}
    >
      <TableView
        columns={TABLE_COLUMNS}
        disabled={noCustomers}
        disabledContent={{
          title: 'No customers yet',
          description:
            'Customers who have purchased from your store will appear here.',
          icon: <User color="#000" size={125} />
        }}
        emptyMessage="No customers found."
        enableInputsWhenLoading
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by customer',
            value: filter.search,
            onChange: (value) => onFilterChange({ ...filter, search: value })
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={!hasFetched.current || loading}
        noSort
        onChangeSortBy={onSortChange}
        onPageChange={onPageChange}
        onRefresh={onRefresh}
        pageIndex={pageIndex}
        rows={customerData.map((a) => ({
          key: a.pkEcomCustomer,
          columns: [
            {
              key: 1,
              label: a.user?.name,
              weight: 500,
              subLabel: a.email,
              component: Link,
              to: `/merchant/customers/${a.pkEcomCustomer}`
            },
            {
              key: 2,
              label: `${a.ecomOrderCount} orders`
            },
            {
              key: 3,
              label: currencyFormat(a.totalSpent / 100)
            },
            {
              key: 4,
              label: currencyFormat(a.totalRefunded / 100)
            }
          ]
        }))}
        sortBy={filter.sort || TABLE_COLUMNS[0].value}
        tableTitle="Customers"
        totalCount={totalCount}
        totalPages={totalPages}
      />
    </HeaderView>
  );
};

export default StoreCustomersTableView;
