import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import StoreOrderDetailsView from './StoreOrderDetailsView';
import StoreOrderContributors from './StoreOrderContributors';

const StoreOrderViews = ({ baseUrl, isBulkOrders }) => {
  const hasFetched = useRef(false);
  const { pkEcomOrder } = useParams();
  const { state: authState } = useContext(AuthContext);
  const { state, fetchStoreOrder } = useContext(StoreContext);
  const loading = !hasFetched.current || state.ecomOrder.loading;
  const ecomOrder =
    pkEcomOrder === state.ecomOrder.value?.pkEcomOrder.toString()
      ? state.ecomOrder.value
      : null;

  useEffect(() => {
    if (authState.pkEcomStore && pkEcomOrder) {
      fetchStoreOrder(authState.pkEcomStore, { pkEcomOrder }, null, (e) => {
        triggerNotification(e);
      });
      hasFetched.current = true;
    }
  }, [authState.pkEcomStore, pkEcomOrder]);

  return (
    <Routes>
      <Route
        element={
          <StoreOrderContributors
            ecomOrder={ecomOrder}
            isBulkOrders={isBulkOrders}
            loading={loading}
          />
        }
        path="/contributors"
      />
      <Route
        element={
          <StoreOrderDetailsView
            ecomOrder={ecomOrder}
            isBulkOrders={isBulkOrders}
            loading={loading}
          />
        }
        path="/*"
      />
      <Route
        element={<Navigate replace to={`/${baseUrl}/${pkEcomOrder}`} />}
        path="*"
      />
    </Routes>
  );
};

StoreOrderViews.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  isBulkOrders: PropTypes.bool
};

export default StoreOrderViews;
