import React, { useContext, useEffect, useRef } from 'react';
import { BuildingStore } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import TableView from '../../common/TableView';
import { usePaginationFilter } from '../../../helpers/hooks';
import HeaderView from '../../common/HeaderView';
import { triggerNotification } from '../../../helpers/notification';
import { currencyFormat } from '../../../helpers/format';

const TABLE_COLUMNS = [
  {
    label: 'Store',
    value: 'name',
    sortable: true
  },
  {
    label: 'Contact',
    value: 'support',
    sortable: true
  },
  {
    label: 'Orders',
    value: 'orders',
    sortable: true
  },
  {
    label: 'Customers',
    value: 'customers'
  },
  {
    label: 'Total spent',
    value: 'total_spent',
    sortable: true
  },
  {
    label: 'First purchase',
    value: 'first_purchase',
    sortable: true
  },
  {
    label: 'Last purchase',
    value: 'last_purchase',
    sortable: true
  }
];

const VendorMerchantTable = () => {
  const hasFetched = useRef(false);
  const { state: authState } = useContext(AuthContext);
  const { state, fetchPaginatedVendorStores } = useContext(VendorContext);
  const {
    pageIndex,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  } = usePaginationFilter(
    {
      search: '',
      status: ''
    },
    (newFilter) => {
      fetchPaginatedVendorStores(
        authState.pkEcomVendor,
        newFilter,
        null,
        (error) => {
          triggerNotification(error);
        }
      );
    },
    state.paginatedEcomVendorStores
  );
  const loading = !hasFetched.current || dataLoading;

  useEffect(() => {
    if (authState.pkEcomVendor) {
      fetchPaginatedVendorStores(authState.pkEcomVendor, {}, null, (error) => {
        triggerNotification(error);
      });
      hasFetched.current = true;
    }
  }, [authState.pkEcomVendor]);

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: 'Merchants',
          to: '/vendor/merchants'
        }
      ]}
    >
      <TableView
        columns={TABLE_COLUMNS}
        disabled={!loading && totalNoFilterCount === 0}
        disabledContent={{
          title: 'No merchants yet.',
          description: 'Merchants who have placed orders will appear here.',
          icon: <BuildingStore color="#000" size={125} />
        }}
        emptyMessage="No merchants found."
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by customer',
            value: filter.search || '',
            onChange: (search) =>
              onFilterChange({
                ...filter,
                search
              })
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={!hasFetched.current || loading}
        noSort
        onChangeSortBy={onSortChange}
        onPageChange={onPageChange}
        onRefresh={onRefresh}
        pageIndex={pageIndex}
        rows={data.map((a) => ({
          key: a.pkEcomStore,
          columns: [
            {
              key: 1,
              label: a.name,
              weight: 500,
              subLabel: a.domain,
              component: Link,
              to: `/vendor/merchants/${a.pkEcomStore}`,
              src: a.logoImageUrl
            },
            {
              key: 2,
              label: a.supportEmail || 'N/A'
            },
            {
              key: 3,
              label: a.ecomOrderCount
            },
            {
              key: 4,
              label: a.ecomCustomerCount
            },
            {
              key: 5,
              label: currencyFormat(a.totalSpent / 100)
            },
            {
              key: 6,
              label: dayjs(a.firstPurchase).format('MMM D, YYYY')
            },
            {
              key: 7,
              label: dayjs(a.lastPurchase).format('MMM D, YYYY')
            }
          ]
        }))}
        sortBy={filter.sort || TABLE_COLUMNS[0].value}
        tableTitle="Customers"
        totalCount={totalCount}
        totalPages={totalPages}
      />
    </HeaderView>
  );
};

export default VendorMerchantTable;
