import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ActionIcon, Grid, Pagination, TextInput } from '@mantine/core';
import { ArrowLeft, ClearAll, ShoppingCart } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import TableViewDisabledContent from '../../common/TableViewDisabledContent';
import VendorCatalogVendorDisplayCard from './VendorCatalogVendorDisplayCard';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const MAX_PAGE_LENGTH = 30;

const VendorCatalogVendorsView = ({ loading, baseUrl }) => {
  const {
    isMobileOrSmaller,
    isTabletOrSmaller,
    isLaptopOrSmaller,
    isDesktopOrSmaller
  } = useMediaQueryIndex();
  const { state: helperState } = useContext(HelperContext);
  const { state } = useContext(StoreContext);
  const [filterState, setFilterState] = useState({
    search: '',
    page: 1
  });
  const filteredVendors = state.catalogVendors.value.filter(
    (v) =>
      (helperState.vendorCatalog.isDemoMode
        ? v.isDemoVendor
        : !v.sandboxEnabled) &&
      (!filterState.search ||
        v.name
          .toLowerCase()
          .replace(' ', '')
          .includes(filterState.search.toLowerCase().replace(' ', '')))
  );
  const showPagination = !loading && filteredVendors.length > MAX_PAGE_LENGTH;

  useEffect(() => {
    setFilterState({
      ...filterState,
      page: 1
    });
  }, [helperState.vendorCatalog.isDemoMode]);

  return (
    <AppStack
      style={{
        flex: 1,
        width: '100%',
        maxWidth: 1600,
        padding: 16,
        marginBottom: 70,
        gap: 20,
        alignSelf: 'center'
      }}
    >
      <AppFlexbox
        style={{
          justifyContent: 'space-between',
          gap: 10,
          flexDirection: isMobileOrSmaller ? 'column' : 'row',
          alignItems: isMobileOrSmaller ? 'stretch' : 'center'
        }}
      >
        <AppFlexbox style={{ alignItems: 'center', gap: 10 }}>
          <ActionIcon
            color="dark"
            component={Link}
            to={baseUrl}
            variant="subtle"
          >
            <ArrowLeft size={24} />
          </ActionIcon>
          <AppText style={{ fontSize: 28, fontWeight: 700 }}>Vendors</AppText>
        </AppFlexbox>
        <TextInput
          onChange={(e) => {
            setFilterState({
              ...filterState,
              search: e.currentTarget.value,
              page: 1
            });
          }}
          placeholder="Search vendors..."
          style={{ flex: 1, maxWidth: isMobileOrSmaller ? 'unset' : 300 }}
          value={filterState.search}
        />
      </AppFlexbox>

      {loading ? (
        <Grid
          columns={
            isMobileOrSmaller
              ? 1
              : isTabletOrSmaller
              ? 2
              : isLaptopOrSmaller
              ? 3
              : isDesktopOrSmaller
              ? 5
              : 6
          }
        >
          {Array.from(Array(12)).map((x, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid.Col key={i} span={1}>
              <VendorCatalogVendorDisplayCard />
            </Grid.Col>
          ))}
        </Grid>
      ) : filteredVendors.length === 0 ? (
        <TableViewDisabledContent
          disabledContent={{
            title: 'No vendors found',
            description:
              "We're sorry, but no vendors were found. Please try a different search term.",
            icon: <ShoppingCart color="#000" size={125} />,
            primaryButton: {
              label: 'Clear search',
              icon: <ClearAll size={18} />,
              onClick: () =>
                setFilterState({
                  ...filterState,
                  search: '',
                  page: 1
                })
            }
          }}
        />
      ) : (
        <AppStack style={{ gap: 20 }}>
          <Grid
            columns={
              isMobileOrSmaller
                ? 1
                : isTabletOrSmaller
                ? 2
                : isLaptopOrSmaller
                ? 3
                : isDesktopOrSmaller
                ? 5
                : 6
            }
          >
            {filteredVendors
              .slice(
                -MAX_PAGE_LENGTH + filterState.page * MAX_PAGE_LENGTH,
                filterState.page * MAX_PAGE_LENGTH
              )
              .map((vendor) => (
                <Grid.Col key={vendor.pkEcomVendor} span={1}>
                  <VendorCatalogVendorDisplayCard
                    baseUrl={baseUrl}
                    ecomVendor={vendor}
                  />
                </Grid.Col>
              ))}
          </Grid>
          {showPagination && (
            <AppFlexbox style={{ justifyContent: 'center' }}>
              <Pagination
                color="blue"
                onChange={(page) => {
                  setFilterState({
                    ...filterState,
                    page
                  });
                }}
                total={Math.ceil(filteredVendors.length / MAX_PAGE_LENGTH)}
                value={filterState.page}
              />
            </AppFlexbox>
          )}
        </AppStack>
      )}
    </AppStack>
  );
};

VendorCatalogVendorsView.propTypes = {
  baseUrl: PropTypes.string,
  loading: PropTypes.bool
};

export default VendorCatalogVendorsView;
