import { useContext } from 'react';
import { Context as StoreContext } from '../providers/StoreContextProvider';
import { Context as AuthContext } from '../providers/AuthContextProvider';
import { getFromStorage, saveToStorage } from './storage';
import { Context as HelperContext } from '../providers/HelperContextProvider';

const getCartId = (pkEcomStore, isDemoMode = false) =>
  `catalog-cart-${pkEcomStore}${isDemoMode ? '-demo' : ''}`;

const getCartStorage = (pkEcomStore, isDemoMode = false) => {
  if (!pkEcomStore) {
    return null;
  }
  const cartString = getFromStorage(getCartId(pkEcomStore, isDemoMode));
  let cart = { pkEcomStore, ecomVendorProducts: [] };
  if (!cartString) {
    saveToStorage(getCartId(pkEcomStore, isDemoMode), JSON.stringify(cart));
  }
  else {
    cart = JSON.parse(cartString);
  }
  return cart;
};

const addCartProducts = (
  pkEcomStore,
  ecomVendorProducts,
  isDemoMode = false
) => {
  const cart = getCartStorage(pkEcomStore, isDemoMode);

  ecomVendorProducts.forEach((p) => {
    if (cart.ecomVendorProducts.every((s) => s.uuid !== p.uuid)) {
      cart.ecomVendorProducts.push(p);
    }
  });
  saveToStorage(getCartId(pkEcomStore, isDemoMode), JSON.stringify(cart));

  return cart;
};

const onUpdateProducts = (
  pkEcomStore,
  ecomVendorProducts,
  isDemoMode = false
) => {
  const cart = getCartStorage(pkEcomStore, isDemoMode);
  cart.ecomVendorProducts = cart.ecomVendorProducts.map((p) => {
    const updatedProduct = ecomVendorProducts.find((f) => f.uuid === p.uuid);
    if (updatedProduct) {
      return {
        ...p,
        ...updatedProduct
      };
    }
    return p;
  });
  saveToStorage(getCartId(pkEcomStore, isDemoMode), JSON.stringify(cart));

  return cart;
};

const removeCartProducts = (pkEcomStore, uuids, isDemoMode = false) => {
  const cart = getCartStorage(pkEcomStore, isDemoMode);

  cart.ecomVendorProducts = cart.ecomVendorProducts.filter(
    (p) => !uuids.includes(p.uuid)
  );
  saveToStorage(getCartId(pkEcomStore, isDemoMode), JSON.stringify(cart));

  return cart;
};

const resetCart = (pkEcomStore, isDemoMode = false) => {
  const cart = { pkEcomStore, ecomVendorProducts: [] };
  saveToStorage(getCartId(pkEcomStore, isDemoMode), JSON.stringify(cart));
  return cart;
};

const useVendorCatalogCheckout = () => {
  const { state: authState } = useContext(AuthContext);
  const { state, fetchStoreCatalogCheckoutSummary } = useContext(StoreContext);
  const {
    state: helperState,
    toggleVendorCatalogDrawer,
    appendItemStorage,
    removeItemStorage,
    setVendorCatalogInputProducts
  } = useContext(HelperContext);
  const cart = getCartStorage(
    authState.pkEcomStore,
    helperState.vendorCatalog.isDemoMode
  );

  const fetchCart = () => {
    fetchStoreCatalogCheckoutSummary(
      authState.pkEcomStore,
      { uuids: cart.ecomVendorProducts.map((p) => p.uuid) },
      (data) => {
        const productInputs = data.filter(
          (f) => f.ecomVendorProductInputs.length > 0
        );
        setVendorCatalogInputProducts({
          inputProducts: productInputs,
          showInputModal: false,
          selectedInputProductUuid: productInputs[0]?.uuid || null
        });
      }
    );
  };

  const addProducts = (uuids, onSuccessCallback, onErrorCallback) => {
    if (cart.ecomVendorProducts.length === 0) {
      toggleVendorCatalogDrawer(true);
    }

    const newCart = addCartProducts(
      authState.pkEcomStore,
      uuids.map((i) => ({ uuid: i, ecomStoreProductInputAnswers: [] })),
      helperState.vendorCatalog.isDemoMode
    );

    appendItemStorage(uuids);
    fetchStoreCatalogCheckoutSummary(
      authState.pkEcomStore,
      {
        skipLoading: true,
        uuids: [...newCart.ecomVendorProducts.map((p) => p.uuid)]
      },
      (data) => {
        const newProducts = data.filter((f) => uuids.includes(f.uuid));
        const newProductInputs = data.filter(
          (f) =>
            f.ecomVendorProductInputs.length > 0 &&
            helperState.vendorCatalog.inputProducts.every(
              (p) => p.uuid !== f.uuid
            )
        );
        const inputProducts = [
          ...helperState.vendorCatalog.inputProducts,
          ...newProductInputs
        ];
        removeItemStorage({ filter: (i) => !uuids.includes(i) });
        setVendorCatalogInputProducts({
          inputProducts,
          showMinimumQuanityModal:
            !state.ecomStoreSetupSummary.value.ecomStore.defaultAddress &&
            newProducts.some((i) => !!i.minimumQuantity),
          showInputModal:
            helperState.vendorCatalog.showInputModal ||
            (!helperState.vendorCatalog.skipInputModalOnAdd &&
              newProductInputs.length > 0),
          selectedInputProductUuid:
            newProductInputs.filter(
              (p) => p.ecomVendorProductInputs.length > 0
            )[0]?.uuid || helperState.vendorCatalog.selectedInputProductUuid
        });

        if (onSuccessCallback) {
          onSuccessCallback();
        }
      },
      onErrorCallback
    );
  };

  const removeProducts = (uuids, onSuccessCallback, onErrorCallback) => {
    const newCart = removeCartProducts(
      authState.pkEcomStore,
      uuids,
      helperState.vendorCatalog.isDemoMode
    );
    const newProductInputs = [
      ...helperState.vendorCatalog.inputProducts.filter(
        (f) => !uuids.includes(f.uuid)
      )
    ];

    setVendorCatalogInputProducts({
      inputProducts: newProductInputs,
      showInputModal: false,
      selectedInputProductUuid: newProductInputs[0]?.uuid || null
    });
    if (newCart.ecomVendorProducts.length === 0) {
      toggleVendorCatalogDrawer(false);
    }
    fetchStoreCatalogCheckoutSummary(
      authState.pkEcomStore,
      {
        skipLoading: true,
        uuids: [
          ...cart.ecomVendorProducts
            .filter((p) => !uuids.includes(p.uuid))
            .map((p) => p.uuid)
        ]
      },
      onSuccessCallback,
      onErrorCallback
    );
  };

  const updateProducts = (ecomVendorProducts) => {
    onUpdateProducts(
      authState.pkEcomStore,
      ecomVendorProducts,
      helperState.vendorCatalog.isDemoMode
    );
  };

  const clearCart = () => {
    resetCart(authState.pkEcomStore, helperState.vendorCatalog.isDemoMode);
    setVendorCatalogInputProducts({
      inputProducts: [],
      showInputModal: false,
      selectedInputProductUuid: null
    });
    toggleVendorCatalogDrawer(false);
  };

  return {
    cart: {
      loading: state.catalogCartProducts.loading,
      storageCart: cart,
      ecomVendorProducts: state.catalogCartProducts.value.filter((f) =>
        cart.ecomVendorProducts.some((s) => s.uuid === f.uuid)
      )
    },
    clearCart,
    addProducts,
    removeProducts,
    getCartStorage,
    updateProducts,
    fetchCart
  };
};

export { useVendorCatalogCheckout };
