import React, { useContext, useEffect, useState } from 'react';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import ConfirmModal from '../../common/ConfirmModal';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { triggerNotification } from '../../../helpers/notification';

const VendorCatalogRemoveStoreProductsModal = () => {
  const { state: authState } = useContext(AuthContext);
  const { state, deleteStoreProducts } = useContext(StoreContext);
  const {
    state: helperState,
    setVendorCatalogRemoveStoreProductModal
  } = useContext(HelperContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (helperState.vendorCatalog.removeProductModal.isOpen) {
      setLoading(false);
    }
  }, [helperState.vendorCatalog.removeProductModal.isOpen]);

  const displayTitle =
    helperState.vendorCatalog.removeProductModal.ecomVendorProducts.length > 1
      ? `${helperState.vendorCatalog.removeProductModal.ecomVendorProducts.length} products`
      : helperState.vendorCatalog.removeProductModal.ecomVendorProducts[0]
          ?.name;

  const onCloseModal = () => {
    setVendorCatalogRemoveStoreProductModal({
      isOpen: false,
      ecomVendorProducts:
        helperState.vendorCatalog.removeProductModal.ecomVendorProducts
    });
  };

  return (
    <ConfirmModal
      confirmActionColor="red"
      confirmActionText="Yes, remove product"
      isLoading={loading}
      isOpen={helperState.vendorCatalog.removeProductModal.isOpen}
      message={
        <AppStack style={{ gap: 10 }}>
          <AppText style={{ fontSize: 16 }}>
            Are you sure you want to remove <b>{displayTitle}</b> from your
            store? This will delete the product and make it unavailable for
            purchase. You will still need to fulfill any open orders.
          </AppText>
          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
            This action cannot be undone.
          </AppText>
        </AppStack>
      }
      onCancel={onCloseModal}
      onConfirm={() => {
        const ecomStoreProducts = state.ecomStoreProducts.value.filter((f) =>
          helperState.vendorCatalog.removeProductModal.ecomVendorProducts.some(
            (s) => s.uuid === f.ecomVendorProduct.uuid
          )
        );
        setLoading(true);
        deleteStoreProducts(
          authState.pkEcomStore,
          ecomStoreProducts.map((p) => p.pkEcomStoreProduct),
          () => {
            triggerNotification('Products removed.', 'success');
            onCloseModal();
          },
          (error) => {
            triggerNotification(error);
            setLoading(false);
          }
        );
      }}
      title={`Remove ${displayTitle}?`}
    />
  );
};

export default VendorCatalogRemoveStoreProductsModal;
