import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import VendorOrderTable from './VendorOrderTable';
import VendorOrderDetailsView from '../orders/VendorOrderDetailsView';
import VendorOrderFulfillmentView from '../orders/VendorOrderFulfillmentView';
import { triggerNotification } from '../../../helpers/notification';

const VendorOrderView = () => {
  const hasFetched = useRef(false);
  const { pkEcomOrder } = useParams();
  const { state: authState } = useContext(AuthContext);
  const { state, fetchVendorOrder } = useContext(VendorContext);

  const loading = !hasFetched.current || state.ecomOrder.loading;
  const ecomOrder =
    pkEcomOrder === state.ecomOrder.value?.pkEcomOrder.toString()
      ? state.ecomOrder.value
      : null;

  useEffect(() => {
    if (authState.pkEcomVendor && pkEcomOrder) {
      fetchVendorOrder(authState.pkEcomVendor, { pkEcomOrder }, null, (e) => {
        triggerNotification(e);
      });
      hasFetched.current = true;
    }
  }, [authState.pkEcomVendor, pkEcomOrder]);

  return (
    <Routes>
      <Route
        element={
          <VendorOrderFulfillmentView ecomOrder={ecomOrder} loading={loading} />
        }
        path="/fulfill"
      />
      <Route
        element={
          <VendorOrderDetailsView ecomOrder={ecomOrder} loading={loading} />
        }
        path="/"
      />
      <Route
        element={<Navigate replace to={`/vendor/orders/${pkEcomOrder}`} />}
        path="*"
      />
    </Routes>
  );
};

const VendorOrdersView = ({ isBulkOrders }) => (
  <Routes>
    <Route element={<VendorOrderView />} path="/:pkEcomOrder/*" />
    <Route
      element={<VendorOrderTable isBulkOrders={isBulkOrders} />}
      path="/"
    />
    <Route element={<Navigate replace to="/vendor/orders" />} path="*" />
  </Routes>
);

VendorOrdersView.propTypes = { isBulkOrders: PropTypes.bool };

export default VendorOrdersView;
