import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'tabler-icons-react';
import { Button } from '@mantine/core';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import { triggerNotification } from '../../../helpers/notification';
import CustomNumberInput from '../../common/CustomNumberInput';
import AppText from '../../common/AppText';
import { ECOM_PRODUCT_STATUS_ENUM } from '../../../config/constants';

const StoreProductPricingModal = ({ ecomStoreProduct, isOpen, onClose }) => {
  const { state: authState } = useContext(AuthContext);
  const { updateStoreProducts } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    percentagePricing: true,
    pricingAmount: '',
    roundingAdjustment: '',
    setProductToLive: false,
    loading: false
  });

  useEffect(() => {
    if (isOpen) {
      const minPricingVariant = ecomStoreProduct?.ecomStoreProductVariants.reduce(
        (minObj, obj) =>
          obj.pricingAmount < minObj.pricingAmount ? obj : minObj,
        ecomStoreProduct.ecomStoreProductVariants[0]
      );

      setFormState({
        percentagePricing: minPricingVariant?.percentagePricing || true,
        pricingAmount: minPricingVariant
          ? minPricingVariant.pricingAmount / 100
          : '',
        roundingAdjustment: minPricingVariant?.roundingAdjustment || '',
        setProductToLive: false,
        loading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        isLoading: formState.loading,
        onCancel: onClose,
        onSubmit: () => {
          setFormState({
            ...formState,
            loading: true
          });
          updateStoreProducts(
            authState.pkEcomStore,
            {
              ecomStoreProducts: [
                {
                  uuid: ecomStoreProduct.uuid,
                  percentagePricing: formState.percentagePricing,
                  pricingAmount: Math.round(formState.pricingAmount * 100),
                  roundingAdjustment: formState.roundingAdjustment,
                  rebaseAllPricing: true,
                  fkEcomProductStatus: formState.setProductToLive
                    ? ECOM_PRODUCT_STATUS_ENUM.ACTIVE
                    : ecomStoreProduct.fkEcomProductStatus
                }
              ],
              selectFirstItem: true
            },
            () => {
              onClose();
              triggerNotification('Goal updated!', 'success');
            },
            (e) => {
              triggerNotification(e);
              setFormState({
                ...formState,
                loading: false
              });
            }
          );
        },
        submitTitle: 'Save changes'
      }}
      isOpen={isOpen && !!ecomStoreProduct}
      onClose={onClose}
      size={450}
      title="Set product pricing"
    >
      <AppStack>
        <CustomNumberInput
          allowNegative={false}
          decimalScale={2}
          disabled={formState.loading}
          fixedDecimalScale
          label={`Margin (in ${formState.percentagePricing ? '%' : '$'})`}
          leftSection={
            !formState.percentagePricing && (
              <AppText
                style={{
                  fontWeight: 500,
                  color: '#666',
                  fontSize: 14
                }}
              >
                $
              </AppText>
            )
          }
          onChange={(v) =>
            setFormState({
              ...formState,
              pricingAmount: v
            })
          }
          placeholder="30.00"
          required
          rightSection={
            formState.percentagePricing ? (
              <AppText
                style={{
                  fontWeight: 500,
                  color: '#666',
                  fontSize: 14
                }}
              >
                %
              </AppText>
            ) : (
              <></>
            )
          }
          value={formState.pricingAmount}
        />

        <Button.Group>
          <Button
            color={formState.percentagePricing ? 'blue' : 'dark'}
            disabled={formState.loading}
            onClick={() => {
              setFormState({
                ...formState,
                percentagePricing: true
              });
            }}
            size="compact-md"
            style={{ flex: 1 }}
            variant={formState.percentagePricing ? 'filled' : 'light'}
          >
            %
          </Button>
          <Button
            color={!formState.percentagePricing ? 'blue' : 'dark'}
            disabled={formState.loading}
            onClick={() => {
              setFormState({
                ...formState,
                percentagePricing: false
              });
            }}
            size="compact-md"
            style={{ flex: 1 }}
            variant={!formState.percentagePricing ? 'filled' : 'light'}
          >
            $
          </Button>
        </Button.Group>

        <CustomNumberInput
          allowNegative={false}
          clampBehavior="strict"
          decimalScale={0}
          disabled={formState.loading}
          fixedDecimalScale
          label="Rounding Adjustment (in cents)"
          max={99}
          min={0}
          onChange={(v) => {
            setFormState({
              ...formState,
              roundingAdjustment: v > 99 ? 99 : v
            });
          }}
          placeholder=".95"
          required
          value={
            formState.roundingAdjustment > 99
              ? 99
              : formState.roundingAdjustment
          }
        />

        {ecomStoreProduct?.fkEcomProductStatus.toString() !==
          ECOM_PRODUCT_STATUS_ENUM.ACTIVE && (
          <Checkbox
            checked={formState.setProductToLive}
            disabled={formState.loading}
            label="Set product to live"
            onChange={() =>
              setFormState({
                ...formState,
                setProductToLive: !formState.setProductToLive
              })
            }
            styles={{ description: { fontSize: 14 } }}
          />
        )}

        <AppText
          style={{
            fontSize: 14,
            color: '#666',
            textAlign: 'center',
            fontStyle: 'italic'
          }}
        >
          This will apply the pricing to all variants of this product.
        </AppText>
      </AppStack>
    </ResponsiveModal>
  );
};

StoreProductPricingModal.propTypes = {
  ecomStoreProduct: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default StoreProductPricingModal;
