import React from 'react';
import { Anchor, Divider, Skeleton } from '@mantine/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import {
  currencyFormat,
  formatUtcDate,
  singularPluralFormat
} from '../../../helpers/format';
import AppCard from '../../common/AppCard';
import VendorOrderProductPreview from './VendorOrderProductPreview';

const StoreOrderContributorListItem = ({ ecomCustomer, showDetails }) => {
  const customerOrders =
    ecomCustomer?.products.reduce(
      (r, c) =>
        r.some((f) => f.pkEcomOrder === c.fkEcomOrder)
          ? r
          : [
              ...r,
              {
                pkEcomOrder: c.fkEcomOrder,
                purchaseDate: c.purchaseDate
              }
            ],
      []
    ) || [];

  return ecomCustomer ? (
    <AppStack
      style={{
        padding: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        style={{
          gap: 8,
          justifyContent: 'space-between'
        }}
      >
        <AppStack style={{ gap: 0, flex: 1, overflow: 'hidden' }}>
          <AppFlexbox>
            {ecomCustomer.user && (
              <Anchor
                style={{ fontSize: 14, fontWeight: 500, color: 'dodgerblue' }}
                to={`/merchant/customers/${ecomCustomer.pkEcomCustomer}`}
              >
                {ecomCustomer.user?.name}
              </Anchor>
            )}
          </AppFlexbox>
          <AppFlexbox>
            <Anchor
              component="a"
              style={{ fontSize: 14, color: '#666' }}
              to={`mailto:${ecomCustomer.email}`}
            >
              {ecomCustomer.email}
            </Anchor>
          </AppFlexbox>
        </AppStack>
        <AppStack style={{ gap: 0, textAlign: 'end' }}>
          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
            {currencyFormat(
              ecomCustomer.products.reduce((r, p) => r + p.totalCost, 0) / 100
            )}
          </AppText>

          <AppText style={{ fontSize: 12, color: '#666' }}>
            {singularPluralFormat(
              ecomCustomer.products.reduce((r, p) => r + p.quantity, 0),
              'item',
              'items'
            )}
          </AppText>
        </AppStack>
      </AppFlexbox>

      {showDetails && (
        <AppCard radius="md" shadow="none" style={{ padding: 0 }} withBorder>
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox
              style={{
                padding: '8px 16px',
                gap: 8,
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  {customerOrders.length === 0 ? 'Order' : 'Orders'}:{' '}
                </AppText>
                {customerOrders.map((order) => (
                  <Anchor
                    key={order.pkEcomOrder}
                    component={Link}
                    style={{ fontSize: 14, color: 'dodgerblue' }}
                    to={`/merchant/orders/${order.pkEcomOrder}`}
                  >
                    #{order.pkEcomOrder}
                  </Anchor>
                ))}
              </AppFlexbox>
              <AppText style={{ fontSize: 14 }}>
                {dayjs(
                  formatUtcDate(
                    customerOrders.sort(
                      (a, b) =>
                        new Date(b.purchaseDate) - new Date(a.purchaseDate)
                    )[0]?.purchaseDate
                  )
                ).format('MMMM D, YYYY')}
              </AppText>
            </AppFlexbox>
            <Divider />
            <AppStack style={{ gap: 0 }}>
              {ecomCustomer.products.map((p) => (
                <AppFlexbox key={p.key} style={{ padding: 16 }}>
                  <VendorOrderProductPreview
                    key={p.key}
                    hideStatus
                    product={p}
                    quantityIndicatorAmount={p.quantity}
                  />
                </AppFlexbox>
              ))}
            </AppStack>
          </AppStack>
        </AppCard>
      )}
    </AppStack>
  ) : (
    <AppFlexbox
      style={{
        padding: 16,
        gap: 8,
        justifyContent: 'space-between'
      }}
    >
      <AppStack style={{ gap: 5, flex: 1, overflow: 'hidden' }}>
        <Skeleton height={18} width="35%" />
        <Skeleton height={18} width="45%" />
      </AppStack>
      <AppStack style={{ gap: 5, textAlign: 'end' }}>
        <Skeleton height={18} width={120} />
        <Skeleton height={14} width={80} />
      </AppStack>
    </AppFlexbox>
  );
};

StoreOrderContributorListItem.propTypes = {
  ecomCustomer: PropTypes.object,
  showDetails: PropTypes.bool
};

export default StoreOrderContributorListItem;
