/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppStack from '../../../../common/AppStack';
import WsBlock from '../../blocks/WsBlock';
import WsSectionContainer from '../WsSectionContainer';
import AppFlexbox from '../../../../common/AppFlexbox';
import placeholderImage from '../../../../../images/sportsheadz-logo.png';
import { IMAGE_SIZE_ENUM } from './wsImageWithTextConfig';
import { getDefaultBannerHeight } from '../../../../../helpers/webstoreHelper';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';
import WsCollectionCard from '../collectionGrid/WsCollectionCard';
import WsProductCard from '../productGrid/WsProductCard';

const WsImageWithText = ({
  blocks,
  config,
  colorSchemes,
  themeConfig,
  mediaQueries,
  baseStoreUrl,
  onNavigate,
  ecomStoreTheme,
  editModeEnabled,
  onChangeSectionConfigValues,
  sectionKey,
  viewPortAdjustment,
  ...rest
}) => {
  const { state } = useContext(StoreUserContext);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { isTabletOrSmaller } = mediaQueries;
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const containerColorScheme =
    colorSchemes[config.containerColorScheme - 1] ?? colorSchemes[0];
  const overlapContent = config.overlapContent === 'true';
  const isImageRightSide = config.imagePlacement === 'right';
  const isFullwidth = config.fullWidth === 'true';
  const imageWidth =
    config.imageWidth === IMAGE_SIZE_ENUM.SMALL
      ? '30'
      : config.imageWidth === IMAGE_SIZE_ENUM.MEDIUM
      ? '50'
      : config.imageWidth === IMAGE_SIZE_ENUM.CUSTOM
      ? config.customImageWidth
      : '70';

  const adaptImageHeight = config.adaptImageHeight * 1;
  const adaptImageWidth = config.adaptImageWidth * 1;
  const adaptToImage = config.imageHeight === IMAGE_SIZE_ENUM.ADAPT_TO_IMAGE;
  const adaptToImageHeight =
    !config.ecomStoreCollection && !config.ecomStoreProduct
      ? getDefaultBannerHeight(
          config,
          adaptImageHeight,
          adaptImageWidth,
          isTabletOrSmaller,
          viewPortAdjustment,
          !isFullwidth ? themeConfig.pageWidth : null,
          isTabletOrSmaller
            ? null
            : isFullwidth
            ? `calc((100vw${
                viewPortAdjustment ? ` - ${viewPortAdjustment}` : ''
              }) * ${(100 - imageWidth) / 100})`
            : `${themeConfig.pageWidth * ((100 - imageWidth) / 100)}px`
        )
      : null;
  const contentAlignment = isTabletOrSmaller
    ? config.mobileContentAlignment
    : config.contentAlignment;

  const ecomStoreProduct = state.ecomStoreProductHistory.value.find(
    (c) => c.uuid === config.ecomStoreProduct
  );
  const productNotFound = state.ecomStoreProductHistory.uuidsNotFound.includes(
    config.ecomStoreProduct
  );

  const ecomStoreCollection = state.ecomStoreCollections.value.find(
    (c) =>
      c.pkEcomStoreCollection.toString() ===
      config.ecomStoreCollection?.toString()
  );
  const collectionNotFound = state.ecomStoreCollections.idsNotFound.includes(
    config.ecomStoreCollection?.toString()
  );

  const contentHeight = adaptToImage
    ? adaptToImageHeight || 'unset'
    : config.imageHeight === IMAGE_SIZE_ENUM.SMALL
    ? 300
    : config.imageHeight === IMAGE_SIZE_ENUM.MEDIUM
    ? 450
    : 700;

  useEffect(() => {
    if (config.image) {
      const image = new Image();
      image.src = config.image;
      image.onload = () => {
        if (
          (adaptImageHeight !== image.naturalHeight ||
            adaptImageWidth !== image.naturalWidth) &&
          editModeEnabled &&
          onChangeSectionConfigValues
        ) {
          onChangeSectionConfigValues(sectionKey, [
            {
              name: 'adaptImageHeight',
              value: image.naturalHeight.toString()
            },
            {
              name: 'adaptImageWidth',
              value: image.naturalWidth.toString()
            }
          ]);
        }
        setImageLoaded(true);
      };
    }
  }, [config.image]);

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      mediaQueries={mediaQueries}
      sectionKey={sectionKey}
      themeConfig={themeConfig}
      {...rest}
    >
      <AppFlexbox
        style={{
          flex: 1,
          width: '100%',
          display: 'grid',
          gap: 0,
          padding: 0,
          gridTemplateColumns: isTabletOrSmaller ? '1fr' : `${imageWidth}% 1fr`,
          direction: !isTabletOrSmaller && isImageRightSide ? 'rtl' : 'ltr'
        }}
      >
        <AppStack
          style={{
            direction: 'ltr',
            width: '100%',
            minHeight: contentHeight
          }}
        >
          {config.ecomStoreProduct ? (
            <AppFlexbox
              style={{
                width: '100%',
                height: '100%',
                maxHeight: adaptToImage ? 'unset' : contentHeight,
                flexShrink: 0,
                visibility: 'visible'
              }}
            >
              <WsProductCard
                baseStoreUrl={baseStoreUrl}
                colorScheme={colorScheme}
                ecomStoreProduct={ecomStoreProduct}
                noProduct={!config.ecomStoreProduct || productNotFound}
                onNavigate={onNavigate}
                showSecondImageOnHover
                showVendorName
              />
            </AppFlexbox>
          ) : config.ecomStoreCollection ? (
            <AppFlexbox
              style={{
                width: '100%',
                height: '100%',
                maxHeight: adaptToImage ? 'unset' : contentHeight,
                flexShrink: 0,
                visibility: 'visible'
              }}
            >
              <WsCollectionCard
                baseStoreUrl={baseStoreUrl}
                colorScheme={colorScheme}
                ecomStoreCollection={ecomStoreCollection}
                noCollection={!config.ecomStoreCollection || collectionNotFound}
                onNavigate={onNavigate}
                portraitRatio
                {...rest}
              />
            </AppFlexbox>
          ) : (
            <AppFlexbox
              style={{
                flex: 1,
                width: '100%',
                height: '100%',
                flexShrink: 0,
                visibility: 'visible',
                backgroundImage: `url(${
                  config.image ||
                  ecomStoreTheme?.logoImageUrl ||
                  placeholderImage
                })`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }}
            />
          )}
        </AppStack>
        <AppStack
          style={{
            zIndex: 1,
            flex: overlapContent ? 'unset' : 1,
            justifyContent: config.contentPosition,
            direction: 'ltr',
            marginTop: isTabletOrSmaller && overlapContent ? '-2em' : 0,
            marginRight:
              !isTabletOrSmaller && isImageRightSide && overlapContent
                ? '-4rem'
                : 0,
            marginLeft:
              !isTabletOrSmaller && !isImageRightSide && overlapContent
                ? '-4rem'
                : 0,
            padding: overlapContent
              ? isTabletOrSmaller
                ? '0px 15px'
                : '30px 0px'
              : 0
          }}
        >
          {blocks.length > 0 && (
            <AppStack
              style={{
                flex: overlapContent ? 'unset' : 1,
                gap: 5,
                backgroundColor: containerColorScheme.backgroundColor,
                color: containerColorScheme.textColor,
                justifyContent: config.contentPosition,
                padding: isTabletOrSmaller ? 20 : 40
              }}
            >
              <AppStack
                style={{
                  alignItems: contentAlignment,
                  justifyContent: contentAlignment,
                  textAlign: contentAlignment,
                  gap: 16
                }}
              >
                {blocks.map((b) => (
                  <WsBlock
                    baseStoreUrl={baseStoreUrl}
                    colorScheme={containerColorScheme}
                    ecomStoreTheme={ecomStoreTheme}
                    onNavigate={onNavigate}
                    {...b}
                  />
                ))}
              </AppStack>
            </AppStack>
          )}
        </AppStack>
      </AppFlexbox>
    </WsSectionContainer>
  );
};

WsImageWithText.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreCollections: PropTypes.array,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onChangeSectionConfigValues: PropTypes.func,
  onNavigate: PropTypes.func,
  sectionKey: PropTypes.number,
  themeConfig: PropTypes.object,
  viewPortAdjustment: PropTypes.string
};

export default WsImageWithText;
