import React from 'react';
import { Grid, Select } from '@mantine/core';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import ProductInputDisplay from './ProductInputDisplay';
import { INPUT_CONTROL_TYPE_ENUM } from '../../../config/constants';

const ProductVariantInputPreview = ({
  ecomVendorProductInputs,
  ecomVendorProductVariantOptions,
  selectedOptions,
  onOptionChange
}) => {
  const variantOptions =
    ecomVendorProductVariantOptions?.reduce((r, c) => {
      const existingOption = r.find((f) => f.name === c.name);
      if (existingOption) {
        if (!existingOption.values.includes(c.value)) {
          existingOption.values.push(c.value);
        }
      }
      else {
        r.push({ name: c.name, values: [c.value] });
      }
      return r;
    }, []) ?? [];

  return (
    (ecomVendorProductInputs?.length > 0 || variantOptions.length > 0) && (
      <AppStack>
        <Grid>
          {variantOptions.map((op) => (
            <Grid.Col
              key={op.name}
              span={{
                base: 12,
                xsm: 6
              }}
              style={{ alignSelf: 'end' }}
            >
              <Select
                data={op.values.map((v) => ({
                  label: v,
                  value: v
                }))}
                label={op.name}
                onChange={(value) => {
                  if (value) {
                    onOptionChange({
                      ...selectedOptions,
                      [op.name]: value
                    });
                  }
                }}
                value={selectedOptions[op.name]}
              />
            </Grid.Col>
          ))}

          {ecomVendorProductInputs
            .sort((a, b) => a.sort - b.sort)
            .map((p) => (
              <Grid.Col
                key={p.pkEcomVendorProductInput}
                span={{
                  base: 12,
                  xsm:
                    p.fkRegFormControlType ===
                      INPUT_CONTROL_TYPE_ENUM.CHECKBOX ||
                    p.fkRegFormControlType ===
                      INPUT_CONTROL_TYPE_ENUM.FILE_UPLOAD
                      ? 12
                      : 6
                }}
                style={{
                  alignSelf: 'end',
                  marginTop:
                    p.fkRegFormControlType === INPUT_CONTROL_TYPE_ENUM.CHECKBOX
                      ? 5
                      : 0
                }}
              >
                <ProductInputDisplay
                  key={p.pkEcomVendorProductInput}
                  ecomVendorProductInput={p}
                  onChange={() => {}}
                  value=""
                />
              </Grid.Col>
            ))}
        </Grid>
      </AppStack>
    )
  );
};

ProductVariantInputPreview.propTypes = {
  ecomVendorProductInputs: PropTypes.array,
  ecomVendorProductVariantOptions: PropTypes.array,
  onOptionChange: PropTypes.func,
  selectedOptions: PropTypes.object
};

export default ProductVariantInputPreview;
