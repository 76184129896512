import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { triggerNotification } from '../../../helpers/notification';
import AnalyticsReportView from './AnalyticsReportView';

const ReportView = ({
  entityId,
  reportConfigs,
  onGenerateReport,
  urlBase,
  reportResult,
  reportViewType
}) => {
  const { search } = useLocation();
  const fromLocation = new URLSearchParams(search).get('from');
  const [reportLoading, setReportLoading] = useState(false);

  const onFetchAnalyticsReport = (uuid, requestData) => {
    setReportLoading(true);
    onGenerateReport(
      { uuid, ...requestData },
      () => setReportLoading(false),
      (error) => {
        setReportLoading(false);
        triggerNotification(error);
      }
    );
  };

  const getUrlParams = () => {
    switch (fromLocation?.toLocaleLowerCase()) {
      case 'analytics':
        return `${urlBase}/analytics`;
      default:
        return `${urlBase}/reports`;
    }
  };

  return (
    <AnalyticsReportView
      entityId={entityId}
      loading={reportLoading}
      onFetchReport={onFetchAnalyticsReport}
      redirectPath={getUrlParams()}
      reportConfigs={reportConfigs}
      reportResult={reportResult}
      reportViewType={reportViewType}
    />
  );
};

ReportView.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onGenerateReport: PropTypes.func,
  reportConfigs: PropTypes.array,
  reportResult: PropTypes.object,
  reportViewType: PropTypes.string,
  urlBase: PropTypes.string
};

export default ReportView;
