import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CustomerTable from '../customers/CustomerTable';
import CustomerDetailsView from '../customers/CustomerDetailsView';

const VendorCustomersView = () => (
  <Routes>
    <Route
      element={<CustomerDetailsView isVendorView />}
      path="/:pkEcomCustomer/*"
    />
    <Route element={<CustomerTable isVendorView />} path="/" />
    <Route element={<Navigate replace to="/vendor/customers" />} path="*" />
  </Routes>
);

export default VendorCustomersView;
