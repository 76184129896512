import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import VendorMerchantTable from './VendorMerchantTable';
import VendorMerchantView from './VendorMerchantView';

const VendorMerchantViews = () => {
  const { state } = useContext(AuthContext);

  return (
    <Routes>
      <Route element={<VendorMerchantView />} path="/:pkEcomStore/*" />
      <Route element={<VendorMerchantTable />} path="/" />
      <Route element={<Navigate replace to="/vendor/merchants" />} path="*" />
    </Routes>
  );
};

export default VendorMerchantViews;
