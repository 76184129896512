const groupStoreOrderProductData = (productData) =>
  productData
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .reduce((r, c) => {
      const existing = r.find(
        (p) =>
          p.uuid === c.uuid &&
          p.addOns.length === c.addOns.length &&
          p.addOns.every(
            (ao, i) =>
              ao.name === c.addOns[i].name && ao.value === c.addOns[i].value
          )
      );
      if (existing) {
        existing.quantity += c.quantity;
        existing.totalCost += c.totalCost;
        existing.vendorCost += c.vendorCost;
        existing.serviceFees += c.serviceFees;
        existing.basePrice += c.basePrice;
        existing.amountRemaining += c.amountRemaining;
        existing.ecomOrderShippingProducts.push(...c.ecomOrderShippingProducts);
        existing.shippedCount += c.shippedCount;
        existing.ecomOrderProducts.push(c);
      }
      else {
        r.push({ ...c });
      }
      return r;
    }, []);

const translateStoreOrderProductData = (
  lineItem,
  product,
  ecomOrder,
  ecomOrderShippingProducts = []
) => {
  const amountRemaining =
    product.count -
    ecomOrderShippingProducts
      .filter(
        (f) =>
          f.fkEcomOrderProduct === product.pkEcomOrderProduct ??
          product.ecomOrderProduct.pkEcomOrderProduct
      )
      .reduce((r2, c2) => r2 + c2.count, 0);

  const shippingProducts = ecomOrderShippingProducts.filter(
    (f) => f.fkEcomOrderProduct === product.pkEcomOrderProduct
  );

  const unitCost = lineItem
    ? lineItem.totalInCents
    : product.ecomStoreProductVariant.price +
      product.ecomOrderProductInputAnswers.reduce((r, c) => {
        if (c.adjustedCost) {
          return r + c.adjustedCost;
        }
        return r;
      }, 0);
  const totalQuantity = lineItem ? lineItem.quantity : product.count;

  return {
    key:
      lineItem?.entityId ||
      product.pkEcomOrderProductBulk ||
      product.pkEcomOrderProduct,
    purchaseDate: ecomOrder.purchaseDate,
    pkEcomOrderProduct: product.pkEcomOrderProduct,
    pkEcomOrderProductBulk: product.pkEcomOrderProductBulk,
    fkEcomOrder: product.fkEcomOrder,
    fkEcomOrderProductStatus: product.fkEcomOrderProductStatus,
    to: `/merchant/products/${
      lineItem?.uuid || product.ecomStoreProductVariant.ecomStoreProduct.uuid
    }`,
    previewImage:
      lineItem?.previewImage ||
      product.ecomStoreProductVariant.ecomVendorProductVariant.previewImage ||
      product.ecomStoreProductVariant.ecomStoreProduct.ecomVendorProduct
        .previewImage,
    isBulkShipment: product.isBulkShipment,
    name:
      lineItem?.productName ||
      product.ecomStoreProductVariant.ecomStoreProduct.ecomVendorProduct.name,
    vendorName:
      product.ecomStoreProductVariant.ecomStoreProduct.ecomVendorProduct
        .vendorName,
    vendorCost:
      (product.ecomStoreProductVariant.ecomVendorProductVariant.price +
        product.vendorAddonPrice) *
      totalQuantity,
    sku: product.ecomStoreProductVariant.ecomVendorProductVariant.sku,
    barcode: product.ecomStoreProductVariant.ecomVendorProductVariant.barcode,
    uuid: product.ecomStoreProductVariant.ecomVendorProductVariant.uuid,
    productUuid: product.ecomStoreProductVariant.ecomStoreProduct.uuid,
    weight: product.ecomStoreProductVariant.ecomVendorProductVariant.weight,
    weightUnit:
      product.ecomStoreProductVariant.ecomVendorProductVariant.weightUnit,
    quantity: totalQuantity,
    unitCost,
    totalCost: unitCost * totalQuantity,
    serviceFees:
      (product.serviceFees + product.addonServiceFees) * totalQuantity,
    basePrice: (product.baseStorePrice + product.addonPrice) * totalQuantity,
    storeProfit: product.storeProfit * totalQuantity,
    minimumQuantity:
      product.ecomStoreProductVariant.ecomStoreProduct.ecomVendorProduct
        .minimumQuantity,
    amountRemaining,
    ecomOrderShippingProducts: shippingProducts,
    shippedCount: shippingProducts.reduce((r, c) => r + c.count, 0),
    ecomOrderProducts: [],
    addOns: lineItem?.addOns
      .map((a) => ({
        name: a.name,
        value: a.value,
        sort: a.sort
      }))
      .sort((a, b) => a.sort - b.sort) || [
      ...product.ecomStoreProductVariant.ecomVendorProductVariant.ecomVendorProductVariantOptions.map(
        (op) => ({
          name: op.name,
          value: op.value,
          sort: op.nameSort
        })
      ),
      ...product.ecomOrderProductInputAnswers
        .map((a) => ({
          name: a.label,
          value: a.value,
          sort: a.sort
        }))
        .sort(
          (a, b) =>
            a.sort -
            b.sort +
            product.ecomStoreProductVariant.ecomVendorProductVariant
              .ecomVendorProductVariantOptions.length
        )
    ],
    ecomOrderProductBulk:
      !ecomOrder.isBulkShipment && product.isBulkShipment
        ? product.ecomOrderProductBulk.map((b) => ({
            pkEcomOrderProductBulk: b.pkEcomOrderProductBulk,
            count: b.count,
            createdAt: b.createdAt,
            fkEcomOrderProductStatus: b.fkEcomOrderProductStatus,
            fkEcomOrderStatus: b.fkEcomOrderStatus,
            fkEcomOrder: b.fkEcomOrder,
            to: `/merchant/group-orders/${b.fkEcomOrder}`
          }))
        : null,
    ecomCustomer: product.ecomCustomer
  };
};

const translateStoreOrderData = (ecomOrder) => {
  const ecomOrderShippingProducts =
    ecomOrder?.ecomOrderShipping.reduce(
      (r, c) => [...r, ...c.ecomOrderShippingProducts],
      []
    ) ?? [];
  const translatedProductData = ecomOrder
    ? [
        ...ecomOrder.checkoutBalance.lineItems.map((p) =>
          translateStoreOrderProductData(
            p,
            ecomOrder.ecomOrderProducts.find(
              (c) => c.pkEcomOrderProduct === p.entityId
            ),
            ecomOrder,
            ecomOrderShippingProducts
          )
        ),
        ...ecomOrder.ecomOrderProductBulk.map((b) =>
          translateStoreOrderProductData(
            null,
            {
              ...b.ecomOrderProduct,
              ...b,
              fkEcomOrder: b.ecomOrderProduct.fkEcomOrder
            },
            ecomOrder,
            ecomOrderShippingProducts
          )
        )
      ]
    : [];

  const productData = groupStoreOrderProductData(translatedProductData);

  return {
    ecomOrderShippingProducts,
    ecomOrderProducts: productData.sort((a, b) => a.name.localeCompare(b.name)),
    groupedEcomOrderProducts: productData.reduce((r, c) => {
      const existing = r.find((f) => f.productUuid === c.productUuid);
      if (existing) {
        existing.quantity += c.quantity;
        existing.totalCost += c.totalCost;
        existing.vendorCost += c.vendorCost;
        existing.serviceFees += c.serviceFees;
        existing.basePrice += c.basePrice;
        existing.amountRemaining += c.amountRemaining;
        existing.ecomOrderShippingProducts.push(...c.ecomOrderShippingProducts);
        existing.shippedCount += c.shippedCount;
        existing.ecomOrderProducts.push(c);
      }
      else {
        r.push({
          ...c,
          addOns: []
        });
      }
      return r;
    }, []),
    ecomCustomers: translatedProductData.reduce((r, c) => {
      const customer = c.ecomCustomer || ecomOrder.ecomCustomer;

      const existing = r.find(
        (f) => f.pkEcomCustomer === customer.pkEcomCustomer
      );
      if (!existing) {
        const products = groupStoreOrderProductData(
          translatedProductData.filter(
            (p) => p.ecomCustomer?.pkEcomCustomer === customer.pkEcomCustomer
          )
        );

        r.push({
          ...customer,
          products
        });
      }
      return r;
    }, [])
  };
};

export { translateStoreOrderData, translateStoreOrderProductData };
