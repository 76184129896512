import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ActionIcon,
  Anchor,
  Divider,
  Loader,
  Overlay,
  Skeleton,
  Table,
  Tooltip
} from '@mantine/core';
import { Filter } from 'tabler-icons-react';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import ReportTableColumnSelectMenu from './ReportTableColumnSelectMenu';
import ReportFilterModal from './ReportFilterModal';
import ReportTableBody from './ReportTableBody';
import ConfirmModal from '../../common/ConfirmModal';
import { formatDataTypeString } from '../../../helpers/format';

const MAX_PAGE_LENGTH = 100;

const ReportTable = ({
  reportConfig,
  report,
  onHeaderFieldChange,
  showSummary,
  uuid,
  loading,
  reportViewType
}) => {
  const headerRef = useRef(null);
  const tableContainer = useRef(null);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [tableResultsState, setTableResultsState] = useState({
    hasInitialized: reportConfig.uuid === uuid,
    pageIndex: 1,
    loading: false,
    viewMoreModal: {
      isOpen: false,
      labelHeader: null,
      label: '',
      valueHeader: null,
      valueList: []
    }
  });
  const { headers, data, compareData } = report ?? {};
  const displayedHeaderFields =
    headers?.map((h) => h.field) ?? reportConfig?.defaultHeaders ?? [];
  const hasReachedMaxPage = data
    ? tableResultsState.pageIndex * MAX_PAGE_LENGTH > data.length
    : true;

  useEffect(() => {
    const container = document.getElementById('analytics-report-view');
    const handleScroll = () => {
      const header = headerRef.current;
      if (header) {
        const tableTop = tableContainer.current.getBoundingClientRect().top;
        if (tableTop <= 60 && !isHeaderFixed) {
          setIsHeaderFixed(true);
        }
        else if (tableTop > 60 && isHeaderFixed) {
          setIsHeaderFixed(false);
        }
      }
    };

    const handleTableScroll = () => {
      if (tableContainer.current) {
        setScrollLeft(tableContainer.current.scrollLeft);
      }
    };

    const updateWidth = () => {
      if (tableContainer.current) {
        const { width } = tableContainer.current.getBoundingClientRect();
        setContainerWidth(width);
      }
    };
    updateWidth();

    const tableContainerDom = tableContainer.current;
    tableContainerDom.addEventListener('scroll', handleTableScroll);
    container.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateWidth);

    return () => {
      tableContainerDom.removeEventListener('scroll', handleTableScroll);
      container.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateWidth);
    };
  }, [isHeaderFixed]);

  useEffect(() => {
    if (data && !loading) {
      setTableResultsState({
        ...tableResultsState,
        hasInitialized: true,
        pageIndex: 1,
        loading: false
      });
    }
  }, [data, loading]);

  useEffect(() => {
    if (tableResultsState.loading) {
      setTableResultsState({
        ...tableResultsState,
        pageIndex: tableResultsState.pageIndex + 1
      });
    }
  }, [tableResultsState.loading]);

  useEffect(() => {
    if (tableResultsState.loading) {
      setTableResultsState({
        ...tableResultsState,
        loading: false
      });
    }
  }, [tableResultsState.pageIndex]);

  return (
    <AppStack style={{ gap: 0, position: 'relative' }}>
      {loading && (
        <Overlay backgroundOpacity={0.3} color="#000">
          <AppStack
            style={{
              width: '100%',
              height: '100%',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Loader color="white" size={32} />
          </AppStack>
        </Overlay>
      )}

      <AppFlexbox
        style={{
          padding: 8,
          justifyContent: 'end',
          alignItems: 'center'
        }}
      >
        {/* <Tooltip label="Filter results" withArrow>
          <ActionIcon
            color="dark"
            onClick={() => {
              setShowFilterModal(true);
            }}
            size="md"
            style={{ border: 'solid 1px #b1b1b1' }}
            variant="subtle"
          >
            <Filter color="#999" size={22} />
          </ActionIcon>
        </Tooltip> */}

        <ReportTableColumnSelectMenu
          displayedHeaderFields={displayedHeaderFields}
          onConfirm={onHeaderFieldChange}
          reportConfig={reportConfig}
          reportViewType={reportViewType}
        />
      </AppFlexbox>
      <Divider />
      <AppFlexbox
        ref={tableContainer}
        style={{
          flex: 1,
          width: '100%',
          overflow: 'auto',
          position: 'relative'
        }}
      >
        {report && report.data.length === 0 && (
          <Overlay backgroundOpacity={0.1} color="#000" />
        )}

        <Table style={{ position: 'unset' }}>
          {/* {isHeaderFixed && <Table.Thead style={{ height: 36 }} />} */}
          <Table.Thead
            ref={headerRef}
            // style={{
            //   backgroundColor: '#FFF',
            //   overflow: 'hidden',
            //   ...(isHeaderFixed
            //     ? {
            //         position: 'fixed',
            //         top: 60,
            //         zIndex: 200,
            //         maxWidth: containerWidth
            //       }
            //     : { position: 'static' })
            // }}
          >
            <Table.Tr
              style={{
                ...(isHeaderFixed
                  ? {
                      position: 'relative',
                      left: `${-scrollLeft}px`
                    }
                  : { position: 'static' })
              }}
            >
              {report && tableResultsState.hasInitialized
                ? headers.map((h, index) => {
                    const isNumber =
                      h.dataType === 'currency' ||
                      h.dataType === 'number' ||
                      h.dataType === 'percentage';
                    return (
                      <Table.Th
                        key={h.field}
                        style={{
                          minWidth: 175,
                          padding: 0,
                          ...(index === 0 && {
                            position: 'sticky',
                            left: 0,
                            backgroundColor: '#FFF'
                          })
                        }}
                      >
                        <AppFlexbox
                          style={{
                            padding: '8px 10px',
                            justifyContent: isNumber ? 'end' : 'start',
                            ...(index === 0 && {
                              borderRight:
                                scrollLeft > 0 ? 'solid 1px #dee2e6' : 'none'
                            })
                          }}
                        >
                          <AppText
                            style={{
                              fontSize: 14,
                              fontWeight: 'normal',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {h.label[0].toUpperCase() + h.label.slice(1)}
                          </AppText>
                        </AppFlexbox>
                      </Table.Th>
                    );
                  })
                : Array.from(Array(displayedHeaderFields.length)).map(
                    (x, i) => (
                      <Table.Td
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        style={{ minWidth: 175, padding: 0 }}
                      >
                        <AppFlexbox style={{ padding: '8px 10px' }}>
                          <Skeleton height={22} width="25%" />
                        </AppFlexbox>
                      </Table.Td>
                    )
                  )}
            </Table.Tr>
          </Table.Thead>
          {report && tableResultsState.hasInitialized ? (
            report.data.length === 0 ? (
              <Table.Tbody style={{ position: 'relative' }}>
                <Table.Tr>
                  <Table.Td colSpan={headers.length} style={{ padding: 0 }}>
                    <AppStack
                      style={{
                        padding: '60px 10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        gap: 5
                      }}
                    >
                      <AppText style={{ fontSize: 20, fontWeight: 500 }}>
                        No data found for the selected date range.
                      </AppText>
                      <AppText style={{ fontSize: 14, color: '#666' }}>
                        Please adjust the date range and try again.
                      </AppText>
                    </AppStack>
                  </Table.Td>
                </Table.Tr>
              </Table.Tbody>
            ) : (
              <ReportTableBody
                compareData={compareData}
                data={data}
                headers={headers}
                maxPageCount={MAX_PAGE_LENGTH}
                onViewMore={(labelHeader, label, valueHeader, valueList) => {
                  setTableResultsState({
                    ...tableResultsState,
                    viewMoreModal: {
                      isOpen: true,
                      labelHeader,
                      label,
                      valueHeader,
                      valueList
                    }
                  });
                }}
                pageIndex={tableResultsState.pageIndex}
                showFirstColumnBorder={scrollLeft > 0}
                showSummary={showSummary}
              />
            )
          ) : (
            <Table.Tbody style={{ position: 'relative' }}>
              {Array.from(Array(8)).map((x1, i1) => (
                // eslint-disable-next-line react/no-array-index-key
                <Table.Tr key={i1}>
                  {Array.from(Array(displayedHeaderFields.length)).map(
                    (x2, i2) => (
                      <Table.Td
                        // eslint-disable-next-line react/no-array-index-key
                        key={i2}
                        style={{ padding: 0 }}
                      >
                        <AppFlexbox
                          style={{ minWidth: 175, padding: '8px 10px' }}
                        >
                          <AppStack
                            style={{
                              width: '100%',
                              gap: 5
                            }}
                          >
                            <Skeleton height={20} width="50%" />
                            <Skeleton height={20} width="50%" />
                          </AppStack>
                        </AppFlexbox>
                      </Table.Td>
                    )
                  )}
                </Table.Tr>
              ))}
            </Table.Tbody>
          )}
        </Table>
      </AppFlexbox>

      {(!report || tableResultsState.loading || report.data.length > 0) && (
        <AppFlexbox
          style={{
            padding: 12,
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            backgroundColor: '#F1F1F1'
          }}
        >
          {!report ? (
            <Skeleton height={20} width={200} />
          ) : tableResultsState.loading ? (
            <Loader color="dark" size={26} />
          ) : (
            report.data.length > 0 && (
              <AppText style={{ fontSize: 14, fontWeight: 500, color: '#666' }}>
                Showing{' '}
                {hasReachedMaxPage
                  ? data.length
                  : tableResultsState.pageIndex * MAX_PAGE_LENGTH}{' '}
                of {data.length} result(s).
                {!hasReachedMaxPage && (
                  <Anchor
                    onClick={() =>
                      setTableResultsState({
                        ...tableResultsState,
                        loading: true
                      })
                    }
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'dodgerblue'
                    }}
                    underline="always"
                  >
                    Load next {MAX_PAGE_LENGTH} results
                  </Anchor>
                )}
              </AppText>
            )
          )}
        </AppFlexbox>
      )}

      <ReportFilterModal
        isOpen={showFilterModal}
        onClose={() => setShowFilterModal(false)}
      />

      <ConfirmModal
        cancelActionText="Close"
        formSectionProps={{ isSubmitHidden: true, padding: 0 }}
        isOpen={tableResultsState.viewMoreModal.isOpen}
        onCancel={() => {
          setTableResultsState({
            ...tableResultsState,
            viewMoreModal: { ...tableResultsState.viewMoreModal, isOpen: false }
          });
        }}
        title={
          tableResultsState.viewMoreModal.labelHeader
            ? `More for ${formatDataTypeString(
                tableResultsState.viewMoreModal.label,
                tableResultsState.viewMoreModal.labelHeader.dataType
              )}`
            : ''
        }
      >
        {tableResultsState.viewMoreModal.valueHeader && (
          <AppStack style={{ gap: 0 }}>
            <AppText
              style={{
                fontSize: 14,
                padding: 16,
                fontWeight: 500,
                color: '#666',
                fontStyle: 'italic',
                textAlign: 'center'
              }}
            >
              You are viewing all of column '
              {tableResultsState.viewMoreModal.valueHeader.label}' for{' '}
              {formatDataTypeString(
                tableResultsState.viewMoreModal.label,
                tableResultsState.viewMoreModal.labelHeader.dataType
              )}
              .
            </AppText>
            {tableResultsState.viewMoreModal.valueList.map((value) => (
              <React.Fragment key={value}>
                <Divider />
                <AppFlexbox style={{ padding: '8px 16px' }}>
                  <AppText style={{ fontSize: 14 }}>
                    {formatDataTypeString(
                      value,
                      tableResultsState.viewMoreModal.valueHeader.dataType
                    )}
                  </AppText>
                </AppFlexbox>
              </React.Fragment>
            ))}
          </AppStack>
        )}
      </ConfirmModal>
    </AppStack>
  );
};

ReportTable.propTypes = {
  loading: PropTypes.bool,
  onHeaderFieldChange: PropTypes.func,
  report: PropTypes.object,
  reportConfig: PropTypes.object,
  reportViewType: PropTypes.string,
  showSummary: PropTypes.bool,
  uuid: PropTypes.string
};

export default ReportTable;
