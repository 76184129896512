import React, { useContext, useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import AppStack from '../components/common/AppStack';
import AppFlexbox from '../components/common/AppFlexbox';
import StoreDashboardOverview from '../components/content/stores/StoreDashboardOverview';
import StoreReportsView from '../components/content/stores/StoreReportsView';
import StoreOrdersView from '../components/content/stores/StoreOrdersView';
import StoreProductsView from '../components/content/stores/StoreProductsView';
import StoreSidebar from '../components/content/navigation/StoreSidebar';
import WebstoreViews from '../components/content/webstore/WebstoreViews';
import StoreCollectionsView from '../components/content/stores/StoreCollectionsView';
import StoreSwitchView from '../components/content/stores/StoreSwitchView';
import StoreSetupGuideSidebar from '../components/content/stores/StoreSetupGuideSidebar';
import { Context as AuthContext } from '../providers/AuthContextProvider';
import { Context as StoreContext } from '../providers/StoreContextProvider';
import StoreSettingsView from '../components/content/stores/StoreSettingsView';
import AppAlertBanner from '../components/common/AppAlertBanner';
import { currencyFormat, formatUtcDate } from '../helpers/format';
import StoreFundraisingView from '../components/content/stores/StoreFundraisingView';
import StoreCustomersView from '../components/content/stores/StoreCustomersView';
import StoreAnalyticsView from '../components/content/stores/StoreAnalyticsView';
import StoreEscrowPaymentsView from '../components/content/stores/StoreEscrowPaymentsView';
import StoreGroupOrdersView from '../components/content/stores/StoreGroupOrdersView';

const hiddenSidebarRoutes = [
  /\/merchant\/webstore\/themes\/(.*)\/(.*)/,
  /\/merchant\/switch/,
  /\/merchant\/reports\/result/,
  /\/merchant\/products\/discover/
];

const MerchantsView = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state: authState } = useContext(AuthContext);
  const { state } = useContext(StoreContext);
  const [hideAlert, setHideAlert] = useState(false);
  const showSidebar = !hiddenSidebarRoutes.some((r) => pathname.match(r));
  const selectedStore = authState.ecomStores.find(
    (v) => v.pkEcomStore.toString() === authState.pkEcomStore?.toString()
  );

  const today = new Date();
  const threeDaysFromNow = new Date(today);
  threeDaysFromNow.setDate(today.getDate() + 3);
  threeDaysFromNow.setHours(0, 0, 0, 0);

  useEffect(() => {
    if (
      authState.isAuthenticated &&
      !authState.loading &&
      authState.ecomStores.length === 0
    ) {
      navigate(`/merchant/switch`);
    }
  }, [authState.isAuthenticated, authState.loading, pathname]);

  useEffect(() => {
    if (authState.pkEcomStore) {
      setHideAlert(false);
    }
  }, [authState.pkEcomStore]);

  return (
    <AppFlexbox style={{ gap: 5, flex: 1 }}>
      {showSidebar && <StoreSidebar />}
      <AppStack style={{ flex: 1, width: '100%', overflow: 'hidden' }}>
        <Routes>
          <Route element={<StoreDashboardOverview />} path="/" />
          <Route element={<StoreSwitchView />} path="/switch" />
          <Route element={<StoreProductsView />} path="/products/*" />
          <Route element={<StoreFundraisingView />} path="/fundraising/*" />
          <Route element={<StoreCollectionsView />} path="/collections/*" />
          <Route element={<StoreGroupOrdersView />} path="/group-orders/*" />
          <Route element={<StoreOrdersView />} path="/orders/*" />
          <Route element={<StoreCustomersView />} path="/customers/*" />
          <Route element={<StoreAnalyticsView />} path="/analytics/*" />
          <Route element={<StoreReportsView />} path="/reports/*" />
          <Route element={<WebstoreViews />} path="/webstore/*" />
          <Route element={<StoreSettingsView />} path="/settings/*" />
          <Route
            element={<StoreEscrowPaymentsView />}
            path="/escrow-payments/*"
          />
          <Route element={<Navigate replace to="/merchant" />} path="*" />
        </Routes>
      </AppStack>
      <StoreSetupGuideSidebar />
      {selectedStore &&
        !hideAlert &&
        (state.ecomStoreSetupSummary.value &&
        state.ecomStoreSetupSummary.value?.escrowBalance > 0 ? (
          <AppAlertBanner
            backgroundColor="#067D62"
            linkText="Click here to claim."
            linkTo="/merchant/escrow-payments?modal=claim"
            message={`Your store has a balance of ${currencyFormat(
              state.ecomStoreSetupSummary.value.escrowBalance / 100
            )} available to claim.`}
            onClose={() => setHideAlert(true)}
          />
        ) : (
          !selectedStore.sandboxEnabled &&
          (!selectedStore.ecomStoreWindow ? (
            <AppAlertBanner
              linkText="Click here to setup."
              linkTo="/merchant/settings?modal=sales-window"
              message="Your store is currently disabled. You must create a sales window before you can start selling."
              onClose={() => setHideAlert(true)}
            />
          ) : (
            new Date(formatUtcDate(selectedStore.ecomStoreWindow.closeDate)) <
              threeDaysFromNow && (
              <AppAlertBanner
                backgroundColor="#eca70a"
                linkText="Click here to extend."
                linkTo="/merchant/settings?modal=sales-window"
                message="Your store is about to close. Extend your sales window to keep selling."
                onClose={() => setHideAlert(true)}
              />
            )
          ))
        ))}
    </AppFlexbox>
  );
};

export default MerchantsView;
