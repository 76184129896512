import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import ResponsiveModal from '../../common/ResponsiveModal';
import EditTrackingDetailsFormSection from './EditTrackingDetailsFormSection';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import { generateTrackingLink } from '../../../helpers/vendorHelper';

const EditShippingDetailsModal = ({
  pkEcomOrder,
  ecomOrderShipping,
  isOpen,
  onClose
}) => {
  const { state: authState } = useContext(AuthContext);
  const { fetchVendorOrder, updateEcomOrderShippings } = useContext(
    VendorContext
  );
  const [formState, setFormState] = useState({
    trackingNumber: '',
    trackingUrl: '',
    shippingCarrier: '',
    notifyCustomer: false,
    additionalShipping: [],
    loading: false,
    error: null
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        trackingNumber: ecomOrderShipping?.trackingNumber ?? '',
        trackingUrl: ecomOrderShipping?.trackingUrl ?? '',
        shippingCarrier: ecomOrderShipping?.shippingCarrier ?? '',
        notifyCustomer: ecomOrderShipping?.notifyCustomer ?? false,
        additionalShipping: ecomOrderShipping?.additionalShipping ?? [],
        ecomOrderShippingProducts:
          ecomOrderShipping?.ecomOrderShippingProducts.map((p) => ({
            fkEcomOrderProduct: p.fkEcomOrderProduct,
            count: p.count
          })) ?? [],
        loading: false,
        error: null
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        onCancel: onClose,
        isLoading: formState.loading,
        submitTitle: 'Save changes',
        isSubmitDisabled: !ecomOrderShipping,
        onSubmit: () => {
          setFormState({
            ...formState,
            loading: true
          });
          const shippingProducts = formState.ecomOrderShippingProducts.filter(
            (p) => p.count > 0
          );

          updateEcomOrderShippings(
            authState.pkEcomVendor,
            [
              {
                pkEcomOrderShipping: ecomOrderShipping.pkEcomOrderShipping,
                fkEcomOrder: pkEcomOrder,
                ecomOrderShippingProducts: shippingProducts,
                ...formState,
                trackingUrl: generateTrackingLink(
                  formState.shippingCarrier,
                  formState.trackingNumber,
                  formState.trackingUrl
                )
              },
              ...formState.additionalShipping.map((as) => ({
                fkEcomOrder: pkEcomOrder,
                ...as,
                ecomOrderShippingProducts: shippingProducts.map((p) => ({
                  ...p,
                  count: 0
                })),
                trackingUrl: generateTrackingLink(
                  as.shippingCarrier,
                  as.trackingNumber,
                  as.trackingUrl
                )
              }))
            ],
            () => {
              triggerNotification('Shipping updated', 'success');
              fetchVendorOrder(authState.pkEcomVendor, { pkEcomOrder });
              onClose();
            },
            (e) => {
              triggerNotification(e);
              setFormState({
                ...formState,
                loading: false
              });
            }
          );
        }
      }}
      isOpen={isOpen}
      onClose={onClose}
      title="Shipping details"
    >
      <AppStack style={{ gap: 20 }}>
        <EditTrackingDetailsFormSection
          formState={formState}
          setFormState={setFormState}
        />
      </AppStack>
    </ResponsiveModal>
  );
};

EditShippingDetailsModal.propTypes = {
  ecomOrderShipping: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkEcomOrder: PropTypes.number
};

export default EditShippingDetailsModal;
