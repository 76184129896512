import React from 'react';
import PropTypes from 'prop-types';
import { Anchor, NumberFormatter, Skeleton } from '@mantine/core';
import { Link } from 'react-router-dom';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import { REPORT_HEADER_ORDER_ENUM } from './reportsConfig';
import AppCard from '../../common/AppCard';

const AnalyticsPaymentSummaryList = ({ reportResult, loading }) => {
  const paymentData = reportResult?.report.data || [];

  const orderIdIndex = reportResult?.report.headers.findIndex(
    (header) => header.field === REPORT_HEADER_ORDER_ENUM.ORDER_ID
  );
  const purchaseDateIndex = reportResult?.report.headers.findIndex(
    (header) => header.field === REPORT_HEADER_ORDER_ENUM.PURCHASE_DATE
  );
  const amountPaidIndex = reportResult?.report.headers.findIndex(
    (header) => header.field === REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID
  );

  return !loading ? (
    paymentData.length === 0 ? (
      <AppText style={{ fontSize: 14, color: '#666', textAlign: 'center' }}>
        No payment data available for the selected date range.
      </AppText>
    ) : (
      <AppStack style={{ gap: 8 }}>
        {paymentData
          .sort(
            (a, b) =>
              new Date(b[purchaseDateIndex]) - new Date(a[purchaseDateIndex])
          )
          .slice(0, 5)
          .map((p) => {
            const orderId = (p[orderIdIndex] || '').replace('#', '');
            const purchaseDate = p[purchaseDateIndex];
            const amountPaid = p[amountPaidIndex];

            return (
              <AppCard
                key={orderId}
                radius={8}
                shadow="none"
                style={{ padding: 8 }}
                withBorder
              >
                <AppFlexbox
                  key={orderId}
                  style={{
                    gap: 5,
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <AppStack style={{ gap: 0 }}>
                    <NumberFormatter
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      style={{ fontSize: 14 }}
                      thousandSeparator
                      value={amountPaid / 100}
                    />
                    <AppText style={{ fontSize: 12, color: '#666' }}>
                      {purchaseDate}
                    </AppText>
                  </AppStack>

                  <AppStack style={{ gap: 0 }}>
                    <Anchor
                      component={Link}
                      style={{ fontSize: 14, color: 'dodgerblue' }}
                      to={`/merchant/orders/${orderId}`}
                    >
                      View order
                    </Anchor>
                  </AppStack>
                </AppFlexbox>
              </AppCard>
            );
          })}
        {paymentData.length > 5 && (
          <AppFlexbox style={{ justifyContent: 'center', marginTop: 8 }}>
            <Anchor
              component={Link}
              style={{ fontSize: 14, color: 'dodgerblue' }}
              to="/merchant/orders"
            >
              View all orders
            </Anchor>
          </AppFlexbox>
        )}
      </AppStack>
    )
  ) : (
    <Skeleton height={56} radius={8} width="100%" />
  );
};

AnalyticsPaymentSummaryList.propTypes = {
  loading: PropTypes.bool,
  reportResult: PropTypes.object
};

export default AnalyticsPaymentSummaryList;
