import React, { useRef, useState } from 'react';
import { Button, Grid } from '@mantine/core';
import { ArrowsMaximize, ArrowsMinimize } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AnalyticsChartBase from './AnalyticsChartBase';
import AnalyticsFilter from './AnalyticsFilter';
import AnalyticsCardSummary from './AnalyticsCardSummary';

const AnalyticsOverviewView = ({
  entityId,
  onFetchOverview,
  analyticConfigs,
  dataState,
  reportResult,
  summaryCardData,
  loading,
  urlBase
}) => {
  const hasFetched = useRef(false);
  const { isTabletOrSmaller, isDesktopOrSmaller } = useMediaQueryIndex();
  const [viewState, setViewState] = useState({ fullScreen: false, filter: {} });

  const overviewLoading = !hasFetched.current || loading;

  return (
    <AppStack
      style={{
        flex: 1,
        gap: 16,
        padding: isTabletOrSmaller ? 10 : '10px 20px 10px 15px',
        backgroundColor: '#F0F0F0',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        ...(viewState.fullScreen
          ? {
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 100,
              padding: 20
            }
          : {})
      }}
    >
      <AppFlexbox
        style={{
          gap: 8,
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
          <AppText style={{ fontSize: 20, fontWeight: 700 }}>Analytics</AppText>
        </AppFlexbox>
        <AppFlexbox>
          <Button
            color="dark"
            onClick={() =>
              setViewState({
                ...viewState,
                fullScreen: !viewState.fullScreen
              })
            }
            radius={8}
            size="compact-sm"
            variant="light"
          >
            {viewState.fullScreen ? (
              <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                <ArrowsMinimize size={18} />
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Minimize
                </AppText>
              </AppFlexbox>
            ) : (
              <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                <ArrowsMaximize size={18} />
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Fullscreen
                </AppText>
              </AppFlexbox>
            )}
          </Button>
        </AppFlexbox>
      </AppFlexbox>

      <AnalyticsFilter
        entityId={entityId}
        includeCompareDateRange
        onFilterChange={(filter) => {
          setViewState({ ...viewState, filter });
          onFetchOverview(filter);
          hasFetched.current = true;
        }}
      />

      <Grid columns={isTabletOrSmaller ? 1 : isDesktopOrSmaller ? 2 : 3}>
        {summaryCardData?.map((s) => (
          <Grid.Col key={s.label} span={1}>
            <AnalyticsCardSummary {...s} />
          </Grid.Col>
        ))}
      </Grid>

      <Grid columns={isTabletOrSmaller ? 1 : isDesktopOrSmaller ? 2 : 3}>
        {analyticConfigs.map((a) => (
          <Grid.Col key={a.reportUuid} span={1}>
            <AnalyticsChartBase
              chart={a}
              chartDataOverrideFunction={
                dataState && a.getData
                  ? (mainDataLabel, compareDataLabel) =>
                      a.getData(dataState, mainDataLabel, compareDataLabel)
                  : null
              }
              compareDateRange={{
                start: viewState.filter.compareStartDate,
                end: viewState.filter.compareEndDate
              }}
              dataState={dataState}
              dateRange={{
                start: viewState.filter.startDate,
                end: viewState.filter.endDate
              }}
              loading={overviewLoading}
              redirectPath={
                a.reportUuid
                  ? `${urlBase}/${a.reportUuid}?start=${
                      viewState.filter.datePeriod
                        ? viewState.filter.datePeriod.start
                        : dayjs(viewState.filter.startDate).format('YYYY-MM-DD')
                    }&end=${
                      viewState.filter.datePeriod
                        ? viewState.filter.datePeriod.end
                        : dayjs(viewState.filter.endDate).format('YYYY-MM-DD')
                    }&period=${viewState.filter.periodInterval}&from=analytics`
                  : null
              }
              reportResult={reportResult}
              selectedChartField={a.header}
            />
          </Grid.Col>
        ))}
      </Grid>
    </AppStack>
  );
};

AnalyticsOverviewView.propTypes = {
  analyticConfigs: PropTypes.array,
  dataState: PropTypes.object,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  onFetchOverview: PropTypes.func,
  reportResult: PropTypes.object,
  summaryCardData: PropTypes.array,
  urlBase: PropTypes.string
};

export default AnalyticsOverviewView;
