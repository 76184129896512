import {
  ECOM_ORDER_STATUS_ENUM,
  ECOM_PRODUCT_STATUS_ENUM,
  ECOM_SHIPPING_CARRIER_ENUM
} from '../config/constants';

const calculateVendorOrderProductAddonCost = (product) =>
  product.ecomOrderProductInputAnswers.reduce((total, answer) => {
    const input = product.ecomVendorProductVariant.ecomVendorProduct.ecomVendorProductInputs.find(
      (i) => i.pkEcomVendorProductInput === answer.fkEcomVendorProductInput
    );

    const optionCost =
      input?.adjustedCost ??
      input?.ecomVendorProductInputOptions.find((v) => v.value === answer.value)
        ?.adjustedCost ??
      0;

    return total + optionCost;
  }, 0);

const generateTrackingLink = (carrier, trackingNumber, customTrackingUrl) => {
  if (carrier === ECOM_SHIPPING_CARRIER_ENUM.OTHER) {
    return customTrackingUrl;
  }

  if (!carrier || !trackingNumber) {
    return null;
  }

  const trackingUrls = {
    [ECOM_SHIPPING_CARRIER_ENUM.CANADA_POST]: `https://www.canadapost-postescanada.ca/track-reperage/en#/details/${trackingNumber}`,
    [ECOM_SHIPPING_CARRIER_ENUM.UPS]: `https://www.ups.com/track?tracknum=${trackingNumber}`,
    [ECOM_SHIPPING_CARRIER_ENUM.FEDEX]: `https://www.fedex.com/en-us/tracking.html?tracknumbers=${trackingNumber}`,
    [ECOM_SHIPPING_CARRIER_ENUM.PUROLATOR]: `https://www.purolator.com/en/tools/tracking.page?track=${trackingNumber}`,
    [ECOM_SHIPPING_CARRIER_ENUM.DHL]: `https://www.dhl.com/global-en/home/tracking.html?trackingId=${trackingNumber}`,
    [ECOM_SHIPPING_CARRIER_ENUM.USPS]: `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`
  };

  return trackingUrls[carrier];
};

const translateVendorOrderProductData = (p, ecomOrderShippingProducts = []) => {
  const addonCost = calculateVendorOrderProductAddonCost(p);
  const amountRemaining =
    p.count -
    ecomOrderShippingProducts
      .filter(
        (f) =>
          f.fkEcomOrderProduct === p.pkEcomOrderProduct ??
          p.ecomOrderProduct.pkEcomOrderProduct
      )
      .reduce((r2, c2) => r2 + c2.count, 0);

  const shippingProducts = ecomOrderShippingProducts.filter(
    (f) => f.fkEcomOrderProduct === p.pkEcomOrderProduct
  );

  return {
    key: p.pkEcomOrderProduct,
    pkEcomOrderProductBulk: p.pkEcomOrderProductBulk,
    pkEcomOrderProduct: p.pkEcomOrderProduct,
    fkEcomOrderProductStatus: p.fkEcomOrderProductStatus,
    to: `/vendor/products/${p.ecomVendorProductVariant.ecomVendorProduct.uuid}/variants/${p.ecomVendorProductVariant.uuid}`,
    previewImage:
      p.ecomVendorProductVariant.previewImage ??
      p.ecomVendorProductVariant.ecomVendorProduct.previewImage,
    name: p.ecomVendorProductVariant.ecomVendorProduct.name,
    sku: p.ecomVendorProductVariant.sku,
    uuid: p.ecomVendorProductVariant.uuid,
    barcode: p.ecomVendorProductVariant.barcode,
    quantity: p.count,
    unitCost: p.ecomVendorProductVariant.price + addonCost,
    totalCost: (p.ecomVendorProductVariant.price + addonCost) * p.count,
    weight: p.ecomVendorProductVariant.weight,
    weightUnit: p.ecomVendorProductVariant.weightUnit,
    amountRemaining,
    addOns: [
      ...p.ecomVendorProductVariant.ecomVendorProductVariantOptions
        .sort((a, b) => a.nameSort - b.nameSort)
        .map((op) => ({
          sort: op.nameSort,
          name: op.name,
          value: op.value
        })),
      ...p.ecomOrderProductInputAnswers
        .map((a) => ({
          sort: a?.sort,
          name: a?.label,
          value: a.value
        }))
        .sort((a, b) => a.sort - b.sort)
    ],
    ecomOrderShippingProducts: shippingProducts,
    shippedCount: shippingProducts.reduce((r, c) => r + c.count, 0),
    ecomOrderProducts: []
  };
};

const translateVendorOrderData = (ecomOrder) => {
  const ecomOrderShippingProducts =
    ecomOrder?.ecomOrderShipping.reduce(
      (r, c) => [...r, ...c.ecomOrderShippingProducts],
      []
    ) ?? [];
  const productData = ecomOrder
    ? [
        ...ecomOrder.ecomOrderProducts.map((p) =>
          translateVendorOrderProductData(p, ecomOrderShippingProducts)
        ),
        ...ecomOrder.ecomOrderProductBulk.map((b) =>
          translateVendorOrderProductData(
            { ...b.ecomOrderProduct, ...b },
            ecomOrderShippingProducts
          )
        )
      ]
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .reduce((r, c) => {
          const existing = r.find(
            (p) =>
              p.uuid === c.uuid &&
              p.addOns.length === c.addOns.length &&
              p.addOns.every(
                (ao, i) =>
                  ao.name === c.addOns[i].name && ao.value === c.addOns[i].value
              )
          );
          if (existing) {
            existing.quantity += c.quantity;
            existing.totalCost += c.totalCost;
            existing.amountRemaining += c.amountRemaining;
            existing.ecomOrderShippingProducts.push(
              ...c.ecomOrderShippingProducts
            );
            existing.shippedCount += c.shippedCount;
            existing.ecomOrderProducts.push(c);
          }
          else {
            r.push(c);
          }
          return r;
        }, [])
    : [];

  return {
    ecomOrderShippingProducts,
    ecomOrderProducts: productData.sort((a, b) => a.name.localeCompare(b.name))
  };
};

const getEcomStoreProductStatus = (product) =>
  product.ecomVendorProduct?.deleted ||
  product.ecomVendorProduct.fkEcomProductStatus.toString() !==
    ECOM_PRODUCT_STATUS_ENUM.ACTIVE
    ? {
        label: 'Not available',
        color: 'red'
      }
    : product.fkEcomProductStatus.toString() === ECOM_PRODUCT_STATUS_ENUM.ACTIVE
    ? {
        label: 'Live',
        color: 'green'
      }
    : product.fkEcomProductStatus.toString() === ECOM_PRODUCT_STATUS_ENUM.DRAFT
    ? {
        label: 'Draft',
        color: 'darkgrey'
      }
    : {
        label: 'Pending action',
        color: 'blue'
      };

const getEcomVendorProductStatus = (product) =>
  product.fkEcomProductStatus.toString() === ECOM_PRODUCT_STATUS_ENUM.ACTIVE
    ? {
        label: 'Active',
        color: 'green'
      }
    : product.fkEcomProductStatus.toString() ===
      ECOM_PRODUCT_STATUS_ENUM.ARCHIVED
    ? {
        label: 'Archived',
        color: 'grey'
      }
    : {
        label: 'Draft',
        color: 'grey'
      };

export {
  calculateVendorOrderProductAddonCost,
  generateTrackingLink,
  translateVendorOrderData,
  translateVendorOrderProductData,
  getEcomStoreProductStatus,
  getEcomVendorProductStatus
};
