const REPORT_HEADER_ORDER_ENUM = {
  STORE: 'store',
  SERVICE_FEES: 'service_fees',
  TIERED_PRICING_FEES: 'tiered_pricing_fees',
  ADJUSTMENT: 'adjustment',
  ORDER_VALUE: 'order_value',
  AVERAGE_ORDER_VALUE: 'average_order_value',
  AMOUNT_PAID: 'amount_paid',
  AMOUNT_REFUNDED: 'amount_refunded',
  AMOUNT_DISCOUNTED: 'amount_discounted',
  FULFILLMENT_STATUS: 'fulfillment_status',
  GROSS_MARGIN: 'gross_margin',
  GROSS_SALES: 'gross_sales',
  NET_PROFIT: 'net_profit',
  NET_SALES: 'net_sales',
  ESCROW_AMOUNT_EARNED: 'escrow_amount_earned',
  ESCROW_AMOUNT_PAID: 'escrow_amount_paid',
  ESCROW_BALANCE: 'escrow_balance',
  ORDER_ID: 'order_id',
  ORDER_COUNT: 'order_count',
  SHIPPING_COST: 'shipping_cost',
  TAXES: 'TAXES',
  TOTAL_SALES: 'total_sales',
  SHIPPED_ORDERS: 'shipped_orders',
  DELIVERED_ORDERS: 'delivered_orders',
  FULFILLED_ORDERS: 'fulfilled_orders',
  DATE: 'date',
  TRANSACTION_COUNT: 'transaction_count',
  PAYMENT_COUNT: 'payment_count',
  PURCHASE_DATE: 'purchase_date'
};

const REPORT_HEADER_CUSTOMER_ENUM = {
  CUSTOMER_EMAIL: 'customer_email',
  CUSTOMER_NAME: 'customer_name',
  CUSTOMER_COUNT: 'customer_count',
  IS_RETURNING: 'is_returning',
  FIRST_ORDER_DATE: 'first_order_date',
  LAST_ORDER_DATE: 'last_order_date'
};

const REPORT_HEADER_PRODUCT_ENUM = {
  PRODUCT_ID: 'product_id',
  PRODUCT_TITLE: 'product_title',
  PRODUCT_NAME: 'product_name',
  PRODUCT_VENDOR: 'product_vendor',
  VARIANT_ID: 'variant_id',
  VARIANT_NAME: 'variant_name',
  VARIANT_SKU: 'variant_sku',
  PRODUCT_COST: 'product_cost',
  ORDERED_QUANTITY: 'ordered_quantity',
  UNITS_ORDERED: 'units_ordered',
  AVERAGE_UNITS_ORDERED: 'average_units_ordered',
  REFUNDED_UNITS: 'refunded_units',
  REFUND_RATE: 'refund_rate',
  NET_QUANTITY: 'net_quantity'
};

const REPORT_HEADER_SHIPPING_ENUM = {
  SHIPPING_ADDRESS: 'shipping_address',
  SHIPPING_CITY: 'shipping_city',
  SHIPPING_COUNTRY: 'shipping_country',
  SHIPPING_POSTAL_CODE: 'shipping_postal_code',
  SHIPPING_PROVINCE: 'shipping_province'
};

const REPORT_HEADER_FUNDRAISER_ENUM = {
  FUNDRAISER_NAME: 'fundraiser_name',
  SALES_WTIH_FUNDRAISER: 'sales_with_fundraiser'
};

const REPORT_HEADER_ADMIN_ENUM = {
  STRIPE_PRODUCT_FEES: 'stripe_product_fees',
  STRIPE_ADJUSTMENT_FEES: 'stripe_adjustment_fees',
  STRIPE_TOTAL_FEES: 'stripe_total_fees',
  SPORTSHEADZ_FEES: 'sportsheadz_fees',
  SPORTSHEADZ_PROFIT: 'sportsheadz_profit',
  VENDOR_AMOUNT_PAID: 'vendor_amount_paid',
  VENDOR_PAYOUT_BALANCE: 'vendor_payout_balance'
};

const REPORT_HEADER_LIST = [
  // ADMIN
  {
    value: REPORT_HEADER_ADMIN_ENUM.STRIPE_PRODUCT_FEES,
    label: 'Stripe product fees',
    category: 'Admin',
    allowedViews: ['admin']
  },
  {
    value: REPORT_HEADER_ADMIN_ENUM.STRIPE_ADJUSTMENT_FEES,
    label: 'Stripe adjustment fees',
    category: 'Admin',
    allowedViews: ['admin']
  },
  {
    value: REPORT_HEADER_ADMIN_ENUM.STRIPE_TOTAL_FEES,
    label: 'Stripe total fees',
    category: 'Admin',
    allowedViews: ['admin']
  },
  {
    value: REPORT_HEADER_ADMIN_ENUM.SPORTSHEADZ_FEES,
    label: 'SportsHeadz fees',
    category: 'Admin',
    allowedViews: ['admin']
  },
  {
    value: REPORT_HEADER_ADMIN_ENUM.SPORTSHEADZ_PROFIT,
    label: 'SportsHeadz profit',
    category: 'Admin',
    allowedViews: ['admin']
  },
  {
    value: REPORT_HEADER_ADMIN_ENUM.VENDOR_AMOUNT_PAID,
    label: 'Vendor amount paid',
    category: 'Admin',
    allowedViews: ['admin']
  },
  {
    value: REPORT_HEADER_ADMIN_ENUM.VENDOR_PAYOUT_BALANCE,
    label: 'Vendor payout balance',
    category: 'Admin',
    allowedViews: ['admin']
  },

  // ORDER
  {
    value: REPORT_HEADER_ORDER_ENUM.STORE,
    label: 'Store',
    category: 'Order'
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.ORDER_ID,
    label: 'Order ID',
    category: 'Order'
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.FULFILLMENT_STATUS,
    label: 'Fulfillment status',
    category: 'Order'
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.SHIPPED_ORDERS,
    label: 'Fulfilled orders',
    category: 'Order'
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.FULFILLED_ORDERS,
    label: 'Fulfilled orders',
    category: 'Order'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.PRODUCT_COST,
    label: 'Product cost',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.ADJUSTMENT,
    label: 'Adjustment',
    category: 'Order'
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.AVERAGE_ORDER_VALUE,
    label: 'Average order value',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
    label: 'Amount paid',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
    label: 'Amount refunded',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.AMOUNT_DISCOUNTED,
    label: 'Amount discounted',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.SERVICE_FEES,
    label: 'Service fees',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.TIERED_PRICING_FEES,
    label: 'Platform fees',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
    label: 'Gross sales',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.NET_PROFIT,
    label: 'Net profit',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.GROSS_MARGIN,
    label: 'Gross margin',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.ESCROW_AMOUNT_EARNED,
    label: 'Escrow amount earned',
    category: 'Order',
    allowedViews: ['admin', 'store']
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.ESCROW_AMOUNT_PAID,
    label: 'Escrow amount paid',
    category: 'Order',
    allowedViews: ['admin', 'store']
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.ESCROW_BALANCE,
    label: 'Escrow balance',
    category: 'Order',
    allowedViews: ['admin', 'store']
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.NET_SALES,
    label: 'Net sales',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
    label: 'Total sales',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.SHIPPING_COST,
    label: 'Shipping cost',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.TAXES,
    label: 'Taxes',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
    label: 'Order count',
    category: 'Order'
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.TRANSACTION_COUNT,
    label: 'Transaction count',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.PAYMENT_COUNT,
    label: 'Payment count',
    category: 'Order',
    chartable: true
  },
  {
    value: REPORT_HEADER_ORDER_ENUM.PURCHASE_DATE,
    label: 'Purchase date',
    category: 'Order',
    chartable: true
  },

  // CUSTOMER
  {
    value: REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
    label: 'Customer email',
    category: 'Customer'
  },
  {
    value: REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_NAME,
    label: 'Customer name',
    category: 'Customer'
  },
  {
    value: REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_COUNT,
    label: 'Customer count',
    category: 'Customer'
  },
  {
    value: REPORT_HEADER_CUSTOMER_ENUM.IS_RETURNING,
    label: 'Is returning',
    category: 'Customer'
  },
  {
    value: REPORT_HEADER_CUSTOMER_ENUM.FIRST_ORDER_DATE,
    label: 'First order date',
    category: 'Customer'
  },
  {
    value: REPORT_HEADER_CUSTOMER_ENUM.LAST_ORDER_DATE,
    label: 'Last order date',
    category: 'Customer'
  },

  // PRODUCT
  {
    value: REPORT_HEADER_PRODUCT_ENUM.PRODUCT_ID,
    label: 'Product ID',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.PRODUCT_TITLE,
    label: 'Product title',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
    label: 'Product name',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.PRODUCT_VENDOR,
    label: 'Product vendor',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.VARIANT_ID,
    label: 'Variant ID',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.VARIANT_NAME,
    label: 'Variant name',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.VARIANT_SKU,
    label: 'Variant SKU',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.ORDERED_QUANTITY,
    label: 'Ordered quantity',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.AVERAGE_UNITS_ORDERED,
    label: 'Average units ordered',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.REFUNDED_UNITS,
    label: 'Refunded units',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.REFUND_RATE,
    label: 'Refund rate',
    category: 'Product'
  },
  {
    value: REPORT_HEADER_PRODUCT_ENUM.NET_QUANTITY,
    label: 'Net quantity',
    category: 'Product'
  },

  // SHIPPING
  {
    value: REPORT_HEADER_SHIPPING_ENUM.SHIPPING_ADDRESS,
    label: 'Shipping address',
    category: 'Shipping'
  },
  {
    value: REPORT_HEADER_SHIPPING_ENUM.SHIPPING_CITY,
    label: 'Shipping city',
    category: 'Shipping'
  },
  {
    value: REPORT_HEADER_SHIPPING_ENUM.SHIPPING_PROVINCE,
    label: 'Shipping province',
    category: 'Shipping'
  },
  {
    value: REPORT_HEADER_SHIPPING_ENUM.SHIPPING_COUNTRY,
    label: 'Shipping country',
    category: 'Shipping'
  },
  {
    value: REPORT_HEADER_SHIPPING_ENUM.SHIPPING_POSTAL_CODE,
    label: 'Shipping postal code',
    category: 'Shipping'
  },

  // FUNDRAISER
  {
    value: REPORT_HEADER_FUNDRAISER_ENUM.FUNDRAISER_NAME,
    label: 'Fundraiser name',
    category: 'Fundraiser'
  },
  {
    value: REPORT_HEADER_FUNDRAISER_ENUM.SALES_WTIH_FUNDRAISER,
    label: 'Sales with fundraiser',
    category: 'Fundraiser'
  }
];

const REPORT_CATEGORY_ENUM = {
  SALES: '1',
  CUSTOMERS: '2',
  ORDERS: '3',
  FINANCES: '4'
};

const REPORT_CATEGORY_LIST = [
  {
    label: 'Sales',
    value: REPORT_CATEGORY_ENUM.SALES
  },
  {
    label: 'Customers',
    value: REPORT_CATEGORY_ENUM.CUSTOMERS
  },
  {
    label: 'Orders',
    value: REPORT_CATEGORY_ENUM.ORDERS
  },
  {
    label: 'Finances',
    value: REPORT_CATEGORY_ENUM.FINANCES
  }
];

const REPORT_TYPE_ENUM = {
  SALES: '1',
  CUSTOMERS: '2',
  ORDERS: '3'
};

const DEFAULT_REPORT_HEADERS = [
  // ADMIN
  REPORT_HEADER_ADMIN_ENUM.STRIPE_PRODUCT_FEES,
  REPORT_HEADER_ADMIN_ENUM.STRIPE_ADJUSTMENT_FEES,
  REPORT_HEADER_ADMIN_ENUM.STRIPE_TOTAL_FEES,
  REPORT_HEADER_ADMIN_ENUM.SPORTSHEADZ_FEES,
  REPORT_HEADER_ADMIN_ENUM.SPORTSHEADZ_PROFIT,
  REPORT_HEADER_ADMIN_ENUM.VENDOR_AMOUNT_PAID,
  REPORT_HEADER_ADMIN_ENUM.VENDOR_PAYOUT_BALANCE,

  // ORDER
  REPORT_HEADER_ORDER_ENUM.STORE,
  REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
  REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
  REPORT_HEADER_ORDER_ENUM.SERVICE_FEES,
  REPORT_HEADER_ORDER_ENUM.TIERED_PRICING_FEES,
  REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
  REPORT_HEADER_ORDER_ENUM.GROSS_MARGIN,
  REPORT_HEADER_ORDER_ENUM.NET_PROFIT,
  REPORT_HEADER_ORDER_ENUM.NET_SALES,
  REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
  REPORT_HEADER_ORDER_ENUM.SHIPPED_ORDERS,
  REPORT_HEADER_ORDER_ENUM.FULFILLED_ORDERS,
  REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
  REPORT_HEADER_ORDER_ENUM.TRANSACTION_COUNT,
  REPORT_HEADER_ORDER_ENUM.PAYMENT_COUNT,
  REPORT_HEADER_ORDER_ENUM.ESCROW_AMOUNT_EARNED,
  REPORT_HEADER_ORDER_ENUM.ESCROW_AMOUNT_PAID,
  REPORT_HEADER_ORDER_ENUM.ESCROW_BALANCE,

  // CUSTOMER
  REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
  REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_NAME,

  // PRODUCT
  REPORT_HEADER_PRODUCT_ENUM.PRODUCT_TITLE,
  REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
  REPORT_HEADER_PRODUCT_ENUM.PRODUCT_VENDOR,
  REPORT_HEADER_PRODUCT_ENUM.VARIANT_ID,
  REPORT_HEADER_PRODUCT_ENUM.VARIANT_NAME,
  REPORT_HEADER_PRODUCT_ENUM.VARIANT_SKU,
  REPORT_HEADER_PRODUCT_ENUM.ORDERED_QUANTITY,
  REPORT_HEADER_PRODUCT_ENUM.UNITS_ORDERED,
  REPORT_HEADER_PRODUCT_ENUM.AVERAGE_UNITS_ORDERED,
  REPORT_HEADER_PRODUCT_ENUM.REFUNDED_UNITS,
  REPORT_HEADER_PRODUCT_ENUM.REFUND_RATE,
  REPORT_HEADER_PRODUCT_ENUM.NET_QUANTITY,
  REPORT_HEADER_PRODUCT_ENUM.PRODUCT_COST,

  // SHIPPING
  REPORT_HEADER_SHIPPING_ENUM.SHIPPING_ADDRESS,
  REPORT_HEADER_SHIPPING_ENUM.SHIPPING_CITY,
  REPORT_HEADER_SHIPPING_ENUM.SHIPPING_PROVINCE,
  REPORT_HEADER_SHIPPING_ENUM.SHIPPING_COUNTRY,
  REPORT_HEADER_SHIPPING_ENUM.SHIPPING_POSTAL_CODE,

  // FUNDRAISER
  REPORT_HEADER_FUNDRAISER_ENUM.FUNDRAISER_NAME,
  REPORT_HEADER_FUNDRAISER_ENUM.SALES_WTIH_FUNDRAISER
];

const REPORT_TYPE_CONFIG = [
  {
    value: REPORT_TYPE_ENUM.SALES,
    label: 'Sales',
    chartable: true,
    headers: [
      ...DEFAULT_REPORT_HEADERS,

      // ORDER
      REPORT_HEADER_ORDER_ENUM.DATE,
      REPORT_HEADER_ORDER_ENUM.ORDER_ID,
      REPORT_HEADER_ORDER_ENUM.FULFILLMENT_STATUS,
      REPORT_HEADER_ORDER_ENUM.ADJUSTMENT,
      REPORT_HEADER_ORDER_ENUM.AVERAGE_ORDER_VALUE,

      // CUSTOMER
      REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_COUNT,

      // PRODUCT
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_ID,

      // FUNDRAISER
      REPORT_HEADER_FUNDRAISER_ENUM.SALES_WTIH_FUNDRAISER
    ]
  },
  {
    value: REPORT_TYPE_ENUM.CUSTOMERS,
    label: 'Customers',
    headers: [
      ...DEFAULT_REPORT_HEADERS,

      // CUSTOMER
      REPORT_HEADER_CUSTOMER_ENUM.FIRST_ORDER_DATE,
      REPORT_HEADER_CUSTOMER_ENUM.LAST_ORDER_DATE,
      REPORT_HEADER_CUSTOMER_ENUM.IS_RETURNING,

      // FUNDRAISER
      REPORT_HEADER_FUNDRAISER_ENUM.SALES_WTIH_FUNDRAISER
    ]
  },
  {
    value: REPORT_TYPE_ENUM.ORDERS,
    label: 'Orders',
    headers: [...DEFAULT_REPORT_HEADERS]
  }
];

const ECOM_REPORT_ENUM = {
  // OVERVIEW
  OVERVIEW: 'overview',

  // SALES
  SALES_OVER_TIME: 'sales_over_time',
  AVERAGE_ORDER_VALUE: 'average_order_value',
  SALES_BY_CUSTOMER: 'sales_by_customer',
  SALES_BY_PRODUCT: 'sales_by_product',
  SALES_BY_LOCATION: 'sales_by_location',
  SALES_BY_VARIANT: 'sales_by_product_variant',
  SALES_BY_SKU: 'sales_by_sku',
  SALES_BY_FUNDRAISER: 'sales_by_fundraiser',

  // CUSTOMERS
  TOP_CUSTOMERS: 'top_customers',
  RETURNING_CUSTOMERS: 'returning_customers',
  FIRST_TIME_CUSTOMERS: 'first_time_customers',
  CUSTOMERS_BY_LOCATION: 'customers_by_location',
  CUSTOMERS_OVER_TIME: 'customers_over_time',
  FIRST_VS_RETURNING_CUSTOMERS: 'first_vs_returning_customers',

  // ORDERS
  PRODUCT_ORDERS: 'product_orders_and_returns',
  FUNDRAISER_ORDERS: 'orders_by_fundraiser',
  ORDERS_OVER_TIME: 'orders_over_time',
  ORDER_VALUE: 'order_value',
  FULFILLMENT_STATUS: 'order_fulfillment_over_time',

  // INVENTORY
  SOLD_INVENTORY_PERCENTAGE: 'sold_inventory_percentage',
  INVENTORY_VALUE: 'inventory_value',
  INVENTORY_SNAPSHOT: 'inventory_snapshot',
  INVENTORY_OVER_TIME: 'inventory_over_time',
  AVERAGE_INVENTORY_SOLD: 'average_inventory_sold',

  // FINANCES
  AMOUNT_PAID: 'amount_paid',
  FINANCE_SUMMARY: 'finance_summary',
  TOTAL_SALES: 'total_sales',
  REFUNDS: 'refunds',
  PAYMENTS: 'payments',
  NET_SALES: 'net_sales',
  NET_PROFIT: 'net_profit',
  SERVICE_FEES: 'service_fees',
  GROSS_PAYMENT_BY_MONTH: 'gross_payment_by_month',
  GROSS_SALES: 'gross_sales',
  COST_OF_GOODS: 'cost_of_goods',

  // ADMIN
  ESCROW_BALANCE: 'escrow_balance',
  VENDOR_PAYOUT_BALANCE: 'vendor_payout_balance',
  SPORTSHEADZ_PROFIT: 'sportsheadz_profit',
  SALES_BY_STORE: 'sales_by_store',
  SALES_BY_VENDOR: 'sales_by_vendor'
};

const DEFAULT_REPORT_CONFIGS = [
  // SALES
  {
    title: 'Sales over time',
    uuid: ECOM_REPORT_ENUM.SALES_OVER_TIME,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.NET_SALES,
      REPORT_HEADER_ORDER_ENUM.TIERED_PRICING_FEES,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES
    ],
    groupBy: 'period',
    compareEnabled: true,
    chart: {
      type: 'line',
      defaultHeaderValue: REPORT_HEADER_ORDER_ENUM.TOTAL_SALES
    }
  },
  {
    title: 'Average order value over time',
    uuid: ECOM_REPORT_ENUM.AVERAGE_ORDER_VALUE,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
    groupBy: 'period',
    compareEnabled: true,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.AVERAGE_ORDER_VALUE,
      REPORT_HEADER_ORDER_ENUM.NET_PROFIT
    ],
    chart: {
      type: 'line',
      defaultHeaderValue: REPORT_HEADER_ORDER_ENUM.AVERAGE_ORDER_VALUE
    }
  },
  {
    title: 'Sales by customer',
    uuid: ECOM_REPORT_ENUM.SALES_BY_CUSTOMER,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
    groupBy: REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
      REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_NAME,
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.NET_SALES,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
      REPORT_HEADER_ORDER_ENUM.NET_PROFIT
    ]
  },
  {
    title: 'Sales by location',
    uuid: ECOM_REPORT_ENUM.SALES_BY_LOCATION,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
    groupBy: REPORT_HEADER_SHIPPING_ENUM.SHIPPING_CITY,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_SHIPPING_ENUM.SHIPPING_CITY,
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.NET_SALES,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
      REPORT_HEADER_ORDER_ENUM.NET_PROFIT
    ]
  },
  {
    title: 'Sales by fundraiser',
    uuid: ECOM_REPORT_ENUM.SALES_BY_FUNDRAISER,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
    groupBy: REPORT_HEADER_FUNDRAISER_ENUM.FUNDRAISER_NAME,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_FUNDRAISER_ENUM.FUNDRAISER_NAME,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.NET_SALES,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
      REPORT_HEADER_ORDER_ENUM.NET_PROFIT
    ]
  },
  // {
  //   title: 'Sales by product',
  //   uuid: ECOM_REPORT_ENUM.SALES_BY_PRODUCT,
  //   fkReportType: REPORT_TYPE_ENUM.SALES,
  //   fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
  //   groupBy: REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
  //   compareEnabled: false,
  //   defaultHeaders: [
  //     REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
  //     REPORT_HEADER_PRODUCT_ENUM.PRODUCT_VENDOR,
  //     REPORT_HEADER_PRODUCT_ENUM.NET_QUANTITY,
  //     REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
  //     REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
  //     REPORT_HEADER_ORDER_ENUM.NET_SALES,
  //     REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
  //     REPORT_HEADER_ORDER_ENUM.NET_PROFIT
  //   ]
  // },
  // {
  //   title: 'Sales by variant',
  //   uuid: ECOM_REPORT_ENUM.SALES_BY_VARIANT,
  //   fkReportType: REPORT_TYPE_ENUM.SALES,
  //   fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
  //   groupBy: REPORT_HEADER_PRODUCT_ENUM.VARIANT_NAME,
  //   compareEnabled: false,
  //   defaultHeaders: [
  //     REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
  //     REPORT_HEADER_PRODUCT_ENUM.VARIANT_NAME,
  //     REPORT_HEADER_PRODUCT_ENUM.PRODUCT_VENDOR,
  //     REPORT_HEADER_PRODUCT_ENUM.NET_QUANTITY,
  //     REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
  //     REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
  //     REPORT_HEADER_ORDER_ENUM.NET_SALES,
  //     REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
  //     REPORT_HEADER_ORDER_ENUM.NET_PROFIT
  //   ]
  // },
  // {
  //   title: 'Sales by SKU',
  //   uuid: ECOM_REPORT_ENUM.SALES_BY_SKU,
  //   fkReportType: REPORT_TYPE_ENUM.SALES,
  //   fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
  //   groupBy: REPORT_HEADER_PRODUCT_ENUM.VARIANT_SKU,
  //   compareEnabled: false,
  //   defaultHeaders: [
  //     REPORT_HEADER_PRODUCT_ENUM.VARIANT_SKU,
  //     REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
  //     REPORT_HEADER_PRODUCT_ENUM.PRODUCT_VENDOR,
  //     REPORT_HEADER_PRODUCT_ENUM.NET_QUANTITY,
  //     REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
  //     REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
  //     REPORT_HEADER_ORDER_ENUM.NET_SALES,
  //     REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
  //     REPORT_HEADER_ORDER_ENUM.NET_PROFIT
  //   ]
  // },

  // CUSTOMERS
  {
    title: 'Customers by spending',
    uuid: ECOM_REPORT_ENUM.TOP_CUSTOMERS,
    fkReportType: REPORT_TYPE_ENUM.CUSTOMERS,
    fkReportCategory: REPORT_CATEGORY_ENUM.CUSTOMERS,
    groupBy: REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
      REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_NAME,
      REPORT_HEADER_CUSTOMER_ENUM.IS_RETURNING,
      REPORT_HEADER_CUSTOMER_ENUM.FIRST_ORDER_DATE,
      REPORT_HEADER_CUSTOMER_ENUM.LAST_ORDER_DATE,
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED
    ]
  },
  // {
  //   title: 'First time customers',
  //   uuid: ECOM_REPORT_ENUM.FIRST_TIME_CUSTOMERS,
  //   fkReportType: REPORT_TYPE_ENUM.CUSTOMERS,
  //   fkReportCategory: REPORT_CATEGORY_ENUM.CUSTOMERS,
  //   groupBy: REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
  //   compareEnabled: false,
  //   defaultHeaders: [
  //     REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
  //     REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_NAME,
  //     REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
  //     REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
  //     REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED
  //   ]
  // },
  // {
  //   title: 'Returning customers',
  //   uuid: ECOM_REPORT_ENUM.RETURNING_CUSTOMERS,
  //   fkReportType: REPORT_TYPE_ENUM.CUSTOMERS,
  //   fkReportCategory: REPORT_CATEGORY_ENUM.CUSTOMERS,
  //   groupBy: REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
  //   compareEnabled: false,
  //   defaultHeaders: [
  //     REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_EMAIL,
  //     REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_NAME,
  //     REPORT_HEADER_CUSTOMER_ENUM.FIRST_ORDER_DATE,
  //     REPORT_HEADER_CUSTOMER_ENUM.LAST_ORDER_DATE,
  //     REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
  //     REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
  //     REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED
  //   ]
  // },
  {
    title: 'Customers over time',
    uuid: ECOM_REPORT_ENUM.CUSTOMERS_OVER_TIME,
    fkReportType: REPORT_TYPE_ENUM.CUSTOMERS,
    fkReportCategory: REPORT_CATEGORY_ENUM.CUSTOMERS,
    groupBy: 'period',
    compareEnabled: false,
    defaultHeaders: [REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_COUNT]
  },
  {
    title: 'Customers by location',
    uuid: ECOM_REPORT_ENUM.CUSTOMERS_BY_LOCATION,
    fkReportType: REPORT_TYPE_ENUM.CUSTOMERS,
    fkReportCategory: REPORT_CATEGORY_ENUM.CUSTOMERS,
    groupBy: REPORT_HEADER_SHIPPING_ENUM.SHIPPING_CITY,
    compareEnabled: false,
    defaultHeaders: [REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_COUNT]
  },

  // {
  //   title: 'First vs returning customer sales',
  //   uuid: ECOM_REPORT_ENUM.FIRST_VS_RETURNING_CUSTOMERS,
  //   fkReportType: REPORT_TYPE_ENUM.CUSTOMERS,
  //   fkReportCategory: REPORT_CATEGORY_ENUM.CUSTOMERS,
  //   groupBy: REPORT_HEADER_SHIPPING_ENUM.IS_RETURNING,
  //   compareEnabled: false,
  //   defaultHeaders: [
  //     REPORT_HEADER_CUSTOMER_ENUM.CUSTOMER_COUNT,
  //     REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
  //     REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
  //     REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
  //     REPORT_HEADER_ORDER_ENUM.GROSS_SALES
  //   ]
  // },

  // ORDERS
  // {
  //   title: 'Orders by product',
  //   uuid: ECOM_REPORT_ENUM.PRODUCT_ORDERS,
  //   fkReportType: REPORT_TYPE_ENUM.ORDERS,
  //   fkReportCategory: REPORT_CATEGORY_ENUM.ORDERS,
  //   groupBy: REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
  //   compareEnabled: false,
  //   defaultHeaders: [
  //     REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
  //     REPORT_HEADER_PRODUCT_ENUM.ORDERED_QUANTITY,
  //     REPORT_HEADER_PRODUCT_ENUM.REFUNDED_UNITS,
  //     REPORT_HEADER_PRODUCT_ENUM.NET_QUANTITY,
  //     REPORT_HEADER_PRODUCT_ENUM.REFUND_RATE
  //   ]
  // },
  {
    title: 'Orders by fundraiser',
    uuid: ECOM_REPORT_ENUM.FUNDRAISER_ORDERS,
    fkReportType: REPORT_TYPE_ENUM.ORDERS,
    fkReportCategory: REPORT_CATEGORY_ENUM.ORDERS,
    groupBy: REPORT_HEADER_FUNDRAISER_ENUM.FUNDRAISER_NAME,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_FUNDRAISER_ENUM.FUNDRAISER_NAME,
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_PRODUCT_ENUM.UNITS_ORDERED,
      REPORT_HEADER_ORDER_ENUM.AVERAGE_ORDER_VALUE,
      REPORT_HEADER_PRODUCT_ENUM.REFUNDED_UNITS,
      REPORT_HEADER_PRODUCT_ENUM.REFUND_RATE
    ]
  },
  {
    title: 'Orders over time',
    uuid: ECOM_REPORT_ENUM.ORDERS_OVER_TIME,
    fkReportType: REPORT_TYPE_ENUM.ORDERS,
    fkReportCategory: REPORT_CATEGORY_ENUM.ORDERS,
    groupBy: 'period',
    compareEnabled: true,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_PRODUCT_ENUM.UNITS_ORDERED,
      REPORT_HEADER_ORDER_ENUM.AVERAGE_ORDER_VALUE,
      REPORT_HEADER_PRODUCT_ENUM.REFUNDED_UNITS,
      REPORT_HEADER_PRODUCT_ENUM.REFUND_RATE
    ],
    chart: {
      type: 'line',
      defaultHeaderValue: REPORT_HEADER_ORDER_ENUM.ORDER_COUNT
    }
  },
  {
    title: 'Order fulfillment over time',
    uuid: ECOM_REPORT_ENUM.FULFILLMENT_STATUS,
    fkReportType: REPORT_TYPE_ENUM.ORDERS,
    fkReportCategory: REPORT_CATEGORY_ENUM.ORDERS,
    groupBy: 'period',
    compareEnabled: true,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.FULFILLED_ORDERS,
      REPORT_HEADER_ORDER_ENUM.SHIPPED_ORDERS
    ]
  },

  // FINANCES
  {
    title: 'Total sales',
    uuid: ECOM_REPORT_ENUM.TOTAL_SALES,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.FINANCES,
    groupBy: REPORT_HEADER_ORDER_ENUM.ORDER_ID,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.DATE,
      REPORT_HEADER_ORDER_ENUM.ORDER_ID,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.NET_SALES,
      REPORT_HEADER_ORDER_ENUM.TIERED_PRICING_FEES,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
      REPORT_HEADER_ORDER_ENUM.NET_PROFIT
    ]
  },
  {
    title: 'Refunds',
    uuid: ECOM_REPORT_ENUM.REFUNDS,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.FINANCES,
    groupBy: REPORT_HEADER_ORDER_ENUM.ORDER_ID,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.DATE,
      REPORT_HEADER_ORDER_ENUM.ORDER_ID,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_PRODUCT_ENUM.ORDERED_QUANTITY,
      REPORT_HEADER_PRODUCT_ENUM.REFUNDED_UNITS,
      REPORT_HEADER_PRODUCT_ENUM.NET_QUANTITY
    ]
  },
  {
    title: 'Payments',
    uuid: ECOM_REPORT_ENUM.PAYMENTS,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.FINANCES,
    groupBy: REPORT_HEADER_ORDER_ENUM.ORDER_ID,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.DATE,
      REPORT_HEADER_ORDER_ENUM.ORDER_ID,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.NET_PROFIT,
      REPORT_HEADER_ORDER_ENUM.TRANSACTION_COUNT,
      REPORT_HEADER_ORDER_ENUM.PAYMENT_COUNT,
      REPORT_HEADER_SHIPPING_ENUM.SHIPPING_COUNTRY,
      REPORT_HEADER_SHIPPING_ENUM.SHIPPING_CITY
    ]
  },
  {
    title: 'Net sales',
    uuid: ECOM_REPORT_ENUM.NET_SALES,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.FINANCES,
    groupBy: REPORT_HEADER_ORDER_ENUM.ORDER_ID,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.DATE,
      REPORT_HEADER_ORDER_ENUM.ORDER_ID,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
      REPORT_HEADER_ORDER_ENUM.NET_SALES
    ]
  },
  {
    title: 'Gross sales',
    uuid: ECOM_REPORT_ENUM.GROSS_SALES,
    fkReportType: REPORT_TYPE_ENUM.ORDERS,
    fkReportCategory: REPORT_CATEGORY_ENUM.FINANCES,
    groupBy: REPORT_HEADER_ORDER_ENUM.ORDER_ID,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.DATE,
      REPORT_HEADER_ORDER_ENUM.ORDER_ID,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES
    ]
  },
  {
    title: 'Cost of goods',
    uuid: ECOM_REPORT_ENUM.COST_OF_GOODS,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.FINANCES,
    groupBy: REPORT_HEADER_ORDER_ENUM.ORDER_ID,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.DATE,
      REPORT_HEADER_ORDER_ENUM.ORDER_ID,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_TITLE,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_COST
    ]
  },
  {
    title: 'Net profit',
    uuid: ECOM_REPORT_ENUM.NET_PROFIT,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.FINANCES,
    groupBy: 'payment_id',
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.DATE,
      REPORT_HEADER_ORDER_ENUM.ORDER_ID,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_TITLE,
      REPORT_HEADER_ORDER_ENUM.NET_PROFIT
    ]
  }
];

export {
  REPORT_HEADER_ADMIN_ENUM,
  REPORT_HEADER_ORDER_ENUM,
  REPORT_HEADER_CUSTOMER_ENUM,
  REPORT_HEADER_PRODUCT_ENUM,
  REPORT_HEADER_SHIPPING_ENUM,
  REPORT_HEADER_FUNDRAISER_ENUM,
  REPORT_HEADER_LIST,
  REPORT_CATEGORY_ENUM,
  REPORT_CATEGORY_LIST,
  REPORT_TYPE_ENUM,
  REPORT_TYPE_CONFIG,
  ECOM_REPORT_ENUM,
  DEFAULT_REPORT_CONFIGS
};
