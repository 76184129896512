import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeft, ChevronDown } from 'tabler-icons-react';
import { Button, Menu, Skeleton } from '@mantine/core';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Context as AdminContext } from '../../../providers/AdminContextProvider';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppMenu from '../../common/AppMenu';
import AdminVendorInfoCard from './AdminVendorInfoCard';
import AdminVendorPaymentProviderInfoCard from './AdminVendorPaymentProviderInfoCard';
import AdminVendorPayoutInfoCard from './AdminVendorPayoutInfoCard';
import { formatUtcDate } from '../../../helpers/format';
import ActionableIcon from '../../common/ActionableIcon';
import OrderStatsCard from '../orders/OrderStatsCard';

const AdminVendorDetailsView = ({ loading }) => {
  const { pkEcomVendor } = useParams();
  const { isDesktopOrSmaller } = useMediaQueryIndex();
  const { state } = useContext(AdminContext);
  const ecomVendorDetails = state.ecomVendor.value;
  const remainingPayoutAmount = ecomVendorDetails
    ? ecomVendorDetails.transactionsOverview.AmountEarned -
      ecomVendorDetails.transactionsOverview.AmountPaidToVendor
    : 0;

  return !loading && ecomVendorDetails ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        style={{
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to="/admin/vendors"
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>

          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
              <AppText
                style={{
                  flex: 1,
                  fontSize: 24,
                  fontWeight: 700,
                  wordBreak: 'break-word'
                }}
              >
                {ecomVendorDetails.ecomVendor.name}
              </AppText>
            </AppFlexbox>
            <AppText
              style={{
                fontSize: 12,
                lineHeight: '16px',
                color: '#666'
              }}
            >
              Created{' '}
              {dayjs(
                formatUtcDate(ecomVendorDetails.ecomVendor.createdAt)
              ).format('MMMM D, YYYY')}{' '}
              at{' '}
              {dayjs(
                formatUtcDate(ecomVendorDetails.ecomVendor.createdAt)
              ).format('h:mm A')}
            </AppText>
          </AppStack>
        </AppFlexbox>

        <AppFlexbox style={{ alignItems: 'center', gap: 8, marginTop: 5 }}>
          <AppMenu
            control={
              <Button
                color="dark"
                rightSection={<ChevronDown size={18} />}
                size="compact-md"
                style={{ fontSize: 14 }}
                variant="light"
              >
                Actions
              </Button>
            }
          >
            <Menu.Item
              component={Link}
              disabled={remainingPayoutAmount <= 0}
              to={`/admin/vendors/${ecomVendorDetails.ecomVendor.pkEcomVendor}/payouts/create`}
            >
              Create Payout
            </Menu.Item>
          </AppMenu>
        </AppFlexbox>
      </AppFlexbox>
      <AppFlexbox
        style={{
          flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row',
          width: '100%'
        }}
      >
        <AppStack style={{ flex: 1, width: '100%' }}>
          <OrderStatsCard
            data={[
              {
                label: 'Amount earned',
                value: ecomVendorDetails.transactionsOverview.amountEarned / 100
              },
              {
                label: 'Received',
                value:
                  ecomVendorDetails.transactionsOverview.amountPaidToVendor /
                  100
              },
              {
                label: 'Amount refunded',
                value:
                  ecomVendorDetails.transactionsOverview
                    .amountRefundedFromVendor / 100
              },
              {
                label: 'Orders',
                value: ecomVendorDetails.transactionsOverview.orderCount,
                type: 'string'
              }
            ]}
          />
          <AdminVendorPayoutInfoCard ecomVendorDetails={ecomVendorDetails} />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <AdminVendorInfoCard ecomVendor={ecomVendorDetails.ecomVendor} />
          <AdminVendorPaymentProviderInfoCard
            ecomVendor={ecomVendorDetails.ecomVendor}
          />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  ) : (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        style={{
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to={`/admin/vendors/${pkEcomVendor}`}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          <AppStack style={{ gap: 5 }}>
            <Skeleton height={36} width={200} />
            <Skeleton height={14} width={150} />
          </AppStack>
        </AppFlexbox>
        <AppFlexbox style={{ alignItems: 'center', gap: 8, marginTop: 5 }}>
          <Skeleton height={28} width={95} />
        </AppFlexbox>
      </AppFlexbox>
      <AppFlexbox
        style={{
          flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row',
          width: '100%'
        }}
      >
        <AppStack style={{ flex: 1, width: '100%' }}>
          <OrderStatsCard loading />
          <AdminVendorPayoutInfoCard />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <AdminVendorInfoCard />
          <AdminVendorPaymentProviderInfoCard />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

AdminVendorDetailsView.propTypes = { loading: PropTypes.bool };

export default AdminVendorDetailsView;
