import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import ResponsiveModal from '../../common/ResponsiveModal';
import { triggerNotification } from '../../../helpers/notification';
import StoreProductInputAnswerForm from './StoreProductInputAnswerForm';

const StoreProductInputAnswerModal = ({
  ecomStoreProducts,
  isOpen,
  onClose
}) => {
  const { state: authState } = useContext(AuthContext);
  const { updateStoreProducts } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    selectedUuid: '',
    ecomStoreProducts: [],
    loading: true
  });

  useEffect(() => {
    if (isOpen && ecomStoreProducts) {
      setFormState({
        selectedUuid: ecomStoreProducts[0].uuid,
        ecomStoreProducts: ecomStoreProducts.map((p) => ({
          uuid: p.uuid,
          name: p.ecomVendorProduct.name,
          vendorName: p.ecomVendorProduct.vendorName,
          ecomVendorProductVariantCount: p.ecomStoreProductVariants.length,
          ecomVendorProductInputs: p.ecomVendorProduct.ecomVendorProductInputs,
          ecomStoreProductInputAnswers: p.ecomStoreProductInputAnswers
            .map((a) => {
              const input = p.ecomVendorProduct.ecomVendorProductInputs.find(
                (i) =>
                  i.pkEcomVendorProductInput === a.fkEcomVendorProductInput ||
                  (a.label?.toLowerCase() === i.label?.toLowerCase() &&
                    a.fkRegFormControlType === i.fkRegFormControlType)
              );

              return input
                ? {
                    value: a.value,
                    fkEcomVendorProductInput: input.pkEcomVendorProductInput
                  }
                : null;
            })
            .filter(Boolean)
            .filter(
              (value, index, self) =>
                self.findIndex(
                  (item) =>
                    item.fkEcomVendorProductInput ===
                    value.fkEcomVendorProductInput
                ) === index
            ),
          hasChanges: false
        })),
        loading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        isLoading: formState.loading,
        onCancel: onClose,
        onSubmit: () => {
          setFormState({
            ...formState,
            loading: true
          });
          updateStoreProducts(
            authState.pkEcomStore,
            {
              ecomStoreProducts: formState.ecomStoreProducts.filter(
                (f) => f.hasChanges
              ),
              selectFirstItem: formState.ecomStoreProducts.length === 1
            },
            () => {
              onClose();
              triggerNotification(
                ecomStoreProducts.length > 1
                  ? 'Products updated!'
                  : 'Product updated!',
                'success'
              );
            },
            (e) => {
              triggerNotification(e);
              setFormState({
                ...formState,
                loading: false
              });
            }
          );
        },
        submitTitle: 'Save changes',
        isSubmitDisabled: !formState.ecomStoreProducts.some((p) => p.hasChanges)
      }}
      isOpen={isOpen}
      onClose={onClose}
      size={550}
      title="Autopopulate product inputs"
    >
      {ecomStoreProducts && (
        <StoreProductInputAnswerForm
          ecomStoreProducts={formState.ecomStoreProducts}
          onChangeProducts={(v) =>
            setFormState({
              ...formState,
              ecomStoreProducts: v
            })
          }
          onChangeSelectedUuid={(v) =>
            setFormState({
              ...formState,
              selectedUuid: v
            })
          }
          pkEcomStore={authState.pkEcomStore}
          selectedUuid={formState.selectedUuid}
        />
      )}
    </ResponsiveModal>
  );
};

StoreProductInputAnswerModal.propTypes = {
  ecomStoreProducts: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default StoreProductInputAnswerModal;
