import React, { useContext, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeft } from 'tabler-icons-react';
import { Anchor, Divider, Skeleton } from '@mantine/core';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import ActionableIcon from '../../common/ActionableIcon';
import AppCard from '../../common/AppCard';
import { triggerNotification } from '../../../helpers/notification';
import ResponsiveModal from '../../common/ResponsiveModal';
import { formatUtcDate } from '../../../helpers/format';
import OrderStatsCard from '../orders/OrderStatsCard';
import OrderSummaryCard from '../orders/OrderSummaryCard';
import CustomerTimeline from './CustomerTimeline';

const CustomerDetailsView = ({ isVendorView }) => {
  const hasFetched = useRef(false);
  const { pkEcomCustomer } = useParams();
  const { isTabletOrSmaller, isDesktopOrSmaller } = useMediaQueryIndex();
  const { state: authState } = useContext(AuthContext);
  const { state: storeState, fetchStoreCustomer } = useContext(StoreContext);
  const { state: vendorState, fetchVendorCustomer } = useContext(VendorContext);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const state = isVendorView ? vendorState : storeState;
  const loading = !hasFetched.current || state.ecomCustomer.loading;
  const { value: ecomCustomerInfo } =
    state.ecomCustomer.value &&
    pkEcomCustomer ===
      state.ecomCustomer.value.ecomCustomer.pkEcomCustomer.toString()
      ? state.ecomCustomer
      : {};
  const defaultAddress =
    ecomCustomerInfo?.ecomCustomer.ecomCustomerAddresses.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    )[0] ?? null;

  const urlBase = isVendorView ? '/vendor/customers' : '/merchant/customers';

  useEffect(() => {
    if (
      pkEcomCustomer &&
      (isVendorView ? !!authState.pkEcomVendor : !!authState.pkEcomStore)
    ) {
      if (isVendorView) {
        fetchVendorCustomer(
          authState.pkEcomVendor,
          { pkEcomCustomer },
          null,
          (error) => {
            triggerNotification(error);
          }
        );
      }
      else {
        fetchStoreCustomer(
          authState.pkEcomStore,
          { pkEcomCustomer },
          null,
          (error) => {
            triggerNotification(error);
          }
        );
      }
      hasFetched.current = true;
    }
  }, [authState.pkEcomVendor, authState.pkEcomStore, pkEcomCustomer]);

  return !loading && ecomCustomerInfo ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to={urlBase}
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 0 }}>
          <AppText
            style={{
              flex: 1,
              fontSize: 24,
              fontWeight: 700,
              wordBreak: 'break-word'
            }}
          >
            {ecomCustomerInfo.ecomCustomer.user?.name ||
              ecomCustomerInfo.ecomCustomer.email}
          </AppText>
          <AppText
            style={{
              fontSize: 12,
              lineHeight: '16px',
              color: '#666'
            }}
          >
            Customer since{' '}
            {dayjs(
              formatUtcDate(ecomCustomerInfo.ecomCustomer.firstPurchaseDate)
            ).format('MMMM D, YYYY')}
          </AppText>
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderStatsCard
            data={[
              {
                label: 'Total spent',
                value: ecomCustomerInfo.ecomCustomer.totalSpent / 100
              },
              {
                label: 'Average order value',
                value:
                  ecomCustomerInfo.ecomCustomer.totalSpent /
                  ecomCustomerInfo.ecomCustomer.ecomOrderCount /
                  100
              },
              {
                label: 'Amount refunded',
                value: ecomCustomerInfo.ecomCustomer.totalRefunded / 100
              },
              {
                label: 'Orders',
                value: ecomCustomerInfo.ecomCustomer.ecomOrderCount,
                type: 'string'
              }
            ]}
          />
          <OrderSummaryCard
            ecomOrder={ecomCustomerInfo.lastOrderPlaced}
            isVendorOrder={isVendorView}
            urlBase={isVendorView ? '/vendor/orders' : '/merchant/orders'}
          />

          <CustomerTimeline ecomCustomer={ecomCustomerInfo.ecomCustomer} />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack
              style={{
                gap: 0,
                flexDirection:
                  !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
              }}
            >
              <AppStack style={{ flex: 1, padding: 16, gap: 8 }}>
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                    Contact information
                  </AppText>
                </AppFlexbox>
                <AppStack style={{ gap: 0 }}>
                  <AppText style={{ fontSize: 13 }}>
                    {ecomCustomerInfo.ecomCustomer.user?.name}
                  </AppText>
                  <Anchor
                    href={`mailto:${ecomCustomerInfo.ecomCustomer.email}`}
                    style={{ fontSize: 13, color: 'dodgerblue' }}
                    target="_blank"
                  >
                    {ecomCustomerInfo.ecomCustomer.email}
                  </Anchor>
                </AppStack>
              </AppStack>

              <Divider
                orientation={
                  !isTabletOrSmaller && isDesktopOrSmaller
                    ? 'vertical'
                    : 'horizontal'
                }
              />
              <AppStack style={{ flex: 1, padding: 16, gap: 8 }}>
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                    Default address
                  </AppText>
                  {ecomCustomerInfo.ecomCustomer.ecomCustomerAddresses.length >
                    1 && (
                    <Anchor
                      onClick={() => onOpenModal('addresses')}
                      style={{ color: 'dodgerblue', fontSize: 14 }}
                    >
                      View all
                    </Anchor>
                  )}
                </AppFlexbox>
                {defaultAddress && (
                  <AppStack style={{ gap: 0 }}>
                    <AppText style={{ fontSize: 13 }}>
                      {defaultAddress.fullName}
                    </AppText>
                    <AppText style={{ fontSize: 13 }}>
                      {defaultAddress.address1}
                    </AppText>
                    {defaultAddress.address2 && (
                      <AppText style={{ fontSize: 13 }}>
                        {defaultAddress.address2}
                      </AppText>
                    )}
                    <AppText style={{ fontSize: 13 }}>
                      {defaultAddress.city} {defaultAddress.state}{' '}
                      {defaultAddress.postalCode}
                    </AppText>
                    <AppText style={{ fontSize: 13 }}>
                      {defaultAddress.country}
                    </AppText>
                  </AppStack>
                )}
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>

      <ResponsiveModal
        formSectionProps={{
          cancelTitle: 'Close',
          onCancel: onCloseModal,
          isSubmitHidden: true
        }}
        isOpen={modalState.isOpen && modalState.action === 'addresses'}
        onClose={onCloseModal}
        title="Customer addresses"
      >
        <AppStack style={{}}>
          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            {ecomCustomerInfo.ecomCustomer.ecomCustomerAddresses.map(
              (a, index) => (
                <AppStack
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  style={{
                    padding: '8px 16px',
                    gap: 0,
                    borderTop: index !== 0 ? '1px solid #f0f0f0' : 'none'
                  }}
                >
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    {a.fullName}
                  </AppText>
                  <AppText style={{ fontSize: 14 }}>{a.address1}</AppText>
                  {a.address2 && (
                    <AppText style={{ fontSize: 14 }}>{a.address1}</AppText>
                  )}
                  <AppText style={{ fontSize: 14 }}>
                    {a.city} {a.state} {a.postalCode}
                  </AppText>
                  <AppText style={{ fontSize: 14 }}>{a.country}</AppText>
                  <AppText style={{ fontSize: 12, color: '#999' }}>
                    Last used{' '}
                    {dayjs(formatUtcDate(a.createdAt)).format('MMMM D, YYYY')}
                  </AppText>
                </AppStack>
              )
            )}
          </AppCard>
        </AppStack>
      </ResponsiveModal>
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to={urlBase}
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 5 }}>
          <Skeleton height={36} width={200} />
          <Skeleton height={14} width={150} />
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderStatsCard loading />

          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 16, padding: 16 }}>
                <Skeleton height={22} width={150} />
                <AppStack style={{ gap: 8 }}>
                  <AppFlexbox
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppFlexbox style={{ alignItems: 'center', gap: 8 }}>
                      <Skeleton height={22} width={50} />
                      <Skeleton height={22} radius={100} width={120} />
                    </AppFlexbox>

                    <Skeleton height={22} width={75} />
                  </AppFlexbox>
                  <Skeleton height={14} width={150} />
                </AppStack>

                <AppStack style={{ gap: 8, marginTop: 8 }}>
                  <AppFlexbox
                    style={{
                      gap: 16,
                      alignItems: 'start',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppFlexbox style={{ gap: 16 }}>
                      <Skeleton height={60} width={60} />
                      <AppStack style={{ flex: 1, gap: 5 }}>
                        <Skeleton height={22} width={150} />
                        <Skeleton height={12} width={80} />
                      </AppStack>
                    </AppFlexbox>

                    <Skeleton height={22} width={80} />
                  </AppFlexbox>
                </AppStack>
              </AppStack>
              <Divider />
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'end',
                  padding: 16,
                  gap: 16
                }}
              >
                <Skeleton height={34} width={108} />
              </AppFlexbox>
            </AppStack>
          </AppCard>
        </AppStack>

        <AppStack
          style={{
            flex: 1,
            maxWidth: isDesktopOrSmaller ? 'unset' : 350
          }}
        >
          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack
              style={{
                gap: 0,
                flexDirection:
                  !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
              }}
            >
              <AppStack
                style={{
                  flex: 1,
                  padding: 16,
                  gap: 12
                }}
              >
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={20} width={90} />
                </AppFlexbox>
                <AppStack style={{ gap: 5 }}>
                  <Skeleton height={16} width={120} />
                  <Skeleton height={16} width={140} />
                </AppStack>
              </AppStack>

              <Divider
                orientation={
                  !isTabletOrSmaller && isDesktopOrSmaller
                    ? 'vertical'
                    : 'horizontal'
                }
              />
              <AppStack style={{ flex: 1, padding: 16, gap: 12 }}>
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={20} width={120} />
                </AppFlexbox>
                <AppStack style={{ gap: 5 }}>
                  <Skeleton height={16} width={130} />
                  <Skeleton height={16} width={150} />
                  <Skeleton height={16} width={80} />
                </AppStack>
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

CustomerDetailsView.propTypes = { isVendorView: PropTypes.bool };

export default CustomerDetailsView;
