import React, { useContext, useEffect, useRef } from 'react';
import { Receipt } from 'tabler-icons-react';
import dayjs from 'dayjs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import TableView from '../../common/TableView';
import {
  ECOM_ORDER_PRODUCT_STATUS_ENUM,
  ECOM_ORDER_STATUS_ENUM,
  PAYMENT_TYPE_ENUM,
  VIEW_ACTIONS_ENUM
} from '../../../config/constants';
import { useModalState, usePaginationFilter } from '../../../helpers/hooks';
import HeaderView from '../../common/HeaderView';
import { triggerNotification } from '../../../helpers/notification';
import { currencyFormat, formatUtcDate } from '../../../helpers/format';
import OrderTransactionModal from '../orders/OrderTransactionModal';
import OrderRefundModal from '../orders/OrderRefundModal';

const VIEW_ACTIONS = [
  {
    label: 'View order',
    value: VIEW_ACTIONS_ENUM.VIEW,
    section: 1
  },
  {
    label: 'View transaction',
    value: 'transaction',
    section: 1
  },
  {
    label: 'Refund',
    value: VIEW_ACTIONS_ENUM.REFUND,
    section: 2,
    paidOnly: true,
    color: 'red'
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Order',
    value: 'order',
    sortable: true
  },
  {
    label: 'Date',
    value: 'date',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  },
  {
    label: 'Customer',
    value: 'customer',
    sortable: true
  },
  {
    label: 'Products ordered',
    value: 'products',
    sortable: true
  },
  {
    label: 'Total cost',
    value: 'total',
    sortable: true
  }
];

const StoreOrderTable = ({ isBulkOrders }) => {
  const fetchedTableType = useRef(null);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const { state: authState } = useContext(AuthContext);
  const { state, fetchPaginatedStoreOrders } = useContext(StoreContext);
  const {
    pageIndex,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data: orderData,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  } = usePaginationFilter(
    {
      search: '',
      status: '',
      sort: 'order_desc'
    },
    (newFilter) => {
      fetchPaginatedStoreOrders(
        authState.pkEcomStore,
        newFilter,
        null,
        (error) => {
          triggerNotification(error);
        }
      );
    },
    isBulkOrders ? state.paginatedEcomOrdersBulk : state.paginatedEcomOrders
  );
  const tableInfo = isBulkOrders
    ? {
        title: 'Group orders',
        type: 'bulk',
        urlBase: '/merchant/group-orders'
      }
    : {
        title: 'Orders',
        type: 'normal',
        urlBase: '/merchant/orders'
      };

  const loading = fetchedTableType.current !== tableInfo.type || dataLoading;

  useEffect(() => {
    if (authState.pkEcomStore) {
      fetchPaginatedStoreOrders(
        authState.pkEcomStore,
        {
          isBulkShipment: isBulkOrders,
          search: new URLSearchParams(search).get('customer') || '',
          escrowOrders: new URLSearchParams(search).get('type') === 'escrow',
          sort: 'order_desc'
        },
        null,
        (error) => {
          triggerNotification(error);
        }
      );
      fetchedTableType.current = tableInfo.type;
    }
  }, [authState.pkEcomStore]);

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: tableInfo.title,
          to: tableInfo.urlBase
        }
      ]}
    >
      <TableView
        columns={TABLE_COLUMNS}
        disabled={!loading && totalNoFilterCount === 0}
        disabledContent={{
          title: 'No orders found.',
          description:
            'Orders will appear here once customers begin to place orders.',
          icon: <Receipt color="#000" size={125} />
        }}
        emptyMessage="No orders found."
        enableInputsWhenLoading
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by customer',
            value: filter.search || '',
            onChange: (v) =>
              onFilterChange({
                ...filter,
                search: v
              })
          },
          {
            key: 2,
            label: 'Status',
            type: 'select',
            value: filter.status || null,
            placeholder: 'Filter by status',
            searchable: true,
            clearable: true,
            data: [
              { value: 'unfulfilled', label: 'Unfulfilled' },
              { value: 'fulfilled', label: 'Fulfilled' },
              { value: 'refunded', label: 'Refunded' }
            ],
            onChange: (value) =>
              onFilterChange({ ...filter, status: value }, true)
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={loading}
        noSort
        onAction={(action, item) => {
          if (action === VIEW_ACTIONS_ENUM.VIEW) {
            navigate(`${tableInfo.urlBase}/${item.key}`);
          }
          else {
            onOpenModal(action, item.key);
          }
        }}
        onChangeSortBy={onSortChange}
        onPageChange={onPageChange}
        onRefresh={onRefresh}
        pageIndex={pageIndex}
        rows={orderData.map((d) => {
          const totals = d.ecomOrderPayments.reduce(
            (r, current) => {
              if (
                current.fkRegFormSubmissionPaymentType.toString() ===
                PAYMENT_TYPE_ENUM.REFUND
              ) {
                return {
                  refunded: r.refunded + current.amount,
                  paid: r.paid
                };
              }
              return {
                refunded: r.refunded,
                paid: r.paid + current.amount
              };
            },
            {
              refunded: 0,
              paid: 0
            }
          );
          const completelyRefunded = totals.refunded >= totals.paid;

          const bulkInfo = d.ecomOrderProductBulk.reduce(
            (r, current) => ({
              itemCount: r.itemCount + current.count,
              isFullyFulfilled:
                r.isFullyFulfilled &&
                current.fkEcomOrderProductStatus.toString() !==
                  ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING
            }),
            {
              totalCost: 0,
              itemCount: 0,
              isFullyFulfilled: true
            }
          );

          const productInfo = d.ecomOrderProducts.reduce(
            (r, current) => ({
              itemCount: r.itemCount + current.count,
              isFullyFulfilled: current.isBulkShipment
                ? r.isFullyFulfilled
                : r.isFullyFulfilled &&
                  current.fkEcomOrderProductStatus.toString() !==
                    ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING
            }),
            {
              itemCount: 0,
              isFullyFulfilled: true
            }
          );

          const statusInfo =
            d.fkEcomOrderStatus.toString() ===
            ECOM_ORDER_STATUS_ENUM.ACCUMULATING
              ? {
                  color: 'blue',
                  label: 'Accumulating'
                }
              : productInfo.isFullyFulfilled && bulkInfo.isFullyFulfilled
              ? {
                  color: 'green',
                  label: 'Fulfilled'
                }
              : {
                  color: 'orange',
                  label: 'Unfulfilled'
                };
          const totalPaid = d.ecomOrderPayments
            .filter(
              (f) =>
                f.fkRegFormSubmissionPaymentType !== PAYMENT_TYPE_ENUM.REFUND
            )
            .reduce((r, c) => r + c.amount, 0);

          return {
            key: d.pkEcomOrder,
            actions: VIEW_ACTIONS.filter(
              (f) => !completelyRefunded || !f.paidOnly
            ),
            columns: [
              {
                key: 1,
                label: `#${d.pkEcomOrder}`,
                weight: 500,
                component: Link,
                to: `${tableInfo.urlBase}/${d.pkEcomOrder}`
              },
              {
                key: 2,
                label: dayjs(formatUtcDate(d.purchasedAt)).format(
                  'MMMM D, YYYY h:mm A'
                )
              },
              {
                key: 3,
                ...statusInfo
              },
              {
                key: 4,
                label: d.ecomCustomer.user?.name,
                weight: 500,
                subLabel: d.ecomCustomer.email,
                component: Link,
                to: `${tableInfo.urlBase}/${d.pkEcomOrder}`
              },
              {
                key: 6,
                label: productInfo.itemCount + bulkInfo.itemCount
              },
              {
                key: 7,
                label: currencyFormat(totalPaid / 100)
              }
            ]
          };
        })}
        sortBy={filter.sort || TABLE_COLUMNS[0].value}
        tableTitle="Orders"
        totalCount={totalCount}
        totalPages={totalPages}
      />

      <OrderTransactionModal
        isOpen={modalState.isOpen && modalState.action === 'transaction'}
        onClose={onCloseModal}
        onShowRefund={() =>
          onOpenModal(VIEW_ACTIONS_ENUM.REFUND, modalState.item)
        }
        pkEcomOrder={
          modalState.action === 'transaction' ? modalState.item : null
        }
      />

      <OrderRefundModal
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.REFUND
        }
        onClose={onCloseModal}
        onSuccess={() => {
          onRefresh();
          onCloseModal();
        }}
        pkEcomOrder={
          modalState.action === VIEW_ACTIONS_ENUM.REFUND
            ? modalState.item
            : null
        }
      />
    </HeaderView>
  );
};

StoreOrderTable.propTypes = { isBulkOrders: PropTypes.bool };

export default StoreOrderTable;
