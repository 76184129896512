import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Badge,
  Button,
  Checkbox,
  Divider,
  List,
  Loader,
  ScrollArea,
  Skeleton,
  Table,
  Tooltip
} from '@mantine/core';
import {
  ArrowDown,
  ArrowUp,
  ChevronDown,
  ChevronUp,
  CornerUpLeft,
  CornerUpRight,
  Plus,
  Refresh,
  X
} from 'tabler-icons-react';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';
import { useMediaQueryIndex, useSortByFilter } from '../../helpers/hooks';
import AppMenu from './AppMenu';
import { singularPluralFormat } from '../../helpers/format';
import ActionableIcon from './ActionableIcon';
import FilterInput from './FilterInput';
import AppCard from './AppCard';
import TableViewDisabledContent from './TableViewDisabledContent';
import TableRow from './TableRow';

const isTableRenderSameAsBefore = (prevProps, nextProps) =>
  (!prevProps.selectedRows ||
    prevProps.selectedRows.length === nextProps.selectedRows.length) &&
  prevProps.rows.length === nextProps.rows.length &&
  prevProps.rows?.every((f) =>
    nextProps.rows?.some(
      (p) =>
        f.key === p.key &&
        f.columns.every(
          (c) =>
            !!p.columns.find(
              (pc) =>
                pc.key === c.key &&
                pc.label === c.label &&
                pc.subLabel === c.subLabel
            )
        )
    )
  ) &&
  (!prevProps.alerts ||
    prevProps.alerts?.every((f) =>
      nextProps.alerts?.some((p) => f.key === p.key && f.message === p.message)
    )) &&
  prevProps.isLoading === nextProps.isLoading &&
  prevProps.sortBy === nextProps.sortBy &&
  prevProps.showDisabledContent === nextProps.showDisabledContent &&
  prevProps.isDescendingSort === nextProps.isDescendingSort &&
  (!prevProps.filters ||
    (prevProps.filters?.length === nextProps.filters?.length &&
      prevProps.filters?.every((f) =>
        nextProps.filters?.some((p) => f.key === p.key && f.value === p.value)
      ))) &&
  prevProps.columns.length === nextProps.columns.length;

const TableView = React.memo(
  ({
    alerts,
    columns,
    rows,
    emptyMessage,
    emptyActionButton,
    emptyContent,
    isLoading,
    onAction,
    filters,
    defaultSort,
    defaultSortDescending,
    sortBy: customSortValue,
    sortFilter: customSortFilter,
    onChangeSortBy: customOnChangeSortBy,
    isDescendingSort: customIsDescendingSort,
    onRefresh,
    tableTitle,
    totalCount,
    noSort,
    onSelect,
    selectedRows,
    selectActionSection,
    cardProps,
    disabled,
    disabledContent,
    showDisabledContent,
    pageIndex,
    onPageChange,
    totalPages,
    enableInputsWhenLoading,
    minRowHeight
  }) => {
    const { isTabletOrSmaller } = useMediaQueryIndex();
    const {
      sortValue: defaultSortValue,
      isDescendingSort: defaultIsDescendingSort,
      onChangeSortBy: defaultOnChangeSortBy,
      customFilterData: defaultCustomFilterData
    } = useSortByFilter(
      columns.filter((c) => c.sortable),
      { defaultSort, defaultDescending: defaultSortDescending }
    );
    const sortBy = (customSortValue || defaultSortValue)?.replace('_desc', '');
    const isDescendingSort = customIsDescendingSort || defaultIsDescendingSort;
    const onChangeSortBy = customOnChangeSortBy || defaultOnChangeSortBy;
    const sortFilter = customSortFilter || defaultCustomFilterData;

    const [alertState, setAlertState] = useState({
      initial: [],
      current: []
    });
    const [showMobileFilters, setShowMobileFilters] = useState(false);
    const TOTAL_ITEM_COUNT = totalCount ?? rows.length;
    const sortByColumnIndex = columns.findIndex((v) => v.value === sortBy);

    const rowsSelected = selectedRows || [];
    const allRowsSelected =
      rows.length > 0 && rowsSelected?.length === rows.length;

    useEffect(() => {
      if (!isLoading && alerts) {
        if (
          alerts.length !== alertState.initial.length ||
          !alerts.every((a) =>
            alertState.initial.some(
              (s) => s.key === a.key && s.message === a.message
            )
          )
        ) {
          setAlertState({
            initial: alerts,
            current: alerts
          });
        }
      }
    }, [alerts, isLoading]);

    useEffect(() => {
      if (isLoading) {
        setAlertState({
          initial: [],
          current: []
        });
      }
    }, [isLoading]);

    const sortRows = (a, b) => {
      if (sortByColumnIndex !== -1) {
        const sortValueA =
          a.columns[sortByColumnIndex].sortValue !== undefined
            ? a.columns[sortByColumnIndex].sortValue
            : a.columns[sortByColumnIndex].label || '';
        const sortValueB =
          b.columns[sortByColumnIndex].sortValue !== undefined
            ? b.columns[sortByColumnIndex].sortValue
            : b.columns[sortByColumnIndex].label || '';

        if (
          a.columns[sortByColumnIndex].sortValueType === 'date' ||
          a.columns[sortByColumnIndex].sortValueType === 'number'
        ) {
          return isDescendingSort
            ? sortValueB - sortValueA
            : sortValueA - sortValueB;
        }

        return isDescendingSort
          ? sortValueB.localeCompare(sortValueA)
          : sortValueA.localeCompare(sortValueB);
      }

      return isDescendingSort
        ? (b.columns[0]?.label || '').localeCompare(a.columns[0]?.label || '')
        : (a.columns[0]?.label || '').localeCompare(b.columns[0]?.label || '');
    };

    const onSelectTableRow = (rowKey) => {
      if (onSelect) {
        if (rowsSelected.includes(rowKey)) {
          onSelect([...rowsSelected.filter((f) => f !== rowKey)]);
        }
        else {
          onSelect([...rowsSelected, rowKey]);
        }
      }
    };

    return (
      <AppCard
        shadow="xs"
        style={{ flex: 1, padding: 0, height: '100%' }}
        withBorder
        {...cardProps}
      >
        <AppStack
          style={{
            gap: 0,
            flex: 1,
            height: '100%'
          }}
        >
          {(tableTitle || filters?.length > 0) && (
            <AppStack hiddenFrom="sm" style={{ padding: 10, gap: 0 }}>
              {tableTitle && (
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <AppText style={{ color: 'grey', fontSize: 14 }} weight={500}>
                    {tableTitle}
                  </AppText>
                  <AppStack>
                    <ActionableIcon
                      color="dodgerblue"
                      disabled={isLoading || disabled}
                      onClick={onRefresh}
                      radius="xl"
                      style={{ background: '#DEE2E6' }}
                      variant="subtle"
                    >
                      <Refresh />
                    </ActionableIcon>
                  </AppStack>
                </AppFlexbox>
              )}

              {filters &&
                filters.length > 0 &&
                (filters.length === 1 ? (
                  <FilterInput
                    disabled={
                      (!enableInputsWhenLoading && isLoading) || disabled
                    }
                    style={{ flex: 1 }}
                    {...filters[0]}
                  />
                ) : (
                  filters.length > 1 && (
                    <>
                      <AppFlexbox
                        onClick={() => setShowMobileFilters(!showMobileFilters)}
                        style={{
                          justifyContent: 'space-between',
                          paddingTop: 10
                        }}
                      >
                        <AppText weight={500}>Filters</AppText>
                        {showMobileFilters ? <ChevronUp /> : <ChevronDown />}
                      </AppFlexbox>
                      {showMobileFilters && (
                        <>
                          <Divider style={{ marginTop: 5 }} />
                          {[...filters].map((filter) => (
                            <FilterInput
                              key={filter.key}
                              disabled={
                                (!enableInputsWhenLoading && isLoading) ||
                                disabled
                              }
                              style={{ flex: 1 }}
                              {...filter}
                            />
                          ))}
                        </>
                      )}
                    </>
                  )
                ))}
            </AppStack>
          )}

          <AppFlexbox
            style={{
              padding: 10,
              paddingTop: filters ? 2 : 10,
              gap: 10,
              alignItems: 'end'
            }}
            visibleFrom="sm"
          >
            <AppFlexbox
              style={{ gap: 10, flex: 1 }}
              visibleFrom={
                filters?.length === 1 ? 'sm' : filters?.length > 4 ? 'xl' : 'lg'
              }
            >
              {filters &&
                filters.map((filter) => (
                  <AppFlexbox
                    key={filter.key}
                    style={{ flex: 1, maxWidth: 250 }}
                  >
                    <FilterInput
                      key={filter.value}
                      disabled={
                        (!enableInputsWhenLoading && isLoading) || disabled
                      }
                      style={{ flex: 1 }}
                      {...filter}
                    />
                  </AppFlexbox>
                ))}
            </AppFlexbox>

            <ScrollArea
              hiddenFrom={
                filters?.length === 1 ? 'sm' : filters?.length > 4 ? 'xl' : 'lg'
              }
              w="100%"
            >
              <AppFlexbox
                style={{
                  gap: 5,
                  alignSelf: 'end',
                  alignItems: 'center'
                }}
              >
                {filters && (
                  <>
                    <AppMenu
                      control={
                        <Button
                          disabled={disabled}
                          radius="xl"
                          rightSection={<Plus size={18} />}
                          variant="outline"
                        >
                          Filters
                        </Button>
                      }
                      position="bottom-start"
                      width={300}
                    >
                      <AppStack style={{ gap: 5, padding: 5 }}>
                        {filters.map((filter) => (
                          <AppFlexbox key={filter.key}>
                            <FilterInput
                              key={filter.value}
                              disabled={
                                (!enableInputsWhenLoading && isLoading) ||
                                disabled
                              }
                              style={{ flex: 1 }}
                              {...filter}
                            />
                          </AppFlexbox>
                        ))}
                      </AppStack>
                    </AppMenu>
                    <AppFlexbox style={{ gap: 5 }}>
                      {filters
                        .filter((f) => f.value)
                        .map((f) => {
                          const option = f.data?.find(
                            (d) => d.value === f.value
                          );
                          return (
                            <Badge
                              key={f.key}
                              rightSection={
                                (f.clearable || f.type !== 'select') && (
                                  <X
                                    onClick={() => f.onChange('', true)}
                                    size={18}
                                    style={{ cursor: 'pointer' }}
                                  />
                                )
                              }
                              style={{ height: 32, textTransform: 'none' }}
                              variant="light"
                            >
                              {option?.label || f.value}
                            </Badge>
                          );
                        })}
                    </AppFlexbox>
                  </>
                )}
              </AppFlexbox>
            </ScrollArea>

            <AppStack
              style={{
                gap: 0,
                justifyContent: 'space-between',
                alignItems: 'end'
              }}
            >
              {isLoading ? (
                <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                  <Loader color="grey" size={15} />
                  <AppText style={{ color: 'grey', fontSize: 14 }}>
                    results
                  </AppText>
                </AppFlexbox>
              ) : (
                <AppText
                  style={{ color: 'grey', fontSize: 14, textWrap: 'balance' }}
                >
                  Showing {rows.length} of{' '}
                  {singularPluralFormat(TOTAL_ITEM_COUNT, 'result', 'results')}
                </AppText>
              )}

              <AppFlexbox
                style={{
                  flex: 1,
                  justifyContent: 'flex-end',
                  placeItems: 'center',
                  gap: 10,
                  maxWidth: 350
                }}
              >
                {onPageChange && totalCount > 0 && totalPages > 1 && (
                  <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                    {isLoading ? (
                      <Loader color="grey" size={18} />
                    ) : (
                      <AppText style={{ fontSize: 14, color: '#666' }}>
                        ({pageIndex}/{totalPages})
                      </AppText>
                    )}

                    <Button.Group>
                      <Button
                        disabled={pageIndex <= 1 || isLoading}
                        onClick={() => onPageChange(pageIndex - 1)}
                        size="compact-md"
                        variant="default"
                      >
                        <CornerUpLeft size={18} />
                      </Button>
                      <Button
                        disabled={pageIndex >= totalPages || isLoading}
                        onClick={() => onPageChange(pageIndex + 1)}
                        size="compact-md"
                        variant="default"
                      >
                        <CornerUpRight size={18} />
                      </Button>
                    </Button.Group>
                  </AppFlexbox>
                )}

                {selectedRows?.length > 0 && selectActionSection}
                <AppFlexbox
                  style={{
                    flex: 1,
                    justifyContent: 'flex-end',
                    placeItems: 'center',
                    gap: 10,
                    minWidth: 300
                  }}
                >
                  {sortFilter && (
                    <>
                      <AppFlexbox style={{ flex: 1 }}>
                        <FilterInput
                          key={sortFilter.value}
                          disabled={
                            (!enableInputsWhenLoading && isLoading) || disabled
                          }
                          style={{ flex: 1 }}
                          {...sortFilter}
                          onChange={(value) => {
                            if (onChangeSortBy) {
                              onChangeSortBy(value, false);
                            }
                            sortFilter.onChange(value, false);
                          }}
                        />
                      </AppFlexbox>
                      {onChangeSortBy && (
                        <Tooltip
                          disabled={disabled}
                          label="Order By"
                          position="bottom"
                          withArrow
                          withinPortal
                        >
                          <AppStack>
                            <ActionableIcon
                              color="dodgerblue"
                              disabled={isLoading || disabled}
                              onClick={() =>
                                onChangeSortBy(sortBy, !isDescendingSort)
                              }
                              radius="xl"
                              style={{ background: '#DEE2E6' }}
                              variant="subtle"
                            >
                              {isDescendingSort ? <ArrowDown /> : <ArrowUp />}
                            </ActionableIcon>
                          </AppStack>
                        </Tooltip>
                      )}
                    </>
                  )}
                  {onRefresh && (
                    <Tooltip
                      disabled={disabled}
                      label="Refresh"
                      position="bottom"
                      withArrow
                      withinPortal
                    >
                      <AppStack>
                        <ActionableIcon
                          color="dodgerblue"
                          disabled={isLoading || disabled}
                          onClick={onRefresh}
                          radius="xl"
                          style={{ background: '#DEE2E6' }}
                          variant="subtle"
                        >
                          <Refresh />
                        </ActionableIcon>
                      </AppStack>
                    </Tooltip>
                  )}
                </AppFlexbox>
              </AppFlexbox>
            </AppStack>
          </AppFlexbox>

          {alertState.current
            .sort((a, b) => a.key - b.key)
            .map((alert, i) => (
              <Alert
                key={alert.key}
                color={alert.color}
                icon={alert.icon}
                onClose={() =>
                  setAlertState({
                    ...alertState,
                    current: [
                      ...alertState.current.filter((a) => a.key !== alert.key)
                    ]
                  })
                }
                style={{
                  padding: 10,
                  borderRadius: 0,
                  borderTop: i > 0 ? '0.01rem solid #DEE2E6' : 'none'
                }}
                styles={{ closeButton: { marginTop: 1 } }}
                variant="filled"
                withCloseButton
              >
                <AppStack style={{ gap: 10 }}>
                  <AppFlexbox
                    onClick={
                      alert.additionalErrors
                        ? () =>
                            setAlertState({
                              ...alertState,
                              current: [
                                ...alertState.current.filter(
                                  (a) => a.key !== alert.key
                                ),
                                {
                                  ...alert,
                                  showAdditionalErrors: !alert.showAdditionalErrors
                                }
                              ]
                            })
                        : null
                    }
                    style={{
                      flex: 1,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      cursor: alert.additionalErrors ? 'pointer' : 'normal'
                    }}
                  >
                    <AppText style={{ fontSize: 14 }}>{alert.message}</AppText>
                    {alert.additionalErrors.length > 0 && (
                      <AppText
                        onClick={() =>
                          setAlertState({
                            ...alertState,
                            current: [
                              ...alertState.current.filter(
                                (a) => a.key !== alert.key
                              ),
                              {
                                ...alert,
                                showAdditionalErrors: !alert.showAdditionalErrors
                              }
                            ]
                          })
                        }
                        style={{ fontSize: 14, marginRight: 20 }}
                      >
                        {alert.showAdditionalErrors ? 'Hide Info' : 'View Info'}
                      </AppText>
                    )}
                  </AppFlexbox>
                  {alert.showAdditionalErrors && (
                    <List>
                      {alert.additionalErrors.map((error, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <List.Item key={index}>{error}</List.Item>
                      ))}
                    </List>
                  )}
                </AppStack>
              </Alert>
            ))}

          {columns.length > 0 && (
            <AppStack style={{ overflow: 'auto', textWrap: 'nowrap', flex: 1 }}>
              <Table horizontalSpacing="xl" striped verticalSpacing="sm">
                <Table.Thead
                  style={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: '#FFF',
                    zIndex: 99,
                    boxShadow: 'inset 0 1px 0 #DEE2E6, inset 0 -1px 0 #DEE2E6'
                  }}
                >
                  <Table.Tr hiddenFrom="sm" style={{ borderBottom: 'none' }}>
                    <Table.Th style={{ padding: '12px 10px' }}>
                      <AppFlexbox
                        style={{
                          gap: 5,
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <AppFlexbox
                          style={{
                            alignItems: 'center',
                            flex: 1,
                            justifyContent: 'flex-end'
                          }}
                        >
                          {selectedRows?.length > 0 && onSelect ? (
                            <>
                              <AppFlexbox style={{ flex: 1 }}>
                                <Checkbox
                                  checked={allRowsSelected}
                                  disabled={isLoading || disabled}
                                  label={
                                    allRowsSelected
                                      ? 'Unselect All'
                                      : 'Select All'
                                  }
                                  onChange={() => {
                                    if (allRowsSelected) {
                                      onSelect([]);
                                    }
                                    else {
                                      onSelect(rows.map((r) => r.key));
                                    }
                                  }}
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </AppFlexbox>
                              {selectActionSection}
                            </>
                          ) : (
                            sortFilter && (
                              <>
                                <AppFlexbox style={{ flex: 1 }}>
                                  <FilterInput
                                    key={sortFilter.value}
                                    disabled={
                                      (!enableInputsWhenLoading && isLoading) ||
                                      disabled
                                    }
                                    style={{ flex: 1 }}
                                    {...sortFilter}
                                    onChange={(value) => {
                                      if (onChangeSortBy) {
                                        onChangeSortBy(value, false);
                                      }
                                      sortFilter.onChange(value, false);
                                    }}
                                  />
                                </AppFlexbox>
                                {onChangeSortBy && (
                                  <ActionableIcon
                                    color="dodgerblue"
                                    disabled={isLoading || disabled}
                                    onClick={() =>
                                      onChangeSortBy(sortBy, !isDescendingSort)
                                    }
                                    radius="xl"
                                    style={{ background: '#DEE2E6' }}
                                    variant="subtle"
                                  >
                                    {isDescendingSort ? (
                                      <ArrowDown />
                                    ) : (
                                      <ArrowUp />
                                    )}
                                  </ActionableIcon>
                                )}
                              </>
                            )
                          )}
                        </AppFlexbox>
                      </AppFlexbox>
                    </Table.Th>
                  </Table.Tr>
                  <Table.Tr
                    style={{
                      border: 'none',
                      borderTop: disabled ? 'solid 1px #dee2e6' : 'none'
                    }}
                    visibleFrom="sm"
                  >
                    {!disabled &&
                      columns.map((c, i) => {
                        const isOrderBySelected = sortBy === c.value;
                        return (
                          <Table.Th key={c.value} style={{ maxWidth: 200 }}>
                            <AppFlexbox
                              onClick={() => {
                                if (!disabled && c.sortable) {
                                  onChangeSortBy(
                                    c.value,
                                    isOrderBySelected
                                      ? !isDescendingSort
                                      : false
                                  );
                                }
                              }}
                              style={{
                                cursor:
                                  !disabled && c.sortable
                                    ? 'pointer'
                                    : 'normal',
                                gap: 10,
                                alignItems: 'center',
                                color: disabled ? '#999' : '#000'
                              }}
                            >
                              {onSelect && i === 0 && (
                                <Tooltip
                                  disabled={disabled}
                                  label={
                                    allRowsSelected
                                      ? 'Unselect All'
                                      : 'Select All'
                                  }
                                >
                                  <Checkbox
                                    checked={allRowsSelected}
                                    disabled={isLoading || disabled}
                                    onChange={() => {
                                      if (allRowsSelected) {
                                        onSelect([]);
                                      }
                                      else {
                                        onSelect(rows.map((r) => r.key));
                                      }
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                </Tooltip>
                              )}
                              {c.label}
                              {!isLoading &&
                                !disabled &&
                                isOrderBySelected &&
                                (isDescendingSort ? (
                                  <ArrowDown color="dodgerblue" size={20} />
                                ) : (
                                  <ArrowUp color="dodgerblue" size={20} />
                                ))}
                            </AppFlexbox>
                          </Table.Th>
                        );
                      })}
                    {!isLoading && onAction && TOTAL_ITEM_COUNT > 0 && (
                      <Table.Th />
                    )}
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody hiddenFrom="sm">
                  {showDisabledContent || disabled ? (
                    <Table.Tr>
                      <Table.Td
                        colSpan={columns.length + 1}
                        style={{ padding: '12px 10px' }}
                      >
                        <TableViewDisabledContent
                          disabledContent={disabledContent}
                        />
                      </Table.Td>
                    </Table.Tr>
                  ) : isLoading ? (
                    Array.from(Array(25)).map((x, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Table.Tr key={i}>
                        <Table.Td style={{ padding: '12px 10px' }}>
                          <AppStack style={{ gap: 5 }}>
                            <Skeleton height={10} width="100%" />
                            <Skeleton height={10} width="80%" />
                          </AppStack>
                        </Table.Td>
                      </Table.Tr>
                    ))
                  ) : TOTAL_ITEM_COUNT === 0 ? (
                    <Table.Tr>
                      <Table.Td
                        colSpan={columns.length + 1}
                        style={{ padding: '12px 10px' }}
                      >
                        {emptyContent || (
                          <AppStack
                            style={{
                              alignItems: 'center',
                              textAlign: 'center',
                              padding: '30px 0px'
                            }}
                          >
                            <AppText style={{ fontWeight: 500 }}>
                              {emptyMessage || 'No Items Available'}
                            </AppText>
                            {emptyActionButton}
                          </AppStack>
                        )}
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    (noSort ? rows : rows.sort(sortRows)).map((r, i) => (
                      <TableRow
                        key={r.key}
                        index={i}
                        isTabletOrSmaller={isTabletOrSmaller}
                        minHeight={minRowHeight}
                        onAction={onAction}
                        onSelect={onSelect ? onSelectTableRow : null}
                        row={r}
                        rowsSelected={rowsSelected}
                      />
                    ))
                  )}
                </Table.Tbody>
                <Table.Tbody visibleFrom="sm">
                  {showDisabledContent || disabled ? (
                    <Table.Tr>
                      <Table.Td colSpan={columns.length + 1}>
                        <TableViewDisabledContent
                          disabledContent={disabledContent}
                        />
                      </Table.Td>
                    </Table.Tr>
                  ) : isLoading ? (
                    Array.from(Array(25)).map((x, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Table.Tr key={i}>
                        {columns.map((c, index) => (
                          <Table.Td key={c.value}>
                            <AppFlexbox
                              style={{ alignItems: 'center', gap: 10 }}
                            >
                              {onSelect && index === 0 && (
                                <Checkbox
                                  disabled
                                  styles={{ input: { cursor: 'pointer' } }}
                                />
                              )}
                              <Skeleton height={12} width="100%" />
                            </AppFlexbox>
                          </Table.Td>
                        ))}
                      </Table.Tr>
                    ))
                  ) : TOTAL_ITEM_COUNT === 0 ? (
                    <Table.Tr>
                      <Table.Td colSpan={columns.length + 1}>
                        {emptyContent || (
                          <AppStack
                            style={{
                              alignItems: 'center',
                              textAlign: 'center',
                              padding: '30px 0px'
                            }}
                          >
                            <AppText style={{ fontWeight: 500 }}>
                              {emptyMessage || 'No Items Available'}
                            </AppText>
                            {emptyActionButton}
                          </AppStack>
                        )}
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    (noSort ? rows : rows.sort(sortRows)).map((r, i) => (
                      <TableRow
                        key={r.key}
                        index={i}
                        isTabletOrSmaller={isTabletOrSmaller}
                        minHeight={minRowHeight}
                        onAction={onAction}
                        onSelect={onSelect ? onSelectTableRow : null}
                        row={r}
                        rowsSelected={rowsSelected}
                      />
                    ))
                  )}
                </Table.Tbody>
              </Table>
            </AppStack>
          )}
        </AppStack>
      </AppCard>
    );
  },
  isTableRenderSameAsBefore
);

TableView.propTypes = {
  alerts: PropTypes.array,
  cardProps: PropTypes.object,
  columns: PropTypes.array,
  defaultSort: PropTypes.string,
  defaultSortDescending: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledContent: PropTypes.any,
  emptyActionButton: PropTypes.node,
  emptyContent: PropTypes.any,
  emptyMessage: PropTypes.string,
  enableInputsWhenLoading: PropTypes.bool,
  filters: PropTypes.array,
  isDescendingSort: PropTypes.bool,
  isLoading: PropTypes.bool,
  minRowHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noSort: PropTypes.bool,
  onAction: PropTypes.func,
  onChangeSortBy: PropTypes.func,
  onPageChange: PropTypes.func,
  onRefresh: PropTypes.func,
  onSelect: PropTypes.func,
  pageIndex: PropTypes.number,
  rows: PropTypes.array,
  selectActionSection: PropTypes.any,
  selectedRows: PropTypes.array,
  showDisabledContent: PropTypes.bool,
  sortBy: PropTypes.string,
  sortFilter: PropTypes.object,
  tableTitle: PropTypes.string,
  totalCount: PropTypes.number,
  totalPages: PropTypes.number
};

export default TableView;
