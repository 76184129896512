import React from 'react';
import PropTypes from 'prop-types';
import { Anchor, Divider } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppText from '../../common/AppText';
import { singularPluralFormat } from '../../../helpers/format';
import { generateTrackingLink } from '../../../helpers/vendorHelper';
import VendorOrderProductPreview from './VendorOrderProductPreview';
import AppFlexbox from '../../common/AppFlexbox';

const VendorOrderFulfillmentConfirmationModal = ({
  isOpen,
  onClose,
  formState,
  onSubmit,
  productData
}) => {
  const selectedItems = formState.ecomOrderShippingProducts.filter(
    (p) => p.count > 0
  );
  const itemCountSelected = selectedItems.reduce((r, c) => r + c.count, 0);
  const trackingLink = generateTrackingLink(
    formState.shippingCarrier,
    formState.trackingNumber,
    formState.trackingUrl
  );

  return (
    <ResponsiveModal
      formSectionProps={{
        cancelTitle: 'Close',
        onCancel: onClose,
        submitTitle: `Yes, fulfill ${
          itemCountSelected === 1 ? 'item' : 'items'
        }`,
        isSubmitDisabled: itemCountSelected <= 0,
        onSubmit,
        isLoading: formState.loading,
        padding: 0,
        leftSection: formState.notifyCustomer && (
          <AppFlexbox
            style={{
              gap: 8,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircleCheck color="#067D62" size={20} />
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              Notify customer
            </AppText>
          </AppFlexbox>
        )
      }}
      isOpen={isOpen}
      onClose={onClose}
      size={500}
      title={`Fulfill ${singularPluralFormat(
        itemCountSelected,
        'item',
        'items'
      )}?`}
    >
      <AppStack style={{ gap: 0 }}>
        <AppStack style={{ padding: 16, gap: 5 }}>
          <AppText
            style={{
              fontSize: 14,
              fontWeight: 500,
              textAlign: 'center'
            }}
          >
            Are you sure you want to fulfill the following items?
          </AppText>
        </AppStack>
        <Divider />
        <AppStack style={{ padding: 16, gap: 16 }}>
          {selectedItems.length === 0 ? (
            <AppText
              style={{
                fontSize: 14,
                color: '#666',
                fontWeight: 500,
                textAlign: 'center'
              }}
            >
              No items selected
            </AppText>
          ) : (
            selectedItems.map((p, index) => {
              const product = productData.find(
                (f) =>
                  f.pkEcomOrderProductBulk === p.fkEcomOrderProductBulk ||
                  f.pkEcomOrderProduct === p.fkEcomOrderProduct
              );

              return (
                <React.Fragment key={p.fkEcomOrderProduct}>
                  {index > 0 && <Divider />}
                  <VendorOrderProductPreview
                    product={product}
                    quantityIndicatorAmount={p.count}
                  />
                </React.Fragment>
              );
            })
          )}
        </AppStack>

        <Divider />
        <AppFlexbox style={{ padding: '8px 16px', backgroundColor: '#F1F1F1' }}>
          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
            Tracking information
          </AppText>
        </AppFlexbox>
        <Divider />
        <AppStack style={{ padding: 16, gap: 8 }}>
          {formState.shippingCarrier && (
            <AppText style={{ fontSize: 14 }}>
              Carrier:{' '}
              <span style={{ fontWeight: 500 }}>
                {formState.shippingCarrier}
              </span>
            </AppText>
          )}

          {formState.trackingNumber && (
            <AppText style={{ fontSize: 14 }}>
              Tracking number:{' '}
              <span style={{ fontWeight: 500 }}>
                {formState.trackingNumber}
              </span>
            </AppText>
          )}

          {trackingLink ? (
            <AppFlexbox>
              <Anchor
                component="a"
                href={trackingLink}
                style={{ fontSize: 14, color: 'dodgerblue' }}
                target="_blank"
              >
                View tracking
              </Anchor>
            </AppFlexbox>
          ) : (
            <AppText style={{ fontSize: 14 }}>Tracking not available</AppText>
          )}
          {formState.additionalShipping
            .map((a) =>
              generateTrackingLink(
                a.shippingCarrier,
                a.trackingNumber,
                a.trackingUrl
              )
            )
            .filter((f) => !!f)
            .map((t, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <AppFlexbox key={index}>
                <Anchor
                  component="a"
                  href={t}
                  style={{ fontSize: 14, color: 'dodgerblue' }}
                  target="_blank"
                >
                  View tracking #{index + 2}
                </Anchor>
              </AppFlexbox>
            ))}
        </AppStack>

        {!formState.notifyCustomer && (
          <>
            <Divider />
            <AppStack style={{ padding: 16 }}>
              <AppText
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  textAlign: 'center',
                  fontStyle: 'italic',
                  color: '#666'
                }}
              >
                Customer will not be notified
              </AppText>
            </AppStack>
          </>
        )}
      </AppStack>
    </ResponsiveModal>
  );
};

VendorOrderFulfillmentConfirmationModal.propTypes = {
  formState: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  productData: PropTypes.array
};

export default VendorOrderFulfillmentConfirmationModal;
