import React from 'react';
import PropTypes from 'prop-types';
import {
  Anchor,
  Badge,
  Divider,
  Image,
  Indicator,
  Skeleton
} from '@mantine/core';
import { Link } from 'react-router-dom';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import ProductLineItemAddonsDisplay from '../products/ProductLineItemAddonsDisplay';

const VendorOrderProductPreview = ({
  product,
  loading,
  quantityIndicatorAmount,
  showVendorName,
  hideStatus
}) => {
  const isFulfilled = product && product.shippedCount >= product.quantity;

  return !loading && product ? (
    <AppFlexbox style={{ gap: 8 }}>
      <Indicator
        disabled={!quantityIndicatorAmount}
        label={quantityIndicatorAmount}
        radius={100}
        size={15}
        style={{ zIndex: 1 }}
      >
        <AppStack
          component={product.to ? Link : null}
          style={{
            width: 50,
            height: 50,
            overflow: 'hidden',
            borderRadius: 8,
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #e1e1e1'
          }}
          to={product.to ? product.to : null}
        >
          <Image fit="contain" h="100%" src={product.previewImage} w="100%" />
        </AppStack>
      </Indicator>
      <AppStack style={{ gap: 0 }}>
        <AppFlexbox
          component={product.to ? Link : null}
          style={{ color: '#000', textDecoration: 'none' }}
          to={product.to ? product.to : null}
        >
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ gap: 8 }}>
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                {product.name}
              </AppText>
              {!hideStatus && (
                <Badge
                  color={isFulfilled ? 'green' : 'grey'}
                  variant={isFulfilled ? 'filled' : 'light'}
                >
                  {product.shippedCount} / {product.quantity}
                </Badge>
              )}
            </AppFlexbox>

            {showVendorName && (
              <AppText style={{ fontSize: 12, color: '#666', fontWeight: 500 }}>
                {product.vendorName}
              </AppText>
            )}

            <ProductLineItemAddonsDisplay addOns={product.addOns} />

            {(product.sku || product.barcode) && (
              <>
                <Divider style={{ margin: '4px 0px' }} />
                {product.sku && (
                  <AppText style={{ fontSize: 12, color: '#666' }}>
                    SKU: {product.sku}
                  </AppText>
                )}
                {product.barcode && (
                  <AppText style={{ fontSize: 12, color: '#666' }}>
                    BARCODE: {product.barcode}
                  </AppText>
                )}
              </>
            )}
          </AppStack>
        </AppFlexbox>
        {product.ecomOrderProductBulk &&
          product.ecomOrderProductBulk.length > 0 && (
            <>
              <Divider style={{ margin: '4px 0px' }} />
              <AppFlexbox style={{ alignItems: 'center', gap: 8 }}>
                <AppText style={{ fontSize: 12, color: '#666' }}>
                  Group{' '}
                  {product.ecomOrderProductBulk.length === 1
                    ? 'order'
                    : 'orders'}
                  :
                </AppText>
                {product.ecomOrderProductBulk.map((b) => (
                  <Anchor
                    key={b.pkEcomOrderProductBulk}
                    c="dodgerblue"
                    component={Link}
                    style={{ fontSize: 14 }}
                    to={b.to}
                  >
                    #{b.fkEcomOrder}
                  </Anchor>
                ))}
              </AppFlexbox>
            </>
          )}
      </AppStack>
    </AppFlexbox>
  ) : (
    <AppFlexbox style={{ width: '100%', flex: 1, gap: 8 }}>
      <AppStack
        style={{
          width: 50,
          height: 50,
          overflow: 'hidden',
          borderRadius: 8,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Skeleton height="100%" width="100%" />
      </AppStack>
      <AppStack style={{ width: '100%', gap: 5 }}>
        <Skeleton height={18} width="40%" />

        <Skeleton height={16} width="30%" />
        <Skeleton height={16} width="30%" />
      </AppStack>
    </AppFlexbox>
  );
};

VendorOrderProductPreview.propTypes = {
  bulkOrdersUrlBase: PropTypes.string,
  hideStatus: PropTypes.bool,
  loading: PropTypes.bool,
  product: PropTypes.object,
  quantityIndicatorAmount: PropTypes.number,
  showVendorName: PropTypes.bool
};

export default VendorOrderProductPreview;
