import React, { useContext, useEffect, useRef } from 'react';
import HeaderView from '../../common/HeaderView';
import { Context as AdminContext } from '../../../providers/AdminContextProvider';
import { adminAnalyticsAndReportsConfig } from '../analytics/adminAnalyticsAndReportsConfig';
import AnalyticsOverviewView from '../analytics/AnalyticsOverviewView';
import { ECOM_REPORT_ENUM } from '../analytics/reportsConfig';
import { currencyFormat } from '../../../helpers/format';

const AdminAnalyticsView = () => {
  const hasFetched = useRef(false);
  const {
    state,
    generateAdminReport,
    fetchAdminAnalytics,
    fetchAdminAnalyticsOverview
  } = useContext(AdminContext);

  const reportConfig = adminAnalyticsAndReportsConfig.reports.find(
    (c) => c.uuid === ECOM_REPORT_ENUM.OVERVIEW
  );

  const loading =
    !hasFetched.current ||
    state.ecomAdminAnalytics.loading ||
    state.ecomAdminReport.loading ||
    reportConfig.uuid !== state.ecomAdminReport.value?.uuid;

  const summaryCardsLoading =
    !hasFetched.current || state.ecomAdminAnalyticsOverview.loading;

  useEffect(() => {
    fetchAdminAnalyticsOverview();
  }, []);

  const onFetchAnalyticsOverview = (filter) => {
    const groupByPeriod = reportConfig.groupBy === 'period';
    const compareEnabled = groupByPeriod && reportConfig.compareEnabled;
    generateAdminReport({
      uuid: reportConfig.uuid,
      headers: reportConfig.defaultHeaders,
      groupBy: reportConfig.groupBy,
      ...filter,
      periodInterval: groupByPeriod ? filter.periodInterval : null,
      compareStartDate: compareEnabled ? filter.compareStartDate : null,
      compareEndDate: compareEnabled ? filter.compareEndDate : null
    });

    fetchAdminAnalytics(filter);
    hasFetched.current = true;
  };

  return (
    <HeaderView noHeader>
      <AnalyticsOverviewView
        analyticConfigs={adminAnalyticsAndReportsConfig.analytics}
        dataState={state.ecomAdminAnalytics.value}
        entityId={1}
        loading={loading}
        onFetchOverview={onFetchAnalyticsOverview}
        reportResult={state.ecomAdminReport.value}
        summaryCardData={[
          {
            label: 'Escrow balance',
            count: state.ecomAdminAnalyticsOverview.value?.ecomStores.filter(
              (v) => v.amountEarned - v.amountPaid > 0
            ).length,
            value: state.ecomAdminAnalyticsOverview.value
              ? currencyFormat(
                  state.ecomAdminAnalyticsOverview.value.ecomStores.reduce(
                    (r, c) => r + (c.amountEarned - c.amountPaid),
                    0
                  ) / 100
                )
              : 0,
            loading: summaryCardsLoading,
            redirectPath: '/admin/stores'
          },
          {
            label: 'Total escrow earned',
            count: state.ecomAdminAnalyticsOverview.value?.ecomStores.length,
            value: state.ecomAdminAnalyticsOverview.value
              ? currencyFormat(
                  state.ecomAdminAnalyticsOverview.value.ecomStores.reduce(
                    (r, c) => r + c.amountEarned,
                    0
                  ) / 100
                )
              : 0,
            loading: summaryCardsLoading,
            redirectPath: '/admin/stores'
          },
          {
            label: 'Vendor payout balance',
            count: state.ecomAdminAnalyticsOverview.value?.ecomVendors.filter(
              (v) => v.amountEarned - v.amountPaid > 0
            ).length,
            value: state.ecomAdminAnalyticsOverview.value
              ? currencyFormat(
                  state.ecomAdminAnalyticsOverview.value.ecomVendors.reduce(
                    (r, c) => r + (c.amountEarned - c.amountPaid),
                    0
                  ) / 100
                )
              : 0,
            loading: summaryCardsLoading,
            redirectPath: '/admin/vendors/payout-balance'
          }
        ]}
        urlBase="/admin/reports"
      />
    </HeaderView>
  );
};

export default AdminAnalyticsView;
