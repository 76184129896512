import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, ChevronDown } from 'tabler-icons-react';
import { Badge, Button, Menu, Skeleton, Tooltip } from '@mantine/core';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import {
  useBackPath,
  useMediaQueryIndex,
  useModalState
} from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import ActionableIcon from '../../common/ActionableIcon';
import AppText from '../../common/AppText';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { formatUtcDate, singularPluralFormat } from '../../../helpers/format';
import OrderCustomerInfoCard from './OrderCustomerInfoCard';
import OrderMerchantInfoCard from './OrderMerchantInfoCard';
import { translateVendorOrderData } from '../../../helpers/vendorHelper';
import VendorOrderShippingNotifyModal from './VendorOrderShippingNotifyModal';
import OrderProductsInfoCard from './OrderProductsInfoCard';
import OrderPaymentInfoCard from './OrderPaymentInfoCard';
import OrderTrackingInfoCard from './OrderTrackingInfoCard';
import EditShippingDetailsModal from './EditShippingDetailsModal';
import { ECOM_ORDER_PRODUCT_STATUS_ENUM } from '../../../config/constants';
import AppMenu from '../../common/AppMenu';

const VendorOrderDetailsView = ({ ecomOrder, loading }) => {
  const { isDesktopOrSmaller } = useMediaQueryIndex();
  const { state: authState } = useContext(AuthContext);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const { backPath } = useBackPath({ baseUrl: '/vendor' });
  const { ecomOrderProducts: productData } = translateVendorOrderData(
    ecomOrder
  );

  const isFullyPaid = false;
  const totalUnitCost =
    productData?.reduce((acc, p) => acc + p.totalCost, 0) ?? 0;

  const isFulfilled = productData.every(
    (p) =>
      p.fkEcomOrderProductStatus?.toString() !==
      ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING
  );
  const ecomOrderShipping =
    ecomOrder?.ecomOrderShipping.filter(
      (s) => s.fkEcomVendor.toString() === authState.pkEcomVendor?.toString()
    ) ?? [];

  const allShippingNotified = ecomOrderShipping.every((s) => s.emailSent);
  const shippedProductCount = productData.reduce(
    (acc, p) => acc + p.shippedCount,
    0
  );
  const haveAllProductsBeenShipped = productData.every(
    (p) => p.shippedCount >= p.quantity
  );

  return !loading && ecomOrder ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <VendorOrderShippingNotifyModal
        ecomOrder={ecomOrder}
        isOpen={modalState.isOpen && modalState.action === 'notify'}
        onClose={onCloseModal}
        pkEcomOrderShipping={
          modalState.action === 'notify'
            ? modalState.item?.pkEcomOrderShipping
            : null
        }
      />

      <EditShippingDetailsModal
        ecomOrderShipping={
          modalState.action === 'edit-shipping' ? modalState.item : null
        }
        isOpen={modalState.isOpen && modalState.action === 'edit-shipping'}
        onClose={onCloseModal}
        pkEcomOrder={ecomOrder.pkEcomOrder}
      />

      <AppFlexbox
        style={{
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to={backPath}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
              <AppFlexbox>
                <AppText
                  style={{
                    flex: 1,
                    fontSize: 24,
                    fontWeight: 700,
                    wordBreak: 'break-word'
                  }}
                >
                  Order #{ecomOrder.pkEcomOrder}
                </AppText>
              </AppFlexbox>

              {!isFullyPaid ? (
                <Badge color="dark" variant="light">
                  Unpaid
                </Badge>
              ) : (
                <Badge color="blue" variant="filled">
                  Paid
                </Badge>
              )}

              {isFulfilled ? (
                <Badge color="green" variant="filled">
                  Fulfilled
                </Badge>
              ) : (
                <Badge color="dark" variant="light">
                  Unfulfilled
                </Badge>
              )}
              {ecomOrderShipping.length > 0 &&
                (allShippingNotified ? (
                  <Badge color="blue" variant="filled">
                    Customer Notified
                  </Badge>
                ) : (
                  <Tooltip label="Customer has not been notified about all shipments.">
                    <Badge color="red" variant="filled">
                      Action Required
                    </Badge>
                  </Tooltip>
                ))}
            </AppFlexbox>
            <AppText
              style={{
                fontSize: 12,
                lineHeight: '16px',
                color: '#666'
              }}
            >
              Placed{' '}
              {dayjs(formatUtcDate(ecomOrder.purchaseDate)).format(
                'MMMM D, YYYY'
              )}{' '}
              at {dayjs(formatUtcDate(ecomOrder.purchaseDate)).format('h:mm A')}{' '}
              from {ecomOrder.ecomStore.name}
            </AppText>
          </AppStack>
        </AppFlexbox>

        {/* <AppFlexbox style={{ alignItems: 'center', gap: 8, marginTop: 5 }}>
          <AppMenu
            control={
              <Button
                color="dark"
                rightSection={<ChevronDown size={18} />}
                size="compact-md"
                style={{ fontSize: 14 }}
                variant="light"
              >
                Actions
              </Button>
            }
          >
            <Menu.Item onClick={() => {}}>Archive order</Menu.Item>
            <Menu.Item onClick={() => {}}>Cancel order</Menu.Item>
            <Menu.Item onClick={() => {}} style={{ color: 'red' }}>
              Refund order
            </Menu.Item>
          </AppMenu>
        </AppFlexbox> */}
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderProductsInfoCard
            fulfillLink={
              !haveAllProductsBeenShipped
                ? `/vendor/${
                    ecomOrder.isBulkShipment ? 'bulk-orders' : 'orders'
                  }/${ecomOrder.pkEcomOrder}/fulfill`
                : null
            }
            isFulfilled={isFulfilled}
            onNotify={
              shippedProductCount > 0 && !allShippingNotified
                ? () => onOpenModal('notify')
                : null
            }
            productData={productData}
          />

          <OrderPaymentInfoCard
            payments={[
              {
                key: 1,
                group: 1,
                label: 'Subtotal',
                color: '#666',
                description: singularPluralFormat(
                  ecomOrder.ecomOrderProducts.reduce(
                    (acc, item) => acc + item.count,
                    0
                  ),
                  'item',
                  'items'
                ),
                value: totalUnitCost / 100
              },
              {
                key: 2,
                group: 2,
                label: 'Total',
                weight: 500,
                description: '',
                value: totalUnitCost / 100
              }
            ]}
          />

          <OrderTrackingInfoCard
            ecomOrderShipping={ecomOrderShipping}
            loading={loading}
            onNotify={(shipping) => onOpenModal('notify', shipping)}
            onUpdateShipping={(shipping) =>
              onOpenModal('edit-shipping', shipping)
            }
            productData={productData}
          />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard
            customerEmail={ecomOrder.ecomCustomer.email}
            customerName={ecomOrder.ecomCustomer.user?.name}
            customerUrl={`/vendor/customers/${ecomOrder.ecomCustomer.pkEcomCustomer}?from=orders&fromId=${ecomOrder.pkEcomOrder}`}
            shippingAddress={ecomOrder.ecomCustomerAddressShipping}
          />

          <OrderMerchantInfoCard ecomStore={ecomOrder.ecomStore} />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to={backPath}
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 5 }}>
          <Skeleton height={36} width={200} />
          <Skeleton height={14} width={150} />
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderProductsInfoCard loading />

          <OrderPaymentInfoCard loading />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard loading />
          <OrderMerchantInfoCard loading />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

VendorOrderDetailsView.propTypes = {
  ecomOrder: PropTypes.object,
  loading: PropTypes.bool
};

export default VendorOrderDetailsView;
