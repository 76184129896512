import React from 'react';
import PropTypes from 'prop-types';
import {
  ActionIcon,
  Badge,
  Button,
  Divider,
  Menu,
  Skeleton,
  Tooltip
} from '@mantine/core';
import {
  ArrowsMaximize,
  DeviceDesktop,
  DeviceTablet,
  Dots,
  DragDrop,
  Logout,
  Settings
} from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import AppFlexbox from '../../common/AppFlexbox';
import AppCard from '../../common/AppCard';
import WebstoreBuilderHeaderNavigationMenu from './WebstoreBuilderHeaderNavigationMenu';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppMenu from '../../common/AppMenu';
import { SIDEBAR_VIEW_ENUM } from './wsAppSettingConfig';

const VIEW_SCREEN_RATIO_ENUM = {
  desktop: 'desktop',
  mobile: 'mobile',
  fullscreen: 'fullscreen'
};

const WebstoreBuilderHeader = ({
  loading,
  ecomStoreTheme,
  viewState,
  onViewStateChange,
  onSelectPage,
  controlState,
  editState,
  onSaveTheme
}) =>
  !loading && ecomStoreTheme ? (
    <AppCard
      shadow="sm"
      style={{
        position: '-webkit-sticky',
        position: 'sticky',
        top: 0,
        height: 60,
        padding: 0,
        width: '100%',
        maxWidth: '100vw',
        borderBottom: 'solid 1px #dee2e6'
      }}
      withBorder
    >
      <AppFlexbox
        style={{
          width: '100%',
          height: '100%',
          gap: 0,
          flex: 1,
          alignItems: 'center'
        }}
      >
        <AppFlexbox
          style={{
            height: '100%',
            width: '100%',
            gap: 0,
            flex: 1,
            alignItems: 'center',
            overflow: 'hidden'
          }}
        >
          <AppFlexbox
            component={Link}
            style={{
              padding: '0px 16px',
              alignItems: 'center',
              gap: 5,
              cursor: 'pointer',
              color: '#000',
              textDecoration: 'none'
            }}
            to="/merchant/webstore/themes"
          >
            <Logout size={20} style={{ transform: 'rotate(180deg)' }} />
          </AppFlexbox>
          <Divider hiddenFrom="sm" orientation="vertical" />
          <AppFlexbox
            style={{
              alignItems: 'center',
              width: '100%',
              overflow: 'hidden',
              gap: 8
            }}
            visibleFrom="sm"
          >
            <AppStack
              style={{
                padding: '0px 16px',
                maxWidth: '100%',
                overflow: 'hidden'
              }}
            >
              <AppFlexbox
                style={{
                  maxWidth: '100%',
                  alignItems: 'center',
                  gap: 8,
                  overflow: 'hidden'
                }}
              >
                <AppText style={{ fontWeight: 500, whiteSpace: 'nowrap' }}>
                  {ecomStoreTheme.name}
                </AppText>
              </AppFlexbox>
            </AppStack>
            <AppStack style={{ minWidth: 75 }}>
              <Badge
                color={ecomStoreTheme.active ? 'green' : 'blue'}
                style={{ marginLeft: 5 }}
                variant="light"
              >
                {ecomStoreTheme.active ? 'Active' : 'Draft'}
              </Badge>
            </AppStack>
          </AppFlexbox>

          <AppFlexbox
            hiddenFrom="sm"
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              flex: 1,
              overflow: 'hidden'
            }}
          >
            <WebstoreBuilderHeaderNavigationMenu
              controlState={controlState}
              ecomStoreTheme={ecomStoreTheme}
              onSelectPage={onSelectPage}
            />
          </AppFlexbox>
        </AppFlexbox>
        <AppFlexbox
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
          visibleFrom="sm"
        >
          <WebstoreBuilderHeaderNavigationMenu
            controlState={controlState}
            ecomStoreTheme={ecomStoreTheme}
            onSelectPage={onSelectPage}
          />
        </AppFlexbox>
        <AppFlexbox
          style={{
            flex: 1,
            padding: '0px 16px',
            justifyContent: 'end',
            alignItems: 'center',
            gap: 5
          }}
          visibleFrom="sm"
        >
          <ActionIcon
            color="dark"
            onClick={() => {
              onViewStateChange({
                ...viewState,
                inspectEnabled: !viewState.inspectEnabled
              });
            }}
            radius="md"
            size={28}
            variant={viewState.inspectEnabled ? 'light' : 'subtle'}
          >
            <DragDrop
              color={viewState.inspectEnabled ? 'dodgerblue' : '#000'}
              size={18}
            />
          </ActionIcon>
          <AppCard
            radius="md"
            shadow="none"
            style={{ padding: 2, backgroundColor: 'rgba(56, 56, 56, 0.1)' }}
          >
            <AppFlexbox style={{ gap: 1, flex: 1, alignItems: 'center' }}>
              <Tooltip label="Mobile" position="bottom" withArrow withinPortal>
                <ActionIcon
                  color="dark"
                  onClick={() => {
                    onViewStateChange({
                      ...viewState,
                      screenRatio: VIEW_SCREEN_RATIO_ENUM.mobile
                    });
                  }}
                  radius="md"
                  size={26}
                  variant={
                    viewState.screenRatio === VIEW_SCREEN_RATIO_ENUM.mobile
                      ? 'white'
                      : 'subtle'
                  }
                >
                  <DeviceTablet size={18} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Desktop" position="bottom" withArrow withinPortal>
                <ActionIcon
                  color="dark"
                  onClick={() => {
                    onViewStateChange({
                      ...viewState,
                      screenRatio: VIEW_SCREEN_RATIO_ENUM.desktop
                    });
                  }}
                  radius="md"
                  size={26}
                  variant={
                    viewState.screenRatio === VIEW_SCREEN_RATIO_ENUM.desktop
                      ? 'white'
                      : 'subtle'
                  }
                >
                  <DeviceDesktop size={18} />
                </ActionIcon>
              </Tooltip>
              <Tooltip
                label="Fullscreen"
                position="bottom"
                withArrow
                withinPortal
              >
                <ActionIcon
                  color="dark"
                  onClick={() => {
                    onViewStateChange({
                      ...viewState,
                      screenRatio: VIEW_SCREEN_RATIO_ENUM.fullscreen
                    });
                  }}
                  radius="md"
                  size={26}
                  variant={
                    viewState.screenRatio === VIEW_SCREEN_RATIO_ENUM.fullscreen
                      ? 'white'
                      : 'subtle'
                  }
                  visibleFrom="md"
                >
                  <ArrowsMaximize size={18} />
                </ActionIcon>
              </Tooltip>
            </AppFlexbox>
          </AppCard>
          {/* <Button.Group>
            <Button color="dark" disabled size="compact-md" variant="filled">
              <ArrowBackUp size={18} />
            </Button>
            <Button color="dark" disabled size="compact-md" variant="filled">
              <ArrowForwardUp size={18} />
            </Button>
          </Button.Group> */}

          <Button
            color="blue"
            disabled={!editState.hasUnsavedChanges}
            loading={editState.loading}
            onClick={onSaveTheme}
            radius="md"
            size="compact-md"
            variant="filled"
          >
            Save
          </Button>
        </AppFlexbox>

        <AppFlexbox
          hiddenFrom="sm"
          style={{
            height: '100%',
            paddingRight: 12,
            justifyContent: 'end',
            alignItems: 'center',
            gap: 12
          }}
        >
          <Divider orientation="vertical" />
          <AppFlexbox style={{ height: '100%', padding: '12px 0px' }}>
            <AppMenu
              control={
                <ActionIcon
                  color="dark"
                  style={{ height: '100%', width: 40 }}
                  variant="subtle"
                >
                  <Dots size={18} />
                </ActionIcon>
              }
              styles={{ dropdown: { padding: 0 } }}
            >
              <AppStack style={{ padding: 2, gap: 1 }}>
                <Menu.Item
                  leftSection={
                    <DragDrop
                      color={viewState.inspectEnabled ? 'dodgerblue' : '#000'}
                      size={16}
                    />
                  }
                  onClick={() => {
                    onViewStateChange({
                      ...viewState,
                      inspectEnabled: !viewState.inspectEnabled
                    });
                  }}
                >
                  {viewState.inspectEnabled ? 'Disable' : 'Enable'} selector
                </Menu.Item>
              </AppStack>
              <Divider />
              {/* <AppStack style={{ padding: 2, gap: 1 }}>
                <Menu.Item leftSection={<ArrowBackUp size={16} />}>
                  Undo
                </Menu.Item>
                <Menu.Item leftSection={<ArrowForwardUp size={16} />}>
                  Redo
                </Menu.Item>
              </AppStack>
              <Divider /> */}
              <AppStack style={{ padding: 2, gap: 1 }}>
                <Menu.Item
                  leftSection={<Settings size={16} />}
                  onClick={() => {
                    onViewStateChange({
                      ...viewState,
                      sidebarView: SIDEBAR_VIEW_ENUM.settings
                    });
                  }}
                >
                  Theme settings
                </Menu.Item>
              </AppStack>
            </AppMenu>
          </AppFlexbox>

          <Divider orientation="vertical" />

          <Button
            color="blue"
            disabled={!editState.hasUnsavedChanges}
            loading={editState.loading}
            onClick={onSaveTheme}
            radius="md"
            size="compact-md"
            variant="filled"
          >
            Save
          </Button>
        </AppFlexbox>
      </AppFlexbox>
    </AppCard>
  ) : (
    <AppCard
      shadow="sm"
      style={{
        position: '-webkit-sticky',
        position: 'sticky',
        top: 0,
        height: 60,
        padding: 0,
        borderBottom: 'solid 1px #dee2e6'
      }}
      withBorder
    >
      <AppFlexbox
        style={{ height: '100%', gap: 0, flex: 1, alignItems: 'center' }}
      >
        <AppFlexbox
          style={{
            height: '100%',
            gap: 0,
            flex: 1,
            alignItems: 'center'
          }}
        >
          <AppFlexbox
            style={{
              padding: '0px 16px',
              alignItems: 'center',
              gap: 5,
              cursor: 'pointer',
              color: '#000',
              textDecoration: 'none'
            }}
          >
            <Skeleton height={20} width={20} />
          </AppFlexbox>
          <Divider hiddenFrom="sm" orientation="vertical" />
          <AppStack style={{ padding: '0px 16px' }} visibleFrom="sm">
            <Skeleton height={26} width={130} />
          </AppStack>

          <AppFlexbox
            hiddenFrom="sm"
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              flex: 1
            }}
          >
            <AppFlexbox
              style={{
                height: '100%',
                width: '100%',
                padding: '0px 12px',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer'
              }}
            >
              <AppFlexbox style={{ flex: 1, alignItems: 'center', gap: 12 }}>
                <Skeleton height={18} width={18} />
                <AppStack style={{ gap: 5, flex: 1 }}>
                  <AppFlexbox
                    style={{ gap: 8, alignItems: 'center', overflow: 'hidden' }}
                  >
                    <Skeleton height={14} width="60%" />
                  </AppFlexbox>

                  <Skeleton height={14} width="45%" />
                </AppStack>
              </AppFlexbox>
              <Skeleton height={18} width={18} />
            </AppFlexbox>
          </AppFlexbox>
        </AppFlexbox>
        <AppFlexbox
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flex: 1
          }}
          visibleFrom="sm"
        >
          <Skeleton height={34} width={152} />
        </AppFlexbox>
        <AppFlexbox
          style={{
            flex: 1,
            padding: '0px 16px',
            justifyContent: 'end',
            alignItems: 'center',
            gap: 5
          }}
          visibleFrom="sm"
        >
          <Skeleton height={28} width={28} />
          <Skeleton height={28} hiddenFrom="md" width={55} />
          {/* <Skeleton height={28} visibleFrom="md" width={80} /> */}
          <Skeleton height={28} width={80} />
          <Skeleton height={28} width={54} />
        </AppFlexbox>

        <AppFlexbox
          hiddenFrom="sm"
          style={{
            height: '100%',
            paddingRight: 12,
            justifyContent: 'end',
            alignItems: 'center',
            gap: 12
          }}
        >
          <Divider orientation="vertical" />
          <Skeleton height={32} width={38} />

          <Divider orientation="vertical" />

          <Skeleton height={28} width={54} />
        </AppFlexbox>
      </AppFlexbox>
    </AppCard>
  );

WebstoreBuilderHeader.propTypes = {
  controlState: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editState: PropTypes.object,
  loading: PropTypes.bool,
  onSaveTheme: PropTypes.func,
  onSelectPage: PropTypes.func,
  onViewStateChange: PropTypes.func,
  viewState: PropTypes.object
};

export default WebstoreBuilderHeader;
