import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@mantine/core';
import ReportTableColumn from './ReportTableColumn';
import { getDateRangeLabel } from '../../../helpers/format';

const isRenderSameAsBefore = (prevProps, nextProps) =>
  prevProps.showFirstColumnBorder === nextProps.showFirstColumnBorder &&
  prevProps.showSummary === nextProps.showSummary &&
  prevProps.pageIndex === nextProps.pageIndex &&
  prevProps.maxPageCount === nextProps.maxPageCount &&
  prevProps.data.length === nextProps.data.length &&
  prevProps.headers.length === nextProps.headers.length &&
  prevProps.headers.every((d, i) => nextProps.headers[i].field === d.field) &&
  (!prevProps.compareData ||
    !nextProps.compareData ||
    (prevProps.compareData.length === nextProps.compareData.length &&
      prevProps.compareData.every((d, i) =>
        d.every((c, j) => c === nextProps.compareData[i][j])
      ))) &&
  prevProps.data.every((d, i) => d.every((c, j) => c === nextProps.data[i][j]));

const summarizeData = (data, headers, isCompareData) =>
  data.reduce((accumulator, current) => {
    // Initialize the accumulator
    if (accumulator.length === 0) {
      headers.forEach((h) => {
        if (h.dataType === 'number' || h.dataType === 'currency') {
          accumulator.push(0);
        }
        else {
          accumulator.push(null);
        }
      });
    }

    // Summarize the data into the accumulator
    headers.forEach((h, i) => {
      if (!isCompareData && h.averageValue !== null) {
        accumulator[i] = Number(h.averageValue);
      }
      else if (isCompareData && h.compareAverageValue !== null) {
        accumulator[i] = Number(h.compareAverageValue);
      }
      else {
        switch (h.dataType) {
          case 'number':
          case 'currency':
            accumulator[i] += Number(current[i]);
            break;
          case 'date': {
            const allDates = data
              .map((d) => d[i])
              .join(',')
              .split(',')
              .filter((d) => !!d)
              .sort((a, b) => new Date(a) - new Date(b));
            accumulator[i] = getDateRangeLabel(
              allDates[0],
              allDates[allDates.length - 1]
            );
            break;
          }
          case 'percentage':
            accumulator[i] = null;
            break;
          default:
            accumulator[i] = null;
            break;
        }
      }
    });

    return accumulator;
  }, []);

const ReportTableBody = React.memo(
  ({
    data,
    compareData,
    headers,
    showFirstColumnBorder,
    showSummary,
    pageIndex,
    maxPageCount,
    onViewMore
  }) => {
    const showCompare = !!compareData && compareData.length > 0;
    const dataSummarized = summarizeData(data, headers);
    const compareDataSummarized = compareData
      ? summarizeData(compareData, headers, true)
      : null;

    return (
      <Table.Tbody style={{ position: 'relative' }}>
        {showSummary && (
          <Table.Tr style={{ backgroundColor: '#F1F1F1' }}>
            {dataSummarized.map((c, i) => (
              <ReportTableColumn
                key={headers[i].field}
                compareValue={
                  compareDataSummarized ? compareDataSummarized[i] : null
                }
                header={headers[i]}
                index={i}
                isSummary
                onViewMore={onViewMore}
                showCompareValue={showCompare}
                showFirstColumnBorder={showFirstColumnBorder}
                value={c}
              />
            ))}
          </Table.Tr>
        )}

        {data.slice(0, pageIndex * maxPageCount).map((d, rowIndex) => {
          const compareDataRow = compareData ? compareData[rowIndex] : null;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Table.Tr key={`${d[0]}-${rowIndex}`}>
              {d.map((c, i) => (
                <ReportTableColumn
                  key={headers[i].field}
                  compareValue={compareDataRow && compareDataRow[i]}
                  header={headers[i]}
                  index={i}
                  onViewMore={(valueList) =>
                    onViewMore(headers[0], d[0], headers[i], valueList)
                  }
                  showCompareValue={showCompare}
                  showFirstColumnBorder={showFirstColumnBorder}
                  value={c}
                />
              ))}
            </Table.Tr>
          );
        })}
      </Table.Tbody>
    );
  },
  isRenderSameAsBefore
);

ReportTableBody.propTypes = {
  compareData: PropTypes.array,
  data: PropTypes.array,
  headers: PropTypes.array,
  maxPageCount: PropTypes.number,
  onViewMore: PropTypes.func,
  pageIndex: PropTypes.number,
  showFirstColumnBorder: PropTypes.bool,
  showSummary: PropTypes.bool
};

export default ReportTableBody;
