import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { ActionIcon, Button } from '@mantine/core';
import { ArrowLeft, Download } from 'tabler-icons-react';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';
import AnalyticsFilter from './AnalyticsFilter';
import ReportTable from './ReportTable';
import AnalyticsChartBase from './AnalyticsChartBase';
import { REPORT_HEADER_LIST } from './reportsConfig';
import AnalyticsReportSummaryView from './AnalyticsReportSummaryView';
import ConfirmModal from '../../common/ConfirmModal';
import AppCheckbox from '../../common/AppCheckbox';
import { downloadPapaCsv } from '../../../helpers/csvHelper';
import { triggerNotification } from '../../../helpers/notification';
import { formatDataTypeString } from '../../../helpers/format';

const AnalyticsReportView = ({
  entityId,
  onFetchReport,
  reportConfigs,
  reportResult,
  loading,
  redirectPath,
  reportViewType
}) => {
  const hasFetched = useRef(false);
  const { isTabletOrSmaller } = useMediaQueryIndex();
  const { uuid } = useParams();
  const [viewState, setViewState] = useState({
    headers: null,
    options: [],
    filter: {},
    selectedChartField: null,
    exportModal: {
      isOpen: false,
      exportCompareData: false,
      loading: false
    }
  });

  const chartableHeaders = reportResult
    ? REPORT_HEADER_LIST.filter(
        (h) =>
          h.chartable &&
          reportResult.report.headers.some((r) => r.field === h.value)
      )
    : [];
  const reportLoading = !hasFetched.current || loading;
  const reportConfig = reportConfigs.find((f) => f.uuid === uuid);

  useEffect(() => {
    if (reportResult) {
      setViewState((c) => ({
        ...c,
        selectedChartField:
          chartableHeaders.find(
            (h) => h.value === reportConfig.chart?.defaultHeaderValue
          )?.value ?? chartableHeaders[0]?.value
      }));
    }
  }, [reportResult]);

  const onFetchReportData = (headers, filter) => {
    const groupByPeriod = reportConfig.groupBy === 'period';
    const compareEnabled = groupByPeriod && reportConfig.compareEnabled;
    onFetchReport(reportConfig.uuid, {
      headers,
      fkReportType: reportConfig.fkReportType,
      groupBy: reportConfig.groupBy,
      ...filter,
      periodInterval: groupByPeriod ? filter.periodInterval : null,
      compareStartDate: compareEnabled ? filter.compareStartDate : null,
      compareEndDate: compareEnabled ? filter.compareEndDate : null
    });
    hasFetched.current = true;
  };

  const onDownloadReport = () => {
    setViewState((c) => ({
      ...c,
      exportModal: { ...c.exportModal, loading: true }
    }));

    setTimeout(() => {
      new Promise((resolve) => {
        const reportHeaders = reportResult.report.headers.map((h) => h.label);

        downloadPapaCsv(
          `SportsHeadz_report`,
          reportHeaders,
          reportResult.report.data.map((d) =>
            d.map((c, index) => {
              const header = reportResult.report.headers[index];
              return formatDataTypeString(c, header.dataType);
            })
          )
        );

        if (
          viewState.exportModal.exportCompareData &&
          reportResult.report.compareData
        ) {
          downloadPapaCsv(
            `SportsHeadz_report_compare`,
            reportHeaders,
            reportResult.report.compareData.map((d) =>
              d.map((c, index) => {
                const header = reportResult.report.headers[index];
                return formatDataTypeString(c, header.dataType);
              })
            )
          );
        }

        resolve();
      }).then(() => {
        setViewState((c) => ({
          ...c,
          exportModal: { ...c.exportModal, isOpen: false }
        }));
        triggerNotification('Report downloaded!', 'success');
      });
    }, 200);
  };

  return reportConfig?.isSummaryReport ? (
    <AppStack
      style={{
        flex: 1,
        gap: 16,
        padding: isTabletOrSmaller
          ? '10px 10px 40px 10px'
          : '10px 20px 40px 15px',
        backgroundColor: '#F0F0F0',
        width: '100%',
        height: '100%',
        margin: 'auto',
        maxWidth: 950
      }}
    >
      <AppFlexbox
        style={{
          gap: 8,
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <ActionIcon
            color="dark"
            component={Link}
            to={redirectPath}
            variant="subtle"
          >
            <ArrowLeft size={24} />
          </ActionIcon>
          <AppText style={{ fontSize: 20, fontWeight: 700 }}>
            {reportConfig.title ?? 'Analytics'}
          </AppText>
        </AppFlexbox>
      </AppFlexbox>

      <AnalyticsFilter
        defaultDateOption={reportConfig.defaultDateOption}
        entityId={entityId}
        onFilterChange={(filter) => {
          setViewState((c) => ({ ...c, filter }));
          onFetchReportData(
            viewState.headers || reportConfig.defaultHeaders,
            filter
          );
        }}
        updateParamsOnFilterChange
        uuid={reportConfig.uuid}
      />

      <AnalyticsReportSummaryView
        loading={reportLoading}
        reportConfig={reportConfig}
        reportResult={reportResult}
      />
    </AppStack>
  ) : reportConfig ? (
    <AppStack
      id="analytics-report-view"
      style={{
        flex: 1,
        gap: 16,
        padding: isTabletOrSmaller
          ? '10px 10px 40px 10px'
          : '10px 20px 40px 15px',
        backgroundColor: '#F0F0F0',
        width: '100%',
        height: '100%',
        overflow: 'auto'
      }}
    >
      <AppFlexbox
        style={{
          gap: 8,
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <ActionIcon
            color="dark"
            component={Link}
            to={redirectPath}
            variant="subtle"
          >
            <ArrowLeft size={24} />
          </ActionIcon>
          <AppText style={{ fontSize: 20, fontWeight: 700 }}>
            {reportConfig.title ?? 'Analytics'}
          </AppText>
        </AppFlexbox>
        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <Button
            color="dark"
            disabled={reportLoading}
            leftSection={<Download size={18} />}
            onClick={() => {
              setViewState((c) => ({
                ...c,
                exportModal: {
                  isOpen: true,
                  exportCompareData: false,
                  loading: false
                }
              }));
            }}
            size="compact-md"
            variant="filled"
          >
            Export
          </Button>
        </AppFlexbox>
      </AppFlexbox>

      <AnalyticsFilter
        defaultDateOption={reportConfig.defaultDateOption}
        entityId={entityId}
        includeCompareDateRange={reportConfig.compareEnabled}
        includePeriod={reportConfig.groupBy === 'period'}
        onFilterChange={(filter) => {
          setViewState((c) => ({ ...c, filter }));
          onFetchReportData(
            viewState.headers || reportConfig.defaultHeaders,
            filter
          );
        }}
        updateParamsOnFilterChange
        uuid={reportConfig.uuid}
      />

      {reportConfig?.chart && (
        <AppStack>
          <AnalyticsChartBase
            chart={reportConfig.chart}
            chartableHeaders={chartableHeaders}
            compareDateRange={{
              start: viewState.filter.compareStartDate,
              end: viewState.filter.compareEndDate
            }}
            dateRange={{
              start: viewState.filter.startDate,
              end: viewState.filter.endDate
            }}
            loading={reportLoading}
            loadingVariant="overlay"
            onChangeChartHeader={(header) =>
              setViewState((c) => ({ ...c, selectedChartField: header.value }))
            }
            reportResult={reportResult}
            selectedChartField={viewState.selectedChartField}
          />
        </AppStack>
      )}

      <AppStack>
        <AppCard
          radius="md"
          shadow="lg"
          style={{ padding: 0, position: 'unset', overflow: 'visible' }}
          withBorder
        >
          <ReportTable
            loading={reportLoading}
            onHeaderFieldChange={(headers) => {
              setViewState((c) => ({
                ...c,
                headers
              }));
              onFetchReportData(headers, viewState.filter);
            }}
            report={reportResult?.report}
            reportConfig={reportConfig}
            reportViewType={reportViewType}
            showSummary
            uuid={reportResult?.uuid}
          />
        </AppCard>
      </AppStack>

      <ConfirmModal
        confirmActionText="Export"
        isLoading={viewState.exportModal.loading}
        isOpen={viewState.exportModal.isOpen}
        onCancel={() => {
          setViewState((c) => ({
            ...c,
            exportModal: { ...c.exportModal, isOpen: false }
          }));
        }}
        onConfirm={onDownloadReport}
        title="Export report data"
      >
        <AppStack style={{ gap: 16 }}>
          <AppText style={{ fontSize: 14 }}>
            The report data will be exported to a CSV file.
          </AppText>
          {reportResult &&
            reportResult.report.compareData &&
            reportResult.report.compareData.length > 0 && (
              <AppCheckbox
                checked={viewState.exportModal.exportCompareData}
                description="Downloaded as a seperate CSV file"
                disabled={viewState.exportModal.loading}
                label="Include comparison data"
                onChange={() =>
                  setViewState({
                    ...viewState,
                    exportModal: {
                      ...viewState.exportModal,
                      exportCompareData: !viewState.exportModal
                        .exportCompareData
                    }
                  })
                }
              />
            )}
        </AppStack>
      </ConfirmModal>
    </AppStack>
  ) : (
    <></>
  );
};

AnalyticsReportView.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  onFetchReport: PropTypes.func,
  redirectPath: PropTypes.string,
  reportConfigs: PropTypes.array,
  reportResult: PropTypes.object,
  reportViewType: PropTypes.string
};

export default AnalyticsReportView;
