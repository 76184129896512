const processProductCounts = (
  analytics,
  dataLabel,
  compareDataLabel,
  dataKey,
  nameKey
) => {
  let chartData = analytics.data
    .map((d) => d[dataKey])
    .flat()
    .reduce((r, c) => {
      const existingProduct = r.find(
        (d) => d.vendorProductUuid === c.vendorProductUuid
      );
      if (existingProduct) {
        existingProduct[dataLabel] += c.count;
      }
      else {
        r.push({
          vendorProductUuid: c.vendorProductUuid,
          name: c[nameKey],
          [dataLabel]: c.count
        });
      }
      return r;
    }, []);

  if (analytics.compareData) {
    chartData = analytics.compareData
      .map((d) => d[dataKey])
      .flat()
      .reduce((r, c) => {
        const existingProduct = r.find(
          (d) => d.vendorProductUuid === c.vendorProductUuid
        );
        if (existingProduct) {
          existingProduct[compareDataLabel] =
            (existingProduct[compareDataLabel] ?? 0) + c.count;
        }
        else {
          r.push({
            vendorProductUuid: c.vendorProductUuid,
            name: c[nameKey],
            [compareDataLabel]: c.count
          });
        }
        return r;
      }, chartData);
  }

  return chartData
    .sort(
      (a, b) =>
        b[dataLabel] - a[dataLabel] ||
        b[compareDataLabel] - a[compareDataLabel] ||
        a.name.localeCompare(b.name)
    )
    .map((m) => ({
      ...m,
      [dataLabel]: m[dataLabel] || 0,
      [compareDataLabel]: m[compareDataLabel] || 0
    }));
};

export { processProductCounts };
