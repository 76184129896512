import React from 'react';
import PropTypes from 'prop-types';
import {
  Anchor,
  Divider,
  NumberFormatter,
  Skeleton,
  Tooltip
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { InfoCircle } from 'tabler-icons-react';
import AppCard from '../../common/AppCard';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { REPORT_HEADER_LIST } from './reportsConfig';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const AnalyticsReportSummaryView = ({
  reportConfig,
  reportResult,
  loading
}) => {
  const { isLaptopOrSmaller } = useMediaQueryIndex();
  const groupedDisplays = reportConfig.summaryDisplays.reduce((r, c) => {
    const existing = r.find((d) => d.group === c.group);
    if (existing) {
      existing.displays.push(c);
    }
    else {
      r.push({ group: c.group, displays: [c] });
    }
    return r;
  }, []);

  return reportConfig ? (
    <AppFlexbox style={{ flexDirection: isLaptopOrSmaller ? 'column' : 'row' }}>
      {groupedDisplays.map((gd) => (
        <AppStack key={gd.group} style={{ flex: 1 }}>
          {gd.displays.map((display) => (
            <AppCard key={display.title} radius="md" shadow="xs" withBorder>
              <AppStack style={{ gap: 16 }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  {display.title}
                </AppText>

                {display.ComponentTop && (
                  <display.ComponentTop
                    loading={loading}
                    reportResult={reportResult}
                  />
                )}

                {display.data.map((d) => {
                  const header = REPORT_HEADER_LIST.find(
                    (h) => h.value === d.header
                  );
                  const headerIndex = reportResult?.report.headers.findIndex(
                    (h) => h.field === header.value
                  );

                  const value =
                    reportResult?.report.data.reduce(
                      (r, c) => r + c[headerIndex] * 1,
                      0
                    ) || 0;
                  return (
                    <React.Fragment key={d.header}>
                      {d.topDivider && <Divider />}
                      <AppFlexbox
                        key={d.header}
                        style={{
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Tooltip
                          disabled={!d.tooltip}
                          label={d.tooltip}
                          multiline
                          w={250}
                          withArrow
                        >
                          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                            {d.reportUuid ? (
                              <Anchor
                                component={Link}
                                style={{ fontSize: 14, color: 'dodgerblue' }}
                                to={`/merchant/reports/${d.reportUuid}`}
                              >
                                {d.label || header.label}
                              </Anchor>
                            ) : (
                              <AppText style={{ fontSize: 14 }}>
                                {d.label || header.label}
                              </AppText>
                            )}
                            {d.tooltip && (
                              <InfoCircle color="dodgerblue" size={18} />
                            )}
                          </AppFlexbox>
                        </Tooltip>
                        {loading ? (
                          <Skeleton height={16} width={65} />
                        ) : (
                          <NumberFormatter
                            decimalScale={2}
                            fixedDecimalScale
                            prefix="$"
                            style={{
                              fontSize: 14,
                              fontWeight: d.fontWeight || 'normal'
                            }}
                            thousandSeparator
                            value={(d.isNegative ? value * -1 : value) / 100}
                          />
                        )}
                      </AppFlexbox>
                      {d.bottomDivider && <Divider />}
                    </React.Fragment>
                  );
                })}

                {display.ComponentBottom && (
                  <display.ComponentBottom
                    loading={loading}
                    reportResult={reportResult}
                  />
                )}
              </AppStack>
            </AppCard>
          ))}
        </AppStack>
      ))}
    </AppFlexbox>
  ) : (
    <></>
  );
};

AnalyticsReportSummaryView.propTypes = {
  loading: PropTypes.bool,
  reportConfig: PropTypes.object,
  reportResult: PropTypes.object
};

export default AnalyticsReportSummaryView;
