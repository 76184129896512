import React, { useContext, useEffect, useState } from 'react';
import { Checkbox } from '@mantine/core';
import PropTypes from 'prop-types';
import ResponsiveModal from '../../common/ResponsiveModal';
import { useVendorCatalogCheckout } from '../../../helpers/vendorCatalogCheckoutHelper';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import StoreProductInputAnswerForm from '../stores/StoreProductInputAnswerForm';

const SetVendorProductInputsModal = ({ isOpen, onClose, skippable }) => {
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    toggleVendorCatalogInputModal,
    toggleVendorCatalogSkipInputModal
  } = useContext(HelperContext);
  const { cart: catalogCart, updateProducts } = useVendorCatalogCheckout();
  const [formState, setFormState] = useState({ ecomVendorProducts: [] });

  useEffect(() => {
    if (state.vendorCatalog.showInputModal || isOpen) {
      setFormState({
        ecomVendorProducts: state.vendorCatalog.inputProducts.map((p) => ({
          uuid: p.uuid,
          name: p.name,
          vendorName: p.vendorName,
          ecomVendorProductInputs: p.ecomVendorProductInputs,
          ecomVendorProductVariantCount: p.ecomVendorProductVariantCount,
          ecomStoreProductInputAnswers:
            catalogCart.storageCart.ecomVendorProducts.find(
              (c) => c.uuid === p.uuid
            )?.ecomStoreProductInputAnswers ?? []
        }))
      });
    }
  }, [state.vendorCatalog.showInputModal, isOpen]);

  const onCloseModal = () => {
    updateProducts(formState.ecomVendorProducts);
    if (onClose) {
      onClose();
    }
    else {
      toggleVendorCatalogInputModal(false);
    }
  };

  return (
    <ResponsiveModal
      formSectionProps={{
        onCancel: onCloseModal,
        onSubmit: onCloseModal,
        submitColor: 'dark',
        submitTitle: 'Confirm',
        isCancelHidden: true,
        isLoading: formState.loading,
        leftSection: skippable && (
          <Checkbox
            checked={state.vendorCatalog.skipInputModalOnAdd}
            label="Skip until the end"
            onChange={() => {
              toggleVendorCatalogSkipInputModal(
                !state.vendorCatalog.skipInputModalOnAdd
              );
            }}
          />
        )
      }}
      isOpen={
        onClose
          ? isOpen
          : state.vendorCatalog.showInputModal &&
            !state.vendorCatalog.showMinimumQuanityModal
      }
      onClose={onCloseModal}
      title="Autopopulate product inputs"
    >
      <AppStack style={{ gap: 16 }}>
        <AppText style={{ fontSize: 14, fontWeight: 500, textAlign: 'center' }}>
          Please confirm the following optional inputs before adding to your
          store.
        </AppText>

        <StoreProductInputAnswerForm
          ecomStoreProducts={formState.ecomVendorProducts}
          onChangeProducts={(v) =>
            setFormState({
              ...formState,
              ecomVendorProducts: v
            })
          }
          onChangeSelectedUuid={(v) =>
            toggleVendorCatalogInputModal(state.vendorCatalog.showInputModal, v)
          }
          pkEcomStore={authState.pkEcomStore}
          selectedUuid={state.vendorCatalog.selectedInputProductUuid}
        />
      </AppStack>
    </ResponsiveModal>
  );
};

SetVendorProductInputsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  skippable: PropTypes.bool
};

export default SetVendorProductInputsModal;
