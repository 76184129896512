import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Select } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import { singularPluralFormat } from '../../../helpers/format';
import { ECOM_PRODUCT_STATUS_ENUM } from '../../../config/constants';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import AppText from '../../common/AppText';
import { triggerNotification } from '../../../helpers/notification';

const PRODUCT_STATUS_OPTIONS = [
  {
    value: ECOM_PRODUCT_STATUS_ENUM.ACTIVE,
    label: 'Active'
  },
  {
    value: ECOM_PRODUCT_STATUS_ENUM.DRAFT,
    label: 'Draft'
  }
];

const ChangeProductStatusModal = ({
  isOpen,
  onClose,
  fkEcomVendor,
  fkEcomStore,
  products,
  archiveProducts,
  onStatusChangeSuccess,
  selectFirstItem
}) => {
  const [formState, setFormState] = useState({
    selectedOptionValue: '',
    loading: false
  });
  const { updateVendorProducts } = useContext(VendorContext);
  const { updateStoreProducts } = useContext(StoreContext);
  const selectedOption = PRODUCT_STATUS_OPTIONS.find(
    (o) => o.value === formState.selectedOptionValue
  );

  useEffect(() => {
    if (isOpen) {
      setFormState({
        selectedOptionValue: PRODUCT_STATUS_OPTIONS[0]?.value,
        loading: false
      });
    }
  }, [isOpen]);

  const onSuccessCallback = () => {
    if (onStatusChangeSuccess) {
      onStatusChangeSuccess();
    }
    triggerNotification(
      `${singularPluralFormat(
        products.length,
        'product',
        'products'
      )} status updated.`,
      'success'
    );
    onClose();
  };

  const onErrorCallback = (e) => {
    setFormState({ ...formState, loading: false });
    triggerNotification(e);
  };

  return (
    <ResponsiveModal
      formSectionProps={{
        onCancel: onClose,
        isLoading: formState.loading,
        onSubmit: () => {
          setFormState({ ...formState, loading: true });
          if (fkEcomVendor) {
            updateVendorProducts(
              fkEcomVendor,
              {
                ecomVendorProducts: products.map((p) => ({
                  uuid: p.uuid,
                  fkEcomProductStatus: archiveProducts
                    ? ECOM_PRODUCT_STATUS_ENUM.ARCHIVED
                    : selectedOption?.value
                })),
                selectFirstItem
              },
              onSuccessCallback,
              onErrorCallback
            );
          }
          else {
            updateStoreProducts(
              fkEcomStore,
              {
                ecomStoreProducts: products.map((p) => ({
                  pkEcomStoreProduct: p.pkEcomStoreProduct,
                  uuid: p.uuid,
                  fkEcomProductStatus: archiveProducts
                    ? ECOM_PRODUCT_STATUS_ENUM.ARCHIVED
                    : selectedOption?.value
                })),
                selectFirstItem
              },
              onSuccessCallback,
              onErrorCallback
            );
          }
        },
        submitTitle: archiveProducts
          ? 'Yes, archive products'
          : `Set as ${selectedOption?.label.toLowerCase() ?? ''}`
      }}
      isOpen={isOpen}
      onClose={onClose}
      title={`${
        archiveProducts ? 'Archive' : 'Change status of'
      } ${singularPluralFormat(products.length, 'product', 'products')}`}
    >
      <AppStack style={{ gap: 20 }}>
        {!archiveProducts && (
          <Select
            data={PRODUCT_STATUS_OPTIONS}
            disabled={formState.loading}
            label="New status"
            onChange={(v) => {
              if (v) {
                setFormState({
                  ...formState,
                  selectedOptionValue: v
                });
              }
            }}
            required
            searchable
            value={formState.selectedOptionValue}
          />
        )}

        <Alert color="blue" title="" variant="outline">
          <AppStack style={{ gap: 10 }}>
            {archiveProducts ? (
              <AppText style={{ fontSize: 14 }}>
                Are you sure you want to archive{' '}
                <b>
                  {singularPluralFormat(products.length, 'product', 'products')}
                </b>
                ?
              </AppText>
            ) : (
              <AppText style={{ fontSize: 14 }}>
                Are you sure you want to <b>change the status</b> of{' '}
                <b>
                  {singularPluralFormat(products.length, 'product', 'products')}
                </b>{' '}
                to <b>{selectedOption?.label ?? ''}</b>?
              </AppText>
            )}

            {!archiveProducts &&
            selectedOption?.value === ECOM_PRODUCT_STATUS_ENUM.ACTIVE ? (
              <>
                <AppText style={{ fontSize: 14 }}>
                  This will make the products <b>available for sale</b> and show
                  them on your store.
                </AppText>
                <AppText
                  style={{ fontSize: 14, color: '#666', fontStyle: 'italic' }}
                >
                  Any products without pricing will be set to draft.
                </AppText>
              </>
            ) : (
              <AppText style={{ fontSize: 14 }}>
                This will make the products <b>unavailable for sale</b> and{' '}
                <b>hide</b> them from your store.
              </AppText>
            )}
          </AppStack>
        </Alert>
      </AppStack>
    </ResponsiveModal>
  );
};

ChangeProductStatusModal.propTypes = {
  archiveProducts: PropTypes.bool,
  fkEcomStore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fkEcomVendor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onStatusChangeSuccess: PropTypes.func,
  products: PropTypes.array,
  selectFirstItem: PropTypes.bool
};

export default ChangeProductStatusModal;
