import React from 'react';
import PropTypes from 'prop-types';
import { Anchor, Table } from '@mantine/core';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { formatDataTypeString } from '../../../helpers/format';

const isRenderSameAsBefore = (prevProps, nextProps) =>
  prevProps.showFirstColumnBorder === nextProps.showFirstColumnBorder &&
  prevProps.value === nextProps.value &&
  prevProps.compareValue === nextProps.compareValue;

const ReportTableColumn = React.memo(
  ({
    header,
    value,
    compareValue,
    index,
    showFirstColumnBorder,
    isSummary,
    onViewMore,
    showCompareValue
  }) => {
    const canSplit =
      !isSummary &&
      (header.dataType === 'string' ||
        header.dataType === 'email' ||
        header.dataType === 'date');
    const isNumber =
      header.dataType === 'currency' ||
      header.dataType === 'number' ||
      header.dataType === 'percentage';

    const splitValue =
      canSplit && value ? value.split(',').filter((v) => !!v.trim()) : null;
    const compareSplitValue =
      canSplit && compareValue
        ? compareValue.split(',').filter((v) => !!v.trim())
        : null;

    const calculatePercentageDifference = (num1, num2) => {
      if (!num2) {
        return '-';
      }
      const change = ((num1 - num2) / Math.abs(num2)) * 100;
      return `${change.toFixed(2)}%`;
    };

    return (
      <Table.Td
        style={{
          padding: 0,
          minWidth: 175,
          alignContent: isSummary ? 'start' : 'center',
          ...(index === 0 && {
            position: 'sticky',
            left: 0,
            backgroundColor: isSummary ? '#F1F1F1' : '#FFF'
          })
        }}
      >
        <AppFlexbox
          style={{
            padding: '8px 10px',
            justifyContent: isNumber ? 'end' : 'start',
            ...(index === 0 && {borderRight: showFirstColumnBorder ? 'solid 1px #dee2e6' : 'none'})
          }}
        >
          <AppStack
            style={{
              gap: 0,
              whiteSpace: 'nowrap',
              textAlign: isNumber ? 'end' : 'start'
            }}
          >
            {splitValue ? (
              <AppFlexbox style={{ gap: 5 }}>
                <AppText
                  style={{
                    fontSize: 14,
                    fontWeight: isSummary ? 500 : 'normal'
                  }}
                >
                  {formatDataTypeString(splitValue[0], header.dataType)}
                </AppText>
                {splitValue.length > 1 && (
                  <Anchor
                    onClick={() => onViewMore(splitValue)}
                    style={{
                      fontSize: 14,
                      fontWeight: isSummary ? 500 : 'normal',
                      color: 'doderblue'
                    }}
                  >
                    {splitValue.length - 1} more
                  </Anchor>
                )}
              </AppFlexbox>
            ) : (
              <AppText
                style={{
                  fontSize: 14,
                  fontWeight: isSummary ? 500 : 'normal'
                }}
              >
                {formatDataTypeString(value, header.dataType)}
              </AppText>
            )}
            {showCompareValue &&
              (compareSplitValue ? (
                <AppFlexbox style={{ gap: 5 }}>
                  <AppText
                    style={{
                      fontSize: 14,
                      fontWeight: isSummary ? 500 : 'normal'
                    }}
                  >
                    {formatDataTypeString(
                      compareSplitValue[0],
                      header.dataType
                    )}
                  </AppText>
                  {compareSplitValue.length > 1 && (
                    <Anchor
                      onClick={() => onViewMore(compareSplitValue)}
                      style={{
                        fontSize: 14,
                        fontWeight: isSummary ? 500 : 'normal',
                        color: 'doderblue'
                      }}
                    >
                      {compareSplitValue.length - 1} more
                    </Anchor>
                  )}
                </AppFlexbox>
              ) : (
                <AppText
                  style={{
                    fontSize: 14,
                    fontWeight: 'normal',
                    color: '#666'
                  }}
                >
                  {formatDataTypeString(compareValue, header.dataType)}
                </AppText>
              ))}
            {showCompareValue && isSummary && (
              <AppText
                style={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: '#666'
                }}
              >
                {index === 0
                  ? '% Difference'
                  : header.dataType === 'currency' ||
                    header.dataType === 'number'
                  ? calculatePercentageDifference(value, compareValue)
                  : '-'}
              </AppText>
            )}
          </AppStack>
        </AppFlexbox>
      </Table.Td>
    );
  },
  isRenderSameAsBefore
);

ReportTableColumn.propTypes = {
  compareValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  header: PropTypes.object,
  index: PropTypes.number,
  isSummary: PropTypes.bool,
  onViewMore: PropTypes.func,
  showCompareValue: PropTypes.bool,
  showFirstColumnBorder: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ReportTableColumn;
