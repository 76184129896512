import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Divider,
  Image,
  Menu,
  NumberFormatter,
  Progress,
  Skeleton,
  Tooltip
} from '@mantine/core';
import {
  Archive,
  ArrowLeft,
  ChevronDown,
  Edit,
  Eye,
  Trash
} from 'tabler-icons-react';
import dayjs from 'dayjs';
import { triggerNotification } from '../../../helpers/notification';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';
import ActionableIcon from '../../common/ActionableIcon';
import AppText from '../../common/AppText';
import {
  ECOM_PRODUCT_STATUS_ENUM,
  VIEW_ACTIONS_ENUM
} from '../../../config/constants';
import EditProductCard from '../products/EditProductCard';
import StoreProductGoalModal from './StoreProductGoalModal';
import AppMenu from '../../common/AppMenu';
import ConfirmModal from '../../common/ConfirmModal';
import OrderStatsCard from '../orders/OrderStatsCard';
import OrderSummaryCard from '../orders/OrderSummaryCard';
import StoreProductPricingModal from './StoreProductPricingModal';
import { formatUtcDate, isUrlString } from '../../../helpers/format';
import StoreProductPricingInfoCard from './StoreProductPricingInfoCard';
import ChangeProductStatusModal from '../products/ChangeProductStatusModal';
import ProductImageShowcase from '../products/ProductImageShowcase';
import ProductVariantInputPreview from '../products/ProductVariantInputPreview';
import StoreProductInputAnswerModal from './StoreProductInputAnswerModal';

const PRODUCT_MODAL_VIEW_ENUM = {
  FUNDRAISER_GOAL: 'FUNDRAISER_GOAL',
  PRICING: 'PRICING',
  INPUT_ANSWERS: 'INPUT_ANSWERS'
};

const StoreProductDetailsView = () => {
  const {
    isLargeMobileOrSmaller,
    isTabletOrSmaller,
    isLaptopOrSmaller
  } = useMediaQueryIndex();
  const hasFetched = useRef(null);
  const navigate = useNavigate();
  const { ecomStoreProductUuid } = useParams();
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchStoreProduct,
    fetchStoreSetupSummary,
    deleteStoreProducts
  } = useContext(StoreContext);
  const {
    state: modalState,
    onOpenModal,
    onCloseModal,
    onChangeModalLoading
  } = useModalState();
  const [productFilterState, setProductFilterState] = useState({
    variantOptionsSelected: {},
    selectedImageIndex: 0,
    variantPageIndex: 1
  });

  const selectedStore =
    authState.ecomStores.find(
      (v) => v.pkEcomStore.toString() === authState.pkEcomStore?.toString()
    ) ?? {};
  const ecomStoreProduct =
    state.ecomStoreProduct.value?.uuid === ecomStoreProductUuid
      ? state.ecomStoreProduct.value
      : null;
  const { orderSummary, lastOrderPlaced } =
    state.ecomStoreProductOverview.value || {};
  const loading =
    !ecomStoreProduct ||
    !hasFetched.current ||
    state.ecomStoreProduct.loading ||
    state.ecomStoreProductOverview.loading;

  const canPreviewProduct =
    ecomStoreProduct?.fkEcomProductStatus.toString() ===
    ECOM_PRODUCT_STATUS_ENUM.ACTIVE;

  const hasMinimumQuantity = !!ecomStoreProduct?.ecomVendorProduct
    .minimumQuantity;
  const requiresExactMinimumQuantity =
    ecomStoreProduct?.ecomVendorProduct.requireExactMinimum;

  const isProductAvailable =
    ecomStoreProduct &&
    !ecomStoreProduct.ecomVendorProduct.deleted &&
    ecomStoreProduct.ecomVendorProduct.fkEcomProductStatus.toString() ===
      ECOM_PRODUCT_STATUS_ENUM.ACTIVE;

  const fundraisingGoal =
    ecomStoreProduct && orderSummary
      ? hasMinimumQuantity
        ? Math.floor(
            orderSummary.totalSold /
              ecomStoreProduct.ecomVendorProduct.minimumQuantity
          ) % ecomStoreProduct.fundraisingQuantityGoal
        : orderSummary.totalSold % ecomStoreProduct.fundraisingQuantityGoal
      : 0;

  const selectedVariant = ecomStoreProduct?.ecomStoreProductVariants.find((v) =>
    Object.entries(
      productFilterState.variantOptionsSelected
    ).every(([key, value]) =>
      v.ecomVendorProductVariant.ecomVendorProductVariantOptions.find(
        (f) => f.name === key && f.value === value
      )
    )
  );

  useEffect(() => {
    if (ecomStoreProductUuid) {
      fetchStoreProduct(ecomStoreProductUuid, null, (e) => {
        triggerNotification(e);
      });
      hasFetched.current = true;
    }
  }, [ecomStoreProductUuid]);

  useEffect(() => {
    if (ecomStoreProduct && !productFilterState.variantOption1) {
      const firstVariant = ecomStoreProduct.ecomStoreProductVariants[0];
      setProductFilterState({
        ...productFilterState,
        variantPageIndex: 1,
        variantOptionsSelected: firstVariant?.ecomVendorProductVariant.ecomVendorProductVariantOptions.reduce(
          (r, c) => ({ ...r, [c.name]: c.value }),
          {}
        )
      });
    }
  }, [ecomStoreProduct]);

  return !loading && ecomStoreProduct ? (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        p={{ base: '0px 8px', sm: 0 }}
        style={{
          justifyContent: 'space-between',
          width: '100%',
          alignItems: isLargeMobileOrSmaller ? 'stretch' : 'center',
          flexDirection: isLargeMobileOrSmaller ? 'column' : 'row'
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to="/merchant/products"
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
              <AppText
                style={{
                  fontSize: 24,
                  fontWeight: 700,
                  wordBreak: 'break-word'
                }}
              >
                {ecomStoreProduct.ecomVendorProduct.name}
              </AppText>
              {!isProductAvailable ? (
                <Badge color="red">UNAVAILABLE</Badge>
              ) : ecomStoreProduct.fkEcomProductStatus.toString() ===
                ECOM_PRODUCT_STATUS_ENUM.ACTIVE ? (
                <Badge color="green">LIVE</Badge>
              ) : ecomStoreProduct.fkEcomProductStatus.toString() ===
                ECOM_PRODUCT_STATUS_ENUM.DRAFT ? (
                <Badge color="grey">DRAFT</Badge>
              ) : ecomStoreProduct.fkEcomProductStatus.toString() ===
                ECOM_PRODUCT_STATUS_ENUM.ARCHIVED ? (
                <Badge color="blue">ARCHIVED</Badge>
              ) : (
                <Badge color="blue">PENDING ACTION</Badge>
              )}
            </AppFlexbox>

            <AppText
              style={{
                fontSize: 12,
                lineHeight: '16px',
                color: '#666'
              }}
            >
              Added{' '}
              {dayjs(formatUtcDate(ecomStoreProduct.createdAt)).format(
                'MMMM D, YYYY'
              )}{' '}
              from{' '}
              <Anchor
                component={Link}
                style={{
                  fontSize: 12,
                  lineHeight: '16px',
                  color: '#666'
                }}
                to={`/merchant/products/discover/vendors/${ecomStoreProduct.ecomVendorProduct.vendorUrlHandle}`}
              >
                {ecomStoreProduct.ecomVendorProduct.vendorName}
              </Anchor>
            </AppText>
          </AppStack>
        </AppFlexbox>

        <AppFlexbox style={{ gap: 8 }}>
          <AppMenu
            control={
              <Button
                color="dark"
                disabled={!isProductAvailable}
                radius="md"
                rightSection={<ChevronDown size={18} />}
                size="xs"
                style={{ flex: isLargeMobileOrSmaller ? 1 : 'unset' }}
                variant="light"
              >
                Actions
              </Button>
            }
          >
            <Menu.Item
              leftSection={<Archive size={18} />}
              onClick={() => {
                onOpenModal(VIEW_ACTIONS_ENUM.ARCHIVE);
              }}
            >
              Archive product
            </Menu.Item>
            <Menu.Item
              leftSection={<Eye size={18} />}
              onClick={() => {
                onOpenModal(VIEW_ACTIONS_ENUM.CHANGE_STATUS);
              }}
            >
              Change status
            </Menu.Item>
            <Menu.Item
              leftSection={<Edit size={18} />}
              onClick={() => onOpenModal(PRODUCT_MODAL_VIEW_ENUM.PRICING)}
            >
              Edit pricing
            </Menu.Item>
            <Menu.Item
              component={Link}
              leftSection={<Edit size={18} />}
              to={`/merchant/products/bulk-edit?ids=${ecomStoreProduct.uuid}`}
            >
              Bulk edit
            </Menu.Item>
            <Menu.Item
              leftSection={<Trash size={18} />}
              onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.DELETE)}
              style={{ color: 'red' }}
            >
              Remove product
            </Menu.Item>
          </AppMenu>

          <Tooltip
            disabled={isProductAvailable}
            label="Vendor product is no longer available"
            withArrow
          >
            <Button
              color="dark"
              component={isProductAvailable ? Link : null}
              disabled={!isProductAvailable}
              radius="md"
              size="xs"
              style={{ flex: isLargeMobileOrSmaller ? 1 : 'unset' }}
              target="_blank"
              to={`/merchant/products/discover/products/${ecomStoreProduct.ecomVendorProduct.vendorUrlHandle}/${ecomStoreProduct.ecomVendorProduct.urlHandle}`}
              variant="light"
            >
              View in catalog
            </Button>
          </Tooltip>

          <Tooltip
            disabled={canPreviewProduct}
            label="Product must be live to view"
            withArrow
          >
            <Button
              color="dark"
              component={canPreviewProduct ? Link : null}
              disabled={!canPreviewProduct}
              radius="md"
              size="xs"
              style={{ flex: isLargeMobileOrSmaller ? 1 : 'unset' }}
              target="_blank"
              to={`/store/${selectedStore?.urlHandle}/products/${ecomStoreProduct.urlHandle}`}
              variant="light"
            >
              View in store
            </Button>
          </Tooltip>
        </AppFlexbox>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isLaptopOrSmaller ? 'column' : 'row' }}
      >
        <AppStack
          style={{
            flex: 1,
            gap: 16
          }}
        >
          <OrderStatsCard
            data={[
              {
                label: 'Gross sales',
                value: orderSummary.grossSales / 100
              },
              {
                label: 'Average order value',
                value:
                  orderSummary.grossSales / orderSummary.ecomOrderCount / 100
              },
              {
                label: 'Profit',
                value:
                  (orderSummary.grossSales -
                    orderSummary.totalVendorProductCost -
                    orderSummary.totalServiceFees) /
                  100
              },
              {
                label: 'Items sold',
                value: orderSummary.totalSold,
                type: 'string'
              }
            ]}
          />

          <EditProductCard title="Preview">
            <AppStack style={{ gap: 8 }}>
              <ProductImageShowcase
                images={
                  ecomStoreProduct.ecomVendorProduct.ecomVendorProductMedia
                }
                maxHeight={450}
              />
              <AppStack style={{ gap: 0 }}>
                <AppText style={{ fontSize: 20, fontWeight: 500 }}>
                  {ecomStoreProduct.ecomVendorProduct.name}
                </AppText>

                <AppText style={{ fontSize: 14 }}>
                  {ecomStoreProduct.ecomVendorProduct.description}
                </AppText>
              </AppStack>

              {selectedVariant && (
                <AppText style={{ fontSize: 18 }}>
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontWeight: 700 }}
                    suffix=" CAD"
                    thousandSeparator
                    value={selectedVariant.price / 100}
                  />
                </AppText>
              )}

              <ProductVariantInputPreview
                ecomVendorProductInputs={
                  ecomStoreProduct.ecomVendorProduct.ecomVendorProductInputs
                }
                ecomVendorProductVariantOptions={ecomStoreProduct.ecomStoreProductVariants
                  .map(
                    (v) =>
                      v.ecomVendorProductVariant.ecomVendorProductVariantOptions
                  )
                  .flat()}
                onOptionChange={(variantOptionsSelected) => {
                  setProductFilterState({
                    ...productFilterState,
                    variantOptionsSelected
                  });
                }}
                selectedOptions={productFilterState.variantOptionsSelected}
              />
            </AppStack>
          </EditProductCard>

          <OrderSummaryCard
            ecomOrder={lastOrderPlaced}
            label="product"
            urlBase="/merchant/orders"
          />
        </AppStack>

        <AppStack style={{ flex: 1, maxWidth: 350 }}>
          <StoreProductPricingInfoCard
            ecomStoreProduct={ecomStoreProduct}
            onAction={() => onOpenModal(PRODUCT_MODAL_VIEW_ENUM.PRICING)}
          />

          <EditProductCard
            contentProps={{ style: { padding: 0 } }}
            title="Purchasing"
          >
            {hasMinimumQuantity ? (
              <AppStack style={{ gap: 0 }}>
                <AppFlexbox style={{ padding: 16, paddingTop: 8, flex: 1 }}>
                  <AppCard
                    radius={8}
                    shadow="none"
                    style={{ padding: 12 }}
                    withBorder
                  >
                    <AppStack style={{ gap: 4 }}>
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        {requiresExactMinimumQuantity ? 'Exact' : 'Minimum'}{' '}
                        group order size:{' '}
                        {ecomStoreProduct.ecomVendorProduct.minimumQuantity}
                      </AppText>
                      <AppText style={{ fontSize: 14, color: '#666' }}>
                        This product requires{' '}
                        {requiresExactMinimumQuantity
                          ? 'exactly'
                          : 'a minimum of'}{' '}
                        {ecomStoreProduct.ecomVendorProduct.minimumQuantity}{' '}
                        items per group order to qualify for purchase.
                      </AppText>
                    </AppStack>
                  </AppCard>
                </AppFlexbox>

                <Divider />
                <AppFlexbox style={{ padding: 16 }}>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    This product is available exclusively through group orders.
                  </AppText>
                </AppFlexbox>
              </AppStack>
            ) : (
              <AppStack style={{ gap: 0 }}>
                <AppFlexbox style={{ padding: 16 }}>
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    No Special Requirements.
                  </AppText>
                </AppFlexbox>

                <Divider />
                <AppFlexbox style={{ padding: 16 }}>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    Orders ship to customer individually.
                  </AppText>
                </AppFlexbox>
              </AppStack>
            )}
          </EditProductCard>

          <EditProductCard
            contentProps={{ style: { padding: 0 } }}
            title="Populated inputs"
            titleAction={
              ecomStoreProduct.ecomVendorProduct.ecomVendorProductInputs
                .length > 0 && (
                <Tooltip label="Edit inputs">
                  <ActionIcon
                    color="dark"
                    onClick={() =>
                      onOpenModal(PRODUCT_MODAL_VIEW_ENUM.INPUT_ANSWERS)
                    }
                    size="lg"
                    variant="subtle"
                  >
                    <Edit color="#000" size={20} />
                  </ActionIcon>
                </Tooltip>
              )
            }
          >
            <AppStack style={{ gap: 0 }}>
              <Divider />
              {ecomStoreProduct.ecomVendorProduct.ecomVendorProductInputs
                .length === 0 ? (
                <AppFlexbox
                  style={{
                    padding: '8px 16px',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <AppText
                    style={{
                      fontSize: 14,
                      color: '#666',
                      fontWeight: 500,
                      textAlign: 'center',
                      fontStyle: 'italic'
                    }}
                  >
                    This product has no inputs to populate.
                  </AppText>
                </AppFlexbox>
              ) : ecomStoreProduct.ecomStoreProductInputAnswers.length === 0 ? (
                <AppFlexbox
                  style={{
                    padding: '8px 16px',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <AppText
                    style={{
                      fontSize: 14,
                      color: '#666',
                      fontWeight: 500,
                      textAlign: 'center',
                      fontStyle: 'italic'
                    }}
                  >
                    Not set
                  </AppText>
                </AppFlexbox>
              ) : (
                <AppStack
                  style={{
                    padding: '0px 16px',
                    gap: 0
                  }}
                >
                  {ecomStoreProduct.ecomStoreProductInputAnswers.map(
                    (a, index) => (
                      <React.Fragment key={a.pkEcomStoreProductInputAnswer}>
                        {index > 0 && <Divider />}
                        <AppFlexbox
                          key={a.pkEcomStoreProductInputAnswer}
                          style={{ gap: 8, padding: '16px 0px' }}
                        >
                          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                            {a.label}:
                          </AppText>{' '}
                          {isUrlString(a.value) ? (
                            <AppFlexbox
                              style={{
                                gap: 8,
                                alignItems: 'center '
                              }}
                            >
                              <AppStack
                                style={{
                                  width: 25,
                                  height: 25,
                                  justifyContent: 'center'
                                }}
                              >
                                <Image
                                  fit="contain"
                                  h="100%"
                                  src={a.value}
                                  w="100%"
                                />
                              </AppStack>
                              <Anchor
                                component="p"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  window.open(a.value, '_blank');
                                }}
                                style={{
                                  fontSize: 14,
                                  color: 'dodgerblue'
                                }}
                              >
                                Download image
                              </Anchor>
                            </AppFlexbox>
                          ) : (
                            <AppText style={{ fontSize: 13, color: '#666' }}>
                              {a.value}
                            </AppText>
                          )}
                        </AppFlexbox>
                      </React.Fragment>
                    )
                  )}
                </AppStack>
              )}
            </AppStack>
          </EditProductCard>

          <EditProductCard
            contentProps={{ style: { padding: 0 } }}
            title="Fundraising"
            titleAction={
              <Tooltip label="Edit goal">
                <ActionIcon
                  color="dark"
                  onClick={() =>
                    onOpenModal(PRODUCT_MODAL_VIEW_ENUM.FUNDRAISER_GOAL)
                  }
                  size="lg"
                  variant="subtle"
                >
                  <Edit color="#000" size={20} />
                </ActionIcon>
              </Tooltip>
            }
          >
            <AppStack style={{ gap: 0 }}>
              <Divider />
              {!ecomStoreProduct.fundraisingQuantityGoal ? (
                <AppFlexbox
                  style={{
                    padding: '8px 16px',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <AppText
                    style={{
                      fontSize: 14,
                      color: '#666',
                      fontWeight: 500,
                      textAlign: 'center',
                      fontStyle: 'italic'
                    }}
                  >
                    Not set
                  </AppText>
                </AppFlexbox>
              ) : (
                <>
                  <AppFlexbox
                    style={{
                      padding: 16,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppStack style={{ gap: 0 }}>
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        Goal:{' '}
                        {ecomStoreProduct.fundraisingQuantityGoal || 'N/A'}
                        {hasMinimumQuantity ? ' group orders' : ' items'}
                      </AppText>
                      <AppText style={{ fontSize: 13, color: '#666' }}>
                        Reached{' '}
                        {hasMinimumQuantity
                          ? Math.floor(
                              orderSummary.totalSold /
                                (ecomStoreProduct.ecomVendorProduct
                                  .minimumQuantity *
                                  ecomStoreProduct.fundraisingQuantityGoal)
                            )
                          : Math.floor(
                              orderSummary.totalSold /
                                ecomStoreProduct.fundraisingQuantityGoal
                            )}{' '}
                        times
                      </AppText>
                    </AppStack>

                    <AppStack style={{ gap: 0 }}>
                      <AppText style={{ fontSize: 14, fontWeight: 700 }}>
                        {fundraisingGoal}/
                        {ecomStoreProduct.fundraisingQuantityGoal || 0}
                      </AppText>
                    </AppStack>
                  </AppFlexbox>
                  <Progress
                    color="green"
                    radius={0}
                    style={{ width: '100%' }}
                    value={
                      (fundraisingGoal /
                        ecomStoreProduct.fundraisingQuantityGoal) *
                      100
                    }
                  />
                </>
              )}
            </AppStack>
          </EditProductCard>
        </AppStack>
      </AppFlexbox>

      <StoreProductGoalModal
        ecomStoreProduct={ecomStoreProduct}
        isOpen={
          modalState.isOpen &&
          modalState.action === PRODUCT_MODAL_VIEW_ENUM.FUNDRAISER_GOAL
        }
        onClose={onCloseModal}
      />

      <StoreProductPricingModal
        ecomStoreProduct={ecomStoreProduct}
        isOpen={
          modalState.isOpen &&
          modalState.action === PRODUCT_MODAL_VIEW_ENUM.PRICING
        }
        onClose={onCloseModal}
      />

      <StoreProductInputAnswerModal
        ecomStoreProducts={ecomStoreProduct ? [ecomStoreProduct] : null}
        isOpen={
          modalState.isOpen &&
          modalState.action === PRODUCT_MODAL_VIEW_ENUM.INPUT_ANSWERS
        }
        onClose={onCloseModal}
      />

      <ChangeProductStatusModal
        archiveProducts={modalState.action === VIEW_ACTIONS_ENUM.ARCHIVE}
        fkEcomStore={authState.pkEcomStore}
        isOpen={
          modalState.isOpen &&
          (modalState.action === VIEW_ACTIONS_ENUM.CHANGE_STATUS ||
            modalState.action === VIEW_ACTIONS_ENUM.ARCHIVE)
        }
        onClose={onCloseModal}
        products={[ecomStoreProduct]}
        selectFirstItem
      />

      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, delete product"
        isLoading={modalState.loading}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.DELETE
        }
        message={
          <AppStack style={{ gap: 10 }}>
            <AppText style={{ fontSize: 16 }}>
              Are you sure you want to delete{' '}
              <b>{ecomStoreProduct.ecomVendorProduct.name}</b>?
            </AppText>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              This action cannot be undone.
            </AppText>
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onChangeModalLoading(true);
          deleteStoreProducts(
            authState.pkEcomStore,
            [ecomStoreProduct.pkEcomStoreProduct],
            () => {
              triggerNotification('Product deleted!', 'success');
              navigate('/merchant/products');
              if (state.ecomStoreSetupSummary.value?.ecomStore.sandboxEnabled) {
                fetchStoreSetupSummary(authState.pkEcomStore, {skipLoading: true});
              }
            },
            (error) => {
              triggerNotification(error);
              onChangeModalLoading(false);
            }
          );
        }}
        title={`Delete ${ecomStoreProduct.ecomVendorProduct?.name}?`}
      />
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        p={{ base: '0px 8px', sm: 0 }}
        style={{
          justifyContent: 'space-between',
          width: '100%',
          alignItems: isLargeMobileOrSmaller ? 'stretch' : 'center',
          flexDirection: isLargeMobileOrSmaller ? 'column' : 'row'
        }}
      >
        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <Skeleton height={30} width={200} />
        </AppFlexbox>

        {ecomStoreProductUuid && (
          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
            <Skeleton height={30} w={{ base: '100%', xsm: 80 }} />
            <Skeleton height={30} w={{ base: '100%', xsm: 80 }} />
          </AppFlexbox>
        )}
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isLaptopOrSmaller ? 'column' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 16 }}
            withBorder
          >
            <AppStack style={{ gap: 10 }}>
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="50%" />
            </AppStack>
          </AppCard>
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 16 }}
            withBorder
          >
            <AppStack style={{ gap: 10 }}>
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="50%" />
            </AppStack>
          </AppCard>
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 16 }}
            withBorder
          >
            <AppStack style={{ gap: 10 }}>
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="50%" />
            </AppStack>
          </AppCard>
        </AppStack>
        <AppStack style={{ flex: 1, maxWidth: 350 }}>
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 16 }}
            withBorder
          >
            <AppStack style={{ gap: 10 }}>
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="50%" />
            </AppStack>
          </AppCard>
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 16 }}
            withBorder
          >
            <AppStack style={{ gap: 10 }}>
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="50%" />
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

export default StoreProductDetailsView;
