import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  Divider,
  NumberFormatter,
  Skeleton,
  Table
} from '@mantine/core';
import { Link } from 'react-router-dom';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import VendorOrderProductPreview from './VendorOrderProductPreview';
import { ECOM_ORDER_PRODUCT_STATUS_ENUM } from '../../../config/constants';

const OrderProductsInfoCard = ({
  title,
  description,
  productData,
  hideStatus,
  onNotify,
  fulfillLink,
  loading,
  showVendorName,
  showLimit
}) => {
  const totalUnitCount =
    productData?.reduce((acc, p) => acc + p.quantity, 0) ?? 0;

  const isFulfilled =
    productData?.every(
      (p) =>
        p.fkEcomOrderProductStatus?.toString() !==
        ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING
    ) || false;
  const shippedProductCount =
    productData?.reduce((acc, p) => acc + p.shippedCount, 0) || 0;

  return !loading && productData ? (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack style={{ gap: 0 }}>
        <AppFlexbox
          style={{ padding: 16, paddingBottom: 8, alignItems: 'center' }}
        >
          <AppText style={{ fontSize: 16, fontWeight: 500 }}>
            {title || 'Products'}
          </AppText>

          {!hideStatus &&
            (shippedProductCount >= totalUnitCount ? (
              <Badge color="green" size="md">
                Fulfilled
              </Badge>
            ) : (
              <Badge color="orange" size="md">
                {shippedProductCount}/{totalUnitCount} Unfulfilled
              </Badge>
            ))}
        </AppFlexbox>
        {description && (
          <AppText
            style={{
              padding: 8,
              textAlign: 'center',
              fontSize: 14,
              color: '#666',
              fontStyle: 'italic'
            }}
          >
            {description}
          </AppText>
        )}
        <AppText />
        {productData.length === 0 ? (
          <>
            <Divider style={{ marginTop: 8 }} />
            <AppText
              style={{
                textAlign: 'center',
                color: '#666',
                fontWeight: 500,
                fontSize: 14,
                padding: 12
              }}
            >
              This order only contains group order products.
            </AppText>
          </>
        ) : (
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th style={{ padding: '8px 16px' }}>
                  <AppFlexbox>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Product
                    </AppText>
                  </AppFlexbox>
                </Table.Th>
                <Table.Th style={{ padding: '8px 16px' }}>
                  <AppFlexbox style={{ justifyContent: 'start' }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Quantity
                    </AppText>
                  </AppFlexbox>
                </Table.Th>
                <Table.Th style={{ padding: '8px 16px' }}>
                  <AppFlexbox style={{ justifyContent: 'end' }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Total
                    </AppText>
                  </AppFlexbox>
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {productData
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((p) => (
                  <Table.Tr key={p.key}>
                    <Table.Td
                      style={{ padding: '8px 16px', alignContent: 'start' }}
                    >
                      <VendorOrderProductPreview
                        hideStatus={hideStatus}
                        product={p}
                        showVendorName={showVendorName}
                      />
                    </Table.Td>
                    <Table.Td
                      style={{ padding: '8px 16px', alignContent: 'start' }}
                    >
                      <AppFlexbox style={{ gap: 8, justifyContent: 'start' }}>
                        <AppText style={{ fontSize: 14 }}>
                          {p.quantity}
                          {showLimit &&
                            p.minimumQuantity &&
                            ` / ${p.minimumQuantity}`}
                        </AppText>
                      </AppFlexbox>
                    </Table.Td>
                    <Table.Td
                      style={{ padding: '8px 16px', alignContent: 'start' }}
                    >
                      <AppFlexbox style={{ justifyContent: 'end' }}>
                        <NumberFormatter
                          decimalScale={2}
                          fixedDecimalScale
                          prefix="$"
                          style={{ fontSize: 14 }}
                          thousandSeparator
                          value={p.totalCost / 100}
                        />
                      </AppFlexbox>
                    </Table.Td>
                  </Table.Tr>
                ))}
            </Table.Tbody>
          </Table>
        )}

        {!isFulfilled && (onNotify || fulfillLink) && (
          <>
            <Divider />

            <AppFlexbox
              style={{
                padding: 12,
                alignItems: 'center',
                justifyContent: 'end'
              }}
            >
              {onNotify && (
                <Button
                  color="dark"
                  onClick={onNotify}
                  size="sm"
                  variant="outline"
                >
                  Notify customer
                </Button>
              )}

              {fulfillLink && (
                <Button
                  color="dark"
                  component={Link}
                  size="sm"
                  to={fulfillLink}
                  variant="filled"
                >
                  Fulfill order
                </Button>
              )}
            </AppFlexbox>
          </>
        )}
      </AppStack>
    </AppCard>
  ) : (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack style={{ gap: 0 }}>
        <AppFlexbox style={{ padding: 16, paddingBottom: 8 }}>
          <Skeleton height={18} width="20%" />
        </AppFlexbox>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th style={{ width: '100%', padding: '8px 16px' }}>
                <AppFlexbox style={{ flex: 1 }}>
                  <Skeleton height={18} width="50%" />
                </AppFlexbox>
              </Table.Th>
              <Table.Th style={{ width: '100%', padding: '8px 16px' }}>
                <AppFlexbox style={{ flex: 1 }}>
                  <Skeleton height={18} width="100%" />
                </AppFlexbox>
              </Table.Th>
              <Table.Th style={{ width: '100%', padding: '8px 16px' }}>
                <AppFlexbox style={{ flex: 1, justifyContent: 'end' }}>
                  <Skeleton height={18} width="100%" />
                </AppFlexbox>
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {Array.from(Array(2)).map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Table.Tr key={i}>
                <Table.Td
                  style={{
                    width: '100%',
                    padding: '8px 16px',
                    alignContent: 'start'
                  }}
                >
                  <VendorOrderProductPreview loading />
                </Table.Td>
                <Table.Td
                  style={{ padding: '8px 16px', alignContent: 'start' }}
                >
                  <Skeleton height={18} width={60} />
                </Table.Td>
                <Table.Td
                  style={{ padding: '8px 16px', alignContent: 'start' }}
                >
                  <AppFlexbox style={{ justifyContent: 'end' }}>
                    <Skeleton height={18} width={100} />
                  </AppFlexbox>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </AppStack>
    </AppCard>
  );
};

OrderProductsInfoCard.propTypes = {
  description: PropTypes.string,
  fulfillLink: PropTypes.string,
  hideStatus: PropTypes.bool,
  loading: PropTypes.bool,
  onNotify: PropTypes.func,
  productData: PropTypes.array,
  showLimit: PropTypes.bool,
  showVendorName: PropTypes.bool,
  title: PropTypes.string
};

export default OrderProductsInfoCard;
