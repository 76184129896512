import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useLocation } from 'react-router-dom';
import { Context as AuthContext } from '../providers/AuthContextProvider';
import { Context as HelperContext } from '../providers/HelperContextProvider';
import { getFromStorage, saveToStorage } from './storage';
import AppFlexbox from '../components/common/AppFlexbox';
import AppText from '../components/common/AppText';
import { usePrompt } from './prompt';
import ConfirmModal from '../components/common/ConfirmModal';
import { triggerNotification } from './notification';

const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
    return () => {};
  }, [ref.current]);

  return isIntersecting;
};

const useMediaQueryIndex = ({ viewPortAdjustment } = {}) => {
  const theme = useMantineTheme();
  const isMobileOrSmaller = useMediaQuery(
    `(max-width: ${
      viewPortAdjustment
        ? `calc(${theme.breakpoints.xs} + ${viewPortAdjustment})`
        : theme.breakpoints.xs
    })`,
    true,
    { getInitialValueInEffect: false }
  );
  const isLargeMobileOrSmaller = useMediaQuery(
    `(max-width: ${
      viewPortAdjustment
        ? `calc(${theme.breakpoints.xsm} + ${viewPortAdjustment})`
        : theme.breakpoints.xsm
    })`,
    true,
    { getInitialValueInEffect: false }
  );
  const isTabletOrSmaller = useMediaQuery(
    `(max-width: ${
      viewPortAdjustment
        ? `calc(${theme.breakpoints.sm} + ${viewPortAdjustment})`
        : theme.breakpoints.sm
    })`,
    true,
    { getInitialValueInEffect: false }
  );
  const isLaptopOrSmaller = useMediaQuery(
    `(max-width: ${
      viewPortAdjustment
        ? `calc(${theme.breakpoints.md} + ${viewPortAdjustment})`
        : theme.breakpoints.md
    })`,
    true,
    { getInitialValueInEffect: false }
  );
  const isDesktopOrSmaller = useMediaQuery(
    `(max-width: ${
      viewPortAdjustment
        ? `calc(${theme.breakpoints.lg} + ${viewPortAdjustment})`
        : theme.breakpoints.lg
    })`,
    true,
    { getInitialValueInEffect: false }
  );
  const isLargeDesktopOrSmaller = useMediaQuery(
    `(max-width: ${
      viewPortAdjustment
        ? `calc(${theme.breakpoints.xlg} + ${viewPortAdjustment})`
        : theme.breakpoints.xlg
    })`,
    true,
    { getInitialValueInEffect: false }
  );
  const isGiantDesktopOrSmaller = useMediaQuery(
    `(max-width: ${
      viewPortAdjustment
        ? `calc(${theme.breakpoints.xl} + ${viewPortAdjustment})`
        : theme.breakpoints.xl
    })`,
    true,
    { getInitialValueInEffect: false }
  );

  const mqIndex = isMobileOrSmaller
    ? 0
    : isTabletOrSmaller
    ? 1
    : isLaptopOrSmaller
    ? 2
    : isDesktopOrSmaller
    ? 3
    : isLargeDesktopOrSmaller
    ? 4
    : isGiantDesktopOrSmaller
    ? 5
    : 6;

  const getResponsiveStyle = (styles = []) =>
    mqIndex > styles.length - 1 ? styles[styles.length - 1] : styles[mqIndex];

  return {
    isMobileOrSmaller,
    isLargeMobileOrSmaller,
    isTabletOrSmaller,
    isLaptopOrSmaller,
    isDesktopOrSmaller,
    isLargeDesktopOrSmaller,
    isGiantDesktopOrSmaller,
    getResponsiveStyle
  };
};

const useModalState = ({ closeOnOpen } = {}) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    action: null,
    item: null,
    secondItem: null,
    loading: false
  });

  useEffect(() => {
    if (modalState.isOpen && closeOnOpen) {
      // eslint-disable-next-line no-use-before-define
      onCloseModal();
    }
  }, [modalState.isOpen]);

  const onOpenModal = (action, item, secondItem) => {
    setModalState({
      isOpen: true,
      action,
      item,
      secondItem,
      loading: false
    });
  };

  const onCloseModal = () => {
    setModalState({
      ...modalState,
      isOpen: false
    });
  };

  const onChangeModalLoading = (isLoading = null) => {
    setModalState({
      ...modalState,
      loading: isLoading ?? !modalState.loading
    });
  };

  return { state: modalState, onCloseModal, onOpenModal, onChangeModalLoading };
};

const usePaginationFilter = (initialFilter, onFilter, resultState) => {
  const hasInitialized = useRef(false);
  const [debouncedFilter, setDebouncedFilter] = useState(null);
  const [filterState, setFilterState] = useState({});
  const {
    pageIndex,
    pageSize,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data,
    loading,
    filter
  } = resultState;
  const currentFilter = { ...initialFilter, ...filter, ...filterState };
  const isDescendingSort =
    currentFilter.orderBy === 'desc' || currentFilter.sort?.endsWith('_desc');

  useEffect(() => {
    if (debouncedFilter !== null) {
      // eslint-disable-next-line no-use-before-define
      onFilterChange(debouncedFilter, true);
    }
  }, [debouncedFilter]);

  useEffect(() => {
    if (hasInitialized.current) {
      const handler = setTimeout(() => {
        setDebouncedFilter(filterState);
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }

    hasInitialized.current = true;
    return () => {};
  }, [filterState]);

  const onPageChange = (page) => {
    // eslint-disable-next-line no-use-before-define
    onFilterChange(currentFilter, true, page);
  };

  const onFilterChange = (newFilter, skipDebounce = false, page = null) => {
    if (skipDebounce) {
      onFilter({
        ...newFilter,
        page: page || 1
      });
    }
    else {
      setFilterState(newFilter);
    }
  };

  const onSortChange = (sortBy, isDescending) => {
    const sortValue = isDescending ? `${sortBy}_desc` : sortBy;
    if (sortValue !== filter.sort) {
      onFilterChange(
        {
          ...filter,
          sort: sortValue
        },
        true
      );
    }
  };

  const onRefresh = () => {
    onFilter({
      ...filter,
      page: pageIndex
    });
  };

  return {
    pageIndex,
    pageSize,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data,
    filter: currentFilter,
    loading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  };
};

const useSortByFilter = (
  sortableTableColumns,
  config = { defaultSort: null, defaultDescending: false }
) => {
  const [sortFilter, setSortFilter] = useState({
    sortBy:
      config.defaultSort != null
        ? config.defaultSort
        : sortableTableColumns[0]?.value,
    descendingOrder: config.defaultDescending || false
  });
  const selectedSort = sortableTableColumns.find(
    (c) => c.value === sortFilter.sortBy
  );

  useEffect(() => {
    setSortFilter({
      sortBy:
        config.defaultSort != null
          ? config.defaultSort
          : sortableTableColumns[0]?.value,
      descendingOrder: config.defaultDescending || false
    });
  }, []);

  const onChangeSortBy = (value, isDescending = false) => {
    setSortFilter({
      ...sortFilter,
      sortBy: value,
      descendingOrder: isDescending
    });
  };

  return {
    sortValue: sortFilter.sortBy,
    isDescendingSort: sortFilter.descendingOrder,
    onChangeSortBy,
    customFilterData: {
      type: 'custom-select',
      value: sortFilter.sortBy,
      placeholder: 'Sort by...',
      searchValue: sortFilter.sortBy ? (
        <AppFlexbox style={{ gap: 5, textWrap: 'nowrap' }}>
          <AppText color="#808080" style={{ fontSize: 14 }} weight={400}>
            Sort by
          </AppText>
          <AppText color="#000" style={{ fontSize: 14, flex: 1 }} weight={400}>
            {selectedSort?.label}
          </AppText>
        </AppFlexbox>
      ) : (
        ''
      ),
      onlyShowSearchValue: true,
      data: sortableTableColumns.map((c) => ({
        label: c.label,
        value: c.value
      })),
      onChange: (value) => {
        if (value && value !== sortFilter.sortBy) {
          onChangeSortBy(value);
        }
      }
    }
  };
};

const useThunk = (thunk) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const runThunk = useCallback(
    (arg) => {
      setIsLoading(true);
      dispatch(thunk(arg))
        .unwrap()
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    },
    [dispatch, thunk]
  );

  return [runThunk, isLoading, error];
};

const useDashboardCookies = () => {
  const { state: authState } = useContext(AuthContext);
  const {
    state: helperState,
    toggleSetupGuideSidebar,
    toggleVendorCatalogDemoMode
  } = useContext(HelperContext);
  const showSetupGuide = getFromStorage('showSetupGuide') === 'true';
  const activeSidebarStep = getFromStorage('activeSidebarStep');

  useEffect(() => {
    if (authState.pkEcomStore) {
      const isVendorCatalogDemoMode =
        getFromStorage(`vendor-catalog-demo-mode-${authState.pkEcomStore}`) ===
        'true';
      toggleVendorCatalogDemoMode(
        authState.pkEcomStore,
        isVendorCatalogDemoMode
      );
    }
  }, [authState.pkEcomStore]);

  useEffect(() => {
    if (helperState.setupGuide.showSidebar !== showSetupGuide) {
      saveToStorage('showSetupGuide', showSetupGuide ? 'true' : 'flase');
      toggleSetupGuideSidebar(showSetupGuide, activeSidebarStep ?? null);
    }
  }, [showSetupGuide]);
};

const useGlobalFormState = (
  initialValues,
  config = {
    confirmDiscard: false,
    containerWidth: 950,
    initializeOnMount: false,
    saveButtonId: 'save-button'
  }
) => {
  const hasInitialized = useRef(false);
  const [initalState, setInitialState] = useState({
    data: initialValues,
    showModal: false,
    discardCallback: null
  });
  const { state: helperState, setFormData } = useContext(HelperContext);
  const { form: formState } = helperState;

  usePrompt(
    'Are you sure you want to leave this page? You have unsaved changes.',
    !formState.loading && formState.hasUnsavedChanges
  );

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    resetFormState(initialValues, true && !config.initializeOnMount);
  }, []);

  useEffect(() => {
    if (formState.triggerDiscard) {
      // eslint-disable-next-line no-use-before-define
      discardFormChanges();
    }
  }, [formState.triggerDiscard]);

  useEffect(() => {
    if (formState.triggerSubmit) {
      document.getElementById(config.saveButtonId ?? 'save-button')?.click();
    }
  }, [formState.triggerSubmit]);

  const setFormState = (data) => {
    setFormData({
      data: {
        ...formState.data,
        ...data
      },
      containerWidth: config.containerWidth,
      hasUnsavedChanges: true
    });
  };

  const resetFormState = (
    data = null,
    isInitialValues = false,
    hasUnsavedChanges = false
  ) => {
    setInitialState({
      ...initalState,
      data: data ?? initalState.data,
      showModal: false
    });
    setFormData({
      data: { ...(data ?? initalState.data) },
      containerWidth: config.containerWidth,
      hasUnsavedChanges
    });
    hasInitialized.current =
      !isInitialValues && (!!data || hasInitialized.current);
  };

  const discardFormChanges = (onCallback = null) => {
    if (config.confirmDiscard) {
      setInitialState({
        ...initalState,
        showModal: true,
        discardCallback: onCallback
      });
    }
    else {
      // eslint-disable-next-line no-use-before-define
      resetFormState();
      if (onCallback) {
        onCallback();
      }
    }
  };

  const submitFormState = (submitFunction) => {
    setFormData({ ...formState, loading: true });
    submitFunction(formState.data, (error) => {
      triggerNotification(error);
      setFormData({ ...formState, loading: false });
    });
  };

  return {
    hasInitialized: hasInitialized.current && formState.initialized,
    hasUnsavedChanges: formState.hasUnsavedChanges,
    formState: hasInitialized.current ? formState.data : initialValues,
    errors: formState.errors,
    isSubmitting: formState.loading,
    setFormState,
    resetFormState,
    discardFormChanges,
    submitFormState,
    ConfirmDiscardModal: config.confirmDiscard ? (
      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, discard changes"
        isOpen={initalState.showModal}
        message="Are you sure you want to discard all unsaved changes?"
        onCancel={() => {
          setInitialState({ ...initalState, showModal: false });
          setFormData({
            data: formState.data,
            containerWidth: config.containerWidth
          });
          if (initalState.discardCallback) {
            initalState.discardCallback(false);
          }
        }}
        onConfirm={() => {
          setInitialState({ ...initalState, showModal: false });
          resetFormState();
          if (initalState.discardCallback) {
            initalState.discardCallback(true);
          }
        }}
        title="Discard changes?"
      />
    ) : null
  };
};

const useOnScrollUp = ({ containerRef }) => {
  const [isVisible, setIsVisible] = useState(true);
  const lastScrollTop = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef?.current || document.documentElement;
      const currentScrollTop = container.scrollTop || window.pageYOffset;

      setIsVisible(currentScrollTop <= lastScrollTop.current);
      lastScrollTop.current = Math.max(currentScrollTop, 0);
    };

    const container = containerRef?.current || window;
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef]);

  return { hasScrolledUp: isVisible };
};

const useBackPath = ({ baseUrl }) => {
  const { search } = useLocation();
  const searchUrl = new URLSearchParams(search);
  const fromLocation = searchUrl.get('from');
  const fromLocationId = searchUrl.get('fromId');

  const getBackPath = () => {
    switch (fromLocation?.toLowerCase()) {
      case 'products': {
        if (fromLocationId) {
          return `${baseUrl}/products/${fromLocationId}`;
        }
        return `${baseUrl}/products`;
      }
      case 'customers': {
        if (fromLocationId) {
          return `${baseUrl}/customers/${fromLocationId}`;
        }
        return `${baseUrl}/customers`;
      }
      default: {
        return `${baseUrl}/orders`;
      }
    }
  };

  return { backPath: getBackPath() };
};

export {
  useOnScreen,
  useMediaQueryIndex,
  useModalState,
  usePaginationFilter,
  useSortByFilter,
  useThunk,
  useGlobalFormState,
  useDashboardCookies,
  useOnScrollUp,
  useBackPath
};
