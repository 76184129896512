/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Loader } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import AppStack from '../../../../common/AppStack';
import AppCard from '../../../../common/AppCard';
import AppText from '../../../../common/AppText';
import AppFlexbox from '../../../../common/AppFlexbox';
import { Context as AuthContext } from '../../../../../providers/AuthContextProvider';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';
import { useCartController } from '../../../../../helpers/webstoreCheckoutHelper';

const WsCheckoutComplete = ({
  baseStoreUrl,
  config,
  themeConfig,
  colorSchemes,
  mediaQueries,
  pathname,
  editModeEnabled,
  builderHasAuthenticated,
  onAuthenticateEditMode,
  ecomStoreTheme,
  search,
  onNavigate,
  secureId,
  ...rest
}) => {
  const { state: authState } = useContext(AuthContext);
  const { state, fetchEcomStoreCartCheckoutSession } = useContext(
    StoreUserContext
  );
  const { clearCartBySecureId } = useCartController();
  const hasFetched = useRef(false);
  const loading =
    !hasFetched.current ||
    state.ecomStoreCartCheckoutSession.loading ||
    !state.ecomStoreCartCheckoutSession.value?.completed;

  const checkoutSession = state.ecomStoreCartCheckoutSession.value;

  useEffect(() => {
    if (secureId) {
      fetchEcomStoreCartCheckoutSession(
        ecomStoreTheme.ecomStore.pkEcomStore,
        { secureId },
        null,
        () => {
          onNavigate(`${baseStoreUrl}/checkout/cart`);
        }
      );
      hasFetched.current = true;
    }
    else {
      onNavigate(`${baseStoreUrl}/checkout/cart`);
    }
  }, [secureId]);

  useEffect(() => {
    if (checkoutSession) {
      if (!checkoutSession.completed) {
        onNavigate(`${baseStoreUrl}/checkout/cart`);
      }
      else if (!authState.isAuthenticated) {
        clearCartBySecureId(
          ecomStoreTheme.ecomStore.pkEcomStore,
          checkoutSession.secureId
        );
      }
    }
  }, [checkoutSession]);

  return (
    <AppStack
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '50vh',
        padding: '40px 0px'
      }}
    >
      {loading ? (
        <Loader color="dark" size={60} type="dots" />
      ) : (
        <AppCard
          radius="md"
          shadow="lg"
          style={{ padding: 20, maxWidth: 400 }}
          withBorder
        >
          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox style={{ flex: 1, justifyContent: 'center' }}>
              <CircleCheck color="#067D62" size={150} />
            </AppFlexbox>
            <AppStack style={{ gap: 20 }}>
              <AppStack style={{ gap: 8, textAlign: 'center' }}>
                <AppText style={{ fontSize: 24, fontWeight: 700 }}>
                  Thank you!
                </AppText>
                <AppText style={{ fontSize: 16, color: '#666' }}>
                  A confirmation email has been sent to your email address. You
                  can track your order progress from your account order history.
                </AppText>
              </AppStack>

              <Button
                color="dark"
                component={Link}
                onClick={onNavigate}
                to={
                  checkoutSession.ecomCustomer.user
                    ? `${baseStoreUrl}/account/orders/${checkoutSession.pkEcomOrder}`
                    : `${baseStoreUrl}/account/orders/${checkoutSession.pkEcomOrder}?email=${checkoutSession.ecomCustomer.email}`
                }
                variant="outline"
              >
                View order history
              </Button>

              <Divider label="OR" labelPosition="center" />

              <Button
                color="dark"
                component={Link}
                onClick={onNavigate}
                to={`${baseStoreUrl}/products`}
              >
                Continue shopping
              </Button>
            </AppStack>
          </AppStack>
        </AppCard>
      )}
    </AppStack>
  );
};

WsCheckoutComplete.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  builderHasAuthenticated: PropTypes.bool,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onAuthenticateEditMode: PropTypes.func,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string,
  search: PropTypes.string,
  secureId: PropTypes.string,
  sort: PropTypes.number,
  themeConfig: PropTypes.object
};

export default WsCheckoutComplete;
