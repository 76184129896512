import React, { useContext, useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { Context as AuthContext } from '../providers/AuthContextProvider';
import AppFlexbox from '../components/common/AppFlexbox';
import AppStack from '../components/common/AppStack';
import Sidebar from '../components/content/navigation/Sidebar';
import { ADMIN_NAVIGATION_LINKS } from '../components/content/navigation/navigationLinks';
import AdminStoresView from '../components/content/admin/AdminStoresView';
import AdminVendorsView from '../components/content/admin/AdminVendorsView';
import AdminAnalyticsView from '../components/content/admin/AdminAnalyticsView';
import AdminReportsView from '../components/content/admin/AdminReportsView';

const hiddenSidebarRoutes = [];

const AdminViews = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state: authState } = useContext(AuthContext);
  const showSidebar = !hiddenSidebarRoutes.some((r) => pathname.match(r));

  useEffect(() => {
    if (
      authState.isAuthenticated &&
      !authState.loading &&
      !authState.userData.isAdmin
    ) {
      navigate(`/`);
    }
  }, [authState.isAuthenticated, authState.loading, pathname]);

  return (
    <AppFlexbox style={{ width: '100%', gap: 5, flex: 1 }}>
      {showSidebar && (
        <Sidebar
          navigation={ADMIN_NAVIGATION_LINKS}
          subTitle="Admin"
          title="SportsHeadz"
        />
      )}

      <AppStack style={{ flex: 1, width: '100%', overflow: 'hidden' }}>
        <Routes>
          <Route element={<AdminAnalyticsView />} path="/" />
          <Route element={<AdminReportsView />} path="/reports/*" />
          <Route element={<AdminStoresView />} path="/stores/*" />
          <Route element={<AdminVendorsView />} path="/vendors/*" />
          <Route element={<Navigate replace to="/admin" />} path="*" />
        </Routes>
      </AppStack>
    </AppFlexbox>
  );
};

export default AdminViews;
