import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Logout, Photo, Shirt } from 'tabler-icons-react';
import { Image } from '@mantine/core';
import roundHalfEven from 'round-half-even';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import BulkEditView from '../bulkEdit/BulkEditView';
import {
  BULK_EDIT_FIELD_TYPE_ENUM,
  ECOM_PRODUCT_PRICING_TYPE_ENUM,
  ECOM_PRODUCT_STATUS_ENUM
} from '../../../config/constants';
import AppFlexbox from '../../common/AppFlexbox';
import { roundUpToCentValue } from '../../../helpers/format';

const PRODUCT_DATA_FIELDS = {
  title: 'title',
  vendorName: 'vendorName',
  urlHandle: 'urlHandle',
  fkEcomProductStatus: 'fkEcomProductStatus'
};

const VARIANT__DATA_FIELDS = {
  vendorPrice: 'vendorPrice',
  fkEcomPricingType: 'fkEcomPricingType',
  pricingAmount: 'pricingAmount',
  roundingAdjustment: 'roundingAdjustment',
  storePrice: 'storePrice',
  serviceFees: 'serviceFees',
  webstorePrice: 'webstorePrice',
  storeProfit: 'storeProfit'
};

const PRODUCT_FIELDS = {
  title: 0,
  vendorName: 1,
  urlHandle: 2,
  fkEcomProductStatus: 3
};

const VARIANT_FIELDS = {
  vendorPrice: 4,
  fkEcomPricingType: 5,
  pricingAmount: 6,
  roundingAdjustment: 7,
  storePrice: 8,
  serviceFees: 9,
  webstorePrice: 10,
  storeProfit: 11
};

const EDIT_VARIANT_FIELDS = [
  {
    value: VARIANT__DATA_FIELDS.vendorPrice,
    label: 'Vendor price',
    dataField: VARIANT__DATA_FIELDS.vendorPrice,
    subLabel: 'CAD',
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_PRICE,
    disabled: true,
    displayed: true,
    category: 'Variant'
  },
  {
    value: VARIANT__DATA_FIELDS.fkEcomPricingType,
    label: 'Pricing type',
    dataField: VARIANT__DATA_FIELDS.fkEcomPricingType,
    type: BULK_EDIT_FIELD_TYPE_ENUM.SELECT,
    displayed: true,
    required: true,
    options: [
      {
        value: ECOM_PRODUCT_PRICING_TYPE_ENUM.PERCENTAGE_ADJUSTMENT,
        label: '% adjustment'
      },
      {
        value: ECOM_PRODUCT_PRICING_TYPE_ENUM.FLAT_ADJUSTMENT,
        label: '$ adjustment'
      }
    ],
    category: 'Variant'
  },
  {
    value: VARIANT__DATA_FIELDS.pricingAmount,
    label: 'Pricing amount',
    dataField: VARIANT__DATA_FIELDS.pricingAmount,
    type: BULK_EDIT_FIELD_TYPE_ENUM.PRICE,
    displayed: true,
    required: true,
    category: 'Variant',
    getPrefix: (rowColumns) =>
      rowColumns[VARIANT_FIELDS.fkEcomPricingType].value ===
      ECOM_PRODUCT_PRICING_TYPE_ENUM.FLAT_ADJUSTMENT
        ? '$'
        : '',
    getSuffix: (rowColumns) =>
      rowColumns[VARIANT_FIELDS.fkEcomPricingType].value ===
      ECOM_PRODUCT_PRICING_TYPE_ENUM.PERCENTAGE_ADJUSTMENT
        ? '%'
        : '',
    getErrorMessage: (rowColumns, subrowColumns = null) =>
      subrowColumns != null &&
      rowColumns[PRODUCT_FIELDS.fkEcomProductStatus].value ===
        ECOM_PRODUCT_STATUS_ENUM.ACTIVE &&
      subrowColumns[VARIANT_FIELDS.pricingAmount].value === 0
        ? 'Price required for active products. Please enter a value or change the status to draft.'
        : null
  },
  {
    value: VARIANT__DATA_FIELDS.roundingAdjustment,
    label: 'Rounding adjustment',
    dataField: VARIANT__DATA_FIELDS.roundingAdjustment,
    subLabel: '(in cents)',
    type: BULK_EDIT_FIELD_TYPE_ENUM.NUMBER,
    displayed: true,
    required: true,
    category: 'Variant',
    decimalScale: 0,
    min: 0,
    max: 99,
    clampBehavior: 'strict'
  },
  {
    value: VARIANT__DATA_FIELDS.storePrice,
    label: 'Base price',
    dataField: VARIANT__DATA_FIELDS.storePrice,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_PRICE,
    displayed: true,
    required: true,
    category: 'Variant'
  },
  {
    value: VARIANT__DATA_FIELDS.serviceFees,
    label: 'Fees',
    dataField: VARIANT__DATA_FIELDS.serviceFees,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_PRICE,
    displayed: false,
    required: true,
    category: 'Variant'
  },
  {
    value: VARIANT__DATA_FIELDS.webstorePrice,
    label: 'Store price',
    dataField: VARIANT__DATA_FIELDS.webstorePrice,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_PRICE,
    displayed: true,
    required: true,
    category: 'Variant'
  },
  {
    value: VARIANT__DATA_FIELDS.storeProfit,
    label: 'Profit',
    dataField: VARIANT__DATA_FIELDS.storeProfit,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_PRICE,
    displayed: true,
    required: true,
    category: 'Variant'
  }
];

const EDIT_PRODUCT_FIELDS = [
  {
    value: PRODUCT_DATA_FIELDS.title,
    label: 'Product title',
    dataField: PRODUCT_DATA_FIELDS.title,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_INPUT,
    disabled: true,
    displayed: true,
    staticDisplay: true,
    category: 'Product',
    firstPosition: true,
    leftSectionWidth: 70,
    noBulkEdit: true,
    getLeftSection: (altValue) => (
      <AppFlexbox
        style={{
          width: 25,
          height: 25,
          borderRadius: 5,
          boxSizing: 'border-box',
          border: 'solid 1px #dee2e6 ',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {altValue ? (
          <Image src={altValue} style={{ width: '100%', height: '100%' }} />
        ) : (
          <Photo color="#dee2e6" size={14} />
        )}
      </AppFlexbox>
    )
  },
  {
    value: PRODUCT_DATA_FIELDS.vendorName,
    label: 'Vendor',
    dataField: PRODUCT_DATA_FIELDS.vendorName,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT,
    disabled: true,
    displayed: true,
    staticDisplay: true,
    category: 'Product'
  },
  {
    value: PRODUCT_DATA_FIELDS.urlHandle,
    label: 'URL handle',
    noBulkEdit: true,
    dataField: PRODUCT_DATA_FIELDS.urlHandle,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_INPUT,
    productOnly: true,
    category: 'Product'
  },
  {
    value: PRODUCT_DATA_FIELDS.fkEcomProductStatus,
    label: 'Status',
    dataField: PRODUCT_DATA_FIELDS.fkEcomProductStatus,
    type: BULK_EDIT_FIELD_TYPE_ENUM.SELECT,
    options: [
      { value: ECOM_PRODUCT_STATUS_ENUM.ACTIVE, label: 'Active' },
      { value: ECOM_PRODUCT_STATUS_ENUM.DRAFT, label: 'Draft' },
      { value: ECOM_PRODUCT_STATUS_ENUM.ARCHIVED, label: 'Archived' }
    ],
    productOnly: true,
    required: true,
    displayed: true,
    category: 'Product'
  },
  ...EDIT_VARIANT_FIELDS
];

const mapProductToForm = (p) => ({
  key: p.pkEcomStoreProduct.toString(),
  pkEcomStoreProduct: p.pkEcomStoreProduct.toString(),
  uuid: p.uuid,
  columns: [
    {
      key: PRODUCT_FIELDS.title,
      value: p.ecomVendorProduct.name,
      disabled: true
    },
    {
      key: PRODUCT_FIELDS.vendorName,
      value: p.ecomVendorProduct.vendorName,
      disabled: true
    },
    {
      key: PRODUCT_FIELDS.urlHandle,
      value: p.urlHandle
    },
    {
      key: PRODUCT_FIELDS.fkEcomProductStatus,
      value:
        p.fkEcomProductStatus.toString() !==
        ECOM_PRODUCT_STATUS_ENUM.PENDING_ACTION
          ? p.fkEcomProductStatus.toString()
          : null
    },
    {
      key: VARIANT_FIELDS.vendorPrice,
      value: '',
      disabled: true
    },
    {
      key: VARIANT_FIELDS.fkEcomPricingType,
      value: '',
      adjustSubrows: true,
      disabled: true
    },
    {
      key: VARIANT_FIELDS.pricingAmount,
      value: '',
      adjustSubrows: true,
      disabled: true
    },
    {
      key: VARIANT_FIELDS.roundingAdjustment,
      value: '',
      adjustSubrows: true,
      disabled: true
    },
    { key: VARIANT_FIELDS.storePrice, value: '', disabled: true },
    { key: VARIANT_FIELDS.serviceFees, value: '', disabled: true },
    { key: VARIANT_FIELDS.webstorePrice, value: '', disabled: true },
    { key: VARIANT_FIELDS.storeProfit, value: '', disabled: true }
  ],
  subrows: p.ecomStoreProductVariants
    .sort((a, b) => {
      const aOptionsSorted = a.ecomVendorProductVariant.ecomVendorProductVariantOptions.sort(
        (a1, b1) => a1.nameSort - b1.nameSort
      );
      const bOptionsSorted = b.ecomVendorProductVariant.ecomVendorProductVariantOptions.sort(
        (a1, b1) => a1.nameSort - b1.nameSort
      );

      return (
        (aOptionsSorted[0]?.valueSort ?? 0) -
          (bOptionsSorted[0]?.valueSort ?? 0) ||
        (aOptionsSorted[1]?.valueSort ?? 0) -
          (bOptionsSorted[1]?.valueSort ?? 0) ||
        (aOptionsSorted[2]?.valueSort ?? 0) -
          (bOptionsSorted[2]?.valueSort ?? 0)
      );
    })
    .map((v) => ({
      key: v.uuid,
      pkEcomStoreProductVariant: v?.pkEcomStoreProductVariant.toString(),
      pkEcomVendorProductVariant: v.ecomVendorProductVariant.pkEcomVendorProductVariant.toString(),
      uuid: v.ecomVendorProductVariant.uuid,
      columns: [
        {
          key: PRODUCT_FIELDS.title,
          value:
            v.ecomVendorProductVariant.ecomVendorProductVariantOptions
              .length === 0
              ? 'Default'
              : v.ecomVendorProductVariant.ecomVendorProductVariantOptions
                  .map((o) => o.value)
                  .join(' / '),
          disabled: true
        },
        {
          key: PRODUCT_FIELDS.vendorName,
          value: '',
          disabled: true
        },
        {
          key: PRODUCT_FIELDS.urlHandle,
          value: '',
          disabled: true
        },
        {
          key: PRODUCT_FIELDS.fkEcomProductStatus,
          value: '',
          disabled: true
        },
        {
          key: VARIANT_FIELDS.vendorPrice,
          value: v.ecomVendorProductVariant.price
            ? v.ecomVendorProductVariant.price / 100
            : '0',
          disabled: true
        },
        {
          key: VARIANT_FIELDS.fkEcomPricingType,
          value: v?.fkEcomPricingType.toString() ?? ''
        },
        {
          key: VARIANT_FIELDS.pricingAmount,
          value: v.pricingAmount / 100,
          getPrefix: (columns) =>
            columns[VARIANT_FIELDS.fkEcomPricingType].value ===
            ECOM_PRODUCT_PRICING_TYPE_ENUM.FLAT_ADJUSTMENT
              ? '$'
              : '',
          getSuffix: (columns) =>
            columns[VARIANT_FIELDS.fkEcomPricingType].value ===
            ECOM_PRODUCT_PRICING_TYPE_ENUM.PERCENTAGE_ADJUSTMENT
              ? '%'
              : ''
        },
        {
          key: VARIANT_FIELDS.roundingAdjustment,
          value: v.roundingAdjustment
        },
        {
          key: VARIANT_FIELDS.storePrice,
          disabled: true,
          getValue: (columns) => {
            if (
              columns[VARIANT_FIELDS.fkEcomPricingType].value ===
              ECOM_PRODUCT_PRICING_TYPE_ENUM.PERCENTAGE_ADJUSTMENT
            ) {
              return (
                columns[VARIANT_FIELDS.vendorPrice].value *
                (1 + columns[VARIANT_FIELDS.pricingAmount].value / 100)
              );
            }

            return (
              columns[VARIANT_FIELDS.vendorPrice].value +
              columns[VARIANT_FIELDS.pricingAmount].value
            );
          }
        },
        {
          key: VARIANT_FIELDS.serviceFees,
          disabled: true,
          getValue: (columns) => {
            const storePrice = columns[VARIANT_FIELDS.storePrice].getValue(
              columns
            );
            const price = storePrice * 1.1;
            return (
              price + roundHalfEven(price / (1 - 0.029) - price) - storePrice
            );
          }
        },
        {
          key: VARIANT_FIELDS.webstorePrice,
          disabled: true,
          getValue: (columns) =>
            roundUpToCentValue(
              columns[VARIANT_FIELDS.storePrice].getValue(columns) +
                columns[VARIANT_FIELDS.serviceFees].getValue(columns),
              columns[VARIANT_FIELDS.roundingAdjustment].value / 100
            )
        },
        {
          key: VARIANT_FIELDS.storeProfit,
          disabled: true,
          getValue: (columns) =>
            columns[VARIANT_FIELDS.webstorePrice].getValue(columns) -
            columns[VARIANT_FIELDS.serviceFees].getValue(columns) -
            columns[VARIANT_FIELDS.vendorPrice].value
        }
      ]
    }))
});

const BulkEditStoreProductsView = () => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchStoreProductExportData,
    updateStoreProducts
  } = useContext(StoreContext);
  const [formState, setFormState] = useState({ loading: false });
  const productUuids = new URLSearchParams(search).get('ids')?.split(',') ?? [];
  const selectedProducts = state.ecomStoreProductsExport.value.filter(
    (p) =>
      !p.ecomVendorProduct.deleted &&
      p.ecomVendorProduct.fkEcomProductStatus.toString() ===
        ECOM_PRODUCT_STATUS_ENUM.ACTIVE &&
      p.fkEcomStore.toString() === authState.pkEcomStore.toString() &&
      (productUuids.length === 0 || productUuids.includes(p.uuid))
  );

  useEffect(() => {
    if (authState.pkEcomStore) {
      fetchStoreProductExportData(
        { fkEcomStore: authState.pkEcomStore, uuids: productUuids },
        null,
        (error) => {
          triggerNotification(error);
        }
      );
      hasFetched.current = true;
    }
  }, [authState.pkEcomStore]);

  const onSaveChanges = (data, onSuccess, onErrorCallback) => {
    setFormState({
      ...formState,
      loading: true
    });
    updateStoreProducts(
      authState.pkEcomStore,
      {
        ecomStoreProducts: data
          .filter(
            (d) =>
              d.hasUnsavedChanges || d.subData.some((s) => s.hasUnsavedChanges)
          )
          .map((d) => ({
            pkEcomStoreProduct: d.pkEcomStoreProduct,
            uuid: d.uuid,
            pricingAmount: d.pricingAmount
              ? Math.round(d.pricingAmount * 100)
              : null,
            roundingAdjustment: d.roundingAdjustment
              ? d.roundingAdjustment
              : null,
            fkEcomPricingType: d.fkEcomPricingType ? d.fkEcomPricingType : null,
            fkEcomProductStatus: d.fkEcomProductStatus ?? null,
            ecomStoreProductVariants: d.subData
              .filter((s) => s.hasUnsavedChanges)
              .map((v) => ({
                uuid: v.uuid,
                fkEcomVendorProductVariant: v.pkEcomVendorProductVariant,
                pricingAmount: v.pricingAmount
                  ? Math.round(v.pricingAmount * 100)
                  : null,
                roundingAdjustment: v.roundingAdjustment
                  ? v.roundingAdjustment
                  : null,
                fkEcomPricingType: v.fkEcomPricingType
                  ? v.fkEcomPricingType
                  : null,
                hidden: v.hidden
              }))
          }))
      },
      () => {
        onSuccess();
        triggerNotification('Products updated.', 'success');
        setFormState({
          ...formState,
          loading: false
        });
      },
      (error) => {
        onErrorCallback();
        triggerNotification(error);
        setFormState({
          ...formState,
          loading: false
        });
      }
    );
  };

  return (
    <BulkEditView
      data={selectedProducts
        .sort(
          (a, b) =>
            a.ecomVendorProduct.name.localeCompare(b.ecomVendorProduct.name) ||
            a.uuid.localeCompare(b.uuid)
        )
        .map((p) => mapProductToForm(p))}
      emptyContent={{
        title: 'No products found',
        description:
          "Oops looks like you haven't selected any products to edit. Try selecting products from your list of products and try again.",
        icon: <Shirt color="#000" size={125} />,
        primaryButton: {
          label: 'Back to products',
          icon: <Logout size={18} style={{ transform: 'rotate(180deg)' }} />,
          link: '/merchant/products'
        }
      }}
      fields={EDIT_PRODUCT_FIELDS}
      loading={!hasFetched.current || state.ecomStoreProductsExport.loading}
      onCancel={() => {
        navigate('/merchant/products');
      }}
      onSave={onSaveChanges}
      saving={formState.loading}
      title={`Editing ${selectedProducts.length} products`}
    />
  );
};

BulkEditStoreProductsView.propTypes = {};

export default BulkEditStoreProductsView;
