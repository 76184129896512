import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import ResponsiveModal from '../../common/ResponsiveModal';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import AppText from '../../common/AppText';
import { triggerNotification } from '../../../helpers/notification';

const VendorOrderShippingNotifyModal = ({
  isOpen,
  onClose,
  ecomOrder,
  pkEcomOrderShipping
}) => {
  const { state: authState } = useContext(AuthContext);
  const {
    fetchVendorOrder,
    fetchVendorSetupSummary,
    notifyEcomOrderShipping
  } = useContext(VendorContext);
  const [formState, setFormState] = useState({ loading: false });
  const shippingWithoutEmailSent =
    ecomOrder?.ecomOrderShipping.filter((s) => !s.emailSent) ?? [];
  const hasAllEmailsSent = shippingWithoutEmailSent.length === 0;
  const selectedShippingHasEmailSent = ecomOrder?.ecomOrderShipping.some(
    (s) => s.pkEcomOrderShipping === pkEcomOrderShipping && s.emailSent
  );

  useEffect(() => {
    if (isOpen) {
      setFormState({ loading: false });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        cancelTitle: formState.showConfirm ? 'Back' : 'Close',
        onCancel: onClose,
        onSubmit: () => {
          setFormState({ ...formState, loading: true });
          notifyEcomOrderShipping(
            authState.pkEcomVendor,
            {
              pkEcomOrderShippings:
                selectedShippingHasEmailSent || hasAllEmailsSent
                  ? ecomOrder.ecomOrderShipping.map(
                      (s) => s.pkEcomOrderShipping
                    )
                  : shippingWithoutEmailSent.map((s) => s.pkEcomOrderShipping)
            },
            () => {
              triggerNotification('Customer notified!', 'success');
              fetchVendorOrder(authState.pkEcomVendor, {
                pkEcomOrder: ecomOrder.pkEcomOrder
              });
              fetchVendorSetupSummary(authState.pkEcomVendor, {
                skipLoading: true
              });
              onClose();
            },
            (e) => {
              setFormState({ ...formState, loading: false });
              triggerNotification(e);
            }
          );
        },
        submitTitle: `Yes, notify customer`,
        isLoading: formState.loading
      }}
      isOpen={isOpen}
      onClose={onClose}
      title="Notify customer of shipping"
    >
      {selectedShippingHasEmailSent || hasAllEmailsSent ? (
        <AppStack>
          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
            Customer has already been notified of shipping.
          </AppText>

          <AppText style={{ fontSize: 14 }}>
            Are you sure you want to notify them again? This will send an email
            with all available tracking details.
          </AppText>
        </AppStack>
      ) : (
        <AppStack>
          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
            Are you sure you want to send the shipment notification to the
            customer?
          </AppText>

          <AppText style={{ fontSize: 14 }}>
            This will send an email with any tracking details that haven't been
            shared yet.
          </AppText>
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

VendorOrderShippingNotifyModal.propTypes = {
  ecomOrder: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkEcomOrderShipping: PropTypes.number
};

export default VendorOrderShippingNotifyModal;
