/* eslint-disable import/no-cycle */
import { LayoutBottombar } from 'tabler-icons-react';
import {
  ECOM_ENTITY_TYPES,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM
} from '../../../../../config/constants';
import WsFooter from './WsFooter';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from '../../blocks/sectionBlockConfig';

const HEADING_SIZE_STYLE_ENUM = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRA_LARGE: 'extra_large',
  HUGE: 'huge'
};

const configNameValues = {
  colorScheme: 'colorScheme',
  showSupportEmail: 'showSupportEmail',
  showPrivacyPolicy: 'showPrivacyPolicy'
};

const configDefaultValues = {
  [configNameValues.colorScheme]: '2',
  [configNameValues.showSupportEmail]: 'true',
  [configNameValues.showPrivacyPolicy]: 'true'
};

const configOptions = {
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  },
  [configNameValues.showSupportEmail]: {
    label: 'Show support email',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.showPrivacyPolicy]: {
    label: 'Show privacy policy',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.FOOTER,
  title: 'Footer',
  icon: LayoutBottombar,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.FOOTER],
  Component: WsFooter,
  configOptions,
  isPermanent: true,
  cannotCreate: true,
  maxItemCount: null,
  blocks: [
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.MENU
      ],
      defaultCount: 0,
      maxCount: null
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.IMAGE
      ],
      configOptions: {
        ...ECOM_WEBSTORE_SECTION_BLOCKS[
          ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.IMAGE
        ].configOptions,
        imageWidth: {
          label: 'ImageWidth',
          type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
          min: 50,
          max: 600
        },
        imageAlignment: {
          label: 'Desktop image alignment',
          type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
          options: [
            {
              value: 'left',
              label: 'Left'
            },
            {
              value: 'center',
              label: 'Center'
            },
            {
              value: 'right',
              label: 'Right'
            }
          ]
        }
      },
      defaultConfig: {
        ...ECOM_WEBSTORE_SECTION_BLOCKS[
          ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.IMAGE
        ].defaultConfig,
        imageWidth: '100',
        imageAlignment: 'center'
      },
      defaultCount: 0,
      maxCount: null
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TEXT
      ],
      configOptions: {
        heading: {
          type: INPUT_CONTROL_TYPE_ENUM.TEXT_INPUT,
          label: 'Heading',
          isTextArea: true
        },
        headingSize: {
          label: 'Heading size',
          type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
          options: [
            {
              value: 'small',
              label: 'Small'
            },
            {
              value: HEADING_SIZE_STYLE_ENUM.MEDIUM,
              label: 'Medium'
            },
            {
              value: HEADING_SIZE_STYLE_ENUM.LARGE,
              label: 'Large'
            },
            {
              value: HEADING_SIZE_STYLE_ENUM.EXTRA_LARGE,
              label: 'Extra large'
            },
            {
              value: HEADING_SIZE_STYLE_ENUM.HUGE,
              label: 'Huge'
            }
          ]
        },
        ...ECOM_WEBSTORE_SECTION_BLOCKS[
          ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TEXT
        ].configOptions
      },
      defaultConfig: {
        ...ECOM_WEBSTORE_SECTION_BLOCKS[
          ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TEXT
        ].defaultConfig,
        heading: 'Heading',
        headingSize: HEADING_SIZE_STYLE_ENUM.MEDIUM,
        text: 'Share store details, contact information and more.',
        fontSize: '14'
      },
      defaultCount: 0,
      maxCount: null
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.BRAND_INFORMATION
      ],
      defaultCount: 0,
      maxCount: null
    }
  ],
  defaultConfig: configDefaultValues
};

export { config, configOptions, configNameValues, configDefaultValues };
