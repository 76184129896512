import onApiRequest from './ApiRequestHandler';
import createDataProvider from './createDataProvider';
import { providerContextReducer } from './providerContextReducer';

const initialPaginatedState = {
  pageIndex: 1,
  pageSize: 25,
  totalCount: 0,
  totalPages: 0,
  totalNoFilterCount: 0,
  data: [],
  filter: null,
  loading: false,
  error: null
};

const initialState = {
  ecomCustomerAddresses: { value: [], loading: false, error: null },
  ecomOrder: { value: null, loading: false, error: null },
  ecomStore: { value: null, loading: false, error: null },
  ecomStoreTheme: { value: null, loading: false, error: null },
  ecomStoreCollections: {
    value: [],
    loading: false,
    error: null,
    idsNotFound: []
  },
  ecomStoreCollectionHistory: {
    value: [],
    loading: false,
    error: null,
    idsNotFound: []
  },
  ecomStoreProduct: { value: null, loading: false, error: null },
  ecomStoreProductFilterOptions: { value: null, loading: false, error: null },
  ecomStoreProductHistory: {
    value: [],
    loading: false,
    error: null,
    urlsNotFound: [],
    uuidsNotFound: []
  },
  ecomStoreCart: { value: null, loading: false, error: null },
  ecomStoreCartCheckoutSession: { value: null, loading: false, error: null },
  ecomStoreGuestCart: { value: null, loading: false, error: null },
  ecomStoreRosterPlayers: { value: [], loading: false, error: null },
  paginatedEcomOrders: initialPaginatedState,
  paginatedEcomStoreCollectionProducts: initialPaginatedState,
  paginatedEcomStoreProducts: initialPaginatedState
};

const fetchEcomStoreTheme = (dispatch) => async (
  urlHandle,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreTheme',
      url: `/api/ecommerce/stores`,
      type: 'get',
      params: { urlHandle },
      resetStateValue: null
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchEcomStoreSummary = (dispatch) => async (
  { pkEcomStore, pkEcomStoreCart, pkTeam, pkAssociation },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStore',
      url: `/api/ecommerce/stores/summary`,
      type: 'get',
      params: { pkEcomStore, pkEcomStoreCart, pkTeam, pkAssociation },
      resetStateValue: null
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchEcomStoreThemePreview = (dispatch) => async (
  pkEcomStoreTheme,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreTheme',
      url: `/api/ecommerce/admin/stores/themes/${pkEcomStoreTheme}`,
      type: 'get',
      resetStateValue: null
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchEcomStoreProductFilterOptions = (dispatch) => async (
  pkEcomStore,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreProductFilterOptions',
      url: `/api/ecommerce/stores/products/filter-options`,
      type: 'get',
      params: { pkEcomStore },
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchEcomStoreProducts = (dispatch) => async (
  pkEcomStore,
  {
    fkEcomStoreCollection,
    vendor,
    search,
    minPrice,
    maxPrice,
    includeInventory,
    includeNoInventory,
    sort,
    page,
    pageSize,
    appendResults
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'paginatedEcomStoreProducts',
      url: `/api/ecommerce/stores/products`,
      type: 'get',
      params: {
        pkEcomStore,
        search,
        minPrice,
        maxPrice,
        includeInventory,
        includeNoInventory,
        sort,
        page,
        pageSize,
        fkEcomStoreCollection,
        vendor
      },
      successAction: appendResults ? 'paginated-append' : 'success',
      transformPayload: (data) => ({ ...data })
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchEcomStoreProduct = (dispatch) => async (
  pkEcomStore,
  { urlHandle, addToHistory = true },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: addToHistory ? 'ecomStoreProductHistory' : 'ecomStoreProduct',
      url: `/api/ecommerce/stores/products/${pkEcomStore}`,
      type: 'get',
      params: { urlHandle },
      successAction: 'replace',
      removeFilter: (f) => f.urlHandle !== urlHandle
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchEcomStoreProductBulk = (dispatch) => async (
  pkEcomStore,
  { uuids, urlHandles },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const uuidsParam = uuids?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `uuids[${index}]=${current}&`),
    ''
  );

  const urlHandlesParam = urlHandles?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `urlHandles[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreProductHistory',
      url: `/api/ecommerce/stores/products/${pkEcomStore}/bulk?${[
        uuidsParam,
        urlHandlesParam
      ]
        .filter(Boolean)
        .join('&')}`,
      type: 'get',
      successAction: 'replace',
      transformPayload: (data) => {
        const existingUuids = data.map((d) => d.uuid);
        const existingUrlHandles = data.map((d) => d.urlHandle);

        return {
          value: data,
          filter: (f) =>
            !urlHandles?.includes(f.urlHandle) && !uuids?.includes(f.uuid),
          uuidsNotFound: uuids?.filter((u) => !existingUuids.includes(u)) ?? [],
          urlsNotFound:
            urlHandles?.filter((u) => !existingUrlHandles.includes(u)) ?? []
        };
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const clearEcomStoreProductHistory = (dispatch) => async () => {
  dispatch({
    type: 'success',
    stateName: 'ecomStoreProductHistory',
    payload: initialState.ecomStoreProductHistory
  });
};

const fetchEcomStoreCollections = (dispatch) => async (
  pkEcomStore,
  { pkEcomStoreCollections },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreCollections',
      url: `/api/ecommerce/stores/collections`,
      type: 'get',
      params: { pkEcomStore },
      successAction: 'success',
      transformPayload: (data) => {
        const existingIds = data.map((d) => d.pkEcomStoreCollection.toString());

        return {
          value: data,
          idsNotFound:
            pkEcomStoreCollections?.filter(
              (u) => !existingIds.includes(u.toString())
            ) ?? []
        };
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchEcomStoreCollectionsHistory = (dispatch) => async (
  pkEcomStoreCollections = [],
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const pkEcomStoreCollectionsParm = pkEcomStoreCollections?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `pkEcomStoreCollections[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreCollectionHistory',
      url: `/api/ecommerce/stores/collections/bulk?${pkEcomStoreCollectionsParm}`,
      type: 'get',
      successAction: 'replace',
      transformPayload: (data) => {
        const existingIds = data.map((d) => d.pkEcomStoreCollection.toString());

        return {
          value: data,
          filter: (f) =>
            !pkEcomStoreCollections.some(
              (s) => f.pkEcomStoreCollection.toString() === s.toString()
            ),
          idsNotFound:
            pkEcomStoreCollections?.filter(
              (u) => !existingIds.includes(u.toString())
            ) ?? []
        };
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchAllProductsPreview = (dispatch) => async (
  pkEcomStore,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'paginatedEcomStoreCollectionProducts',
      url: `/api/ecommerce/stores/products`,
      type: 'get',
      params: { pkEcomStore },
      successAction: 'success',
      resetStateValue: initialPaginatedState,
      transformPayload: (data) => ({
        ...data,
        initialized: true
      })
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchEcomStoreGuestCart = (dispatch) => async (
  pkEcomStore,
  { ecomStoreCartProducts, removeUnavailable },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreGuestCart',
      url: `/api/ecommerce/stores/cart/${pkEcomStore}/guest`,
      type: 'post',
      data: {
        ecomStoreCartProducts,
        removeUnavailable
      },
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchEcomStoreCart = (dispatch) => async (
  pkEcomStore,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreCart',
      url: `/api/ecommerce/stores/cart/${pkEcomStore}`,
      type: 'get',
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const addProductsToCart = (dispatch) => async (
  pkEcomStore,
  { ecomStoreCartProducts, removeUnavailable },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreCart',
      url: `/api/ecommerce/stores/cart/${pkEcomStore}`,
      type: 'post',
      data: { ecomStoreCartProducts, removeUnavailable },
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const updateEcomStoreCartProducts = (dispatch) => async (
  pkEcomStore,
  { ecomStoreCartProducts, removeUnavailable },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreCart',
      url: `/api/ecommerce/stores/cart/${pkEcomStore}`,
      type: 'put',
      data: { ecomStoreCartProducts, removeUnavailable },
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const removeProductsFromCart = (dispatch) => async (
  pkEcomStore,
  pkEcomStoreCartProducts,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const pkEcomStoreCartProductsParam = pkEcomStoreCartProducts?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `pkEcomStoreCartProducts[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreCart',
      url: `/api/ecommerce/stores/cart/${pkEcomStore}?${pkEcomStoreCartProductsParam}`,
      type: 'delete',
      successAction: 'success',
      transformPayload: (data) => ({ value: data })
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const checkoutCart = (dispatch) => async (
  pkEcomStore,
  { installments, fkEcomStoreRosterPlayer },
  onSuccessCallback,
  onErrorCallback
) => {
  onApiRequest(
    {
      url: `/api/payment/ecommerce/cart/${pkEcomStore}/checkout`,
      type: 'post',
      data: { installments, fkEcomStoreRosterPlayer }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const checkoutGuestCart = (dispatch) => async (
  pkEcomStore,
  { email, installments, ecomStoreCartProducts },
  onSuccessCallback,
  onErrorCallback
) => {
  onApiRequest(
    {
      url: `/api/payment/ecommerce/cart/${pkEcomStore}/guest/checkout`,
      type: 'post',
      data: { email, installments, ecomStoreCartProducts }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchEcomStoreCartCheckoutSession = (dispatch) => async (
  pkEcomStore,
  { secureId },
  onSuccessCallback,
  onErrorCallback
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreCartCheckoutSession',
      url: `/api/payment/ecommerce/cart/${pkEcomStore}/checkout/session`,
      type: 'get',
      params: { secureId }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchEcomStoreOrdersPaginated = (dispatch) => async (
  pkEcomStore,
  { sort, page, pageSize, appendResults },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'paginatedEcomOrders',
      url: `/api/ecommerce/stores/customer/orders`,
      type: 'get',
      params: {
        pkEcomStore,
        sort,
        page,
        pageSize
      },
      successAction: appendResults ? 'paginated-append' : 'success',
      transformPayload: (data) => ({ ...data })
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchEcomStoreOrder = (dispatch) => async (
  pkEcomStoreCart,
  { guestEmail },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  let url = `/api/ecommerce/stores/customer/orders/${pkEcomStoreCart}`;
  if (guestEmail) {
    url += `?email=${guestEmail}`;
  }
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomOrder',
      url,
      type: 'get',
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const sendEcomStoreCartOrderReceipt = (dispatch) => async (
  pkEcomStore,
  { pkEcomStoreCart },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      url: `/api/ecommerce/stores/cart/${pkEcomStore}/history/${pkEcomStoreCart}/receipt`,
      type: 'post',
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchEcomStoreRosterPlayers = (dispatch) => async (
  pkEcomStore,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreRosterPlayers',
      url: `/api/ecommerce/stores/fundraising/roster-options`,
      type: 'get',
      params: { pkEcomStore },
      successAction: 'success',
      transformPayload: (data) => ({
        value: data,
        hasFetched: true
      })
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchEcomCustomerAddresses = (dispatch) => async (
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomCustomerAddresses',
      url: `/api/me/customer/addresses`,
      type: 'get',
      filter: {},
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const deleteEcomCustomerAddress = (dispatch) => async (
  pkEcomCustomerAddress,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomCustomerAddresses',
      url: `/api/me/customer/addresses/${pkEcomCustomerAddress}`,
      type: 'delete',
      filter: {},
      successAction: 'remove',
      removeFilter: (f) => f.pkEcomCustomerAddress !== pkEcomCustomerAddress
    },
    onSuccessCallback,
    onErrorCallback
  );
};

export const { Provider, Context } = createDataProvider(
  providerContextReducer,
  {
    addProductsToCart,
    checkoutCart,
    checkoutGuestCart,
    clearEcomStoreProductHistory,
    deleteEcomCustomerAddress,
    fetchAllProductsPreview,
    fetchEcomCustomerAddresses,
    fetchEcomStoreTheme,
    fetchEcomStoreCart,
    fetchEcomStoreCartCheckoutSession,
    fetchEcomStoreCollections,
    fetchEcomStoreCollectionsHistory,
    fetchEcomStoreGuestCart,
    fetchEcomStoreOrder,
    fetchEcomStoreOrdersPaginated,
    fetchEcomStoreProduct,
    fetchEcomStoreProductBulk,
    fetchEcomStoreProductFilterOptions,
    fetchEcomStoreProducts,
    fetchEcomStoreRosterPlayers,
    fetchEcomStoreSummary,
    fetchEcomStoreThemePreview,
    updateEcomStoreCartProducts,
    removeProductsFromCart,
    sendEcomStoreCartOrderReceipt
  },
  initialState
);
