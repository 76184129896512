import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, ChevronDown, Download, Send } from 'tabler-icons-react';
import {
  Anchor,
  Badge,
  Button,
  Divider,
  Menu,
  Pagination,
  Select,
  Skeleton,
  TextInput
} from '@mantine/core';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import ActionableIcon from '../../common/ActionableIcon';
import { formatUtcDate } from '../../../helpers/format';
import OrderCustomerInfoCard from './OrderCustomerInfoCard';
import AppMenu from '../../common/AppMenu';
import { translateStoreOrderData } from '../../../helpers/storeHelper';
import AppCard from '../../common/AppCard';
import VendorOrderProductPreview from './VendorOrderProductPreview';
import StoreOrderContributorListItem from './StoreOrderContributorListItem';

const SORT_OPTIONS_ENUM = {
  NAME: 'NAME',
  ORDER: 'ORDER',
  DATE: 'DATE',
  ITEMS: 'ITEMS'
};

const MAX_PAGE_LENGTH = 5;

const StoreOrderContributors = ({ loading, ecomOrder, isBulkOrders }) => {
  const navigate = useNavigate();
  const { pkEcomOrder } = useParams();
  const { isLargeMobileOrSmaller, isDesktopOrSmaller } = useMediaQueryIndex();
  const { groupedEcomOrderProducts, ecomCustomers } = translateStoreOrderData(
    ecomOrder
  );
  const [filterState, setFilterState] = useState({
    customer: '',
    sort: SORT_OPTIONS_ENUM.NAME,
    pageIndex: 1
  });

  const filteredCustomers = ecomCustomers
    .filter(
      (c) =>
        !filterState.customer ||
        c.user?.name
          .toLowerCase()
          .includes(filterState.customer.toLowerCase()) ||
        c.email.toLowerCase().includes(filterState.customer.toLowerCase())
    )
    .sort((a, b) => {
      switch (filterState.sort) {
        case SORT_OPTIONS_ENUM.NAME:
          return (a.user?.name || a.email).localeCompare(
            b.user?.name || b.email
          );
        case SORT_OPTIONS_ENUM.ORDER:
          return a.products[0].fkEcomOrder - b.products[0].fkEcomOrder;
        case SORT_OPTIONS_ENUM.DATE:
          return (
            new Date(
              b.products.sort(
                (a2, b2) =>
                  new Date(b2.purchaseDate) - new Date(a2.purchaseDate)
              )[0]?.purchaseDate
            ) -
            new Date(
              a.products.sort(
                (a2, b2) =>
                  new Date(b2.purchaseDate) - new Date(a2.purchaseDate)
              )[0]?.purchaseDate
            )
          );
        case SORT_OPTIONS_ENUM.ITEMS:
          return (
            a.products.reduce((r, c) => r + c.quantity, 0) -
            b.products.reduce((r, c) => r + c.quantity, 0)
          );
        default:
          return 0;
      }
    });

  useEffect(() => {
    if (!isBulkOrders) {
      navigate(`/merchant/orders/${ecomOrder.pkEcomOrder}`);
    }
  }, [isBulkOrders]);

  return !loading && ecomOrder ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        style={{
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to={`/merchant/group-orders/${ecomOrder.pkEcomOrder}`}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
              <AppText
                style={{
                  flex: 1,
                  fontSize: 24,
                  fontWeight: 700,
                  wordBreak: 'break-word'
                }}
              >
                Order #{ecomOrder.pkEcomOrder} contributors
              </AppText>
            </AppFlexbox>

            <AppText
              style={{
                fontSize: 12,
                lineHeight: '16px',
                color: '#666'
              }}
            >
              Placed{' '}
              {dayjs(formatUtcDate(ecomOrder.purchaseDate)).format(
                'MMMM D, YYYY'
              )}{' '}
              at {dayjs(formatUtcDate(ecomOrder.purchaseDate)).format('h:mm A')}
            </AppText>
          </AppStack>
        </AppFlexbox>

        <AppFlexbox style={{ alignItems: 'center', gap: 8, marginTop: 5 }}>
          <AppMenu
            control={
              <Button
                color="dark"
                rightSection={<ChevronDown size={18} />}
                size="compact-md"
                style={{ fontSize: 14 }}
                variant="light"
              >
                Actions
              </Button>
            }
          >
            <Menu.Item leftSection={<Download size={18} />} onClick={() => {}}>
              Export order
            </Menu.Item>
            <Menu.Item leftSection={<Send size={18} />} onClick={() => {}}>
              Email customers
            </Menu.Item>
          </AppMenu>
        </AppFlexbox>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <AppStack id="order-contributors-list" style={{ gap: 16 }}>
            <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
              <AppStack style={{ gap: 0 }}>
                <AppStack style={{ padding: 16, gap: 8 }}>
                  <AppFlexbox
                    style={{
                      gap: 8,
                      alignItems: 'center'
                    }}
                  >
                    <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                      Contributors
                    </AppText>
                    <Badge circle color="blue">
                      {filteredCustomers.length}
                    </Badge>
                  </AppFlexbox>

                  <AppFlexbox
                    style={{
                      gap: 0,
                      alignItems: 'end',
                      justifyContent: 'space-between',
                      flexDirection: isLargeMobileOrSmaller ? 'column' : 'row'
                    }}
                  >
                    <TextInput
                      onChange={(e) =>
                        setFilterState({
                          ...filterState,
                          customer: e.currentTarget.value,
                          pageIndex: 1
                        })
                      }
                      placeholder="Search by name or email..."
                      style={{
                        flex: 1,
                        maxWidth: isLargeMobileOrSmaller ? 'unset' : 200
                      }}
                      value={filterState.customer}
                    />

                    <AppFlexbox style={{ alignItems: 'center', gap: 8 }}>
                      {!isLargeMobileOrSmaller && (
                        <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                          Sort by:
                        </AppText>
                      )}

                      <Select
                        data={[
                          {
                            value: SORT_OPTIONS_ENUM.NAME,
                            label: 'Customer name'
                          },
                          {
                            value: SORT_OPTIONS_ENUM.ORDER,
                            label: 'Order ID'
                          },
                          {
                            value: SORT_OPTIONS_ENUM.DATE,
                            label: 'Purchase date'
                          },
                          {
                            value: SORT_OPTIONS_ENUM.ITEMS,
                            label: 'Item count'
                          }
                        ]}
                        onChange={(v) =>
                          setFilterState({
                            ...filterState,
                            sort: v,
                            pageIndex: 1
                          })
                        }
                        style={{
                          flex: 1,
                          maxWidth: isLargeMobileOrSmaller ? 'unset' : 200
                        }}
                        value={filterState.sort}
                      />
                    </AppFlexbox>
                  </AppFlexbox>
                </AppStack>
                <Divider />

                <AppStack style={{ flex: 1, gap: 16 }}>
                  {filteredCustomers.length === 0 ? (
                    filterState.customer ? (
                      <AppStack
                        style={{
                          padding: 16,
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 8
                        }}
                      >
                        <AppText
                          style={{
                            fontSize: 14,
                            color: '#666',
                            textAlign: 'center'
                          }}
                        >
                          No customers found by search.
                        </AppText>
                        <Button
                          color="dark"
                          onClick={() =>
                            setFilterState({
                              ...filterState,
                              customer: '',
                              pageIndex: 1
                            })
                          }
                          variant="outline"
                        >
                          Clear search
                        </Button>
                      </AppStack>
                    ) : (
                      <AppFlexbox
                        style={{ padding: 16, justifyContent: 'center' }}
                      >
                        <AppText
                          style={{
                            fontSize: 14,
                            color: '#666',
                            textAlign: 'center'
                          }}
                        >
                          No one has contributed to this order yet.
                        </AppText>
                      </AppFlexbox>
                    )
                  ) : (
                    filteredCustomers
                      .slice(
                        -MAX_PAGE_LENGTH +
                          filterState.pageIndex * MAX_PAGE_LENGTH,
                        filterState.pageIndex * MAX_PAGE_LENGTH
                      )
                      .map((c, index) => (
                        <React.Fragment key={c.pkEcomCustomer}>
                          {index > 0 && <Divider />}
                          <StoreOrderContributorListItem
                            ecomCustomer={c}
                            showDetails
                          />
                        </React.Fragment>
                      ))
                  )}
                </AppStack>
              </AppStack>
            </AppCard>
            {filteredCustomers.length > MAX_PAGE_LENGTH && (
              <AppFlexbox style={{ justifyContent: 'center' }}>
                <Pagination
                  color="blue"
                  onChange={(pageIndex) => {
                    setFilterState({
                      ...filterState,
                      pageIndex
                    });
                    const element = document.getElementById(
                      'order-contributors-list'
                    );
                    if (element) {
                      element.scrollIntoView({ block: 'start' });
                    }
                  }}
                  total={Math.ceil(filteredCustomers.length / MAX_PAGE_LENGTH)}
                  value={filterState.pageIndex}
                />
              </AppFlexbox>
            )}
          </AppStack>
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard
            customerEmail={ecomOrder.ecomCustomer.email}
            customerName={ecomOrder.ecomCustomer.user?.name}
            customerUrl={`/merchant/customers/${ecomOrder.ecomCustomer.pkEcomCustomer}?from=orders&fromId=${ecomOrder.pkEcomOrder}`}
            hideBilling
            shippingAddress={ecomOrder.ecomCustomerAddressShipping}
            title="Receiver"
          />
          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox
                style={{
                  padding: 16,
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                  Products
                </AppText>
                <Anchor
                  c="dodgerblue"
                  component={Link}
                  style={{ fontSize: 14 }}
                  to={`/merchant/group-orders/${ecomOrder.pkEcomOrder}`}
                >
                  View order
                </Anchor>
              </AppFlexbox>
              <Divider />

              <AppStack style={{ flex: 1, padding: 16, gap: 16 }}>
                {groupedEcomOrderProducts.map((p) => (
                  <VendorOrderProductPreview
                    key={p.pkEcomOrderProduct}
                    hideStatus
                    product={p}
                    quantityIndicatorAmount={p.quantity}
                  />
                ))}
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to={`/merchant/group-orders/${pkEcomOrder}`}
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 5 }}>
          <Skeleton height={36} width={200} />
          <Skeleton height={14} width={150} />
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <AppStack style={{ gap: 16 }}>
            <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
              <AppStack style={{ gap: 0 }}>
                <AppStack style={{ padding: 16, gap: 8 }}>
                  <AppFlexbox
                    style={{
                      gap: 8,
                      alignItems: 'center'
                    }}
                  >
                    <Skeleton height={16} width="25%" />
                  </AppFlexbox>

                  <AppFlexbox
                    style={{
                      gap: 0,
                      alignItems: 'end',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Skeleton
                      height={36}
                      width={isLargeMobileOrSmaller ? '100%' : 200}
                    />

                    <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                      {!isLargeMobileOrSmaller && (
                        <Skeleton height={14} width={80} />
                      )}

                      <Skeleton
                        height={36}
                        width={isLargeMobileOrSmaller ? '100%' : 200}
                      />
                    </AppFlexbox>
                  </AppFlexbox>
                </AppStack>
                <Divider />

                <AppStack style={{ flex: 1, gap: 16 }}>
                  {Array.from(Array(3)).map((x, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={i}>
                      {i > 0 && <Divider />}
                      <StoreOrderContributorListItem showDetails />
                    </React.Fragment>
                  ))}
                </AppStack>
              </AppStack>
            </AppCard>
          </AppStack>
        </AppStack>
        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard loading />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

StoreOrderContributors.propTypes = {
  loading: PropTypes.bool.isRequired,
  ecomOrder: PropTypes.object,
  isBulkOrders: PropTypes.bool
};

export default StoreOrderContributors;
