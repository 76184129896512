import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Badge } from '@mantine/core';
import { BuildingStore, Plus, User } from 'tabler-icons-react';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as StoreUserContext } from '../../../providers/StoreUserContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import CustomerAddressInfoCard from './CustomerAddressInfoCard';
import AppText from '../../common/AppText';
import CustomerAddressForm from './CustomerAddressForm';
import { triggerNotification } from '../../../helpers/notification';

const StoreCustomerAddressModal = ({ isOpen, onClose }) => {
  const hasFetched = useRef(false);
  const { state: authState } = useContext(AuthContext);
  const {
    state: userState,
    fetchEcomCustomerAddresses,
    deleteEcomCustomerAddress
  } = useContext(StoreUserContext);
  const {
    state,
    fetchStoreAddresses,
    createOrUpdateStoreAddresses,
    deleteStoreAddresses
  } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    pkEcomStoreAddress: null,
    ecomCustomerAddress: null,
    isDefault: false,
    showDeleteConfirm: false,
    showStoreAddressSelect: !!state.ecomStore.value?.defaultAddress,
    loading: false
  });

  const isFetching =
    !hasFetched.current ||
    state.ecomStoreAddresses.loading ||
    userState.ecomCustomerAddresses.loading;
  const storeAddresses = state.ecomStoreAddresses.value.filter(
    (f) => f.fkEcomStore.toString() === authState.pkEcomStore.toString()
  );
  const hasExistingDefaultAddress =
    !!state.ecomStore.value?.defaultAddress ||
    storeAddresses.some((a) => a.isDefault);
  const canAssignAsDefault =
    hasExistingDefaultAddress && !formState.selectedAddress?.isDefault;

  const selectedStoreAddress = storeAddresses.find(
    (a) => a.pkEcomStoreAddress === formState.pkEcomStoreAddress
  );

  const preventUserFromEditingStoreAddress =
    selectedStoreAddress &&
    selectedStoreAddress.ecomCustomerAddress.ecomCustomer.user.pkUser !==
      authState.userData.user.pkUser;

  useEffect(() => {
    if (isOpen) {
      if (
        state.ecomStoreAddresses.filter?.pkEcomStore !== authState.pkEcomStore
      ) {
        fetchStoreAddresses(authState.pkEcomStore);
      }

      if (!userState.ecomCustomerAddresses.filter) {
        fetchEcomCustomerAddresses();
      }
      hasFetched.current = true;
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !isFetching) {
      const noAddress =
        !hasExistingDefaultAddress &&
        !userState.ecomCustomerAddresses.value.length;
      setFormState({
        pkEcomStoreAddress: null,
        ecomCustomerAddress: noAddress ? { isDefault: true } : null,
        isDefault: noAddress,
        showDeleteConfirm: false,
        showStoreAddressSelect: hasExistingDefaultAddress,
        loading: false
      });
    }
  }, [isOpen, isFetching]);

  const onError = (e) => {
    setFormState({
      ...formState,
      loading: false
    });
    triggerNotification(e, 'error');
  };

  const onSuccess = (message) => {
    triggerNotification(message, 'success');
    fetchStoreAddresses(authState.pkEcomStore);
    fetchEcomCustomerAddresses(authState.pkEcomStore);
    setFormState({
      ...formState,
      loading: false,
      ecomCustomerAddress: null,
      showStoreAddressSelect: true
    });
  };

  const onSubmit = () => {
    setFormState({
      ...formState,
      loading: true
    });
    if (formState.showDeleteConfirm) {
      if (formState.pkEcomStoreAddress) {
        deleteStoreAddresses(
          authState.pkEcomStore,
          [formState.pkEcomStoreAddress],
          () => {
            onSuccess('Address deleted!');
          },
          onError
        );
      }
      else {
        deleteEcomCustomerAddress(
          formState.ecomCustomerAddress.pkEcomCustomerAddress,
          () => {
            onSuccess('Address deleted!');
          },
          onError
        );
      }
    }
    else {
      createOrUpdateStoreAddresses(
        authState.pkEcomStore,
        [
          {
            ...formState,
            isDefault: !hasExistingDefaultAddress ? true : formState.isDefault,
            fkEcomCustomerAddress:
              formState.ecomCustomerAddress.pkEcomCustomerAddress,
            ecomCustomerAddress: preventUserFromEditingStoreAddress
              ? null
              : formState.ecomCustomerAddress
          }
        ],
        () => {
          onSuccess(
            formState.ecomCustomerAddress.pkEcomCustomerAddress
              ? 'Address updated!'
              : 'Address added!'
          );
        },
        onError
      );
    }
  };

  return (
    <ResponsiveModal
      formSectionProps={{
        padding: 0,
        isLoading: formState.loading,
        cancelTitle:
          formState.showStoreAddressSelect || storeAddresses.length === 0
            ? 'Close'
            : 'Back',
        onCancel: () => {
          if (formState.showDeleteConfirm) {
            setFormState({
              ...formState,
              showDeleteConfirm: false
            });
          }
          else if (formState.ecomCustomerAddress) {
            if (!formState.pkEcomStoreAddress && !storeAddresses.length) {
              onClose();
            }
            else {
              setFormState({
                ...formState,
                ecomCustomerAddress: null,
                pkEcomStoreAddress: null,
                isDefault: false,
                showDeleteConfirm: false,
                showStoreAddressSelect:
                  !!formState.pkEcomStoreAddress ||
                  userState.ecomCustomerAddresses.value.length === 0
              });
            }
          }
          else if (
            !formState.showStoreAddressSelect &&
            storeAddresses.length > 0
          ) {
            setFormState({
              ...formState,
              ecomCustomerAddress: null,
              pkEcomStoreAddress: null,
              isDefault: false,
              showDeleteConfirm: false,
              showStoreAddressSelect: true
            });
          }
          else {
            onClose();
          }
        },
        onSubmit,
        submitColor: formState.showDeleteConfirm ? 'red' : 'dodgerblue',
        isSubmitHidden: !formState.ecomCustomerAddress,
        isSubmitDisabled:
          preventUserFromEditingStoreAddress &&
          formState.ecomCustomerAddress?.isDefault,
        submitTitle: formState.showDeleteConfirm
          ? `Yes, ${selectedStoreAddress ? 'remove' : 'delete'} address`
          : formState.pkEcomStoreAddress
          ? 'Save changes'
          : 'Add address',
        leftSection:
          !formState.showDeleteConfirm &&
          !formState.ecomCustomerAddress &&
          (formState.showStoreAddressSelect ? (
            <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
              <Plus color="dodgerblue" size={14} />
              <Anchor
                onClick={() => {
                  setFormState({
                    ...formState,
                    showStoreAddressSelect: false,
                    ecomCustomerAddress:
                      userState.ecomCustomerAddresses.value.length === 0
                        ? {}
                        : null
                  });
                }}
                style={{ color: 'dodgerblue', fontSize: 14 }}
              >
                Add new address
              </Anchor>
            </AppFlexbox>
          ) : (
            <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
              <Plus color="dodgerblue" size={14} />
              <Anchor
                onClick={() =>
                  setFormState({
                    ...formState,
                    showAddressSelect: true,
                    isDefault: !hasExistingDefaultAddress,
                    ecomCustomerAddress: {}
                  })
                }
                style={{ color: 'dodgerblue', fontSize: 14 }}
              >
                Create new address
              </Anchor>
            </AppFlexbox>
          ))
      }}
      isLoading={isFetching}
      isOpen={isOpen}
      onClose={onClose}
      title={
        formState.showStoreAddressSelect
          ? 'Manage store addresses'
          : formState.showDeleteConfirm
          ? selectedStoreAddress
            ? 'Remove store address?'
            : 'Delete account address?'
          : !hasExistingDefaultAddress
          ? 'Setup default address'
          : formState.pkEcomStoreAddress
          ? 'Edit address'
          : formState.ecomCustomerAddress &&
            !formState.ecomCustomerAddress.pkEcomCustomerAddress
          ? 'Add new address'
          : 'Add an address from your account'
      }
    >
      {formState.showDeleteConfirm ? (
        <AppStack style={{ padding: 16, gap: 16 }}>
          <CustomerAddressInfoCard
            ecomCustomerAddress={formState.ecomCustomerAddress}
          />
          <AppStack style={{ gap: 8 }}>
            <AppText style={{ fontSize: 16 }}>
              {selectedStoreAddress
                ? 'Are you sure you want remove to the selected address from your store?'
                : 'Are you sure you want delete to the selected address from your account?'}
            </AppText>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              This action cannot be undone.
            </AppText>
          </AppStack>
        </AppStack>
      ) : formState.showStoreAddressSelect ? (
        <AppStack style={{ gap: 16, padding: 16 }}>
          <AppText
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: '#666',
              textAlign: 'center'
            }}
          >
            Select a store address to manage it or add a new one.
          </AppText>
          {storeAddresses.map((a) => (
            <CustomerAddressInfoCard
              key={a.pkEcomStoreAddress}
              badge={
                a.isDefault && (
                  <Badge color="blue" size="sm">
                    DEFAULT
                  </Badge>
                )
              }
              ecomCustomerAddress={a.ecomCustomerAddress}
              icon={<BuildingStore size={50} />}
              isDefault={a.isDefault}
              onSelect={() => {
                setFormState({
                  ...formState,
                  showStoreAddressSelect: false,
                  pkEcomStoreAddress: a.pkEcomStoreAddress,
                  ecomCustomerAddress: {
                    ...a.ecomCustomerAddress,
                    isDefault: a.isDefault
                  },
                  isDefault: a.isDefault
                });
              }}
              ownerName={a.ecomCustomerAddress.ecomCustomer.user.name}
            />
          ))}
        </AppStack>
      ) : formState.ecomCustomerAddress ? (
        <AppStack style={{ gap: 16, padding: 16 }}>
          <CustomerAddressForm
            defaultDisabled={formState.ecomCustomerAddress.isDefault}
            disabled={formState.loading}
            editDisabledMessage={
              preventUserFromEditingStoreAddress ? (
                <>
                  <AppText
                    style={{
                      fontSize: 14,
                      textAlign: 'center',
                      color: '#c40000'
                    }}
                  >
                    This address belongs to the{' '}
                    {
                      selectedStoreAddress.ecomCustomerAddress.ecomCustomer.user
                        .name
                    }{' '}
                    and cannot be edited.
                  </AppText>
                  <AppText
                    style={{ fontSize: 14, textAlign: 'center', color: '#666' }}
                  >
                    You may only remove it from the store or set it as the
                    default address.
                  </AppText>
                </>
              ) : null
            }
            formState={formState.ecomCustomerAddress}
            isDefault={formState.isDefault}
            onDefaultAddressSelect={
              canAssignAsDefault && !formState.loading
                ? () => {
                    if (canAssignAsDefault) {
                      setFormState({
                        ...formState,
                        isDefault: !formState.isDefault
                      });
                    }
                  }
                : null
            }
            onDelete={
              formState.ecomCustomerAddress.pkEcomCustomerAddress
                ? () => {
                    setFormState({
                      ...formState,
                      showDeleteConfirm: true
                    });
                  }
                : null
            }
            removeTitle={selectedStoreAddress ? 'Remove from store' : null}
            setFormState={(v) =>
              setFormState({
                ...formState,
                ecomCustomerAddress: v
              })
            }
            showDefaultAddressSelect
          />
        </AppStack>
      ) : (
        <AppStack style={{ gap: 16, padding: 16 }}>
          <AppText
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: '#666',
              textAlign: 'center'
            }}
          >
            Please select an address from your account to add to your store.
          </AppText>
          {userState.ecomCustomerAddresses.value.map((a) => {
            const storeAddress = storeAddresses.find(
              (s) =>
                s.ecomCustomerAddress.pkEcomCustomerAddress ===
                a.pkEcomCustomerAddress
            );

            return (
              <CustomerAddressInfoCard
                key={a.pkEcomCustomerAddress}
                badge={
                  !!storeAddress && (
                    <Badge color="blue" size="sm">
                      ADDED
                    </Badge>
                  )
                }
                ecomCustomerAddress={a}
                icon={<User size={50} />}
                onSelect={() => {
                  setFormState({
                    ...formState,
                    ecomCustomerAddress: {
                      ...a,
                      isDefault: storeAddress?.isDefault
                    },
                    isDefault:
                      storeAddress?.isDefault || !hasExistingDefaultAddress
                  });
                }}
              />
            );
          })}
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

StoreCustomerAddressModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default StoreCustomerAddressModal;
