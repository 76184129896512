import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Anchor, Button, Divider, TextInput } from '@mantine/core';
import { Coin } from 'tabler-icons-react';
import ResponsiveModal from '../../common/ResponsiveModal';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import { currencyFormat } from '../../../helpers/format';

const OnboardVendorPaymentProviderModal = ({
  ecomStore,
  ecomVendor,
  isOpen,
  onClose,
  onSuccessCallback,
  onClaimEscrowPayments
}) => {
  const {
    fetchVendorSetupSummary,
    onboardVendorPaymentProvider,
    disconnectVendorPaymentProvider
  } = useContext(VendorContext);
  const {
    state,
    fetchStoreSetupSummary,
    onboardStorePaymentProvider,
    disconnectStorePaymentProvider
  } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    accountId: '',
    showRemoveConfirm: false,
    loading: false
  });
  const existingAccountId = ecomVendor
    ? ecomVendor?.ecomVendorPaymentProvider?.accountId
    : ecomStore?.ecomStorePaymentProvider?.accountId;
  const isCompleted = ecomVendor
    ? ecomVendor?.ecomVendorPaymentProvider?.completed
    : ecomStore?.ecomStorePaymentProvider?.completed;

  const { escrowBalance } = state.ecomStoreSetupSummary.value || {};

  useEffect(() => {
    if (isOpen && (ecomVendor || ecomStore)) {
      setFormState({
        accountId: existingAccountId || '',
        showRemoveConfirm: false,
        loading: false
      });
    }
  }, [isOpen]);

  const onError = (e) => {
    triggerNotification(e);
    setFormState({ ...formState, isLoading: false });
  };

  const onSuccess = (session) => {
    if (session.sessionUrl) {
      window.location.href = session.sessionUrl;
    }
    else if (session.onboardingComplete) {
      triggerNotification('Payment provider onboarded successfully', 'success');
      if (ecomVendor) {
        fetchVendorSetupSummary(ecomVendor.pkEcomVendor);
      }
      else {
        fetchStoreSetupSummary(ecomStore.pkEcomStore);
      }
      onClose();
    }

    if (onSuccessCallback) {
      onSuccessCallback(session);
    }
  };

  const onDisconnectSuccess = () => {
    triggerNotification('Stripe disconnected successfully', 'success');
    onClose();

    if (ecomVendor) {
      fetchVendorSetupSummary(ecomVendor.pkEcomVendor);
    }
    else {
      fetchStoreSetupSummary(ecomStore.pkEcomStore);
    }
  };

  const onVendorRequest = () => {
    if (formState.showRemoveConfirm) {
      disconnectVendorPaymentProvider(
        { pkEcomVendor: ecomVendor.pkEcomVendor },
        onDisconnectSuccess,
        onError
      );
    }
    else {
      onboardVendorPaymentProvider(
        {
          pkEcomVendor: ecomVendor.pkEcomVendor,
          accountId: formState.accountId,
          updateAccount: !!existingAccountId
        },
        onSuccess,
        onError
      );
    }
  };

  const onStoreRequest = () => {
    if (formState.showRemoveConfirm) {
      disconnectStorePaymentProvider(
        { pkEcomStore: ecomStore.pkEcomStore },
        onDisconnectSuccess,
        onError
      );
    }
    else {
      onboardStorePaymentProvider(
        {
          pkEcomStore: ecomStore.pkEcomStore,
          accountId: formState.accountId,
          updateAccount: !!existingAccountId
        },
        onSuccess,
        onError
      );
    }
  };

  return (
    <ResponsiveModal
      formSectionProps={{
        isLoading: formState.loading,
        onCancel: () => {
          if (formState.showRemoveConfirm) {
            setFormState({
              ...formState,
              showRemoveConfirm: false
            });
          }
          else {
            onClose();
          }
        },
        onSubmit: () => {
          setFormState({
            ...formState,
            loading: true
          });
          if (ecomVendor) {
            onVendorRequest();
          }
          else if (ecomStore) {
            onStoreRequest();
          }
        },
        leftSection: existingAccountId &&
          !formState.showRemoveConfirm &&
          !formState.loading && (
            <Anchor
              onClick={() => {
                setFormState({
                  ...formState,
                  showRemoveConfirm: true
                });
              }}
              style={{ fontSize: 14, color: '#C40000' }}
            >
              Remove connection
            </Anchor>
          ),
        submitColor: formState.showRemoveConfirm ? 'red' : 'dark',
        submitTitle: formState.showRemoveConfirm
          ? 'Yes, remove Stripe'
          : existingAccountId
          ? 'Update Stripe'
          : 'Onboard Stripe'
      }}
      isOpen={isOpen}
      onClose={onClose}
      title={
        formState.showRemoveConfirm
          ? 'Remove payment provider?'
          : existingAccountId
          ? 'Update payment provider'
          : 'Onboard payment provider'
      }
    >
      {formState.showRemoveConfirm ? (
        <AppStack style={{ gap: 20 }}>
          <AppStack>
            <Alert
              color="red"
              title="Remove Stripe connection"
              variant="outline"
            >
              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Are you sure you want to disconnect your Stripe account?
                </AppText>
                <AppText style={{ fontSize: 14 }}>
                  You’ll still receive payments through our escrow system unless
                  your store is in sandbox mode.
                </AppText>
              </AppStack>
            </Alert>
            <AppText
              style={{ fontSize: 14, color: '#666', textAlign: 'center' }}
            >
              To access your escrow balance, simply onboard a payment provider
              anytime and transfer the funds to your account.
            </AppText>
          </AppStack>
        </AppStack>
      ) : (
        <AppStack style={{ gap: 20 }}>
          {!existingAccountId ? (
            <Alert color="blue" title="Onboard with Stripe!" variant="outline">
              <AppStack style={{ gap: 10 }}>
                <AppText style={{ fontSize: 14 }}>
                  You are about to onboard <b>Stripe</b> as a payment provider
                  for <b>{ecomVendor?.name ?? ecomStore?.name}</b>.
                </AppText>
                <AppText style={{ fontSize: 14 }}>
                  If you already have a <b>Stripe</b> account you can enter the
                  ID for it below. If you do not have a <b>Stripe</b> account
                  you can leave this field blank and we will create one for you.
                </AppText>
              </AppStack>
            </Alert>
          ) : isCompleted ? (
            <Alert color="green" title="Stripe onboarded!" variant="outline">
              <AppStack style={{ gap: 10 }}>
                <AppText style={{ fontSize: 14 }}>
                  You have successfully onboarded <b>Stripe</b> as a payment
                  provider.
                </AppText>

                <AppText style={{ fontSize: 14, color: '#666' }}>
                  You may update your account ID below if needed.
                </AppText>
              </AppStack>
            </Alert>
          ) : (
            <Alert color="yellow" title="Action required" variant="outline">
              <AppStack style={{ gap: 10 }}>
                <AppText style={{ fontSize: 14 }}>
                  You have not completed the onboarding process with{' '}
                  <b>Stripe</b>.
                </AppText>
              </AppStack>
            </Alert>
          )}

          <TextInput
            disabled={formState.loading || !!existingAccountId}
            label="Existing Stripe account ID"
            onChange={(e) =>
              setFormState({
                ...formState,
                accountId: e.target.value
              })
            }
            value={formState.accountId}
          />

          {onClaimEscrowPayments && escrowBalance > 0 && (
            <AppCard
              style={{
                padding: 0,
                borderRadius: 8
              }}
              withBorder
            >
              <AppFlexbox
                style={{
                  gap: 0,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <AppStack style={{ padding: 16 }}>
                  <Coin color="#067D62" size={60} />
                </AppStack>
                <Divider orientation="vertical" />
                <AppStack
                  style={{
                    flex: 1,
                    padding: 16,
                    gap: 0
                  }}
                >
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    Claim your {currencyFormat(escrowBalance / 100)} escrow
                    balance.
                  </AppText>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    Your store continues to generate revenue without onboarding
                    a payment provider. You can claim your escrow balance at any
                    time by onboarding a payment provider and transfering the
                    funds to your account.
                  </AppText>
                  <AppFlexbox style={{ marginTop: 10 }}>
                    {isCompleted ? (
                      <Button
                        color="blue"
                        onClick={onClaimEscrowPayments}
                        size="compact-md"
                        style={{ fontSize: 14 }}
                        variant="filled"
                      >
                        Claim {currencyFormat(escrowBalance / 100)}
                      </Button>
                    ) : (
                      existingAccountId && (
                        <AppText style={{ fontSize: 14, color: '#c40000' }}>
                          You must complete the onboarding process to claim your
                          escrow balance.
                        </AppText>
                      )
                    )}
                  </AppFlexbox>
                </AppStack>
              </AppFlexbox>
            </AppCard>
          )}

          <AppText style={{ fontSize: 14, color: '#666', textAlign: 'center' }}>
            You will be redirected to <b>Stripe</b> to{' '}
            {isCompleted
              ? 'update your account'
              : 'complete the onboarding process'}
            .
          </AppText>
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

OnboardVendorPaymentProviderModal.propTypes = {
  ecomStore: PropTypes.object,
  ecomVendor: PropTypes.object,
  isOpen: PropTypes.bool,
  onClaimEscrowPayments: PropTypes.func,
  onClose: PropTypes.func,
  onSuccessCallback: PropTypes.func
};

export default OnboardVendorPaymentProviderModal;
