import React, { useContext, useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import AppStack from '../components/common/AppStack';
import AppFlexbox from '../components/common/AppFlexbox';
import VendorDashboardOverview from '../components/content/vendors/VendorDashboardOverview';
import VendorProductsView from '../components/content/vendors/VendorProductsView';
import VendorOrdersView from '../components/content/vendors/VendorOrdersView';
import VendorSidebar from '../components/content/navigation/VendorSidebar';
import VendorProductCollectionsView from '../components/content/vendors/VendorCollectionsView';
import VendorSwitchView from '../components/content/vendors/VendorSwitchView';
import VendorSetupGuideSidebar from '../components/content/vendors/VendorSetupGuideSidebar';
import { Context as AuthContext } from '../providers/AuthContextProvider';
import VendorSettingsView from '../components/content/vendors/VendorSettingsView';
import VendorReportsView from '../components/content/vendors/VendorReportsView';
import VendorInventoryView from '../components/content/vendors/VendorInventoryView';
import VendorCustomersView from '../components/content/vendors/VendorCustomersView';
import VendorCatalogView from '../components/content/vendorCatalog/VendorCatalogView';
import VendorMerchantViews from '../components/content/vendors/VendorMerchantViews';
import VendorAnalyticsView from '../components/content/vendors/VendorAnalyticsView';

const hiddenSidebarRoutes = [/\/vendor\/reports\/result/];

const VendorsView = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state: authState } = useContext(AuthContext);
  const showSidebar = !hiddenSidebarRoutes.some((r) => pathname.match(r));

  useEffect(() => {
    if (
      authState.isAuthenticated &&
      !authState.loading &&
      authState.ecomVendors.length === 0
    ) {
      navigate(`/vendor/switch`);
    }
  }, [authState.isAuthenticated, authState.loading, pathname]);

  return (
    <AppFlexbox style={{ gap: 5, flex: 1 }}>
      {showSidebar && <VendorSidebar />}

      <AppStack style={{ flex: 1, width: '100%', overflow: 'hidden' }}>
        <Routes>
          <Route element={<VendorDashboardOverview />} path="/" />
          <Route element={<VendorSwitchView />} path="/switch" />
          <Route element={<VendorProductsView />} path="/products/*" />
          <Route
            element={<VendorProductCollectionsView />}
            path="/collections/*"
          />
          <Route element={<VendorInventoryView />} path="/inventory/*" />
          <Route element={<VendorCustomersView />} path="/customers/*" />
          <Route element={<VendorMerchantViews />} path="/merchants/*" />
          <Route
            element={<VendorOrdersView isBulkOrders />}
            path="/bulk-orders/*"
          />
          <Route element={<VendorOrdersView />} path="/orders/*" />
          <Route element={<VendorAnalyticsView />} path="/analytics/*" />
          <Route element={<VendorReportsView />} path="/reports/*" />
          <Route element={<VendorSettingsView />} path="/settings/*" />
          <Route
            element={<VendorCatalogView vendorPreview />}
            path="/catalog/*"
          />
          <Route element={<Navigate replace to="/vendor" />} path="*" />
        </Routes>
      </AppStack>
      <VendorSetupGuideSidebar />
    </AppFlexbox>
  );
};
export default VendorsView;
