import React from 'react';
import PropTypes from 'prop-types';
import { ActionIcon, Anchor, Badge, Divider, Menu } from '@mantine/core';
import { Dots, Search, Send } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppMenu from '../../common/AppMenu';
import * as classes from '../../../styles/nestedStyles.module.css';
import StoreOrderContributorListItem from './StoreOrderContributorListItem';

const StoreOrderContributorsInfoCard = ({ ecomOrder, ecomCustomers }) => {
  const { isTabletOrSmaller, isDesktopOrSmaller } = useMediaQueryIndex();

  return ecomOrder ? (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack
        style={{
          gap: 0,
          flexDirection:
            !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
        }}
      >
        <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 8
              }}
            >
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  gap: 8
                }}
              >
                <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                  Contributors
                </AppText>
                <Badge circle color="blue">
                  {ecomCustomers.length}
                </Badge>
              </AppFlexbox>
              <AppMenu
                control={
                  <ActionIcon color="dark" variant="subtle">
                    <Dots />
                  </ActionIcon>
                }
              >
                <Menu.Item
                  component={Link}
                  leftSection={<Search size={18} />}
                  onClick={() => {}}
                  to={`/merchant/group-orders/${ecomOrder.pkEcomOrder}/contributors`}
                >
                  View details
                </Menu.Item>
                <Menu.Item
                  component="a"
                  href={`mailto:${ecomCustomers.map((c) => c.email).join(',')}`}
                  leftSection={<Send size={18} />}
                  onClick={() => {}}
                >
                  Send email
                </Menu.Item>
              </AppMenu>
            </AppFlexbox>
          </AppStack>
        </AppStack>

        <Divider
          orientation={
            !isTabletOrSmaller && isDesktopOrSmaller ? 'vertical' : 'horizontal'
          }
        />

        <AppStack style={{ flex: 1, gap: 24 }}>
          {ecomCustomers.length === 0 ? (
            <AppFlexbox style={{ padding: 16, justifyContent: 'center' }}>
              <AppText
                style={{ fontSize: 14, color: '#666', textAlign: 'center' }}
              >
                No one has contributed to this order yet.
              </AppText>
            </AppFlexbox>
          ) : (
            ecomCustomers
              .slice(0, 5)
              .map((c) => (
                <StoreOrderContributorListItem
                  key={c.pkEcomCustomer}
                  ecomCustomer={c}
                />
              ))
          )}
        </AppStack>
        <Divider />

        <AppFlexbox
          className={classes['hover-action-card']}
          component={Link}
          style={{
            padding: 8,
            justifyContent: 'center',
            textDecoration: 'none'
          }}
          to={`/merchant/group-orders/${ecomOrder.pkEcomOrder}/contributors`}
        >
          <Anchor
            component="p"
            style={{ textAlign: 'center', color: 'dodgerblue', fontSize: 14 }}
          >
            View {ecomCustomers.length > 5 ? 'contributors' : 'details'}
          </Anchor>
        </AppFlexbox>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder />
  );
};

StoreOrderContributorsInfoCard.propTypes = {
  ecomCustomers: PropTypes.array,
  ecomOrder: PropTypes.object
};

export default StoreOrderContributorsInfoCard;
