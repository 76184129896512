import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRight } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import * as classes from '../../../styles/nestedStyles.module.css';
import AppCard from '../../common/AppCard';

const CustomerAddressInfoCard = ({
  ecomCustomerAddress,
  onSelect,
  badge,
  icon,
  ownerName
}) =>
  ecomCustomerAddress ? (
    <AppCard radius={8} style={{ padding: 0 }} withBorder>
      <AppFlexbox
        className={onSelect ? classes['hover-action-card'] : null}
        onClick={onSelect ? () => onSelect() : null}
        style={{
          padding: 16,
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <AppFlexbox style={{ gap: 16, alignItems: 'center' }}>
          {icon}
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ alignItems: 'center' }}>
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                {ecomCustomerAddress.fullName}
              </AppText>
              {badge}
            </AppFlexbox>
            <AppText style={{ fontSize: 14 }}>
              {ecomCustomerAddress.address1}
            </AppText>
            {ecomCustomerAddress.address2 && (
              <AppText style={{ fontSize: 14 }}>
                {ecomCustomerAddress.address2}
              </AppText>
            )}
            <AppText style={{ fontSize: 14 }}>
              {ecomCustomerAddress.city} {ecomCustomerAddress.postalCode}{' '}
              {ecomCustomerAddress.state}, {ecomCustomerAddress.country}
            </AppText>

            {ownerName && (
              <AppText
                style={{
                  fontSize: 13,
                  color: '#666',
                  marginTop: 5,
                  fontStyle: 'italic'
                }}
              >
                Owned by {ownerName}
              </AppText>
            )}
          </AppStack>
        </AppFlexbox>
        {onSelect && <ChevronRight color="#999" size={24} />}
      </AppFlexbox>
    </AppCard>
  ) : null;

CustomerAddressInfoCard.propTypes = {
  badge: PropTypes.any,
  ecomCustomerAddress: PropTypes.object,
  icon: PropTypes.any,
  onSelect: PropTypes.func,
  ownerName: PropTypes.string
};

export default CustomerAddressInfoCard;
