import React from 'react';
import { PropTypes } from 'prop-types';
import { Image, NumberFormatter } from '@mantine/core';
import { Link } from 'react-router-dom';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import ProductLineItemAddonsDisplay from '../../../products/ProductLineItemAddonsDisplay';

const WsAccountOrderSummaryProduct = ({
  ecomOrderProductSummary,
  mediaQueries,
  baseStoreUrl,
  onNavigate
}) => {
  const { isTabletOrSmaller, getResponsiveStyle: rs } = mediaQueries;
  const url = `${baseStoreUrl}/products/${ecomOrderProductSummary.urlHandle}`;

  return (
    <AppFlexbox
      key={ecomOrderProductSummary.pkEcomOrderProduct}
      style={{
        flex: 1,
        flexWrap: 'nowrap',
        flexDirection: rs(['column', 'column', 'column', 'row']),
        gap: 0
      }}
    >
      <AppFlexbox style={{ flexWrap: 'nowrap', alignSelf: 'stretch', flex: 2 }}>
        <AppStack
          component={Link}
          onClick={onNavigate}
          style={{
            height: isTabletOrSmaller ? 70 : 100,
            width: isTabletOrSmaller ? 70 : 100
          }}
          to={url}
        >
          <Image
            fit="contain"
            h="100%"
            src={ecomOrderProductSummary.previewImage}
            w="100%"
          />
        </AppStack>
        <AppStack
          component={url ? Link : null}
          onClick={onNavigate}
          style={{
            flex: 1,
            gap: 0,
            textDecoration: 'none',
            color: '#000'
          }}
          to={url}
        >
          <AppText size="lg" weight={700}>
            {ecomOrderProductSummary.productName}
          </AppText>

          <ProductLineItemAddonsDisplay
            addOns={[
              ...ecomOrderProductSummary.ecomVendorProductVariantOptions.map(
                (op) => ({
                  sort: op.nameSort,
                  name: op.name,
                  value: op.value,
                  unitAmountInCents: 0
                })
              ),
              ...ecomOrderProductSummary.ecomOrderProductInputAnswers.map(
                (op) => ({
                  sort:
                    op.sort +
                    ecomOrderProductSummary.ecomVendorProductVariantOptions
                      .length,
                  name: op.label,
                  value: op.value,
                  unitAmountInCents: op.adjustedCost
                })
              )
            ]}
          />

          <AppText style={{ fontSize: 12, color: '#666' }}>
            Qty: {ecomOrderProductSummary.count}
          </AppText>
        </AppStack>
      </AppFlexbox>

      <AppStack
        style={{
          flex: 1,
          alignSelf: 'start',
          placeItems: 'end',
          marginLeft: 'auto',
          gap: rs([0, 20]),
          flexDirection: rs([
            'row-reverse',
            'row-reverse',
            'row-reverse',
            'column'
          ]),
          justifyContent: rs([
            'space-between',
            'space-between',
            'space-between',
            'inital'
          ]),
          width: rs(['100%', '100%', '100%', 'unset'])
        }}
      >
        <AppStack style={{ gap: 0, alignItems: 'end' }}>
          <AppFlexbox style={{ gap: 10, flexWrap: 'nowrap' }}>
            <AppText size="lg" weight={700}>
              <NumberFormatter
                decimalScale={2}
                displayType="text"
                fixedDecimalScale
                prefix="$"
                thousandSeparator
                value={
                  ((ecomOrderProductSummary.productCost +
                    ecomOrderProductSummary.addonCost) *
                    ecomOrderProductSummary.count) /
                  100
                }
              />
            </AppText>
          </AppFlexbox>
        </AppStack>
      </AppStack>
    </AppFlexbox>
  );
};

WsAccountOrderSummaryProduct.propTypes = {
  baseStoreUrl: PropTypes.string.isRequired,
  ecomOrderProductSummary: PropTypes.object.isRequired,
  mediaQueries: PropTypes.object.isRequired,
  onNavigate: PropTypes.func
};

export default WsAccountOrderSummaryProduct;
