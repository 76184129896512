import React, { useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  NumberFormatter,
  Skeleton,
  Table
} from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Context as AdminContext } from '../../../providers/AdminContextProvider';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { formatUtcDate } from '../../../helpers/format';
import * as classes from '../../../styles/nestedStyles.module.css';

const AdminVendorPayoutInfoCard = ({ ecomVendorDetails }) => {
  const hasFetched = useRef(false);
  const { state, fetchPaginatedEcomVendorPayouts } = useContext(AdminContext);
  const loading =
    !hasFetched.current || state.paginatedEcomVendorPayouts.loading;
  const vendorPayouts = state.paginatedEcomVendorPayouts.data || [];
  const remainingPayoutAmount = ecomVendorDetails
    ? ecomVendorDetails.transactionsOverview.amountEarned -
      ecomVendorDetails.transactionsOverview.amountPaidToVendor
    : 0;

  useEffect(() => {
    if (ecomVendorDetails && !hasFetched.current) {
      fetchPaginatedEcomVendorPayouts({
        pkEcomVendor: ecomVendorDetails.ecomVendor.pkEcomVendor});
      hasFetched.current = true;
    }
  }, [ecomVendorDetails]);

  return ecomVendorDetails ? (
    <AppCard
      radius="md"
      shadow="xs"
      style={{ padding: 0, width: '100%' }}
      withBorder
    >
      <AppStack style={{ gap: 0 }}>
        <AppStack style={{ padding: 16 }}>
          <AppFlexbox
            style={{ alignItems: 'center', justifyContent: 'space-between' }}
          >
            <AppText style={{ fontSize: 16, fontWeight: 500 }}>Payouts</AppText>

            <Button
              color="dark"
              component={remainingPayoutAmount > 0 ? Link : null}
              disabled={remainingPayoutAmount <= 0}
              leftSection={<Plus />}
              size="compact-sm"
              style={{ fontSize: 14 }}
              to={`/admin/vendors/${ecomVendorDetails.ecomVendor.pkEcomVendor}/payouts/create`}
              variant="outline"
            >
              Create
            </Button>
          </AppFlexbox>

          <AppStack style={{ gap: 8 }}>
            <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
              <AppText style={{ fontSize: 14, flex: 1 }}>Earned</AppText>
              <NumberFormatter
                decimalScale={2}
                fixedDecimalScale
                prefix="$"
                style={{ fontSize: 14 }}
                thousandSeparator
                value={
                  ecomVendorDetails.transactionsOverview.amountEarned / 100
                }
              />
            </AppFlexbox>
            <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
              <AppText style={{ fontSize: 14, flex: 1 }}>Received</AppText>
              <NumberFormatter
                decimalScale={2}
                fixedDecimalScale
                prefix="$"
                style={{ fontSize: 14 }}
                thousandSeparator
                value={
                  ecomVendorDetails.transactionsOverview.amountPaidToVendor /
                  100
                }
              />
            </AppFlexbox>
            <Divider />
            <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
              <AppText style={{ fontSize: 14, fontWeight: 500, flex: 1 }}>
                Balance
              </AppText>
              <NumberFormatter
                decimalScale={2}
                fixedDecimalScale
                prefix="$"
                style={{ fontSize: 14, fontWeight: 500 }}
                thousandSeparator
                value={
                  (ecomVendorDetails.transactionsOverview.amountEarned -
                    ecomVendorDetails.transactionsOverview.amountPaidToVendor) /
                  100
                }
              />
            </AppFlexbox>
          </AppStack>
        </AppStack>
        <Divider />

        <AppStack style={{ width: '100%', overflow: 'auto' }}>
          <Table
            horizontalSpacing={0}
            style={{
              borderTop: 'solid 1px  #dee2e6',
              width: '100%'
            }}
            verticalSpacing={0}
          >
            <Table.Thead style={{ backgroundColor: '#E1E1E1' }}>
              <Table.Tr>
                <Table.Th style={{ padding: 8 }}>
                  <AppText
                    style={{
                      whiteSpace: 'nowrap',
                      fontSize: 14,
                      fontWeight: 500
                    }}
                  >
                    Date
                  </AppText>
                </Table.Th>
                <Table.Th style={{ padding: 8 }}>
                  <AppText
                    style={{
                      whiteSpace: 'nowrap',
                      fontSize: 14,
                      fontWeight: 500
                    }}
                  >
                    Cheque / Description
                  </AppText>
                </Table.Th>
                <Table.Th style={{ padding: 8 }}>
                  <AppText
                    style={{
                      whiteSpace: 'nowrap',
                      fontSize: 14,
                      fontWeight: 500,
                      textAlign: 'end'
                    }}
                  >
                    Amount
                  </AppText>
                </Table.Th>
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody>
              {loading ? (
                Array.from(Array(3)).map((x, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Table.Tr key={i}>
                    <Table.Td style={{ padding: 8 }}>
                      <Skeleton height={18} width="100%" />
                    </Table.Td>
                    <Table.Td style={{ padding: 8 }}>
                      <Skeleton height={18} width="100%" />
                    </Table.Td>
                    <Table.Td style={{ padding: 8 }}>
                      <AppFlexbox style={{ flex: 1, justifyContent: 'end' }}>
                        <Skeleton height={18} width={80} />
                      </AppFlexbox>
                    </Table.Td>
                  </Table.Tr>
                ))
              ) : vendorPayouts.length === 0 ? (
                <Table.Tr>
                  <Table.Td colSpan={3}>
                    <AppFlexbox
                      style={{
                        padding: 20,
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center'
                      }}
                    >
                      <AppText
                        style={{ fontSize: 14, color: '#666', fontWeight: 500 }}
                      >
                        No payout history
                      </AppText>
                    </AppFlexbox>
                  </Table.Td>
                </Table.Tr>
              ) : (
                vendorPayouts.map((p) => (
                  <Table.Tr
                    key={p.pkEcomVendorPayout}
                    className={classes['hover-action-card']}
                  >
                    <Table.Td>
                      <AppStack
                        component={Link}
                        style={{
                          padding: 8,
                          gap: 0,
                          color: '#000',
                          textDecoration: 'none'
                        }}
                        to={`/admin/vendors/${ecomVendorDetails.ecomVendor.pkEcomVendor}/payouts/${p.pkEcomVendorPayout}`}
                      >
                        <AppText
                          style={{
                            whiteSpace: 'nowrap',
                            fontSize: 14
                          }}
                        >
                          {dayjs(formatUtcDate(p.createdAt)).format(
                            'MMM D, YYYY'
                          )}
                        </AppText>
                      </AppStack>
                    </Table.Td>
                    <Table.Td>
                      <AppStack
                        component={Link}
                        style={{
                          padding: 8,
                          gap: 0,
                          color: '#000',
                          textDecoration: 'none'
                        }}
                        to={`/admin/vendors/${ecomVendorDetails.ecomVendor.pkEcomVendor}/payouts/${p.pkEcomVendorPayout}`}
                      >
                        <AppText
                          style={{
                            whiteSpace: 'nowrap',
                            fontSize: 14
                          }}
                        >
                          {p.chequeId || 'N/A'}
                        </AppText>
                        <AppText
                          style={{
                            whiteSpace: 'nowrap',
                            fontSize: 12,
                            color: '#666'
                          }}
                        >
                          {p.description}
                        </AppText>
                      </AppStack>
                    </Table.Td>
                    <Table.Td>
                      <AppFlexbox
                        component={Link}
                        style={{
                          padding: 8,
                          flex: 1,
                          justifyContent: 'end',
                          color: '#000',
                          textDecoration: 'none'
                        }}
                        to={`/admin/vendors/${ecomVendorDetails.ecomVendor.pkEcomVendor}/payouts/${p.pkEcomVendorPayout}`}
                      >
                        <NumberFormatter
                          decimalScale={2}
                          fixedDecimalScale
                          prefix="$"
                          style={{
                            whiteSpace: 'nowrap',
                            fontSize: 14,
                            fontWeight: 500,
                            textAlign: 'end'
                          }}
                          thousandSeparator
                          value={p.amountPaid / 100}
                        />
                      </AppFlexbox>
                    </Table.Td>
                  </Table.Tr>
                ))
              )}
            </Table.Tbody>
          </Table>
        </AppStack>
      </AppStack>
    </AppCard>
  ) : (
    <></>
  );
};

AdminVendorPayoutInfoCard.propTypes = { ecomVendorDetails: PropTypes.object };

export default AdminVendorPayoutInfoCard;
