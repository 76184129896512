import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'tabler-icons-react';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';
import ActionableIcon from './ActionableIcon';

const PageView = ({
  title,
  subLabel,
  backPath,
  width,
  buttonAction,
  children
}) => (
  <AppStack
    p={{ base: 8, sm: '16px 16px 80px 16px' }}
    style={{
      flex: 1,
      margin: 'auto',
      width: '100%',
      maxWidth: width || 1050,
      paddingTop: 16,
      gap: 16
    }}
  >
    <AppFlexbox
      style={{
        alignItems: 'start',
        justifyContent: 'space-between',
        gap: 8
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to={backPath}
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>

        <AppStack style={{ gap: 0 }}>
          <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
            <AppText
              style={{
                flex: 1,
                fontSize: 24,
                fontWeight: 700,
                wordBreak: 'break-word'
              }}
            >
              {title}
            </AppText>
          </AppFlexbox>
          {subLabel && (
            <AppText
              style={{
                fontSize: 12,
                lineHeight: '16px',
                color: '#666'
              }}
            >
              {subLabel}
            </AppText>
          )}
        </AppStack>
      </AppFlexbox>

      {buttonAction && (
        <AppFlexbox style={{ alignItems: 'center', gap: 8, marginTop: 5 }}>
          {buttonAction}
        </AppFlexbox>
      )}
    </AppFlexbox>
    {children}
  </AppStack>
);

PageView.propTypes = {
  backPath: PropTypes.string,
  buttonAction: PropTypes.node,
  children: PropTypes.node,
  subLabel: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number
};

export default PageView;
