import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Context as AdminContext } from '../../../providers/AdminContextProvider';
import { adminAnalyticsAndReportsConfig } from '../analytics/adminAnalyticsAndReportsConfig';
import ReportTableListView from '../analytics/ReportTableListView';
import ReportView from '../analytics/ReportView';

const AdminReportsView = () => {
  const { state, generateAdminReport } = useContext(AdminContext);

  return (
    <Routes>
      <Route
        element={
          <ReportTableListView
            baseUrl="/admin"
            reports={adminAnalyticsAndReportsConfig.reports}
          />
        }
        path="/"
      />
      <Route
        element={
          <ReportView
            entityId={1}
            onGenerateReport={(data, onSuccess, onError) =>
              generateAdminReport(data, onSuccess, onError)
            }
            reportConfigs={adminAnalyticsAndReportsConfig.reports}
            reportResult={state.ecomAdminReport.value}
            reportViewType="admin"
            urlBase="/admin"
          />
        }
        path="/:uuid"
      />
      <Route element={<Navigate replace to="/admin/reports" />} path="*" />
    </Routes>
  );
};

export default AdminReportsView;
