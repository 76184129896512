import React, { useContext, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeft } from 'tabler-icons-react';
import { Divider, Skeleton } from '@mantine/core';
import dayjs from 'dayjs';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import ActionableIcon from '../../common/ActionableIcon';
import AppCard from '../../common/AppCard';
import { triggerNotification } from '../../../helpers/notification';
import { formatUtcDate } from '../../../helpers/format';
import OrderStatsCard from '../orders/OrderStatsCard';
import OrderSummaryCard from '../orders/OrderSummaryCard';
import OrderMerchantInfoCard from '../orders/OrderMerchantInfoCard';
import VendorMerchantTimeline from './VendorMerchantTimeline';

const VendorMerchantView = () => {
  const hasFetched = useRef(false);
  const { pkEcomStore } = useParams();
  const { isTabletOrSmaller, isDesktopOrSmaller } = useMediaQueryIndex();
  const { state: authState } = useContext(AuthContext);
  const { state, fetchVendorStore } = useContext(VendorContext);

  const loading = !hasFetched.current || state.ecomStore.loading;
  const ecomStoreInfo =
    state.ecomStore.value &&
    pkEcomStore === state.ecomStore.value.ecomStore.pkEcomStore.toString()
      ? state.ecomStore.value
      : {};
  const { ecomStore, lastOrderPlaced } = ecomStoreInfo;

  useEffect(() => {
    if (authState.pkEcomVendor && pkEcomStore) {
      fetchVendorStore(
        authState.pkEcomVendor,
        { pkEcomStore },
        null,
        (error) => {
          triggerNotification(error);
        }
      );
      hasFetched.current = true;
    }
  }, [authState.pkEcomVendor, pkEcomStore]);

  return !loading && ecomStore ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to="/"
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 0 }}>
          <AppText
            style={{
              flex: 1,
              fontSize: 24,
              fontWeight: 700,
              wordBreak: 'break-word'
            }}
          >
            {ecomStore.name}
          </AppText>
          <AppText
            style={{
              fontSize: 12,
              lineHeight: '16px',
              color: '#666'
            }}
          >
            Purchasing since{' '}
            {dayjs(formatUtcDate(ecomStore.firstPurchaseDate)).format(
              'MMMM D, YYYY'
            )}
          </AppText>
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderStatsCard
            data={[
              {
                label: 'Total spent',
                value: ecomStore.totalSpent / 100
              },
              {
                label: 'Average order value',
                value: ecomStore.totalSpent / ecomStore.ecomOrderCount / 100
              },
              {
                label: 'Customers',
                value: ecomStore.ecomCustomerCount,
                type: 'string'
              },
              {
                label: 'Orders',
                value: ecomStore.ecomOrderCount,
                type: 'string'
              }
            ]}
          />
          <OrderSummaryCard
            ecomOrder={lastOrderPlaced}
            isMerchantView
            isVendorOrder
            urlBase="/vendor/orders"
          />
          <VendorMerchantTimeline ecomStore={ecomStore} />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderMerchantInfoCard ecomStore={ecomStore} />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to="/"
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 5 }}>
          <Skeleton height={36} width={200} />
          <Skeleton height={14} width={150} />
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderStatsCard loading />

          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 16, padding: 16 }}>
                <Skeleton height={22} width={150} />
                <AppStack style={{ gap: 8 }}>
                  <AppFlexbox
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppFlexbox style={{ alignItems: 'center', gap: 8 }}>
                      <Skeleton height={22} width={50} />
                      <Skeleton height={22} radius={100} width={120} />
                    </AppFlexbox>

                    <Skeleton height={22} width={75} />
                  </AppFlexbox>
                  <Skeleton height={14} width={150} />
                </AppStack>

                <AppStack style={{ gap: 8, marginTop: 8 }}>
                  <AppFlexbox
                    style={{
                      gap: 16,
                      alignItems: 'start',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppFlexbox style={{ gap: 16 }}>
                      <Skeleton height={60} width={60} />
                      <AppStack style={{ flex: 1, gap: 5 }}>
                        <Skeleton height={22} width={150} />
                        <Skeleton height={12} width={80} />
                      </AppStack>
                    </AppFlexbox>

                    <Skeleton height={22} width={80} />
                  </AppFlexbox>
                </AppStack>
              </AppStack>
              <Divider />
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'end',
                  padding: 16,
                  gap: 16
                }}
              >
                <Skeleton height={34} width={108} />
              </AppFlexbox>
            </AppStack>
          </AppCard>
        </AppStack>

        <AppStack
          style={{
            flex: 1,
            maxWidth: isDesktopOrSmaller ? 'unset' : 350
          }}
        >
          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack
              style={{
                gap: 0,
                flexDirection:
                  !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
              }}
            >
              <AppStack
                style={{
                  flex: 1,
                  padding: 16,
                  gap: 12
                }}
              >
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={20} width={90} />
                </AppFlexbox>
                <AppStack style={{ gap: 5 }}>
                  <Skeleton height={16} width={120} />
                  <Skeleton height={16} width={140} />
                </AppStack>
              </AppStack>

              <Divider
                orientation={
                  !isTabletOrSmaller && isDesktopOrSmaller
                    ? 'vertical'
                    : 'horizontal'
                }
              />
              <AppStack style={{ flex: 1, padding: 16, gap: 12 }}>
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={20} width={120} />
                </AppFlexbox>
                <AppStack style={{ gap: 5 }}>
                  <Skeleton height={16} width={130} />
                  <Skeleton height={16} width={150} />
                  <Skeleton height={16} width={80} />
                </AppStack>
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

export default VendorMerchantView;
