import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mantine/core';
import { AlertTriangle } from 'tabler-icons-react';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import AppCard from '../../common/AppCard';
import AppStack from '../../common/AppStack';

const VendorCatalogResourceUnavailable = ({ notFound }) => {
  const { state: authState } = useContext(AuthContext);
  const { state: helperState, toggleVendorCatalogDemoMode } = useContext(
    HelperContext
  );
  const { isDemoMode } = helperState.vendorCatalog;

  return (
    <AppCard style={{ padding: '40px 16px' }}>
      <AppFlexbox style={{ justifyContent: 'center' }}>
        <AppStack style={{ gap: 12 }}>
          <AppFlexbox>
            <AppCard shadow="lg" style={{ padding: 8 }} withBorder>
              <AlertTriangle color="#666" size={28} />
            </AppCard>
          </AppFlexbox>

          {notFound ? (
            <AppStack style={{ gap: 0 }}>
              <AppText style={{ fontSize: 24, fontWeight: 500 }}>
                Not found
              </AppText>
              <AppText style={{ fontSize: 14, color: '#666' }}>
                We couldn’t locate what you were looking for. Please check your
                search or try again later.
              </AppText>
            </AppStack>
          ) : (
            <>
              <AppStack style={{ gap: 0 }}>
                <AppText style={{ fontSize: 24, fontWeight: 500 }}>
                  Did you mean {isDemoMode ? 'live' : 'demo'} mode?
                </AppText>
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  We couldn't locate what you were looking for in{' '}
                  {isDemoMode ? 'demo' : 'live'} mode, but it is available in{' '}
                  {isDemoMode ? 'live' : 'demo'} mode.
                </AppText>
              </AppStack>
              <Button
                color="dark"
                onClick={() =>
                  toggleVendorCatalogDemoMode(
                    authState.pkEcomStore,
                    !isDemoMode
                  )
                }
                size="compact-sm"
                style={{ maxWidth: 250 }}
                variant="outline"
              >
                Switch to {isDemoMode ? 'live' : 'demo'} mode
              </Button>
            </>
          )}
        </AppStack>
      </AppFlexbox>
    </AppCard>
  );
};

VendorCatalogResourceUnavailable.propTypes = { notFound: PropTypes.bool };

export default VendorCatalogResourceUnavailable;
