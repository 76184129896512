import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import StoreOrderTable from './StoreOrderTable';
import StoreOrderViews from '../orders/StoreOrderViews';

const StoreGroupOrdersView = () => (
  <Routes>
    <Route
      element={
        <StoreOrderViews baseUrl="/merchant/group-orders" isBulkOrders />
      }
      path="/:pkEcomOrder/*"
    />
    <Route element={<StoreOrderTable isBulkOrders />} path="/" />
    <Route
      element={<Navigate replace to="/merchant/group-orders" />}
      path="*"
    />
  </Routes>
);

export default StoreGroupOrdersView;
