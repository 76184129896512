import React from 'react';
import { Button, Divider, Image } from '@mantine/core';
import { ArrowLeft, ArrowRight } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import ProductInputDisplay from '../products/ProductInputDisplay';

const StoreProductInputAnswerForm = ({
  pkEcomStore,
  ecomStoreProducts,
  onChangeProducts,
  selectedUuid,
  onChangeSelectedUuid
}) => {
  const selectedIndex = ecomStoreProducts.findIndex(
    (p) => p.uuid === selectedUuid
  );
  const selectedProduct = ecomStoreProducts[selectedIndex];

  return (
    <AppStack>
      <AppText
        style={{
          textAlign: 'center',
          fontWeight: 500,
          fontSize: 14,
          color: '#666'
        }}
      >
        The following{' '}
        {ecomStoreProducts.length === 1 ? 'product has' : 'products have'}{' '}
        optional inputs that you can choose to autopopulate. If selected, these
        values will ensure that values are correctly assigned for every customer
        checkout. If you prefer not to use this feature, simply leave the inputs
        blank.
      </AppText>

      <Divider />

      <AppStack style={{ gap: 5 }}>
        <AppFlexbox
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <AppText style={{ fontSize: 16, fontWeight: 500 }}>Product</AppText>
          {ecomStoreProducts.length > 1 && (
            <AppFlexbox style={{ gap: 8 }}>
              ({selectedIndex + 1}/{ecomStoreProducts.length})
              <Button.Group>
                <Button
                  color="dark"
                  onClick={() => {
                    const prev = ecomStoreProducts[selectedIndex - 1];
                    if (prev) {
                      onChangeSelectedUuid(prev.uuid);
                    }
                    else {
                      onChangeSelectedUuid(
                        ecomStoreProducts[ecomStoreProducts.length - 1].uuid
                      );
                    }
                  }}
                  size="compact-sm"
                  variant="outline"
                >
                  <ArrowLeft size={18} />
                </Button>
                <Button
                  color="dark"
                  onClick={() => {
                    const next = ecomStoreProducts[selectedIndex + 1];
                    if (next) {
                      onChangeSelectedUuid(next.uuid);
                    }
                    else {
                      onChangeSelectedUuid(ecomStoreProducts[0].uuid);
                    }
                  }}
                  size="compact-sm"
                  variant="outline"
                >
                  <ArrowRight size={18} />
                </Button>
              </Button.Group>
            </AppFlexbox>
          )}
        </AppFlexbox>
        <AppCard radius="md" shadow="none" style={{ padding: 0 }} withBorder>
          <AppFlexbox style={{ gap: 0, alignItems: 'center' }}>
            <AppStack style={{ height: 90, width: 90, padding: 8 }}>
              <Image
                fit="contain"
                src={selectedProduct?.previewImage}
                style={{ height: '100%', width: '100%' }}
              />
            </AppStack>
            <Divider orientation="vertical" />
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppStack style={{ padding: 8, gap: 0 }}>
                <AppText style={{ fontSize: 14, fontWeight: 700 }}>
                  {selectedProduct?.name}
                </AppText>
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  {selectedProduct?.ecomVendorProductVariantCount} variants
                </AppText>
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  by {selectedProduct?.vendorName}
                </AppText>
              </AppStack>
            </AppFlexbox>
          </AppFlexbox>
        </AppCard>
      </AppStack>

      {selectedProduct?.ecomVendorProductInputs.map((i) => {
        const selectedAnswer = selectedProduct.ecomStoreProductInputAnswers?.find(
          (a) => a.fkEcomVendorProductInput === i.pkEcomVendorProductInput
        );

        return (
          <ProductInputDisplay
            key={i.pkEcomVendorProductInput}
            ecomVendorProductInput={i}
            onChange={(v) => {
              onChangeProducts(
                ecomStoreProducts.map((p) => {
                  if (p.uuid === selectedProduct.uuid) {
                    return {
                      ...p,
                      ecomStoreProductInputAnswers: [
                        ...p.ecomStoreProductInputAnswers.filter(
                          (a) =>
                            a.fkEcomVendorProductInput !==
                            i.pkEcomVendorProductInput
                        ),
                        {
                          fkEcomVendorProductInput: i.pkEcomVendorProductInput,
                          value: v
                        }
                      ],
                      hasChanges: true
                    };
                  }
                  return p;
                })
              );
            }}
            pkEcomStore={pkEcomStore}
            value={selectedAnswer ? selectedAnswer.value : ''}
          />
        );
      })}
    </AppStack>
  );
};

StoreProductInputAnswerForm.propTypes = {
  ecomStoreProducts: PropTypes.array,
  onChangeProducts: PropTypes.func,
  onChangeSelectedUuid: PropTypes.func,
  pkEcomStore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedUuid: PropTypes.string
};

export default StoreProductInputAnswerForm;
