/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mantine/core';
import { Link } from 'react-router-dom';
import { Carousel } from '@mantine/carousel';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';
import WsSectionContainer from '../WsSectionContainer';
import {
  HEADING_SIZE_STYLE_ENUM,
  IMAGE_DISPLAY_ENUM,
  QUICK_ADD_STYLE_ENUM,
  VIEW_ALL_STYLE_ENUM
} from './wsFeaturedCollectionConfig';
import AppFlexbox from '../../../../common/AppFlexbox';
import HoverableAnchor from '../../../../common/HoverableAnchor';
import WsProductCard from '../productGrid/WsProductCard';
import { sterilizeUrlHandle } from '../../../../../helpers/format';

const WsFeaturedCollection = ({
  config,
  colorSchemes,
  themeConfig,
  baseStoreUrl,
  onNavigate,
  ecomStoreTheme,
  mediaQueries,
  ...rest
}) => {
  const { state } = useContext(StoreUserContext);
  const { isTabletOrSmaller } = mediaQueries;

  const ecomStoreCollection = state.ecomStoreCollectionHistory.value.find(
    (c) =>
      c.pkEcomStoreCollection.toString() ===
      config.ecomStoreCollection?.toString()
  );
  const vendorProducts = config.ecomStoreCollection
    ? ecomStoreCollection?.ecomStoreCollectionProducts.map(
        (p) => p.ecomStoreProduct
      ) ?? []
    : state.paginatedEcomStoreCollectionProducts.data;

  const loading = config.ecomStoreCollection
    ? !ecomStoreCollection || state.ecomStoreCollectionHistory.loading
    : !state.paginatedEcomStoreCollectionProducts.initialized ||
      state.paginatedEcomStoreCollectionProducts.loading;

  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const showSecondImageOnHover = config.showSecondImageOnHover === 'true';
  const showVendorName = config.showVendorName === 'true';
  const showVariantsSeparately = config.showVariantsSeparately === 'true';
  const variantSelector = config.seperateVariantsSelector;
  const maxDisplayed = config.maximumProductsDisplayed * 1 ?? 25;
  const squareRatio = config.imageRatio === IMAGE_DISPLAY_ENUM.SQUARE;
  const isQuickAddEnabled =
    config.quickAddStyle === QUICK_ADD_STYLE_ENUM.STANDARD;

  const collectionNotFound = state.ecomStoreCollections.idsNotFound.includes(
    config.ecomStoreCollection?.toString()
  );

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      mediaQueries={mediaQueries}
      themeConfig={themeConfig}
      withPadding
      {...rest}
    >
      <AppStack
        style={{
          flex: 1,
          justifyContent: 'space-between',
          margin: 'auto',
          width: '100%',
          gap: 8
        }}
      >
        {config.headingText && (
          <AppText
            style={{
              fontWeight: 600,
              fontSize:
                config.headingSize === HEADING_SIZE_STYLE_ENUM.HUGE
                  ? 50
                  : config.headingSize === HEADING_SIZE_STYLE_ENUM.EXTRA_LARGE
                  ? 44
                  : config.headingSize === HEADING_SIZE_STYLE_ENUM.LARGE
                  ? 36
                  : config.headingSize === HEADING_SIZE_STYLE_ENUM.MEDIUM
                  ? 28
                  : 22
            }}
          >
            {config.headingText}
          </AppText>
        )}
        {config.descriptionText && (
          <AppText style={{ fontSize: 16 }}>
            {config.showCollectionDescription === 'true'
              ? ecomStoreCollection?.description
              : config.descriptionText}
          </AppText>
        )}
        <AppStack
          style={{
            flex: 1,
            justifyContent: 'space-between',
            margin: 'auto',
            width: '100%',
            gap: 0
          }}
        >
          {collectionNotFound || (!loading && vendorProducts.length === 0) ? (
            <AppStack style={{ alignItems: 'center', padding: 40 }}>
              <AppText
                style={{
                  color: colorScheme.textColor,
                  fontSize: 18,
                  fontWeight: '500'
                }}
              >
                No products found
              </AppText>
            </AppStack>
          ) : (isTabletOrSmaller && config.mobileCarousel === 'true') ||
            (!isTabletOrSmaller && config.desktopCarousel === 'true') ? (
            <Carousel
              align={isTabletOrSmaller ? 'center' : 'start'}
              controlSize={40}
              draggable
              loop={false}
              slideGap={16}
              slideSize={`${
                (isTabletOrSmaller ? 85 : 100) /
                (isTabletOrSmaller
                  ? config.mobileColumnCount * 1 ?? 1
                  : config.desktopColumnCount * 1 ?? 6)
              }%`}
              slidesToScroll={
                isTabletOrSmaller
                  ? config.mobileColumnCount * 1 ?? 1
                  : config.desktopColumnCount * 1 ?? 6
              }
              withControls={!isTabletOrSmaller}
              withIndicators={false}
            >
              {loading
                ? Array.from(Array(config.desktopColumnCount * 1 ?? 6))
                    .slice(0, maxDisplayed)
                    .map((x, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Carousel.Slide key={i}>
                        <WsProductCard
                          baseStoreUrl={baseStoreUrl}
                          colorScheme={colorScheme}
                          isQuickAddEnabled={isQuickAddEnabled}
                          onNavigate={onNavigate}
                          portraitRatio={!squareRatio}
                          showSecondImageOnHover={showSecondImageOnHover}
                          showVendorName={showVendorName}
                        />
                      </Carousel.Slide>
                    ))
                : vendorProducts.slice(0, maxDisplayed).map((p) => (
                    <Carousel.Slide key={p.pkEcomStoreProduct}>
                      <WsProductCard
                        baseStoreUrl={baseStoreUrl}
                        colorScheme={colorScheme}
                        ecomStoreProduct={p}
                        isQuickAddEnabled={isQuickAddEnabled}
                        onNavigate={onNavigate}
                        portraitRatio={!squareRatio}
                        showSecondImageOnHover={showSecondImageOnHover}
                        showVendorName={showVendorName}
                      />
                    </Carousel.Slide>
                  ))}
            </Carousel>
          ) : (
            <AppStack style={{}}>
              <Grid
                columns={
                  isTabletOrSmaller
                    ? config.mobileColumnCount * 1 ?? 1
                    : config.desktopColumnCount * 1 ?? 6
                }
              >
                {loading
                  ? Array.from(Array(config.desktopColumnCount * 1 ?? 6))
                      .slice(0, maxDisplayed)
                      .map((x, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Grid.Col key={i} span={1}>
                          <WsProductCard
                            baseStoreUrl={baseStoreUrl}
                            colorScheme={colorScheme}
                            isQuickAddEnabled={isQuickAddEnabled}
                            onNavigate={onNavigate}
                            portraitRatio={!squareRatio}
                            showSecondImageOnHover={showSecondImageOnHover}
                            showVendorName={showVendorName}
                          />
                        </Grid.Col>
                      ))
                  : vendorProducts.slice(0, maxDisplayed).map((p) => (
                      <Grid.Col key={p.pkEcomStoreProduct} span={1}>
                        <WsProductCard
                          baseStoreUrl={baseStoreUrl}
                          colorScheme={colorScheme}
                          ecomStoreProduct={p}
                          isQuickAddEnabled={isQuickAddEnabled}
                          onNavigate={onNavigate}
                          portraitRatio={!squareRatio}
                          showSecondImageOnHover={showSecondImageOnHover}
                          showVendorName={showVendorName}
                        />
                      </Grid.Col>
                    ))}
              </Grid>
            </AppStack>
          )}

          {config.enableViewAll === 'true' &&
            (ecomStoreCollection
              ? ecomStoreCollection.ecomStoreCollectionProductCount
              : state.paginatedEcomStoreCollectionProducts.totalCount) >
              maxDisplayed && (
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 20
                }}
              >
                {config.viewAllButtonStyle === VIEW_ALL_STYLE_ENUM.LINK ? (
                  <HoverableAnchor
                    component={Link}
                    onClick={onNavigate}
                    style={{ color: colorScheme.textColor }}
                    to={
                      ecomStoreCollection
                        ? `${baseStoreUrl}/collections/${sterilizeUrlHandle(
                            ecomStoreCollection.name
                          )}`
                        : `${baseStoreUrl}/products`
                    }
                  >
                    View all
                  </HoverableAnchor>
                ) : config.viewAllButtonStyle ===
                  VIEW_ALL_STYLE_ENUM.OUTLINE_BUTTON ? (
                  <Button
                    color={colorScheme.outlineButtonColor}
                    component={Link}
                    onClick={onNavigate}
                    radius={0}
                    size="md"
                    to={
                      ecomStoreCollection
                        ? `${baseStoreUrl}/collections/${sterilizeUrlHandle(
                            ecomStoreCollection.name
                          )}`
                        : `${baseStoreUrl}/products`
                    }
                    variant="outline"
                  >
                    View all
                  </Button>
                ) : (
                  <Button
                    color={colorScheme.solidButtonColor}
                    component={Link}
                    onClick={onNavigate}
                    radius={0}
                    size="md"
                    style={{ color: colorScheme.solidButtonLabel }}
                    to={
                      ecomStoreCollection
                        ? `${baseStoreUrl}/collections/${sterilizeUrlHandle(
                            ecomStoreCollection.name
                          )}`
                        : `${baseStoreUrl}/products`
                    }
                    variant="filled"
                  >
                    View all
                  </Button>
                )}
              </AppFlexbox>
            )}
        </AppStack>
      </AppStack>
    </WsSectionContainer>
  );
};

WsFeaturedCollection.propTypes = {
  baseStoreUrl: PropTypes.string,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  themeConfig: PropTypes.object
};

export default WsFeaturedCollection;
