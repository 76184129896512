import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import ReportTableListView from '../analytics/ReportTableListView';
import ReportView from '../analytics/ReportView';
import { vendorAnalyticsAndReportsConfig } from '../analytics/vendorAnalyticsAndReportsConfig';

const VendorReportsView = () => {
  const { state: authState } = useContext(AuthContext);
  const { state, generateVendorReport } = useContext(VendorContext);

  return (
    <Routes>
      <Route
        element={
          <ReportTableListView
            baseUrl="/vendor"
            reports={vendorAnalyticsAndReportsConfig.reports}
          />
        }
        path="/"
      />
      <Route
        element={
          <ReportView
            entityId={authState.pkEcomVendor}
            onGenerateReport={(data, onSuccess, onError) =>
              generateVendorReport(
                authState.pkEcomVendor,
                data,
                onSuccess,
                onError
              )
            }
            reportConfigs={vendorAnalyticsAndReportsConfig.reports}
            reportResult={state.ecomVendorReport.value}
            reportViewType="vendor"
            urlBase="/vendor"
          />
        }
        path="/:uuid"
      />
      <Route element={<Navigate replace to="/vendor/reports" />} path="*" />
    </Routes>
  );
};

export default VendorReportsView;
