/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  Divider,
  Loader,
  NumberFormatter,
  Select,
  Skeleton,
  TextInput,
  Tooltip
} from '@mantine/core';
import { Lock, Minus } from 'tabler-icons-react';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import ResponsiveWebstoreDrawer from './ResponsiveWebstoreDrawer';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';
import { Context as StoreUserContext } from '../../../providers/StoreUserContextProvider';
import { useCartController } from '../../../helpers/webstoreCheckoutHelper';
import CartDrawerLineItem from './CartDrawerLineItem';
import { triggerNotification } from '../../../helpers/notification';
import WsUnavailableProductsAlert from './sections/checkoutContent/WsUnavailableProductsAlert';

const CartDrawer = ({
  onNavigate,
  baseStoreUrl,
  ecomStoreTheme,
  mediaQueries,
  editModeEnabled,
  pathname
}) => {
  const {
    isGuest,
    ecomStoreCart,
    isCartFetching,
    checkoutStoreCart,
    removeStoreProducts,
    removeUnavailableStoreCartProducts,
    updateStoreCartProducts,
    onShowCartProductCustomization
  } = useCartController();
  const {
    state: helperState,
    toggleWebstoreCheckoutSummaryDrawer
  } = useContext(HelperContext);
  const { state, fetchEcomStoreRosterPlayers } = useContext(StoreUserContext);
  const [checkoutState, setCheckoutState] = useState({
    email: '',
    fkEcomStoreRosterPlayer: null,
    loading: false,
    removing: false,
    updating: false
  });
  const { isLargeMobileOrSmaller } = mediaQueries;
  const loading = isCartFetching || !ecomStoreCart;
  const productCount =
    ecomStoreCart?.checkoutCart.lineItems.reduce((r, c) => r + c.quantity, 0) ??
    0;
  const cartChanging = checkoutState.updating || checkoutState.removing;
  const ecomStoreRosterPlayers = state.ecomStoreRosterPlayers.value;

  useEffect(() => {
    if (ecomStoreCart) {
      setCheckoutState({
        ...checkoutState,
        fkEcomStoreRosterPlayer: ecomStoreCart.fkEcomStoreRosterPlayer?.toString()
      });
    }
  }, [ecomStoreCart]);

  useEffect(() => {
    if (
      helperState.webstore.checkoutSummaryDrawer.isOpen &&
      !state.ecomStoreRosterPlayers.hasFetched
    ) {
      fetchEcomStoreRosterPlayers(ecomStoreTheme.ecomStore.pkEcomStore);
    }
  }, [helperState.webstore.checkoutSummaryDrawer.isOpen]);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    onClose();
  }, [pathname]);

  const onClose = () => {
    toggleWebstoreCheckoutSummaryDrawer({ isOpen: false });
  };

  const submitCheckout = () => {
    setCheckoutState({
      ...checkoutState,
      loading: true
    });
    checkoutStoreCart(
      ecomStoreTheme.ecomStore.pkEcomStore,
      {
        email: checkoutState.email,
        fkEcomStoreRosterPlayer: checkoutState.fkEcomStoreRosterPlayer?.toString()
      },
      ({ sessionUrl }) => {
        window.location.href = sessionUrl;
      },
      (error) => {
        triggerNotification(error);
        setCheckoutState({
          ...checkoutState,
          loading: false
        });
      }
    );
  };

  const onRemoveProduct = (
    ecomStoreCartProducts,
    onSuccessCallback,
    onErrorCallback
  ) => {
    setCheckoutState({
      ...checkoutState,
      removing: true
    });
    removeStoreProducts(
      ecomStoreTheme.ecomStore.pkEcomStore,
      ecomStoreCartProducts,
      (data) => {
        setCheckoutState({
          ...checkoutState,
          removing: false
        });
        if (onSuccessCallback) {
          onSuccessCallback(data);
        }
      },
      (error) => {
        triggerNotification(error);
        setCheckoutState({
          ...checkoutState,
          removing: false
        });
        if (onErrorCallback) {
          onErrorCallback(error);
        }
      }
    );
  };

  const onUpdateProducts = (
    ecomStoreCartProducts,
    onSuccessCallback,
    onErrorCallback
  ) => {
    setCheckoutState({
      ...checkoutState,
      updating: true
    });
    updateStoreCartProducts(
      ecomStoreTheme.ecomStore.pkEcomStore,
      { ecomStoreCartProducts },
      (data) => {
        setCheckoutState({
          ...checkoutState,
          removing: false
        });
        if (onSuccessCallback) {
          onSuccessCallback(data);
        }
      },
      (error) => {
        triggerNotification(error);
        setCheckoutState({
          ...checkoutState,
          removing: false
        });
        if (onErrorCallback) {
          onErrorCallback(error);
        }
      }
    );
  };

  const onRemoveUnavailableProducts = () => {
    setCheckoutState({
      ...checkoutState,
      updating: true
    });
    removeUnavailableStoreCartProducts(
      ecomStoreTheme.ecomStore.pkEcomStore,
      () => {
        setCheckoutState({
          ...checkoutState,
          removing: false
        });
        triggerNotification('Cart updated!', 'success');
      },
      (error) => {
        triggerNotification(error);
        setCheckoutState({
          ...checkoutState,
          removing: false
        });
      }
    );
  };

  return (
    <ResponsiveWebstoreDrawer
      closeIcon={
        <Tooltip label="Close" position="bottom" withArrow>
          <AppStack>
            <Minus size={18} />
          </AppStack>
        </Tooltip>
      }
      editModeEnabled={editModeEnabled}
      footerContent={
        loading ? (
          <AppStack
            style={{
              padding: 16,
              gap: 16,
              backgroundColor: '#000',
              color: '#FFF',
              opacity: 0.8
            }}
          >
            <AppFlexbox
              style={{
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Skeleton height={24} width={80} />
              <Skeleton height={24} width={60} />
            </AppFlexbox>
            <AppFlexbox
              style={{
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Skeleton height={24} width={80} />
              <Skeleton height={24} width={60} />
            </AppFlexbox>
            <AppFlexbox
              style={{
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Skeleton height={24} width={80} />
              <Skeleton height={24} width={60} />
            </AppFlexbox>
            <Skeleton height={48} width="100%" />
            <Divider
              color="#FFF"
              labelPosition="center"
              style={{ margin: '5px 0px' }}
            />
            <AppStack style={{ gap: 10 }}>
              <Skeleton height={48} width="100%" />
              <AppFlexbox style={{ justifyContent: 'center' }}>
                <Skeleton height={20} width="80%" />
              </AppFlexbox>
            </AppStack>
          </AppStack>
        ) : (
          <AppStack
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              submitCheckout();
            }}
            style={{
              padding: 16,
              gap: 16,
              backgroundColor: '#000',
              color: '#FFF',
              opacity: 0.8
            }}
          >
            <AppStack style={{ gap: 10 }}>
              {ecomStoreCart?.checkoutCart.subtotalInCents !==
                ecomStoreCart.checkoutCart.totalInCents && (
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap'
                  }}
                >
                  <AppText style={{ fontSize: 20, fontWeight: 500 }}>
                    Subtotal
                  </AppText>
                  {cartChanging ? (
                    <Skeleton height={24} width={60} />
                  ) : (
                    <AppText style={{ fontSize: 20, fontWeight: 500 }}>
                      <NumberFormatter
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={ecomStoreCart.checkoutCart.subtotalInCents / 100}
                      />
                    </AppText>
                  )}
                </AppFlexbox>
              )}

              {!cartChanging &&
                ecomStoreCart?.checkoutCart.discountTotalInCents > 0 && (
                  <AppFlexbox
                    style={{
                      justifyContent: 'space-between',
                      flexWrap: 'nowrap'
                    }}
                  >
                    <AppText style={{ fontSize: 20, fontWeight: 500 }}>
                      Discount
                    </AppText>
                    <AppText style={{ fontSize: 20, fontWeight: 500 }}>
                      <NumberFormatter
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={
                          (ecomStoreCart.checkoutCart.discountTotalInCents /
                            100) *
                          -1
                        }
                      />
                    </AppText>
                  </AppFlexbox>
                )}
              {!cartChanging &&
                ecomStoreCart?.checkoutCart.taxTotalInCents > 0 && (
                  <AppFlexbox
                    style={{
                      justifyContent: 'space-between',
                      flexWrap: 'nowrap'
                    }}
                  >
                    <AppText style={{ fontSize: 20, fontWeight: 500 }}>
                      Taxes
                    </AppText>
                    <AppText style={{ fontSize: 20, fontWeight: 500 }}>
                      <NumberFormatter
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={ecomStoreCart.checkoutCart.taxTotalInCents / 100}
                      />
                    </AppText>
                  </AppFlexbox>
                )}
              {!cartChanging &&
                ecomStoreCart?.checkoutCart.customerServiceFeeTotalInCents >
                  0 && (
                  <AppFlexbox
                    style={{
                      justifyContent: 'space-between',
                      flexWrap: 'nowrap'
                    }}
                  >
                    <AppText style={{ fontSize: 20, fontWeight: 500 }}>
                      Service Fee
                    </AppText>
                    <AppText style={{ fontSize: 20, fontWeight: 500 }}>
                      <NumberFormatter
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={
                          ecomStoreCart.checkoutCart
                            .customerServiceFeeTotalInCents / 100
                        }
                      />
                    </AppText>
                  </AppFlexbox>
                )}

              {ecomStoreCart?.checkoutCart.tieredPricingFeeInCents > 0 && (
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap'
                  }}
                >
                  <AppText style={{ fontSize: 20, fontWeight: 500 }}>
                    Service Fee
                  </AppText>
                  {cartChanging ? (
                    <Skeleton height={24} width={60} />
                  ) : (
                    <AppText style={{ fontSize: 20, fontWeight: 500 }}>
                      <NumberFormatter
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={
                          ecomStoreCart.checkoutCart.tieredPricingFeeInCents /
                          100
                        }
                      />
                    </AppText>
                  )}
                </AppFlexbox>
              )}

              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap'
                }}
              >
                <AppText style={{ fontSize: 20, fontWeight: 500 }}>
                  Shipping
                </AppText>
                {cartChanging ? (
                  <Skeleton height={24} width={60} />
                ) : (
                  <AppText
                    style={{ fontSize: 20, fontWeight: 500, color: 'green' }}
                  >
                    Free
                  </AppText>
                )}
              </AppFlexbox>

              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap',
                  alignItems: 'center'
                }}
              >
                <AppText style={{ fontSize: 20, fontWeight: 500 }}>
                  Total
                </AppText>
                {cartChanging ? (
                  <Skeleton height={24} width={60} />
                ) : (
                  <AppText style={{ fontSize: 20, fontWeight: 500 }}>
                    <NumberFormatter
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={ecomStoreCart.checkoutCart.totalInCents / 100}
                    />
                  </AppText>
                )}
              </AppFlexbox>
              {isGuest && ecomStoreCart?.checkoutCart.lineItems.length > 0 && (
                <TextInput
                  autoComplete="on"
                  disabled={cartChanging || loading || checkoutState.loading}
                  onChange={(e) =>
                    setCheckoutState({
                      ...checkoutState,
                      email: e.currentTarget.value
                    })
                  }
                  placeholder="Email address"
                  required
                  type="email"
                  value={checkoutState.email}
                />
              )}
              <Button
                id="checkout-order-summary-submit-drawer"
                style={{ display: 'none' }}
                type="submit"
              />

              <Button
                color="blue"
                disabled={
                  !!ecomStoreCart?.latestVersionEcomStoreCartProducts ||
                  cartChanging ||
                  ecomStoreCart.checkoutCart.lineItems.length === 0
                }
                leftSection={<Lock />}
                loading={checkoutState.loading}
                onClick={() => {
                  document
                    .getElementById('checkout-order-summary-submit-drawer')
                    .click();
                }}
                size="lg"
                type="button"
                variant="filled"
              >
                Continue to Checkout
              </Button>
            </AppStack>
          </AppStack>
        )
      }
      mediaQueries={mediaQueries}
      onClose={onClose}
      opened={helperState.webstore.checkoutSummaryDrawer.isOpen}
      position="right"
      size={isLargeMobileOrSmaller ? '100%' : 450}
      styles={{}}
      title={
        <AppFlexbox style={{ alignItems: 'center', gap: 10 }}>
          <AppText style={{ fontSize: 28, fontWeight: 500 }}>Cart</AppText>
          {checkoutState.updating || loading ? (
            <Loader color="blue" size={28} />
          ) : (
            <Badge circle color="blue" radius="xl" size={28} variant="filled">
              <AppText style={{ fontSize: 18, fontWeight: 500 }}>
                {productCount}
              </AppText>
            </Badge>
          )}
        </AppFlexbox>
      }
    >
      {!loading &&
        ecomStoreCart?.latestVersionEcomStoreCartProducts &&
        ecomStoreCart?.latestVersionEcomStoreCartProducts.length > 0 && (
          <WsUnavailableProductsAlert
            baseStoreUrl={baseStoreUrl}
            ecomStoreCartProducts={ecomStoreCart.ecomStoreCartProducts}
            isDrawerView
            latestVersionEcomStoreCartProducts={
              ecomStoreCart.latestVersionEcomStoreCartProducts
            }
            mediaQueries={mediaQueries}
            onNavigate={onNavigate}
            onRemoveUnavailableProducts={onRemoveUnavailableProducts}
            submitting={checkoutState.loading}
          />
        )}

      <AppStack
        style={{
          flex: 1,
          gap: 16,
          flexDirection: 'column',
          height: '100%',
          padding: 16
        }}
      >
        {ecomStoreCart &&
          ecomStoreCart.checkoutCart.lineItems.length > 0 &&
          ecomStoreRosterPlayers.length > 0 && (
            <Select
              clearable
              data={ecomStoreRosterPlayers.map((p) => ({
                label: p.name,
                value: p.pkEcomStoreRosterPlayer.toString()
              }))}
              description="Select the player that you are fundraising for"
              disabled={cartChanging || loading || checkoutState.loading}
              label="Fundraiser individual"
              onChange={(fkEcomStoreRosterPlayer) => {
                setCheckoutState({
                  ...checkoutState,
                  fkEcomStoreRosterPlayer
                });
              }}
              searchable
              value={checkoutState.fkEcomStoreRosterPlayer}
            />
          )}
        {!loading &&
          ecomStoreCart.checkoutCart.lineItems.map((lineItem) => {
            const product = ecomStoreCart.ecomStoreCartProducts.find(
              (p) => p.pkEcomStoreCartProduct === lineItem.entityId
            );
            return (
              <React.Fragment key={lineItem.entityId}>
                <CartDrawerLineItem
                  baseStoreUrl={baseStoreUrl}
                  disabled={checkoutState.loading || cartChanging || loading}
                  ecomStoreCartProduct={product}
                  ecomStoreTheme={ecomStoreTheme}
                  latestVersionEcomStoreCartProduct={ecomStoreCart.latestVersionEcomStoreCartProducts?.find(
                    (p) =>
                      p.ecomVendorProductVariant.uuid ===
                      product.ecomStoreProductVariant.ecomVendorProductVariant
                        .uuid
                  )}
                  lineItem={lineItem}
                  onCustomize={() => {
                    onShowCartProductCustomization(
                      ecomStoreCart.ecomStoreCartProducts.find(
                        (p) => p.pkEcomStoreCartProduct === lineItem.entityId
                      )
                    );
                  }}
                  onNavigate={(e) => {
                    onNavigate(e);
                    onClose();
                  }}
                  onRemove={onRemoveProduct}
                  onRemoveUnavailableProducts={onRemoveUnavailableProducts}
                  onUpdate={onUpdateProducts}
                />
                <Divider />
              </React.Fragment>
            );
          })}
      </AppStack>
    </ResponsiveWebstoreDrawer>
  );
};

CartDrawer.propTypes = {
  baseStoreUrl: PropTypes.string,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  logoImageUrl: PropTypes.string,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string
};

export default CartDrawer;
