import React from 'react';
import { Link } from 'react-router-dom';
import { Anchor, Timeline } from '@mantine/core';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import {
  currencyFormat,
  formatUtcDate,
  singularPluralFormat
} from '../../../helpers/format';

const VendorMerchantTimeline = ({ ecomStore }) => {
  const orderTimelineContent = ecomStore
    ? ecomStore.ecomOrderSummaries
        .filter((f) => f.amountPaid > 0)
        .sort((a, b) => new Date(b.purchaseDate) - new Date(a.purchaseDate))
        .map((order) => ({
          title: 'New order placed',
          date: order.purchaseDate,
          content: (
            <AppStack style={{ gap: 8 }}>
              <AppText style={{ fontSize: 14 }}>
                {order.ecomCustomer.user?.name || 'A guest'} placed order{' '}
                <Anchor
                  component={Link}
                  style={{ color: 'dodgerblue' }}
                  to={`/vendor/orders/${order.pkEcomOrder}`}
                >
                  #{order.pkEcomOrder}
                </Anchor>{' '}
                containing{' '}
                {singularPluralFormat(order.itemCount, 'item', 'items')} with a
                total cost of {currencyFormat(order.amountPaid / 100)}.
              </AppText>
              <AppText style={{ fontSize: 13, color: '#666' }}>
                {dayjs(formatUtcDate(order.purchaseDate)).format(
                  'MMMM D, YYYY'
                )}{' '}
                at {dayjs(formatUtcDate(order.purchaseDate)).format('h:mm A')}
              </AppText>
            </AppStack>
          )
        }))
    : [];

  const timelineContent = [...orderTimelineContent].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  return ecomStore ? (
    <AppStack style={{}}>
      <AppFlexbox style={{ padding: '0px 16px' }}>
        <AppText style={{ fontSize: 16, fontWeight: 500 }}>Timeline</AppText>
      </AppFlexbox>

      <AppStack style={{ padding: '0px 16px' }}>
        <Timeline
          active={timelineContent.length}
          bulletSize={16}
          color="dark"
          lineWidth={2}
        >
          {timelineContent.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Timeline.Item key={index} title={item.title}>
              {item.content}
            </Timeline.Item>
          ))}
        </Timeline>
      </AppStack>
    </AppStack>
  ) : (
    <></>
  );
};

VendorMerchantTimeline.propTypes = { ecomStore: PropTypes.object };

export default VendorMerchantTimeline;
