import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminVendorsTableView from './AdminVendorsTableView';
import AdminVendorView from './AdminVendorView';
import AdminVendorPayoutBalanceView from './AdminVendorPayoutBalanceView';

const AdminVendorsView = () => (
  <Routes>
    <Route
      element={<AdminVendorPayoutBalanceView />}
      path="/payout-balance/*"
    />
    <Route element={<AdminVendorView />} path="/:pkEcomVendor/*" />
    <Route element={<AdminVendorsTableView />} path="/" />
    <Route element={<Navigate replace to="/admin/vendors" />} path="*" />
  </Routes>
);

export default AdminVendorsView;
