import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Checkbox, List } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';

const MinimumQuantityConfirmation = ({
  selectedProducts,
  onConfirm,
  isConfirmed,
  disabled,
  defaultAddressSetup
}) => (
  <AppStack style={{ gap: 20 }}>
    <Alert
      color="#eca70a"
      icon={<AlertCircle size={18} />}
      title="Confirm minimum order quantity"
      variant="outline"
    >
      <AppStack style={{ gap: 10 }}>
        <AppText style={{ fontSize: 14 }}>
          Please note that the following products have a minimum order quantity
          set by the vendor. You must reach the vendors minimum order count in
          order for products to be fulfilled.
        </AppText>

        <List withPadding>
          {selectedProducts
            .filter((p) => p.minimumQuantity)
            .map((p) => (
              <List.Item key={p.pkEcomVendorProduct}>
                <AppText style={{ fontSize: 14 }}>
                  {p.name} ({p.minimumQuantity})
                </AppText>
              </List.Item>
            ))}
        </List>

        <AppText style={{ fontSize: 14, fontWeight: 500, marginTop: 10 }}>
          You are responsible for all refunds to customers as a result to orders
          not being fulfilled.
        </AppText>

        {onConfirm && (
          <Checkbox
            checked={isConfirmed}
            disabled={!defaultAddressSetup || disabled}
            label="I understand and agree to the minimum order quantity."
            onChange={onConfirm}
            required
            style={{ marginTop: 5, fontWeight: 500 }}
          />
        )}
      </AppStack>
    </Alert>

    {!defaultAddressSetup && (
      <AppStack style={{ gap: 20, alignItems: 'center' }}>
        <AppText
          style={{ color: '#c40000', fontSize: 14, textAlign: 'center' }}
        >
          Please add a default address before adding group order products to
          your store.
        </AppText>
        <Button
          color="dark"
          component={Link}
          to="/merchant/settings/?modal=address"
          variant="filled"
        >
          Setup default address
        </Button>
      </AppStack>
    )}
  </AppStack>
);

MinimumQuantityConfirmation.propTypes = {
  defaultAddressSetup: PropTypes.bool,
  disabled: PropTypes.bool,
  isConfirmed: PropTypes.bool,
  onConfirm: PropTypes.func,
  selectedProducts: PropTypes.array
};

export default MinimumQuantityConfirmation;
