import React from 'react';
import PropTypes from 'prop-types';
import { CirclePlus } from 'tabler-icons-react';
import { Anchor } from '@mantine/core';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';
import AppStack from './AppStack';
import * as classes from '../../styles/nestedStyles.module.css';

const SettingSelector = ({
  title,
  description,
  descriptionColor,
  icon,
  leftSection,
  leftSectionWidth,
  rightSection,
  withBorder,
  onClick,
  onRemove,
  disabled,
  children,
  ...rest
}) => (
  <AppFlexbox
    className={!disabled ? classes['hover-action-card'] : null}
    onClick={
      onClick
        ? () => {
            if (!disabled) {
              onClick();
            }
          }
        : undefined
    }
    {...rest}
    style={{
      flex: 1,
      padding: 8,
      gap: 8,
      borderRadius: 8,
      backgroundColor: disabled
        ? 'rgba(220, 220, 220, 0.2)'
        : 'rgba(240, 240, 240, 1)',
      cursor: disabled ? 'not-allowed' : onClick ? 'pointer' : 'default',
      alignItems: 'center',
      border: withBorder ? 'solid 1px lightgrey' : 'none',
      ...rest.style
    }}
  >
    <AppStack
      style={{
        border: leftSection ? 'none' : 'dashed 2px #666',
        height: leftSectionWidth ?? 50,
        width: leftSectionWidth ?? 50,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden'
      }}
    >
      {leftSection ?? icon}
    </AppStack>

    <AppFlexbox style={{ flex: 1, alignItems: 'center' }}>
      <AppStack style={{ flex: 1, gap: 0 }}>
        <AppText style={{ fontSize: 14, fontWeight: 500 }}>{title}</AppText>

        {typeof description === 'string' ? (
          <AppText style={{ fontSize: 13, color: descriptionColor ?? '#666' }}>
            {description}
          </AppText>
        ) : (
          description
        )}
      </AppStack>
      {onRemove ? (
        <Anchor
          c="red"
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onRemove();
          }}
          style={{ fontSize: 14 }}
        >
          Remove
        </Anchor>
      ) : (
        !disabled &&
        (rightSection ?? <CirclePlus color="dodgerblue" size={24} />)
      )}
    </AppFlexbox>
    {children}
  </AppFlexbox>
);

SettingSelector.propTypes = {
  children: PropTypes.node,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  descriptionColor: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  leftSection: PropTypes.node,
  leftSectionWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  rightSection: PropTypes.node,
  title: PropTypes.string,
  withBorder: PropTypes.bool
};

export default SettingSelector;
