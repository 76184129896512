import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import VendorProductTable from './VendorProductTable';
import EditProductView from '../products/EditProductView';
import BulkEditVendorProductsView from './BulkEditVendorProductsView';
import EditProductVariantsView from '../products/EditProductVariantsView';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';

const VendorProductsView = () => {
  const { state: authState } = useContext(AuthContext);
  const { fetchVendorCollections } = useContext(VendorContext);

  useEffect(() => {
    if (authState.pkEcomVendor) {
      fetchVendorCollections({ fkEcomVendor: authState.pkEcomVendor });
    }
  }, [authState.pkEcomVendor]);

  return (
    <Routes>
      <Route element={<EditProductView />} path="/new" />
      <Route element={<BulkEditVendorProductsView />} path="/bulk-edit" />
      <Route element={<EditProductView />} path="/:ecomVendorProductUuid" />
      <Route
        element={<EditProductView duplicate />}
        path="/:ecomVendorProductUuid/duplicate"
      />

      <Route
        element={<EditProductVariantsView newVariant />}
        path="/:ecomVendorProductUuid/variants/new"
      />
      <Route
        element={<EditProductVariantsView />}
        path="/:ecomVendorProductUuid/variants/:ecomVendorProductVariantUuid"
      />
      <Route
        element={<EditProductVariantsView duplicate />}
        path="/:ecomVendorProductUuid/variants/:ecomVendorProductVariantUuid/duplicate"
      />
      <Route
        element={<EditProductVariantsView />}
        path="/:ecomVendorProductUuid/variants"
      />
      <Route element={<VendorProductTable />} path="/" />
      <Route element={<Navigate replace to="/vendor/products" />} path="*" />
    </Routes>
  );
};

export default VendorProductsView;
