import React from 'react';
import { ActionIcon, Divider, NumberFormatter, Tooltip } from '@mantine/core';
import { Edit } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import EditProductCard from '../products/EditProductCard';
import { ECOM_PRODUCT_PRICING_TYPE_ENUM } from '../../../config/constants';

const defaultValues = {
  maxVendorPrice: 0,
  minVendorPrice: 0,
  maxServiceFees: 0,
  minServiceFees: 0,
  maxBasePrice: 0,
  minBasePrice: 0,
  maxStorePrice: 0,
  minStorePrice: 0,
  maxRounding: 0,
  minRounding: 0,
  maxProfit: 0,
  minProfit: 0,
  minPricing: { amount: 0, type: '' },
  maxPricing: { amount: 0, type: '' }
};

const StoreProductPricingInfoCard = ({ ecomStoreProduct, onAction }) => {
  const {
    minVendorPrice,
    maxVendorPrice,
    minBasePrice,
    maxBasePrice,
    minStorePrice,
    maxStorePrice,
    maxServiceFees,
    minServiceFees,
    minRounding,
    maxRounding,
    minProfit,
    maxProfit,
    minPricing,
    maxPricing
  } = ecomStoreProduct?.ecomStoreProductVariants.reduce(
    (acc, variant) => {
      const {
        serviceFees,
        basePrice,
        price: storePrice,
        roundingAdjustment,
        pricingAmount,
        fkEcomPricingType
      } = variant;
      const { price: vendorPrice } = variant.ecomVendorProductVariant;

      acc.maxVendorPrice = Math.max(acc.maxVendorPrice, vendorPrice);
      acc.minVendorPrice =
        acc.minVendorPrice === 0
          ? vendorPrice
          : Math.min(acc.minVendorPrice, vendorPrice);

      acc.maxBasePrice = Math.max(acc.maxBasePrice, basePrice);
      acc.minBasePrice =
        acc.minBasePrice === 0
          ? basePrice
          : Math.min(acc.minBasePrice, basePrice);

      acc.maxStorePrice = Math.max(acc.maxStorePrice, storePrice);
      acc.minStorePrice =
        acc.minStorePrice === 0
          ? storePrice
          : Math.min(acc.minStorePrice, storePrice);

      acc.maxServiceFees = Math.max(acc.maxServiceFees, serviceFees);
      acc.minServiceFees =
        acc.minServiceFees === 0
          ? serviceFees
          : Math.min(acc.minServiceFees, serviceFees);

      acc.maxRounding = Math.max(acc.maxRounding, roundingAdjustment);
      acc.minRounding =
        acc.minRounding === 0
          ? roundingAdjustment
          : Math.min(acc.minRounding, roundingAdjustment);

      const profit = storePrice - vendorPrice - serviceFees;
      acc.maxProfit = Math.max(acc.maxProfit, profit);
      acc.minProfit =
        acc.minProfit === 0 ? profit : Math.min(acc.minProfit, profit);

      const maxPricingAmount = Math.max(
        acc.maxPricing?.amount || 0,
        pricingAmount
      );
      acc.maxPricing = {
        amount: maxPricingAmount,
        type:
          maxPricingAmount === pricingAmount
            ? fkEcomPricingType
            : acc.maxPricing?.type || ''
      };

      const minPricingAmount =
        acc.minPricing?.amount === 0
          ? pricingAmount
          : Math.min(acc.minPricing?.amount || 0, pricingAmount);
      acc.minPricing = {
        amount: minPricingAmount,
        type:
          minPricingAmount === pricingAmount
            ? fkEcomPricingType
            : acc.minPricing?.type || ''
      };

      return acc;
    },
    { ...defaultValues }
  ) || { ...defaultValues };

  return (
    <EditProductCard
      title="Pricing"
      titleAction={
        <Tooltip label="Edit pricing">
          <ActionIcon
            color="dark"
            onClick={onAction}
            size="lg"
            variant="subtle"
          >
            <Edit color="#000" size={20} />
          </ActionIcon>
        </Tooltip>
      }
    >
      <AppStack>
        <AppStack style={{ gap: 0 }}>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 8
            }}
          >
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              Vendor price:
            </AppText>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              <NumberFormatter
                decimalScale={2}
                fixedDecimalScale
                prefix="$"
                style={{ fontSize: 14 }}
                thousandSeparator
                value={minVendorPrice / 100}
              />
              {minVendorPrice !== maxVendorPrice && (
                <>
                  {' '}
                  -{' '}
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontSize: 14 }}
                    thousandSeparator
                    value={maxVendorPrice / 100}
                  />
                </>
              )}
            </AppText>
          </AppFlexbox>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 8
            }}
          >
            <AppText style={{ fontSize: 14, color: '#666' }}>Pricing:</AppText>
            <AppText style={{ fontSize: 14, color: '#666' }}>
              <NumberFormatter
                decimalScale={2}
                fixedDecimalScale
                prefix={
                  minPricing.type.toString() ===
                  ECOM_PRODUCT_PRICING_TYPE_ENUM.FLAT_ADJUSTMENT
                    ? '+$'
                    : '+'
                }
                style={{ fontSize: 14 }}
                suffix={
                  minPricing.type.toString() !==
                  ECOM_PRODUCT_PRICING_TYPE_ENUM.FLAT_ADJUSTMENT
                    ? '%'
                    : ''
                }
                thousandSeparator
                value={minPricing.amount / 100}
              />
              {minPricing.amount !== maxPricing.amount && (
                <>
                  {' '}
                  -{' '}
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix={
                      maxPricing.type.toString() ===
                      ECOM_PRODUCT_PRICING_TYPE_ENUM.FLAT_ADJUSTMENT
                        ? '+$'
                        : '+'
                    }
                    style={{ fontSize: 14 }}
                    suffix={
                      maxPricing.type.toString() !==
                      ECOM_PRODUCT_PRICING_TYPE_ENUM.FLAT_ADJUSTMENT
                        ? '%'
                        : ''
                    }
                    thousandSeparator
                    value={maxPricing.amount / 100}
                  />
                </>
              )}
            </AppText>
          </AppFlexbox>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 8
            }}
          >
            <AppText style={{ fontSize: 14, color: '#666' }}>
              Base Price:
            </AppText>
            <AppText style={{ fontSize: 14, color: '#666' }}>
              <NumberFormatter
                decimalScale={2}
                fixedDecimalScale
                prefix="$"
                style={{ fontSize: 14 }}
                thousandSeparator
                value={minBasePrice / 100}
              />
              {minBasePrice !== maxBasePrice && (
                <>
                  {' '}
                  -{' '}
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontSize: 14 }}
                    thousandSeparator
                    value={maxBasePrice / 100}
                  />
                </>
              )}
            </AppText>
          </AppFlexbox>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 8
            }}
          >
            <AppText style={{ fontSize: 14, color: '#666' }}>Fees:</AppText>

            <AppText style={{ fontSize: 14, color: '#666' }}>
              <NumberFormatter
                decimalScale={2}
                fixedDecimalScale
                prefix="$"
                style={{ fontSize: 14 }}
                thousandSeparator
                value={minServiceFees / 100}
              />
              {minServiceFees !== maxServiceFees && (
                <>
                  {' '}
                  -{' '}
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontSize: 14 }}
                    thousandSeparator
                    value={maxServiceFees / 100}
                  />
                </>
              )}
            </AppText>
          </AppFlexbox>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 8
            }}
          >
            <AppText style={{ fontSize: 14, color: '#666' }}>Rounding:</AppText>

            <AppText style={{ fontSize: 14, color: '#666' }}>
              <NumberFormatter
                decimalScale={2}
                fixedDecimalScale
                prefix="$"
                style={{ fontSize: 14 }}
                thousandSeparator
                value={minRounding / 100}
              />
              {minRounding !== maxRounding && (
                <>
                  {' '}
                  -{' '}
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontSize: 14 }}
                    thousandSeparator
                    value={maxRounding / 100}
                  />
                </>
              )}
            </AppText>
          </AppFlexbox>

          <Divider style={{ margin: '4px 0px' }} />

          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 8
            }}
          >
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              Store price:
            </AppText>

            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              <NumberFormatter
                decimalScale={2}
                fixedDecimalScale
                prefix="$"
                style={{ fontSize: 14 }}
                thousandSeparator
                value={minStorePrice / 100}
              />
              {minStorePrice !== maxStorePrice && (
                <>
                  {' '}
                  -{' '}
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontSize: 14 }}
                    thousandSeparator
                    value={maxStorePrice / 100}
                  />
                </>
              )}
            </AppText>
          </AppFlexbox>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 8
            }}
          >
            <AppText style={{ fontSize: 14, color: '#666' }}>Profit:</AppText>

            <AppText style={{ fontSize: 14, color: '#666' }}>
              <NumberFormatter
                decimalScale={2}
                fixedDecimalScale
                prefix="$"
                style={{ fontSize: 14 }}
                thousandSeparator
                value={minProfit / 100}
              />
              {minProfit !== maxProfit && (
                <>
                  {' '}
                  -{' '}
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontSize: 14 }}
                    thousandSeparator
                    value={maxProfit / 100}
                  />
                </>
              )}
            </AppText>
          </AppFlexbox>
        </AppStack>
      </AppStack>
    </EditProductCard>
  );
};

StoreProductPricingInfoCard.propTypes = {
  ecomStoreProduct: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired
};

export default StoreProductPricingInfoCard;
