import React from 'react';
import { Link } from 'react-router-dom';
import {
  ArrowLeft,
  ChevronDown,
  CreditCard,
  Dots,
  Download,
  Receipt,
  X
} from 'tabler-icons-react';
import { ActionIcon, Badge, Button, Menu, Skeleton } from '@mantine/core';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import ActionableIcon from '../../common/ActionableIcon';
import { formatUtcDate, singularPluralFormat } from '../../../helpers/format';
import OrderCustomerInfoCard from './OrderCustomerInfoCard';
import AppMenu from '../../common/AppMenu';
import OrderTransactionModal from './OrderTransactionModal';
import {
  ECOM_ORDER_PRODUCT_STATUS_ENUM,
  ECOM_ORDER_STATUS_ENUM,
  PAYMENT_TYPE_ENUM,
  VIEW_ACTIONS_ENUM
} from '../../../config/constants';
import OrderProductsInfoCard from './OrderProductsInfoCard';
import OrderPaymentInfoCard from './OrderPaymentInfoCard';
import OrderTrackingInfoCard from './OrderTrackingInfoCard';
import { translateStoreOrderData } from '../../../helpers/storeHelper';
import StoreOrderContributorsInfoCard from './StoreOrderContributorsInfoCard';

const StoreOrderDetailsView = ({ loading, ecomOrder, isbulkShipment }) => {
  const { isDesktopOrSmaller } = useMediaQueryIndex();
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();

  const {
    ecomOrderProducts: productData,
    groupedEcomOrderProducts,
    ecomCustomers
  } = translateStoreOrderData(ecomOrder);
  const regularProducts = productData.filter((f) => !f.isBulkShipment);
  const groupOrderProducts = productData.filter((f) => f.isBulkShipment);

  const totalUnitCost = productData.reduce((acc, p) => acc + p.totalCost, 0);
  const totalRegularProductUnitCost =
    regularProducts.reduce((acc, p) => acc + p.totalCost, 0) ?? 0;
  const totalBulkProductUnitCost =
    groupOrderProducts.reduce((acc, p) => acc + p.totalCost, 0) ?? 0;

  const vendorPayout = productData.reduce((r, c) => r + c.vendorCost, 0) || 0;
  const totalFees = ecomOrder
    ? productData.reduce((r, c) => r + c.serviceFees, 0) +
      ecomOrder.checkoutBalance.tieredPricingFeeInCents
    : 0;

  const orderPayments =
    ecomOrder?.ecomOrderPayments
      .filter(
        (f) =>
          f.fkRegFormSubmissionPaymentType.toString() !==
          PAYMENT_TYPE_ENUM.REFUND.toString()
      )
      .sort((a, b) => new Date(b.created) - new Date(a.created)) ?? [];

  const baseUrl = isbulkShipment
    ? '/merchant/group-orders'
    : '/merchant/orders';

  return !loading && ecomOrder ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <OrderTransactionModal
        ecomOrder={ecomOrder}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.VIEW
        }
        onClose={onCloseModal}
      />

      <AppFlexbox
        style={{
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to={baseUrl}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
              <AppText
                style={{
                  flex: 1,
                  fontSize: 24,
                  fontWeight: 700,
                  wordBreak: 'break-word'
                }}
              >
                Order #{ecomOrder.pkEcomOrder}
              </AppText>
              {ecomOrder.isEscrowPurchase && (
                <Badge color="dark" variant="light">
                  Escrow
                </Badge>
              )}
              {ecomOrder?.fkEcomOrderStatus.toString() ===
              ECOM_ORDER_STATUS_ENUM.ACCUMULATING.toString() ? (
                <Badge color="blue" variant="filled">
                  Accumulating
                </Badge>
              ) : (
                  ecomOrder.isBulkShipment
                    ? groupOrderProducts.every(
                        (p) =>
                          p.fkEcomOrderProductStatus?.toString() !==
                          ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING
                      )
                    : regularProducts.every(
                        (p) =>
                          p.fkEcomOrderProductStatus?.toString() !==
                          ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING
                      )
                ) ? (
                <Badge color="green" variant="filled">
                  Fulfilled
                </Badge>
              ) : (
                <Badge color="dark" variant="light">
                  Unfulfilled
                </Badge>
              )}
            </AppFlexbox>

            <AppText
              style={{
                fontSize: 12,
                lineHeight: '16px',
                color: '#666'
              }}
            >
              Placed{' '}
              {dayjs(formatUtcDate(ecomOrder.purchaseDate)).format(
                'MMMM D, YYYY'
              )}{' '}
              at {dayjs(formatUtcDate(ecomOrder.purchaseDate)).format('h:mm A')}
            </AppText>
          </AppStack>
        </AppFlexbox>

        <AppFlexbox style={{ alignItems: 'center', gap: 8, marginTop: 5 }}>
          <AppMenu
            control={
              <Button
                color="dark"
                rightSection={<ChevronDown size={18} />}
                size="compact-md"
                style={{ fontSize: 14 }}
                variant="light"
              >
                Actions
              </Button>
            }
          >
            <Menu.Item leftSection={<Download size={18} />} onClick={() => {}}>
              Export order
            </Menu.Item>
            <Menu.Item
              leftSection={<Receipt size={18} />}
              onClick={() => {
                onOpenModal(VIEW_ACTIONS_ENUM.VIEW);
              }}
            >
              View transaction
            </Menu.Item>
            <Menu.Item
              leftSection={<X color="#c40000" size={18} />}
              onClick={() => {}}
              style={{ color: '#c40000' }}
            >
              Cancel order
            </Menu.Item>
            <Menu.Item
              leftSection={<CreditCard color="#c40000" size={18} />}
              onClick={() => {}}
              style={{ color: '#c40000' }}
            >
              Refund order
            </Menu.Item>
          </AppMenu>
        </AppFlexbox>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          {ecomOrder.isBulkShipment ? (
            <>
              <OrderProductsInfoCard
                hideStatus
                productData={groupedEcomOrderProducts}
                showLimit
                title="Included products"
              />
              <OrderProductsInfoCard
                productData={productData}
                title="Variants"
              />
            </>
          ) : (
            <>
              <OrderProductsInfoCard
                productData={regularProducts}
                showVendorName
                title={ecomOrder.isBulkShipment ? 'Variants' : 'Products'}
              />

              {groupOrderProducts.length > 0 && (
                <OrderProductsInfoCard
                  description="These products are grouped with items from other orders and will be fulfilled separately."
                  productData={groupOrderProducts}
                  showVendorName
                  title="Grouped products"
                />
              )}
            </>
          )}

          <OrderPaymentInfoCard
            actionMenu={
              <AppMenu
                control={
                  <ActionIcon color="dark" variant="subtle">
                    <Dots />
                  </ActionIcon>
                }
              >
                <Menu.Item
                  leftSection={<Receipt size={18} />}
                  onClick={() => {
                    onOpenModal(VIEW_ACTIONS_ENUM.VIEW);
                  }}
                >
                  View transaction
                </Menu.Item>
                <Menu.Item
                  leftSection={<X color="#c40000" size={18} />}
                  onClick={() => {}}
                  style={{ color: '#c40000' }}
                >
                  Cancel order
                </Menu.Item>
                <Menu.Item
                  leftSection={<CreditCard color="#c40000" size={18} />}
                  onClick={() => {}}
                  style={{ color: '#c40000' }}
                >
                  Refund order
                </Menu.Item>
              </AppMenu>
            }
            payments={[
              ...(groupOrderProducts.length > 0 && regularProducts.length > 0
                ? [
                    {
                      key: 1,
                      group: 1,
                      label: 'Regular products',
                      color: '#666',
                      description: singularPluralFormat(
                        regularProducts.reduce((r, c) => r + c.quantity, 0),
                        'item',
                        'items'
                      ),
                      value: totalRegularProductUnitCost / 100
                    },
                    {
                      key: 2,
                      group: 1,
                      label: 'Grouped products',
                      color: '#666',
                      description: singularPluralFormat(
                        groupOrderProducts.reduce((r, c) => r + c.quantity, 0),
                        'item',
                        'items'
                      ),
                      value: totalBulkProductUnitCost / 100
                    }
                  ]
                : [
                    {
                      key: 1,
                      group: 1,
                      label: 'Subtotal',
                      color: '#666',
                      description: singularPluralFormat(
                        productData.reduce(
                          (acc, item) => acc + item.quantity,
                          0
                        ),
                        'item',
                        'items'
                      ),
                      value: totalUnitCost / 100
                    }
                  ]),
              ...(ecomOrder.checkoutBalance.tieredPricingFeeInCents > 0
                ? [
                    {
                      key: 3,
                      group: 1,
                      label: 'Platform fee',
                      color: '#666',
                      description: '-',
                      value:
                        ecomOrder.checkoutBalance.tieredPricingFeeInCents / 100
                    }
                  ]
                : []),
              {
                key: 4,
                group: 2,
                label: 'Total',
                weight: 500,
                description: '',
                value:
                  (ecomOrder.checkoutBalance.totalInCents || totalUnitCost) /
                  100
              },
              {
                key: 5,
                group: 2,
                label: 'Vendor payout',
                color: '#666',
                value: (vendorPayout / 100) * -1
              },
              {
                key: 6,
                group: 2,
                label: 'Fees',
                color: '#666',
                value: (totalFees / 100) * -1
              },
              {
                key: 7,
                group: 3,
                label: 'Profit',
                weight: 500,
                description: '',
                value:
                  ((ecomOrder.checkoutBalance.totalInCents || totalUnitCost) -
                    vendorPayout -
                    totalFees) /
                  100
              }
            ]}
          />

          <OrderTrackingInfoCard
            ecomOrderShipping={ecomOrder.ecomOrderShipping}
            loading={loading}
            productData={productData}
          />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard
            billingAddress={
              orderPayments.find((p) => !!p.ecomCustomerAddressBilling)
                ?.ecomCustomerAddressBilling
            }
            customerEmail={ecomOrder.ecomCustomer.email}
            customerName={ecomOrder.ecomCustomer.user?.name}
            customerUrl={`/merchant/customers/${ecomOrder.ecomCustomer.pkEcomCustomer}?from=orders&fromId=${ecomOrder.pkEcomOrder}`}
            shippingAddress={ecomOrder.ecomCustomerAddressShipping}
          />

          {ecomOrder.isBulkShipment && (
            <StoreOrderContributorsInfoCard
              ecomCustomers={ecomCustomers}
              ecomOrder={ecomOrder}
            />
          )}
        </AppStack>
      </AppFlexbox>
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to={baseUrl}
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 5 }}>
          <Skeleton height={36} width={200} />
          <Skeleton height={14} width={150} />
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderProductsInfoCard loading />
          <OrderPaymentInfoCard loading />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard loading />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

StoreOrderDetailsView.propTypes = {
  loading: PropTypes.bool.isRequired,
  ecomOrder: PropTypes.object,
  isbulkShipment: PropTypes.bool
};

export default StoreOrderDetailsView;
