/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Divider, NumberFormatter, Skeleton } from '@mantine/core';
import dayjs from 'dayjs';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import AppCard from '../../../../common/AppCard';
import { formatUtcDate } from '../../../../../helpers/format';
import WsCheckoutCartProductCard from '../checkoutContent/WsCheckoutCartProductCard';

const WsAccountOrderInfo = ({
  baseStoreUrl,
  onNavigate,
  mediaQueries,
  checkoutBalance
}) => {
  const { isTabletOrSmaller } = mediaQueries;
  const currency = checkoutBalance?.currency.toUpperCase() ?? 'CAD';

  return checkoutBalance ? (
    <>
      <AppCard
        radius={isTabletOrSmaller ? 0 : 'md'}
        shadow="xs"
        style={{ padding: 16, borderRadius: 8 }}
        withBorder
      >
        <AppFlexbox
          style={{
            alignItems: 'start',
            flexDirection: isTabletOrSmaller ? 'column-reverse' : 'row'
          }}
        >
          <AppStack
            style={{
              flex: 1,
              gap: 0,
              alignSelf: 'stretch'
            }}
          >
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap',
                  gap: 40
                }}
              >
                <AppText size="lg" style={{ fontSize: 16 }}>
                  Subtotal
                </AppText>
                <AppFlexbox style={{ gap: 10 }}>
                  <AppText size="lg" style={{ fontSize: 16 }}>
                    <NumberFormatter
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={checkoutBalance.subtotalInCents / 100}
                    />
                  </AppText>
                  <AppText size="lg" style={{ fontSize: 16 }}>
                    {currency}
                  </AppText>
                </AppFlexbox>
              </AppFlexbox>
              {checkoutBalance.discountInCents > 0 && (
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap'
                  }}
                >
                  <AppText size="lg" style={{ fontSize: 16 }}>
                    Discount
                  </AppText>
                  <AppFlexbox style={{ fontSize: 16, gap: 10 }}>
                    <AppText size="lg">
                      <NumberFormatter
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={(checkoutBalance.discountInCents / 100) * -1}
                      />
                    </AppText>
                    <AppText size="lg" style={{ fontSize: 16 }}>
                      {currency}
                    </AppText>
                  </AppFlexbox>
                </AppFlexbox>
              )}
              {checkoutBalance.taxInCents && (
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap'
                  }}
                >
                  <AppText size="lg" style={{ fontSize: 16 }}>
                    Taxes
                  </AppText>
                  <AppFlexbox style={{ gap: 10 }}>
                    <AppText size="lg" style={{ fontSize: 16 }}>
                      <NumberFormatter
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={checkoutBalance.taxInCents / 100}
                      />
                    </AppText>
                    <AppText size="lg" style={{ fontSize: 16 }}>
                      {currency}
                    </AppText>
                  </AppFlexbox>
                </AppFlexbox>
              )}
              {checkoutBalance.serviceFeeInCents > 0 && (
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap'
                  }}
                >
                  <AppText size="lg" style={{ fontSize: 16 }}>
                    Service Fee
                  </AppText>
                  <AppFlexbox style={{ gap: 10 }}>
                    <AppText size="lg" style={{ fontSize: 16 }}>
                      <NumberFormatter
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={checkoutBalance.serviceFeeInCents / 100}
                      />
                    </AppText>
                    <AppText size="lg" style={{ fontSize: 16 }}>
                      {currency}
                    </AppText>
                  </AppFlexbox>
                </AppFlexbox>
              )}
              {checkoutBalance.tieredPricingFeeInCents > 0 && (
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap'
                  }}
                >
                  <AppText size="lg" style={{ fontSize: 16 }}>
                    Service Fee
                  </AppText>
                  <AppFlexbox style={{ gap: 10 }}>
                    <AppText size="lg" style={{ fontSize: 16 }}>
                      <NumberFormatter
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={checkoutBalance.tieredPricingFeeInCents / 100}
                      />
                    </AppText>
                    <AppText size="lg" style={{ fontSize: 16 }}>
                      {currency}
                    </AppText>
                  </AppFlexbox>
                </AppFlexbox>
              )}

              <Divider style={{ margin: '10px 0px' }} />
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap'
                }}
              >
                <AppText size="lg" style={{ fontSize: 16 }} weight={700}>
                  Total
                </AppText>
                <AppFlexbox style={{ gap: 10 }}>
                  <AppText size="lg" style={{ fontSize: 16 }} weight={700}>
                    <NumberFormatter
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={checkoutBalance.totalInCents / 100}
                    />
                  </AppText>
                  <AppText size="lg" style={{ fontSize: 16 }} weight={700}>
                    {currency}
                  </AppText>
                </AppFlexbox>
              </AppFlexbox>
            </AppStack>
          </AppStack>
          <AppStack
            style={{
              flex: isTabletOrSmaller ? 1 : 2,
              gap: 0,
              alignItems: isTabletOrSmaller ? 'start' : 'end',
              alignSelf: 'stretch',
              justifyContent: 'space-between',
              textAlign: isTabletOrSmaller ? 'start' : 'end'
            }}
          >
            <AppStack
              style={{
                gap: 0,
                alignSelf: 'stretch',
                flexDirection: isTabletOrSmaller ? 'row' : 'column',
                justifyContent: 'space-between'
              }}
            >
              <AppText
                style={{ fontSize: 16, whiteSpace: 'nowrap' }}
                weight={700}
              >
                Order # {checkoutBalance.entityId}
              </AppText>
              <AppText style={{ fontSize: 16 }}>RESERVED</AppText>
            </AppStack>

            {checkoutBalance.purchasedAt && (
              <AppText style={{ fontSize: 16, textAlign: 'end' }}>
                Ordered on{' '}
                {dayjs(formatUtcDate(checkoutBalance.purchasedAt)).format(
                  'MMM D, YYYY'
                )}
              </AppText>
            )}
          </AppStack>
        </AppFlexbox>
      </AppCard>

      <AppStack
        style={{
          flex: 2,
          gap: isTabletOrSmaller ? 10 : 20,
          marginBottom: isTabletOrSmaller ? 10 : 0
        }}
      >
        {checkoutBalance.lineItems.map((lineItem) => (
          <WsCheckoutCartProductCard
            key={lineItem.entityId}
            baseStoreUrl={baseStoreUrl}
            isOrderHistory
            lineItem={lineItem}
            mediaQueries={mediaQueries}
            onNavigate={onNavigate}
          />
        ))}
      </AppStack>
    </>
  ) : (
    <>
      <AppCard withBorder>
        <AppFlexbox
          style={{
            padding: '0px 15px',
            alignItems: 'start',
            flexDirection: isTabletOrSmaller ? 'column-reverse' : 'row'
          }}
        >
          <AppStack
            style={{
              flex: 1,
              gap: 10,
              alignSelf: 'stretch'
            }}
          >
            <AppStack style={{ gap: 10 }}>
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap',
                  gap: 40
                }}
              >
                <Skeleton height={14} width={100} />
                <Skeleton height={14} width={100} />
              </AppFlexbox>
              <Divider style={{ margin: '10px 0px' }} />
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap'
                }}
              >
                <Skeleton height={14} width={100} />
                <Skeleton height={14} width={100} />
              </AppFlexbox>
            </AppStack>
          </AppStack>
          <AppStack
            style={{
              flex: isTabletOrSmaller ? 1 : 2,
              gap: 0,
              alignItems: 'end',
              alignSelf: 'stretch',
              justifyContent: 'space-between',
              flexDirection: isTabletOrSmaller ? 'row' : 'column'
            }}
          >
            <AppStack style={{ gap: 5 }}>
              <Skeleton height={14} width={100} />
              <Skeleton height={14} width={100} />
            </AppStack>
            <Skeleton height={14} width={130} />
          </AppStack>
        </AppFlexbox>
      </AppCard>

      <AppStack
        style={{
          flex: 2,
          gap: isTabletOrSmaller ? 10 : 20,
          width: isTabletOrSmaller ? '100%' : 'auto',
          marginBottom: isTabletOrSmaller ? 10 : 0
        }}
      >
        <WsCheckoutCartProductCard isOrderHistory mediaQueries={mediaQueries} />
      </AppStack>
    </>
  );
};

WsAccountOrderInfo.propTypes = {
  baseStoreUrl: PropTypes.string,
  checkoutBalance: PropTypes.object,
  isOrderDetailsLoading: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string
};

export default WsAccountOrderInfo;
