import React, { useContext } from 'react';
import { MERCHANT_NAVIGATION_LINKS } from './navigationLinks';
import Sidebar from './Sidebar';
import StoreSelectInput from '../stores/StoreSelectInput';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';

const StoreSidebar = () => {
  const { state } = useContext(StoreContext);

  return (
    <Sidebar
      navigation={MERCHANT_NAVIGATION_LINKS}
      selectInput={<StoreSelectInput placeholder="Select a store" />}
      state={state}
      subTitle="Merchant"
      title="SportsHeadz"
    />
  );
};

export default StoreSidebar;
