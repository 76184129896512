import { TextCaption } from 'tabler-icons-react';
import {
  ECOM_WEBSTORE_SECTION_BOUND_TYPE,
  ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM,
  ECOM_WEBSTORE_SECTION_TYPE_ENUM,
  INPUT_CONTROL_TYPE_ENUM,
  ECOM_ENTITY_TYPES
} from '../../../../../config/constants';
import WsImageWithText from './WsImageWithText';
import { ECOM_WEBSTORE_SECTION_BLOCKS } from '../../blocks/sectionBlockConfig';

const IMAGE_SIZE_ENUM = {
  ADAPT_TO_IMAGE: 'adapt-to-first-image',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  CUSTOM: 'custom'
};

const ALIGNMENT_HORIZONTAL_POSITION_ENUM = {
  LEFT: 'start',
  CENTER: 'center',
  RIGHT: 'end'
};

const ALIGNMENT_VERTICAL_POSITION_ENUM = {
  TOP: 'start',
  CENTER: 'center',
  BOTTOM: 'end'
};

const IMAGE_PLACEMENT_ENUM = {
  LEFT: 'left',
  RIGHT: 'right'
};

const alignmentHorizontalOptions = [
  {
    value: ALIGNMENT_HORIZONTAL_POSITION_ENUM.LEFT,
    label: 'Left'
  },
  {
    value: ALIGNMENT_HORIZONTAL_POSITION_ENUM.CENTER,
    label: 'Middle'
  },
  {
    value: ALIGNMENT_HORIZONTAL_POSITION_ENUM.RIGHT,
    label: 'Right'
  }
];

const alignmentVerticalOptions = [
  {
    value: ALIGNMENT_VERTICAL_POSITION_ENUM.TOP,
    label: 'Top'
  },
  {
    value: ALIGNMENT_VERTICAL_POSITION_ENUM.CENTER,
    label: 'Center'
  },
  {
    value: ALIGNMENT_VERTICAL_POSITION_ENUM.BOTTOM,
    label: 'Bottom'
  }
];

const configNameValues = {
  image: 'image',
  ecomStoreProduct: 'ecomStoreProduct',
  ecomStoreCollection: 'ecomStoreCollection',
  imageHeight: 'imageHeight',
  customImageHeight: 'customImageHeight',
  imageWidth: 'imageWidth',
  customImageWidth: 'customImageWidth',
  imagePlacement: 'imagePlacement',
  contentPosition: 'contentPosition',
  contentAlignment: 'contentAlignment',
  contentPadding: 'contentPadding',
  overlapContent: 'overlapContent',
  contentSpacing: 'contentSpacing',
  colorScheme: 'colorScheme',
  containerColorScheme: 'containerColorScheme',
  adaptImageHeight: 'adaptImageHeight',
  adaptImageWidth: 'adaptImageWidth',
  mobileContentAlignment: 'mobileContentAlignment'
};

const configDefaultValues = {
  [configNameValues.image]: '',
  [configNameValues.ecomStoreProduct]: '',
  [configNameValues.ecomStoreCollection]: '',
  [configNameValues.imageHeight]: IMAGE_SIZE_ENUM.ADAPT_TO_IMAGE,
  [configNameValues.customImageHeight]: '450',
  [configNameValues.imageWidth]: IMAGE_SIZE_ENUM.MEDIUM,
  [configNameValues.customImageWidth]: '300',
  [configNameValues.imagePlacement]: IMAGE_PLACEMENT_ENUM.LEFT,
  [configNameValues.contentPosition]: ALIGNMENT_VERTICAL_POSITION_ENUM.CENTER,
  [configNameValues.contentAlignment]: ALIGNMENT_HORIZONTAL_POSITION_ENUM.LEFT,
  [configNameValues.contentPadding]: '1',
  [configNameValues.overlapContent]: 'false',
  [configNameValues.contentSpacing]: '16',
  [configNameValues.colorScheme]: '1',
  [configNameValues.containerColorScheme]: '1',
  [configNameValues.adaptImageHeight]: '0',
  [configNameValues.adaptImageWidth]: '0',
  [configNameValues.mobileContentAlignment]:
    ALIGNMENT_HORIZONTAL_POSITION_ENUM.LEFT
};

const configOptions = {
  [configNameValues.image]: {
    label: 'Image',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreMedia,
    isVisible: (config) =>
      !config.ecomStoreProduct && !config.ecomStoreCollection
  },
  [configNameValues.ecomStoreProduct]: {
    label: 'Product',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreProduct,
    removable: true,
    isVisible: (config) => !config.image && !config.ecomStoreCollection
  },
  [configNameValues.ecomStoreCollection]: {
    label: 'Collection',
    type: 'entity',
    removable: true,
    allCollectionDisabled: true,
    entityType: ECOM_ENTITY_TYPES.ecomStoreCollection,
    isVisible: (config) => !config.ecomStoreProduct && !config.image
  },
  [configNameValues.imageHeight]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Content height',
    options: [
      {
        value: IMAGE_SIZE_ENUM.ADAPT_TO_IMAGE,
        label: 'Adapt to content'
      },
      { value: IMAGE_SIZE_ENUM.SMALL, label: 'Small' },
      { value: IMAGE_SIZE_ENUM.MEDIUM, label: 'Medium' },
      { value: IMAGE_SIZE_ENUM.LARGE, label: 'Large' }
    ]
  },
  [configNameValues.imageWidth]: {
    type: INPUT_CONTROL_TYPE_ENUM.DROP_DOWN,
    label: 'Desktop content width',
    options: [
      { value: IMAGE_SIZE_ENUM.SMALL, label: 'Small' },
      { value: IMAGE_SIZE_ENUM.MEDIUM, label: 'Medium' },
      { value: IMAGE_SIZE_ENUM.LARGE, label: 'Large' },
      { value: IMAGE_SIZE_ENUM.CUSTOM, label: 'Custom' }
    ]
  },
  [configNameValues.customImageWidth]: {
    type: INPUT_CONTROL_TYPE_ENUM.SLIDER,
    label: 'Layout',
    min: 20,
    max: 80,
    unit: '%',
    isVisible: (config) => config.imageWidth === IMAGE_SIZE_ENUM.CUSTOM
  },
  [configNameValues.imagePlacement]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Content placement',
    options: [
      { value: IMAGE_PLACEMENT_ENUM.LEFT, label: 'Left' },
      { value: IMAGE_PLACEMENT_ENUM.RIGHT, label: 'Right' }
    ]
  },
  [configNameValues.contentPosition]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Desktop content position',
    options: alignmentVerticalOptions
  },
  [configNameValues.contentAlignment]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Desktop content alignment',
    options: alignmentHorizontalOptions
  },
  [configNameValues.overlapContent]: {
    label: 'Overlap content',
    type: INPUT_CONTROL_TYPE_ENUM.CHECKBOX
  },
  [configNameValues.colorScheme]: {
    label: 'Color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  },
  [configNameValues.containerColorScheme]: {
    label: 'Container color scheme',
    type: 'entity',
    entityType: ECOM_ENTITY_TYPES.ecomStoreThemeColorScheme
  },
  [configNameValues.mobileContentAlignment]: {
    type: INPUT_CONTROL_TYPE_ENUM.BUTTON_GROUP,
    label: 'Mobile content alignment',
    category: 'Mobile layout',
    options: alignmentHorizontalOptions
  }
};

const config = {
  type: ECOM_WEBSTORE_SECTION_TYPE_ENUM.IMAGE_WITH_TEXT,
  title: 'Content with text',
  icon: TextCaption,
  allowedBounds: [ECOM_WEBSTORE_SECTION_BOUND_TYPE.BODY],
  Component: WsImageWithText,
  configOptions,
  maxItemCount: 4,
  blocks: [
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.HEADING
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.CAPTION
      ],
      defaultCount: 0,
      type: ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.CAPTION,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.TEXT
      ],
      defaultCount: 1,
      maxCount: 1
    },
    {
      ...ECOM_WEBSTORE_SECTION_BLOCKS[
        ECOM_WEBSTORE_SECTION_BLOCK_TYPE_ENUM.BUTTON_GROUP
      ],
      defaultCount: 1,
      maxCount: 1
    }
  ],
  defaultConfig: configDefaultValues,
  onFetchDataConfig: (sectionConfigValues) => ({
    type: sectionConfigValues.ecomStoreCollection
      ? 'ecomStoreCollections'
      : 'ecomStoreProduct',
    entityId:
      sectionConfigValues.ecomStoreCollection ||
      sectionConfigValues.ecomStoreProduct,
    filter: {}
  })
};

export {
  config,
  configOptions,
  configNameValues,
  configDefaultValues,
  IMAGE_SIZE_ENUM
};
