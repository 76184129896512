import React from 'react';
import PropTypes from 'prop-types';
import { Anchor, Badge, Button, Divider, NumberFormatter } from '@mantine/core';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Receipt } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import VendorOrderProductPreview from './VendorOrderProductPreview';
import { formatUtcDate } from '../../../helpers/format';
import { translateVendorOrderData } from '../../../helpers/vendorHelper';
import AppCard from '../../common/AppCard';
import { translateStoreOrderData } from '../../../helpers/storeHelper';

const OrderSummaryCard = ({
  ecomOrder,
  title,
  isMerchantView,
  isVendorOrder,
  urlBase,
  label
}) => {
  const { ecomOrderProducts: productData } = isVendorOrder
    ? translateVendorOrderData(ecomOrder)
    : translateStoreOrderData(ecomOrder);
  const totalUnitCount =
    productData?.reduce((acc, p) => acc + p.quantity, 0) ?? 0;
  const shippedProductCount = productData.reduce(
    (acc, p) => acc + p.shippedCount,
    0
  );
  const totalUnitCost = isVendorOrder
    ? productData?.reduce((acc, p) => acc + p.totalCost, 0) ?? 0
    : ecomOrder?.checkoutBalance.totalInCents;

  return ecomOrder ? (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack style={{ gap: 0 }}>
        <AppStack style={{ gap: 8, padding: 16 }}>
          <AppText style={{ fontSize: 16, fontWeight: 500 }}>
            {title || 'Latest order'}
          </AppText>
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppFlexbox style={{ alignItems: 'center', gap: 8 }}>
                <Anchor
                  component={Link}
                  style={{
                    color: 'dodgerblue',
                    fontSize: 16,
                    fontWeight: 500
                  }}
                  to={`${urlBase}/${ecomOrder.pkEcomOrder}`}
                >
                  #{ecomOrder.pkEcomOrder}
                </Anchor>
                {shippedProductCount >= totalUnitCount ? (
                  <Badge color="green" size="md">
                    Fulfilled
                  </Badge>
                ) : (
                  <Badge color="orange" size="md">
                    {shippedProductCount}/{totalUnitCount} Unfulfilled
                  </Badge>
                )}
              </AppFlexbox>

              <NumberFormatter
                decimalScale={2}
                fixedDecimalScale
                prefix="$"
                style={{ fontSize: 20, fontWeight: 500 }}
                thousandSeparator
                value={totalUnitCost / 100}
              />
            </AppFlexbox>
            <AppText style={{ color: '#666', fontSize: 13 }}>
              {dayjs(formatUtcDate(ecomOrder.purchaseDate)).format(
                'MMMM D, YYYY'
              )}{' '}
              at {dayjs(formatUtcDate(ecomOrder.purchaseDate)).format('h:mm A')}
            </AppText>
          </AppStack>

          <AppStack style={{ gap: 16, marginTop: 8 }}>
            {productData
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((p, index) => (
                <React.Fragment key={p.key}>
                  {index > 0 && <Divider />}
                  <AppFlexbox
                    style={{
                      gap: 16,
                      alignItems: 'start',
                      justifyContent: 'space-between'
                    }}
                  >
                    <VendorOrderProductPreview
                      product={p}
                      quantityIndicatorAmount={p.quantity}
                    />

                    <NumberFormatter
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      style={{ fontSize: 16, fontWeight: 500 }}
                      thousandSeparator
                      value={p.totalInCents / 100}
                    />
                  </AppFlexbox>
                </React.Fragment>
              ))}
          </AppStack>
        </AppStack>

        <Divider />
        <AppFlexbox
          style={{
            alignItems: 'center',
            justifyContent: 'end',
            padding: 16,
            gap: 16
          }}
        >
          <Anchor
            component={Link}
            style={{ color: 'dodgerblue', fontSize: 14 }}
            to={
              isMerchantView
                ? `${urlBase}?merchant=${ecomOrder.ecomStore.name}`
                : `${urlBase}?customer=${ecomOrder.ecomCustomer.email}`
            }
          >
            View all orders
          </Anchor>

          <Button
            color="dark"
            component={Link}
            size="sm"
            to={`${urlBase}/${ecomOrder.pkEcomOrder}`}
            variant="outline"
          >
            See details
          </Button>
        </AppFlexbox>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppFlexbox
        style={{
          flex: 1,
          padding: 16,
          justifyContent: 'space-between'
        }}
      >
        <AppStack style={{ gap: 8 }}>
          <AppText style={{ fontSize: 16, fontWeight: 500 }}>
            {title || 'Latest order'}
          </AppText>

          <AppText style={{ fontSize: 14, color: '#666' }}>
            No orders were found for this {label || 'customer'}.
          </AppText>
        </AppStack>
        <Receipt color="#000" size={60} />
      </AppFlexbox>
    </AppCard>
  );
};

OrderSummaryCard.propTypes = {
  ecomOrder: PropTypes.object,
  isMerchantView: PropTypes.bool,
  isVendorOrder: PropTypes.bool,
  label: PropTypes.string,
  title: PropTypes.number,
  urlBase: PropTypes.string
};

export default OrderSummaryCard;
