import React, { useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Users } from 'tabler-icons-react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import TableView from '../../common/TableView';
import { usePaginationFilter } from '../../../helpers/hooks';
import HeaderView from '../../common/HeaderView';
import { triggerNotification } from '../../../helpers/notification';
import { currencyFormat } from '../../../helpers/format';

const TABLE_COLUMNS = [
  {
    label: 'Customer',
    value: 'name',
    sortable: true
  },
  {
    label: 'Orders',
    value: 'orders',
    sortable: true
  },
  {
    label: 'Total spent',
    value: 'total_spent',
    sortable: true
  },
  {
    label: 'Amount refunded',
    value: 'total_refunded',
    sortable: true
  },
  {
    label: 'First purchase',
    value: 'first_purchase',
    sortable: true
  },
  {
    label: 'Last purchase',
    value: 'last_purchase',
    sortable: true
  }
];

const CustomerTable = ({ isVendorView }) => {
  const hasFetched = useRef(false);
  const { state: authState } = useContext(AuthContext);
  const { state: vendorState, fetchPaginatedVendorCustomers } = useContext(
    VendorContext
  );
  const { state: storeState, fetchPaginatedStoreCustomers } = useContext(
    StoreContext
  );
  const {
    pageIndex,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data: customerData,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  } = usePaginationFilter(
    {
      search: '',
      status: ''
    },
    (newFilter) => {
      if (isVendorView) {
        fetchPaginatedVendorCustomers(
          authState.pkEcomVendor,
          newFilter,
          null,
          (error) => {
            triggerNotification(error);
          }
        );
      }
      else {
        fetchPaginatedStoreCustomers(
          authState.pkEcomStore,
          newFilter,
          null,
          (error) => {
            triggerNotification(error);
          }
        );
      }
    },
    isVendorView
      ? vendorState.paginatedEcomVendorCustomers
      : storeState.paginatedEcomStoreCustomers
  );
  const loading = !hasFetched.current || dataLoading;
  const urlBase = isVendorView ? '/vendor/customers' : '/merchant/customers';

  useEffect(() => {
    if (authState.pkEcomVendor && isVendorView) {
      fetchPaginatedVendorCustomers(
        authState.pkEcomVendor,
        {},
        null,
        (error) => {
          triggerNotification(error);
        }
      );
      hasFetched.current = true;
    }
    else if (authState.pkEcomStore) {
      fetchPaginatedStoreCustomers(authState.pkEcomStore, {}, null, (error) => {
        triggerNotification(error);
      });
      hasFetched.current = true;
    }
  }, [authState.pkEcomVendor, authState.pkEcomStore]);

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: 'Customers',
          to: urlBase
        }
      ]}
    >
      <TableView
        columns={TABLE_COLUMNS}
        disabled={!loading && totalNoFilterCount === 0}
        disabledContent={{
          title: 'No customers yet.',
          description: 'Customers who have placed orders will appear here.',
          icon: <Users color="#000" size={125} />
        }}
        emptyMessage="No customers found."
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by customer',
            value: filter.search || '',
            onChange: (search) =>
              onFilterChange({
                ...filter,
                search
              })
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={!hasFetched.current || loading}
        noSort
        onChangeSortBy={onSortChange}
        onPageChange={onPageChange}
        onRefresh={onRefresh}
        pageIndex={pageIndex}
        rows={customerData.map((a) => ({
          key: a.pkEcomCustomer,
          columns: [
            {
              key: 1,
              label: a.user?.name,
              weight: 500,
              subLabel: a.email,
              component: Link,
              to: `${urlBase}/${a.pkEcomCustomer}`
            },
            {
              key: 2,
              label: a.ecomOrderCount
            },
            {
              key: 3,
              label: currencyFormat(a.totalSpent / 100)
            },
            {
              key: 4,
              label: currencyFormat(a.totalRefunded / 100)
            },
            {
              key: 5,
              label: dayjs(a.firstPurchase).format('MMM D, YYYY')
            },
            {
              key: 6,
              label: dayjs(a.lastPurchase).format('MMM D, YYYY')
            }
          ]
        }))}
        sortBy={filter.sort || TABLE_COLUMNS[0].value}
        tableTitle="Customers"
        totalCount={totalCount}
        totalPages={totalPages}
      />
    </HeaderView>
  );
};

CustomerTable.propTypes = { isVendorView: PropTypes.bool };

export default CustomerTable;
