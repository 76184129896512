import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NumberInput } from '@mantine/core';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import { triggerNotification } from '../../../helpers/notification';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';

const StoreProductGoalModal = ({ ecomStoreProduct, isOpen, onClose }) => {
  const { state: authState } = useContext(AuthContext);
  const { updateStoreProducts } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    fundraisingQuantityGoal: null,
    loading: false
  });
  const hasMinimumQuantity = !!ecomStoreProduct?.ecomVendorProduct
    .minimumQuantity;
  const requiresExactMinimumQuantity =
    ecomStoreProduct?.ecomVendorProduct.requireExactMinimum;

  useEffect(() => {
    if (isOpen && ecomStoreProduct) {
      setFormState({
        fundraisingQuantityGoal:
          ecomStoreProduct?.fundraisingQuantityGoal ?? null,
        loading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        isLoading: formState.loading,
        onCancel: onClose,
        onSubmit: () => {
          setFormState({
            ...formState,
            loading: true
          });
          updateStoreProducts(
            authState.pkEcomStore,
            {
              ecomStoreProducts: [
                {
                  uuid: ecomStoreProduct.uuid,
                  fundraisingQuantityGoal: formState.fundraisingQuantityGoal
                    ? formState.fundraisingQuantityGoal
                    : -1
                }
              ],
              selectFirstItem: true
            },
            () => {
              onClose();
              triggerNotification('Goal updated!', 'success');
            },
            (e) => {
              triggerNotification(e);
              setFormState({
                ...formState,
                loading: false
              });
            }
          );
        },
        submitTitle: 'Save changes'
      }}
      isOpen={isOpen && !!ecomStoreProduct}
      onClose={onClose}
      size={450}
      title="Set product goal"
    >
      {ecomStoreProduct && (
        <AppStack style={{ gap: 16 }}>
          {requiresExactMinimumQuantity ? (
            <>
              <AppCard radius={8} withBorder>
                <AppStack style={{ gap: 8 }}>
                  <AppText style={{ fontSize: 14 }}>
                    <span style={{ fontWeight: 500 }}>
                      Required group order size:
                    </span>{' '}
                    {ecomStoreProduct.ecomVendorProduct.minimumQuantity}
                  </AppText>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    This product requires each group order to contain exactly{' '}
                    {ecomStoreProduct.ecomVendorProduct.minimumQuantity} items
                    to qualify for purchase.
                  </AppText>
                </AppStack>
              </AppCard>
              <AppText
                style={{
                  fontSize: 14,
                  color: '#666',
                  fontWeight: 500,
                  textAlign: 'center',
                  fontStyle: 'italic',
                  padding: '0px 24px'
                }}
              >
                Please enter the number of group orders you aim to achieve as
                your goal.
              </AppText>
            </>
          ) : hasMinimumQuantity ? (
            <>
              <AppCard radius={8} withBorder>
                <AppStack style={{ gap: 8 }}>
                  <AppText style={{ fontSize: 14 }}>
                    <span style={{ fontWeight: 500 }}>
                      Minimum group order size:
                    </span>{' '}
                    {ecomStoreProduct.ecomVendorProduct.minimumQuantity}
                  </AppText>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    This product requires a minimum of{' '}
                    {ecomStoreProduct.ecomVendorProduct.minimumQuantity} items
                    per group order to qualify for purchase. Please enter the
                    number of group orders you aim to achieve as your goal.
                  </AppText>
                </AppStack>
              </AppCard>
              <AppText
                style={{
                  fontSize: 14,
                  color: '#666',
                  fontWeight: 500,
                  textAlign: 'center',
                  fontStyle: 'italic',
                  padding: '0px 24px'
                }}
              >
                Please enter the number of group orders you aim to achieve as
                your goal.
              </AppText>
            </>
          ) : (
            <AppText
              style={{
                fontSize: 14,
                color: '#666',
                fontWeight: 500,
                textAlign: 'center',
                fontStyle: 'italic',
                padding: '0px 24px'
              }}
            >
              Please enter the number of units you aim to achieve as your goal.
            </AppText>
          )}

          <NumberInput
            label={hasMinimumQuantity ? '(# of Group orders)' : '(# of units)'}
            onChange={(v) =>
              setFormState({ ...formState, fundraisingQuantityGoal: v })
            }
            placeholder="Enter quantity"
            value={formState.fundraisingQuantityGoal}
          />
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

StoreProductGoalModal.propTypes = {
  ecomStoreProduct: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default StoreProductGoalModal;
