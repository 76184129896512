import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import StoreOrderTable from './StoreOrderTable';
import StoreOrderViews from '../orders/StoreOrderViews';

const StoreOrdersView = () => (
  <Routes>
    <Route
      element={<StoreOrderViews baseUrl="/merchant/orders" />}
      path="/:pkEcomOrder/*"
    />
    <Route element={<StoreOrderTable />} path="/" />
    <Route element={<Navigate replace to="/merchant/orders" />} path="*" />
  </Routes>
);

export default StoreOrdersView;
