import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Loader,
  Menu,
  Overlay,
  ScrollArea,
  Skeleton
} from '@mantine/core';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import AnalyticsLineChart from './AnalyticsLineChart';
import AnalyticsBarChart from './AnalyticsBarChart';
import AppCard from '../../common/AppCard';
import * as classes from '../../../styles/nestedStyles.module.css';
import AppMenu from '../../common/AppMenu';
import { currencyFormat, getDateRangeLabel } from '../../../helpers/format';

const convertHeaderDataValue = (value, dataType) => {
  switch (dataType) {
    case 'currency':
      return Number(value) / 100;
    case 'number':
      return Number(value);
    case 'percentage':
      return `${Number(value)?.toFixed(2)}%`;
    default:
      return value ?? 'N/A';
  }
};

const convertReportData = (dataKey, data, index, dataLabel, dataType) => {
  const isDate = dataKey === 'date';
  const seenDates = new Set();
  const includeHours = data.some((d) => {
    const date = dayjs(d[0]).format('YYYY-MM-DD');
    if (seenDates.has(date)) return true;
    seenDates.add(date);
    return false;
  });

  return data.map((d) => {
    const value = convertHeaderDataValue(d[index], dataType);

    return {
      [dataKey]: isDate
        ? dayjs(d[0]).format(`MMM D, YYYY${includeHours ? ' h:mm A' : ''}`)
        : d[0],
      [dataLabel]: value
    };
  });
};

const getReportResultChartData = (
  reportResult,
  dataKey,
  selectedHeaderField,
  mainDataLabel,
  compareDataLabel
) => {
  const selectedHeaderIndex = reportResult.report.headers.findIndex(
    (f) => f.field === selectedHeaderField
  );
  if (selectedHeaderIndex === -1) {
    return [];
  }

  const selectedHeader = reportResult.report.headers[selectedHeaderIndex];

  const mainData = reportResult?.report.data
    ? convertReportData(
        dataKey,
        reportResult.report.data,
        selectedHeaderIndex,
        mainDataLabel,
        selectedHeader.dataType
      )
    : null;

  const compareData = reportResult?.report.compareData
    ? convertReportData(
        dataKey,
        reportResult.report.compareData,
        selectedHeaderIndex,
        compareDataLabel,
        selectedHeader.dataType
      )
    : null;

  const data = mainData;
  if (compareData) {
    compareData.forEach((d, i) => {
      const dataItem = data[i];
      if (dataItem) {
        dataItem[compareDataLabel] = d[compareDataLabel];
      }
      else {
        data.push({
          [dataKey]: d[dataKey],
          [compareDataLabel]: d[compareDataLabel]
        });
      }
    });
  }

  return data.length === 1 ? [...data, ...data] : data;
};

const AnalyticsChartBase = ({
  chart,
  chartDataOverrideFunction,
  reportResult,
  redirectPath,
  chartableHeaders,
  selectedChartField,
  onChangeChartHeader,
  loading,
  loadingVariant,
  dateRange,
  compareDateRange
}) => {
  const mainDataLabel = getDateRangeLabel(dateRange.start, dateRange.end);
  const compareDataLabel = compareDateRange
    ? getDateRangeLabel(compareDateRange.start, compareDateRange.end)
    : null;
  const selectedHeader =
    reportResult?.report.headers.find((f) => f.field === selectedChartField) ||
    {};
  const isCurrency = selectedHeader.dataType === 'currency';
  const chartDataKey = chart.dataKey || 'date';

  const isChartLoading =
    loading || !reportResult || !selectedChartField || !chart;

  const chartData = !isChartLoading
    ? chartDataOverrideFunction
      ? chartDataOverrideFunction(mainDataLabel, compareDataLabel)
      : getReportResultChartData(
          reportResult,
          chartDataKey,
          selectedHeader.field,
          mainDataLabel,
          compareDataLabel
        )
    : null;

  const totalDataAmounts =
    selectedHeader?.averageValue && selectedHeader?.compareAverageValue
      ? {
          total: convertHeaderDataValue(
            selectedHeader.averageValue,
            selectedHeader.dataType
          ),
          compareTotal: convertHeaderDataValue(
            selectedHeader.compareAverageValue,
            selectedHeader.dataType
          )
        }
      : chartData?.reduce(
          (r, c) => ({
            total: r.total + (c[mainDataLabel] || 0),
            compareTotal: r.compareTotal + (c[compareDataLabel] || 0)
          }),
          {
            total: 0,
            compareTotal: 0
          }
        ) ?? { total: 0, compareTotal: 0 };
  const { total, compareTotal } =
    !loading && chart?.getOverviewData
      ? chart.getOverviewData(chartData, mainDataLabel, compareDataLabel)
      : {};

  const selectedChartHeader =
    selectedChartField && chartableHeaders
      ? chartableHeaders.find((h) => h.value === selectedChartField)
      : null;

  return loadingVariant || !isChartLoading ? (
    <AppCard
      radius="md"
      shadow="lg"
      style={{
        flex: 1,
        width: '100%',
        height: '100%',
        minHeight: '100%',
        padding: 0
      }}
      withBorder
    >
      {loading && (
        <Overlay backgroundOpacity={0.3} color="#000">
          <AppStack
            style={{
              width: '100%',
              height: '100%',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Loader color="white" size={32} />
          </AppStack>
        </Overlay>
      )}

      <AppStack style={{ flex: 1, gap: 0 }}>
        {chartableHeaders && onChangeChartHeader ? (
          <AppMenu
            closeOnClickOutside
            closeOnItemClick
            control={
              <AppFlexbox
                className={classes['hover-action-card']}
                component={redirectPath ? Link : null}
                style={{
                  padding: 8,
                  cursor: 'pointer',
                  color: '#000',
                  textDecoration: 'none'
                }}
                to={redirectPath}
              >
                <AppText
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: '22px',
                    borderBottom: 'dotted 2px #B1B1B1'
                  }}
                >
                  {selectedChartHeader?.label ?? chart.title}
                </AppText>
              </AppFlexbox>
            }
            offset={0}
            position="bottom-start"
            radius={0}
            shadow="xl"
            styles={{ dropdown: { padding: 0 } }}
            width="target"
          >
            <ScrollArea.Autosize
              style={{ width: '100%', height: '100%', maxHeight: 300 }}
            >
              <AppStack style={{ gap: 0, flex: 1 }}>
                {chartableHeaders.map((h) => (
                  <Menu.Item
                    key={h.value}
                    onClick={(e) => {
                      e.preventDefault();
                      onChangeChartHeader(h);
                    }}
                  >
                    <AppFlexbox
                      style={{
                        alignItems: 'center',
                        gap: 12,
                        padding: '5px 0px'
                      }}
                    >
                      <Checkbox
                        checked={selectedChartField === h.value}
                        onChange={() => {}}
                      />
                      <AppText style={{ fontSize: 14 }}>{h.label}</AppText>
                    </AppFlexbox>
                  </Menu.Item>
                ))}
              </AppStack>
            </ScrollArea.Autosize>
          </AppMenu>
        ) : (
          <AppFlexbox
            className={redirectPath && classes['hover-action-card']}
            component={redirectPath ? Link : null}
            style={{
              padding: 8,
              cursor: redirectPath ? 'pointer' : 'normal',
              color: '#000',
              textDecoration: 'none'
            }}
            to={redirectPath}
          >
            <AppText
              style={{
                fontSize: 16,
                fontWeight: 500,
                lineHeight: '22px',
                borderBottom: 'dotted 2px #B1B1B1'
              }}
            >
              {chart.title}
            </AppText>
          </AppFlexbox>
        )}

        {!chart.noOverview && (
          <AppFlexbox
            style={{
              gap: 5,
              alignItems: 'center',
              textAlign: 'center',
              padding: 8
            }}
          >
            <AppText
              style={{
                fontSize: 24,
                fontWeight: 500,
                lineHeight: '22px'
              }}
            >
              {total ||
                (isCurrency
                  ? currencyFormat(totalDataAmounts.total)
                  : totalDataAmounts.total)}
            </AppText>
            {compareDataLabel && (
              <AppText
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                  lineHeight: '22px',
                  color: '#B1B1B1'
                }}
              >
                {' - '}
                {compareTotal ||
                  (isCurrency
                    ? currencyFormat(totalDataAmounts.compareTotal)
                    : totalDataAmounts.compareTotal)}
              </AppText>
            )}
          </AppFlexbox>
        )}

        <AppFlexbox
          style={{
            flex: 1,
            height: '100%',
            minHeight: 300,
            width: '100%',
            padding: 8,
            marginTop: 'auto',
            justifyContent: 'center',
            overflow: 'hidden'
          }}
        >
          {chart.type === 'line' ? (
            <AnalyticsLineChart
              chartData={chartData}
              compareDataLabel={compareDataLabel}
              dataKey={chartDataKey}
              dataType={selectedHeader.dataType}
              mainDataLabel={mainDataLabel}
            />
          ) : (
            chart.type === 'bar' && (
              <AnalyticsBarChart
                chartData={chartData}
                compareDataLabel={compareDataLabel}
                dataKey={chartDataKey}
                dataType={selectedHeader.dataType}
                mainDataLabel={mainDataLabel}
              />
            )
          )}
        </AppFlexbox>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard style={{ minHeight: '100%' }}>
      <AppStack style={{ flex: 1, gap: 8 }}>
        <AppFlexbox style={{}}>
          <Skeleton height={24} width="20%" />
        </AppFlexbox>
        <AppFlexbox style={{}}>
          <Skeleton height={30} width="30%" />
        </AppFlexbox>
        <AppFlexbox style={{}}>
          <Skeleton height={250} width="100%" />
        </AppFlexbox>
      </AppStack>
    </AppCard>
  );
};

AnalyticsChartBase.propTypes = {
  chart: PropTypes.object,
  chartData: PropTypes.array,
  chartDataOverrideFunction: PropTypes.func,
  chartableHeaders: PropTypes.array,
  compareDataLabel: PropTypes.string,
  compareDateRange: PropTypes.object,
  dateRange: PropTypes.object,
  loading: PropTypes.bool,
  loadingVariant: PropTypes.string,
  mainDataLabel: PropTypes.string,
  onChangeChartHeader: PropTypes.func,
  redirectPath: PropTypes.string,
  reportResult: PropTypes.object,
  selectedChartField: PropTypes.string
};

export default AnalyticsChartBase;
